const GaestebeitragData = {
	contact: {
		name: "Dirk Minor",
		street: "Kurfürstenstraße 1",
		city: "54516 Wittlich",
		phone: "tel:06571107269",
		phoneFormat: "06571 / 107 - 269",
		mail: "dirk.minor@vg-wittlich-land.de",
	},
};

export default GaestebeitragData;
