import { toast } from "react-toastify";

export default class Notify {
	public static e = (message: string) => {
		toast.error(message, {
			position: "bottom-right",
		});
	};

	public static s = (message: string) => {
		toast.success(message, {
			position: "bottom-right",
		});
	};

	public static w = (message: string) => {
		toast.warning(message, {
			position: "bottom-right",
		});
	};
}
