/* eslint-disable @typescript-eslint/ban-types */
import React, { Component } from "react";
import "./TicketScanner.scss";
import { Card, Select, Table, Dialog, Boolean, Button } from "@onedash/tools";
import { ValueLabelPair } from "@onedash/tools/dist/ToolTypes";
import moment from "moment";
import Backend from "../../Utils/Backend/Backend";
import Action from "../../Utils/Redux/Action";
import Sidebar from "../../Pages/Dashboard/Sidebar/Sidebar";
import ENV from "../../Utils/ENV";
import Notify from "../../Utils/Notify";
import VGCoronaDataEntry from "./VGCoronaDataEntry";

import VGCoronaBookingTableHeaders from "./TicketTableHeaders";
import DateUtils from "../../Utils/DateUtils";
import Storage from "../../Utils/Storage";

interface TicketTableProps {}
const INTERVALL = 30000;

interface StatisticProps {
	title: string;
	value: number;
	of?: number;
}

const Statistic = ({ title, value, of }: StatisticProps) => (
	<div className="stat">
		<p className="title">{title}</p>
		<div className="value-obj">
			<div className="value">{value}</div>
			{of && (
				<>
					<div className="progress-bar">
						<span style={{ width: `${(value / of) * 100}%` }} />
					</div>
					<div className="of">{of}</div>
				</>
			)}
		</div>
	</div>
);

class TicketTable extends Component<TicketTableProps> {
	sidebar = React.createRef<Sidebar>();

	addDialog = React.createRef<Dialog>();

	dataEntry = React.createRef<VGCoronaDataEntry>();

	toggleSendMail = React.createRef<Boolean>();

	selectedTicket: SwimTicket | undefined;

	statsIntervall: undefined | number;

	initialLoad = true;

	state = {
		poolData: undefined as PoolData | undefined,
		selectedPool: undefined as SwimmingPool | undefined,
		selectedSlot: undefined as PoolSlot | undefined,
		tableValues: undefined as undefined | any[],
		newEntryValid: false,
		selectedDay: undefined as undefined | { dayName: string; date: number; slots: ValueLabelPair[] },
	};

	componentDidMount() {
		this.loadPoolData();
		this.statsIntervall = setInterval(this.loadPoolData, INTERVALL) as any;
	}

	componentWillUnmount() {
		if (this.statsIntervall) clearInterval(this.statsIntervall);
	}

	loadPoolData = async () => {
		const poolData = await Backend.get<PoolData>("/vg/corona");
		this.setState({
			poolData: poolData.data,
		});
		const poolStorage = Storage.get("LATEST_POOL");
		if ((poolData.data.pools.length === 1 || poolStorage) && !this.state.selectedPool && !this.state.selectedSlot) {
			let poolID;
			if (poolData.data.pools.length === 1) {
				poolID = poolData.data.pools[0].id;
			} else {
				poolID = poolStorage;
			}
			if (poolID) this.selectPool(poolID);
		}

		if (this.state.selectedPool && this.state.selectedDay && this.state.selectedSlot) {
			this.selectPool(this.state.selectedPool.id);
			if (!this.state.selectedPool || !this.state.selectedPool.slots) {
				this.setState({
					selectedPool: undefined,
					selectedSlot: undefined,
				});
				return;
			}
			const daySlots = this.state.selectedPool.slots;
			let selectedSlot;
			Object.keys(daySlots).forEach((dayName) => {
				const slots = daySlots[dayName];
				const slot = slots.find((x) => x.from === this.state.selectedSlot?.from);
				if (slot) {
					selectedSlot = slot;
				}
			});
			if (selectedSlot) this.selectBookings(selectedSlot);
		}
		Action.tabLoaded();
	};

	selectPool = async (poolID: string) => {
		// eslint-disable-next-line react/no-access-state-in-setstate
		const selectedPool = this.state.poolData?.pools.find((x) => x.id === poolID);
		this.setState(
			{
				selectedPool,
			},
			() => {
				// Preselect time
				const daySlots = selectedPool?.slots ?? {};
				// Default diff is one day
				let selectedSlot: undefined | PoolSlot;
				let selectedDay: undefined | number;
				if (!Array.isArray(daySlots)) {
					let diff = 24 * 60 * 60 * 1000;
					Object.keys(daySlots).forEach((dayName) => {
						const slots = daySlots[dayName];
						// Search current active slot
						let slot = slots.find((x) => x.from < new Date().getTime() && x.to > new Date().getTime());

						// If no slot is active => select the next free
						if (!selectedSlot && !slot) {
							const today = new Date().getTime();
							slots.forEach((s) => {
								if (s.from > today && s.from - today < diff) {
									diff = s.from - today;
									slot = s;
								}
							});
						}
						if (slot) {
							selectedSlot = slot;
							selectedDay = DateUtils.setTime(moment(slot.from)).toDate().getTime();
						}
					});
				}
				if (selectedDay && selectedSlot && !this.state.selectedSlot) {
					this.selectDay(selectedDay);
					this.selectTimeslot(selectedSlot.from);
				}
			}
		);
	};

	onPoolSelect = (obj: { value: any; name: string }) => {
		this.setState({ selectedDay: undefined, selectedSlot: undefined }, () => {
			this.selectPool(obj.value);
			Storage.set("LATEST_POOL", obj.value);
		});
	};

	onTimeslotSelect = (obj: { value: number; name: string }) => {
		this.selectTimeslot(obj.value);
	};

	selectTimeslot = (timeslot: number) => {
		let selectedSlot;
		const daySlots = this.state.selectedPool?.slots ?? {};
		Object.keys(daySlots).forEach((dayName) => {
			const slots = daySlots[dayName];
			const slot = slots.find((x) => x.from === timeslot);
			if (slot) {
				selectedSlot = slot;
			}
		});
		if (selectedSlot) this.selectBookings(selectedSlot);
	};

	selectBookings = async (slot: PoolSlot) => {
		const response = await Backend.post<SwimTicket[]>(`/vg/corona/data/${this.state.selectedPool?.id}/${slot.from}`);
		const tableValues = response.data;
		if (this.sidebar.current && this.initialLoad === true) {
			this.initialLoad = false;
			this.sidebar.current.close();
		}
		tableValues.forEach((tv, i) => {
			(tv as any).id = i;
		});

		this.setState({ tableValues, selectedSlot: slot });
	};

	removeMember = (e: any, memberName: string) => {
		e.preventDefault();
		const ticketID = this.selectedTicket?.ticketID;

		Backend.delete(`/vg/corona/ticket/${ticketID}`, { memberName })
			.then(() => {
				Notify.s(`${memberName} wurde erfolgreich gelöscht`);
			})
			.catch((err) => {
				Notify.e(err.data);
			});
	};

	memberFormatingFunction = (members?: string[]) => {
		return (
			<ul>
				{members?.map((member, i) => (
					<li key={i as any}>
						<button onClick={(e) => this.removeMember(e, member)}>
							<i className="im im-minus-circle" />
						</button>
						<div className="name">{member}</div>
					</li>
				))}
			</ul>
		);
	};

	stateFormattingFunction = (state: string, shortForm?: boolean) => {
		let status = "";
		let btnText = "";
		// eslint-disable-next-line default-case
		switch (state) {
			case "inside":
				status = "🏠 Eingelassen";
				btnText = "Ausstempeln";
				break;
			case "open":
				status = "💤 Reserviert";
				btnText = "Einstempeln";
				break;
			case "redeemed":
				status = "✅ Einrichtung verlassen";
				break;
			case "storno":
				status = "❌ Storniert";
				break;
		}

		const t = (
			<>
				{status} {!shortForm && btnText !== "" && <button onClick={() => this.redeemTicket()}>{btnText}</button>}
			</>
		);
		return t;
	};

	redeemTicket = () => {
		const ticketID = this.selectedTicket?.ticketID;
		Backend.get(`/tickets/redeem/${ticketID}`).then(() => {
			this.loadPoolData();
		});
	};

	addReservation = () => {
		const { selectedPool, selectedSlot } = this.state;
		if (!selectedPool || !selectedSlot) return;
		// Get Data entry data
		if (!this.dataEntry.current) return;

		// Should a mail be send?
		const mailSendValue = this.toggleSendMail.current?.getValue()?.value;
		const isTest = mailSendValue === undefined ? true : !mailSendValue;

		const households = this.dataEntry.current.getHouseholds();
		this.dataEntry.current.clearData();
		this.toggleSendMail.current?.reset();
		// isTest is used to send no mail
		const data = {
			isTest,
			personalData: {},
			values: {
				poolID: selectedPool.id,
				timeSlot: selectedSlot.from,
				households,
			},
		};

		Backend.post<any>(`/form/submit/swimTicket`, data)
			.catch((ex) => {
				Notify.e(ex.data);
			})
			.then(() => {
				this.loadPoolData();
			});
	};

	triggerNewEntry = () => {
		if (this.addDialog.current) this.addDialog.current.show();
	};

	onDateChange = (obj: { value: any; name: string }) => {
		this.setState({ selectedSlot: undefined }, () => this.selectDay(obj.value));
	};

	selectDay = (dayTimestamp: number) => {
		const { selectedPool } = this.state;
		const dayName = moment(dayTimestamp).format("LL");
		this.setState({
			selectedDay: {
				date: dayTimestamp,
				dayName: moment(dayTimestamp).format("LL"),
				slots: selectedPool?.slots[dayName].map((slot) => {
					return {
						label: `${moment(slot.from).format("HH:mm")} - ${moment(slot.to).format("HH:mm")}`,
						value: slot.from,
					};
				}),
			},
		});
	};

	render() {
		const { selectedPool, selectedDay, selectedSlot, poolData, tableValues } = this.state;
		const pools: ValueLabelPair[] = [];
		const days: ValueLabelPair[] = [];
		poolData?.pools?.forEach((pool) => {
			pools.push({
				label: pool.name,
				value: pool.id,
			});
		});

		let sumPeople = 0;

		if (selectedDay?.dayName) {
			const slots = selectedPool?.slots[selectedDay?.dayName];
			slots?.forEach((slot) => {
				sumPeople += slot.options.inside.persons + slot.options.redeemed.persons;
			});
		}

		Object.keys(selectedPool?.slots ?? {}).forEach((dayName) => {
			const daySlots = selectedPool?.slots[dayName] ?? [];
			daySlots.forEach((slot) => {
				if (!days.find((x) => x.value === DateUtils.setTime(moment(slot.from)).toDate().getTime())) {
					days.push({
						label: moment(slot.from).format("ll"),
						value: DateUtils.setTime(moment(slot.from)).toDate().getTime(),
					});
				}
			});
		});

		const pdfLink = `${ENV.default.apiURL}/${Backend.getAppID()}/vg/corona/data/${selectedPool?.id}/${selectedSlot?.from}`;

		const tableHeaders = VGCoronaBookingTableHeaders();
		const membersTHeader = tableHeaders.find((x) => x.columnName === "members");
		if (membersTHeader) {
			membersTHeader.formattingFunction = this.memberFormatingFunction;
		}
		const stateTHeader = tableHeaders.find((x) => x.columnName === "state");
		if (stateTHeader) {
			stateTHeader.formattingFunction = this.stateFormattingFunction;
		}

		return (
			<>
				<Sidebar ref={this.sidebar}>
					<Card>
						<Select
							native
							onChange={this.onPoolSelect}
							options={pools}
							value={selectedPool?.id}
							name="poolID"
							label="Wählen Sie eine Einrichtung"
							required
						/>
					</Card>
					{this.state.selectedPool && (
						<Card>
							<Select
								native
								value={selectedDay?.date}
								onChange={this.onDateChange}
								options={days}
								name="daySlot"
								label="Wählen Sie ein Datum"
								required
							/>
							<Select
								native
								value={selectedSlot?.from}
								onChange={this.onTimeslotSelect}
								options={selectedDay?.slots ?? []}
								name="timeSlot"
								disabled={selectedDay === undefined}
								label="Wählen Sie die Uhrzeit"
								required
							/>
						</Card>
					)}
					{selectedSlot && (
						<Card className="corona-swimming-statistics">
							{selectedSlot.to <= new Date().getTime() ? (
								<Statistic
									title="In der Einrichtung gewesen [Personen]"
									value={selectedSlot.options.inside.persons + selectedSlot.options.redeemed.persons}
									of={selectedSlot.options.capacity}
								/>
							) : (
								<Statistic
									title="Aktuell in der Einrichtung [Personen]"
									value={selectedSlot.options.inside.persons}
									of={selectedSlot.options.capacity}
								/>
							)}
							{selectedSlot.to > new Date().getTime() && (
								<Statistic
									title="Reservierungen [Personen]"
									value={selectedSlot.options.reserved.persons}
									of={selectedSlot.options.capacity}
								/>
							)}
							<Statistic title="Stornierungen [Tickets]" value={selectedSlot.options.storno.tickets} />
							<Statistic title="Gesamt heute [Personen]" value={sumPeople} />
						</Card>
					)}

					{selectedSlot && selectedPool && tableValues && (
						<Card>
							<a href={pdfLink} target="_blank" rel="noopener noreferrer">
								Als PDF herunterladen
							</a>
						</Card>
					)}
				</Sidebar>
				<Card className="ticket-table" maxWidth={2000}>
					{!selectedPool && (
						<div className="center-center">
							<h1 className="info-text">Wählen Sie eine Einrichtung auf der rechten Seite</h1>
						</div>
					)}
					{!selectedSlot && selectedPool && (
						<div className="center-center">
							<h1 className="info-text">Wählen Sie ein Datum und eine Uhrzeit auf der rechten Seite</h1>
						</div>
					)}
					{selectedSlot && selectedPool && tableValues && (
						<>
							{selectedSlot.to > new Date().getTime() && (
								<div className="toolbar">
									<Button onClick={this.triggerNewEntry}>Neue Reservierung</Button>
									<div className="toolbar-stat">
										<p className="title">Freie Tickets</p>
										<div className="value">
											{selectedSlot.options.freeSlots >= 0 ? selectedSlot.options.freeSlots : 0} /{" "}
											{selectedSlot.options.capacity}
										</div>
									</div>
									{poolData?.additionalSlots && (
										<div className="toolbar-stat">
											<p className="title">Zusätzliche Tickets</p>
											<div className="value">
												{selectedSlot.options.freeSlots <= 0
													? poolData.additionalSlots + selectedSlot.options.freeSlots
													: poolData.additionalSlots}{" "}
												/ {poolData.additionalSlots}
											</div>
										</div>
									)}
								</div>
							)}
							<Table
								className="full-width-table"
								editable={false}
								tableHeaders={tableHeaders}
								tableValues={tableValues}
								searchable
								onRowClick={{
									event: (row: any) => {
										this.selectedTicket = row;
									},
								}}
							/>
						</>
					)}
				</Card>

				<Dialog
					ref={this.addDialog}
					buttons={[
						{
							type: "close",
							text: "Abbrechen",
							onClick: () => {
								this.toggleSendMail.current?.reset();
								this.dataEntry.current?.clearData();
							},
						},
						{
							type: "save",
							text: "Reservierung anlegen",
							validateFunc: () => this.state.newEntryValid,
							onClick: this.addReservation,
						},
					]}
					title="Neue Reservierung">
					<VGCoronaDataEntry
						ref={this.dataEntry}
						onChange={(valid) => this.setState({ newEntryValid: valid })}
						maxPeople={poolData?.maxBookingPersons ?? 6}
					/>
					<Boolean ref={this.toggleSendMail} label="E-Mail versenden" name="send-mail-toggle" />
				</Dialog>
			</>
		);
	}
}

export default TicketTable;
