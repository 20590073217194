/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import moment from "moment";

interface ListItemProps {
	date?: number;
	id: string | number;
	children?: any;
	onClick?: (id: string | number) => void;
	selected?: boolean;
	image?: any;
}

const ListItem = (props: ListItemProps) => (
	<div className={props.selected ? "selected list-item" : "list-item"} onClick={() => props.onClick?.(props.id)}>
		<div className="value">{props.children}</div>
		<div className="details">
			{props.id && <span className="id">#{props.id}</span>}
			{props.date && (
				<div className="date">
					<i className="im im-date-o" />
					<span>{moment(props.date).format("DD.MM HH")} Uhr</span>
				</div>
			)}
			{props.image}
		</div>
	</div>
);

export default ListItem;
