import React, { Component } from "react";
import { Select, Table, Card, Form, Button, Input } from "@onedash/tools";
import { ValueLabelPair } from "@onedash/tools/dist/ToolTypes";
import Action from "../../Utils/Redux/Action";
import Sidebar from "../../Pages/Dashboard/Sidebar/Sidebar";
import Backend from "../../Utils/Backend/Backend";
import TABLE_HEADERS_LIST from "./TableHeadersList";
import BrowserUtils from "../../Utils/BrowserUtils";

interface TableHeadersProps {}

class TableHeaders extends Component<TableHeadersProps> {
	state = {
		tableList: undefined as undefined | ValueLabelPair[],
		tableData: undefined as any,
		table: undefined as string | undefined,
	};

	componentDidMount() {
		this.loadTables();
		const table = BrowserUtils.getParam("tableName");
		if (table) {
			this.openTable({ tableName: table });
		}
	}

	loadTables = async () => {
		const tables = await Backend.get<string[]>("/base/tableHeaders");
		const tableList = tables.data.map((t) => {
			return {
				label: t,
				value: t,
			};
		});
		this.setState({ tableList });
		Action.tabLoaded();
	};

	openTable = async (obj: any) => {
		const table = obj.tableName;
		const tableHeaderWithName = await Backend.post<TableHeaderWithTable>("/base/tableHeaders", { tableName: table });
		if (!tableHeaderWithName.data) {
			BrowserUtils.removeParam("tableName");
			return;
		}
		const tableData = tableHeaderWithName.data.headers;
		tableData.forEach((td, i) => {
			(td as any).id = i;
		});
		this.setState({ table, tableData: tableHeaderWithName.data.headers });
		BrowserUtils.addParam("tableName", table);
	};

	createTable = (values: any) => {
		const tableName = values.newTableName;
		BrowserUtils.addParam("tableName", tableName);
		this.setState({ table: tableName, tableData: [] });
	};

	onSave = async (entry: any) => {
		await Backend.put<any>("/base/tableHeaders", { tableName: this.state.table, headers: entry });
	};

	render() {
		return (
			<div>
				{!this.state.tableData && (
					<div className="center-center">
						<h1 className="info-text">Wählen Sie zuerst eine Tabelle auf der rechten Seite</h1>
					</div>
				)}

				{this.state.tableData && (
					<Table
						searchable
						className="full-width-table"
						editable
						tableHeaders={TABLE_HEADERS_LIST as any}
						tableValues={this.state.tableData}
						onSave={this.onSave}
					/>
				)}
				<Sidebar>
					<Card title="Tabelle bearbeiten">
						<Form onSubmit={this.openTable}>
							<Select
								settings={{ requiredNotVisible: true }}
								required
								options={this.state.tableList}
								name="tableName"
								label="Tabelle"
								value={this.state.table}
							/>
							<Button type="submit" className="full-width">
								Tabelle öffnen
							</Button>
						</Form>
					</Card>
					<Card title="Neue Tabelle">
						<Form onSubmit={this.createTable}>
							<Input
								required
								settings={{ requiredNotVisible: true, allowNumberNull: false, validateEmail: false }}
								name="newTableName"
								label="Tabellenname"
								placeholder="Name der neuen Tabelle"
							/>
							<Button type="submit" className="full-width">
								Tabelle erstellen
							</Button>
						</Form>
					</Card>
				</Sidebar>
			</div>
		);
	}
}

export default TableHeaders;
