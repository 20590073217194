import { TableHeader } from "@onedash/tools/dist/Table/TableTypes";
import React from "react";
import moment from "moment";

const VGCoronaBookingTableHeaders = () => {
	const headers: TableHeader[] = [
		{
			columnName: "name",
			title: "Name",
			type: "text",
			visible: true,
		},
		{
			columnName: "arrival",
			title: "Betreten",
			type: "text",
			visible: true,
			// eslint-disable-next-line react/display-name
			formattingFunction: (timestamp: number) => <>{moment(timestamp).format("HH:mm")} Uhr</>,
		},
		{
			columnName: "departure",
			title: "Verlassen",
			type: "text",
			visible: true,
			// eslint-disable-next-line react/display-name
			formattingFunction: (timestamp: number) => <>{moment(timestamp).format("HH:mm")} Uhr</>,
		},
		{
			columnName: "state",
			title: "Status",
			type: "text",
			visible: true,
		},
		{
			columnName: "street",
			title: "Straße",
			type: "text",
			visible: false,
		},
		{
			columnName: "plz",
			title: "Postleitzahl",
			type: "text",
			visible: false,
		},
		{
			columnName: "place",
			title: "Ort",
			type: "text",
			visible: false,
		},
		{
			columnName: "phone",
			title: "Telefonnummer",
			type: "text",
			visible: false,
		},
		{
			columnName: "email",
			title: "Email - Adresse",
			type: "text",
			visible: false,
		},
		{
			columnName: "members",
			title: "Anwohner",
			type: "text",
			visible: false,
		},
	];

	return headers;
};

export default VGCoronaBookingTableHeaders;
