/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Backend from "../../Utils/Backend/Backend";
import BrowserHistory from "../../Utils/BrowserHistory";
import Notify from "../../Utils/Notify";
import { Session } from "../../Utils/Session";
import MiniPages from "../MiniPages/MiniPages";
import CompanyInformation from "../MiniPages/Pages/CompanyInformation";
import CompanyStats from "../MiniPages/Pages/CompanyStats";
import EMailValidation from "../MiniPages/Pages/EMailValidation";
import GaestebeitragLandingPage from "../MiniPages/Pages/GaestebeitragLandingPage";
import GaestebeitragRegisterResponse from "../MiniPages/Pages/GaestebeitragRegisterResponse";
import PasswordChange from "../MiniPages/Pages/PasswordChange";
import "./GaestebeitragRegister.sass";

const OBJ_NAME = "gaestebeitrag-register";

class GaestebeitragRegister extends Component {
	appID = (this.props as any).match.params.appId;

	loadingStart = 0;

	state = {
		currentStep: 0,
		locations: [] as Location[],
		appData: undefined as undefined | ApplicationData,
		isLoading: true,
		loadingText: "Wir laden ein Paar Daten ...",
	};

	componentDidMount() {
		Backend.toggleAuth(false);
		this.loadAppData().then(() => {
			this.setState({ isLoading: false });
			this.loadLocations();

			// Load current step
			const currentStep = Session.getObject(OBJ_NAME, "currentStep");
			if (currentStep) {
				this.setState({ currentStep });
			}
		});
	}

	onChangeStep = (newStep: number) => {
		this.setState({ currentStep: newStep });
	};

	loadAppData = async () => {
		Backend.setApp({ appId: this.appID, appType: "vg", appName: "Verbandsgemeinde", order: 0 });

		const appData = await Backend.get<ApplicationData>(`/appSettings/appData`).catch(() => {
			// the given appID does not exist => redirect to login
			BrowserHistory.push("/login");
		});
		if (appData) this.setState({ appData: appData.data });
	};

	loadLocations = async () => {
		const locations = await Backend.get<Location[]>(`/masterData/location`);
		if (locations) this.setState({ locations: locations.data });
	};

	registerUserAndBetrieb = (password: string) => {
		const data = Session.get(OBJ_NAME);
		data.user.password = password;
		this.setState({ isLoading: true, loadingText: "Ihr Account wird nun erstellt ..." });
		Backend.post<Location[]>(`/account/registration/LODGINGS`, data)
			.then(() => {
				this.setState({ isLoading: false, currentStep: 5 });
				Session.set(OBJ_NAME, undefined);
			})
			.catch((x) => {
				Notify.e(x.data);
				this.setState({ isLoading: false, currentStep: 4 });
			});
	};

	onStatsChange = (data: any) => {
		data.company.sepa = data.company.sepa ? 1 : 0;
		Session.mergeObject(OBJ_NAME, data);
	};

	render() {
		const { currentStep, locations, appData, isLoading, loadingText } = this.state;

		return (
			<MiniPages
				className="gaestebeitrag-register"
				isLoading={isLoading}
				loadingText={loadingText}
				onPageChange={this.onChangeStep}
				currentStep={currentStep}
				showHeader>
				<GaestebeitragLandingPage appData={appData} hideHeader />
				<EMailValidation
					email={Session.getObject(OBJ_NAME, "user.email")}
					onMailChange={(mail) => Session.setObject("gaestebeitrag-register", "user.email", mail)}
					label="E-Mail verifizieren"
				/>
				<CompanyInformation
					onDataChange={(data) => Session.mergeObject(OBJ_NAME, data)}
					locations={locations}
					label="Betriebsdaten"
				/>
				<CompanyStats onDataChange={this.onStatsChange} label="Betriebsinformationen" />
				<PasswordChange onPasswordSubmit={this.registerUserAndBetrieb} label="Passwort festlegen" />
				<GaestebeitragRegisterResponse hideHeader succeeded />
			</MiniPages>
		);
	}
}

export default GaestebeitragRegister;
