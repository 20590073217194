import { StyleLoaderStyle } from "@onedash/tools/dist/ToolTypes";

const StyleTwenty: StyleLoaderStyle = {
	light: {
		onedashGray: "#383838",

		light: "#ececec",
		lightBlue: "#3c67f1",
		blue: "#30475e",
		dark: "#222831",
		lightGray: "#f0f0f0",
		middleGray: "#c5c5c5",
		veryLightGray: "#edf1f6",
		"tools-icon-background": "#eeeeee",
		"tools-input-background": "white",
		gray: "#5a5a5a",
		white: "white",
		black: "black",
		invalid: "#e66f59",
		lightInvalid: "#e66f5990",

		"nav-bar": "#192029",

		sidebar: "#f0f0f0",
		pageBackground: "#f1f5f8",
		background: "white",
		"head-bar": "white",
		text: "#0b0b26",

		"scrollbar-thumb": "#c5c5c5",

		"tools-table-header-background": "#f0f0f0",
		"tools-table-header-border": "#f0f0f0",
		"tools-table-header-text": "#30475e",
	},
	dark: {
		onedashGray: "white",

		light: "#222831",
		lightBlue: "#5c96d2",
		blue: "#3c67f1",
		dark: "#ececec",
		veryLightGray: "#212121",
		lightGray: "#5a5a5a",
		middleGray: "#afafaf",
		"tools-icon-background": "#1c1c1c",

		gray: "#ececec",
		white: "#181818",
		black: "white",
		invalid: "#e66f59",
		lightInvalid: "#f1a091",

		"nav-bar": "#192029",

		sidebar: "#1c2127",

		pageBackground: "#363535",
		background: "#222831",
		"head-bar": "#222831",
		text: "white",

		"scrollbar-thumb": "#16191b",

		"tools-scrollbar-bg": "#1b2027",
		"tools-table-header-text": "#f2a365",
		"tools-table-border": "#161b23",
	},
	all: {
		"tools-spinner-color": "#f2a365",
		onedashOrange: "#e88760",
		onedashGreen: "#28ffac",
		padding: "30px",
		boxShadow: "0px 0px 15px rgba(0,0,0,0.15)",

		"scrollbar-width": "0px",
		"scrollbar-bg": "transparent",
	},
};

export default StyleTwenty;
