import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A JavaScript Timestamp number. It can easily parsed by a Date object. */
  Timestamp: any;
};




export type Absence = {
  __typename?: 'Absence';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  timeslot: Timeslot;
  userID: Scalars['String'];
};

export enum AccessModes {
  R = 'r',
  Rw = 'rw',
  W = 'w'
}

export enum AppTypes {
  App = 'app',
  Gastro = 'gastro',
  Vg = 'vg',
  Website = 'website'
}

export type BookingDepartment = {
  __typename?: 'BookingDepartment';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateAbsence = {
  description?: Maybe<Scalars['String']>;
  from: Scalars['Timestamp'];
  name: Scalars['String'];
  periodRule?: Maybe<Scalars['String']>;
  periodTo?: Maybe<Scalars['Timestamp']>;
  to: Scalars['Timestamp'];
};

export type DeletionPayload = {
  __typename?: 'DeletionPayload';
  affectedRows: Scalars['Int'];
};

export type Department = {
  __typename?: 'Department';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  services: Array<DepartmentService>;
  users: Array<User>;
};

export type DepartmentBooking = {
  __typename?: 'DepartmentBooking';
  clerk?: Maybe<User>;
  created_at: Scalars['Timestamp'];
  customer: PersonData;
  departmentService: DepartmentServiceBookings;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  state: DepartmentBookingState;
  timeslot: Timeslot;
  updated_at: Scalars['Timestamp'];
};

export enum DepartmentBookingState {
  Accepted = 'ACCEPTED',
  DateSuggested = 'DATE_SUGGESTED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Storno = 'STORNO'
}

export type DepartmentService = {
  __typename?: 'DepartmentService';
  additional?: Maybe<Scalars['String']>;
  departmentID: Scalars['ID'];
  duration: Scalars['Int'];
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  users?: Maybe<Array<User>>;
};

export type DepartmentServiceBookings = {
  __typename?: 'DepartmentServiceBookings';
  additional?: Maybe<Scalars['String']>;
  department: BookingDepartment;
  duration: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FlyFishingForm = {
  __typename?: 'FlyFishingForm';
  city: Scalars['String'];
  country: Scalars['String'];
  id: Scalars['ID'];
  lodging?: Maybe<Scalars['String']>;
  meta_creation: Scalars['Timestamp'];
  meta_userId: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  street: Scalars['String'];
  tickets: Array<FlyFishingTicket>;
  variant: FlyFishingTicketVariants;
  withGuestCard: Scalars['Boolean'];
};

export type FlyFishingFormInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  lodging?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  street: Scalars['String'];
  tickets: Array<FlyFishingTicketInput>;
  variant: FlyFishingTicketVariants;
  withGuestCard: Scalars['Boolean'];
};

export enum FlyFishingRoutes {
  LowerRoute = 'lowerRoute',
  UpperRoute = 'upperRoute'
}

export type FlyFishingTicket = {
  __typename?: 'FlyFishingTicket';
  dateFrom: Scalars['Timestamp'];
  dateTo: Scalars['Timestamp'];
  route: FlyFishingRoutes;
};

export type FlyFishingTicketInput = {
  dateFrom: Scalars['Timestamp'];
  route: FlyFishingRoutes;
};

export enum FlyFishingTicketVariants {
  Jahresschein = 'jahresschein',
  Tagesschein = 'tagesschein',
  Wochenschein = 'wochenschein'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** @deprecated No longer supported */
  _empty?: Maybe<Scalars['String']>;
  /**
   * !!! Attention !!!
   * Only for testing purposes!
   * @deprecated No longer supported
   */
  _failingTransaction: Scalars['Boolean'];
  /**
   * !!! Attention !!!
   * Only for testing purposes!
   * @deprecated No longer supported
   */
  _transaction: Scalars['Boolean'];
  assignDepartmentAdmin: ObjectAccessAssignment;
  assignDepartmentServiceClerk: ObjectAccessAssignment;
  /** Manages Public Functions on Bookings */
  cancelDepartmentBooking: DepartmentBooking;
  /** Creates a new Absence */
  createAbsence: Array<Maybe<Absence>>;
  /** Creates a new Department */
  createDepartment: Department;
  /** Create Bookings */
  createDepartmentBooking: DepartmentBooking;
  /** Creates a new Department Service */
  createDepartmentService: DepartmentService;
  createFlyFishingForm: FlyFishingForm;
  /** Creates a new public vacation */
  createPublicVacation: PublicVacation;
  /** Creates a new working hour */
  createWorkingHour: WorkingHour;
  deassignDepartmentAdmin: DeletionPayload;
  deassignDepartmentServiceClerk: DeletionPayload;
  /** Deletes a Absence by it's id */
  deleteAbsence: DeletionPayload;
  /** Deletes a department by it's id */
  deleteDepartment: DeletionPayload;
  /** Deletes a department service by it's id */
  deleteDepartmentService: DeletionPayload;
  /** Deletes a public vacation by it's id */
  deletePublicVacation: DeletionPayload;
  /** Deletes a working hour by it's id */
  deleteWorkingHour: DeletionPayload;
  /** Manages Admin Functions on Bookings */
  manageAdminDepartmentBooking: DepartmentBooking;
  /** Updates a Absence */
  updateAbsence: Absence;
  /** Updates a Department */
  updateDepartment: Department;
  /** Updates a Department Service */
  updateDepartmentService: DepartmentService;
  /** Updates a public vacation */
  updatePublicVacation: PublicVacation;
  /** Updates a working hour */
  updateWorkingHour: WorkingHour;
};


export type MutationAssignDepartmentAdminArgs = {
  departmentId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationAssignDepartmentServiceClerkArgs = {
  serviceId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationCancelDepartmentBookingArgs = {
  identifier: Scalars['String'];
};


export type MutationCreateAbsenceArgs = {
  absence: CreateAbsence;
};


export type MutationCreateDepartmentArgs = {
  department: UpdateDepartment;
};


export type MutationCreateDepartmentBookingArgs = {
  departmentBooking: UpdateDepartmentBooking;
  timeslotGenerationStart?: Maybe<Scalars['Timestamp']>;
};


export type MutationCreateDepartmentServiceArgs = {
  departmentID: Scalars['ID'];
  service: UpdateDepartmentService;
};


export type MutationCreateFlyFishingFormArgs = {
  flyFishingForm: FlyFishingFormInput;
};


export type MutationCreatePublicVacationArgs = {
  vacation: UpdatePublicVation;
};


export type MutationCreateWorkingHourArgs = {
  workingHour: UpdateWorkingHour;
};


export type MutationDeassignDepartmentAdminArgs = {
  departmentId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationDeassignDepartmentServiceClerkArgs = {
  serviceId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationDeleteAbsenceArgs = {
  deletePeriod?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};


export type MutationDeleteDepartmentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDepartmentServiceArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePublicVacationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWorkingHourArgs = {
  id: Scalars['ID'];
};


export type MutationManageAdminDepartmentBookingArgs = {
  identifier: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  type: UpdateDepartmentAdminTypes;
};


export type MutationUpdateAbsenceArgs = {
  absence: UpdateAbsence;
  id: Scalars['ID'];
};


export type MutationUpdateDepartmentArgs = {
  department: UpdateDepartment;
  id: Scalars['ID'];
};


export type MutationUpdateDepartmentServiceArgs = {
  id: Scalars['ID'];
  service: UpdateDepartmentService;
};


export type MutationUpdatePublicVacationArgs = {
  id: Scalars['ID'];
  vacation: UpdatePublicVation;
};


export type MutationUpdateWorkingHourArgs = {
  id: Scalars['ID'];
  workingHour: UpdateWorkingHour;
};

export type ObjectAccessAssignment = {
  __typename?: 'ObjectAccessAssignment';
  mode: AccessModes;
  objectID: Scalars['String'];
  tableName: ObjectAccessTable;
  tableType: TableType;
  userId: Scalars['String'];
};

export enum ObjectAccessTable {
  DepartmentServices = 'DepartmentServices',
  Departments = 'Departments',
  Lodgings = 'Lodgings'
}

export type PersonData = {
  __typename?: 'PersonData';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  houseNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageURL?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  plz?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type PublicVacation = {
  __typename?: 'PublicVacation';
  id: Scalars['ID'];
  name: Scalars['String'];
  timeslot: Timeslot;
};

export type Query = {
  __typename?: 'Query';
  /**
   * !!! Attention !!!
   * Only for testing purposes!
   * @deprecated No longer supported
   */
  _appID: Scalars['Boolean'];
  /**
   * !!! Attention !!!
   * Only for testing purposes!
   * @deprecated No longer supported
   */
  _appIDType: Scalars['Boolean'];
  /** @deprecated No longer supported */
  _empty?: Maybe<Scalars['String']>;
  /**
   * !!! Attention !!!
   * Only for testing purposes!
   * @deprecated No longer supported
   */
  _secret: Scalars['Boolean'];
  /**
   * !!! Attention !!!
   * Only for testing purposes!
   * @deprecated No longer supported
   */
  _secretPerm: Scalars['Boolean'];
  /** Check whether the user has access for a object. */
  checkAccess: Scalars['Boolean'];
  departmentBooking: DepartmentBooking;
  departmentBookings: Array<DepartmentBooking>;
  departmentServiceTimeslots: Array<TemporaryTimeslot>;
  departments: Array<Department>;
  /** Returns a list of users which match either by their name, email or id */
  findUser: Array<User>;
  flyFishingDocument?: Maybe<Array<FlyFishingForm>>;
  publicVacations: Array<PublicVacation>;
  userAbsence?: Maybe<Absence>;
  userAbsences: Array<Absence>;
  version: Scalars['String'];
  workingHours: Array<WorkingHour>;
};


export type QueryCheckAccessArgs = {
  mode: AccessModes;
  objectID: Scalars['ID'];
  tableName: ObjectAccessTable;
  tableType: TableType;
};


export type QueryDepartmentBookingArgs = {
  id: Scalars['ID'];
};


export type QueryDepartmentBookingsArgs = {
  from: Scalars['Timestamp'];
  to: Scalars['Timestamp'];
};


export type QueryDepartmentServiceTimeslotsArgs = {
  serviceID: Scalars['ID'];
  timeslotGenerationStart?: Maybe<Scalars['Timestamp']>;
};


export type QueryDepartmentsArgs = {
  notActive?: Maybe<Scalars['Boolean']>;
};


export type QueryFindUserArgs = {
  identifier: Scalars['String'];
};


export type QueryPublicVacationsArgs = {
  from: Scalars['Timestamp'];
  to: Scalars['Timestamp'];
};


export type QueryUserAbsenceArgs = {
  id: Scalars['ID'];
};


export type QueryUserAbsencesArgs = {
  from: Scalars['Timestamp'];
  to: Scalars['Timestamp'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /** @deprecated No longer supported */
  _empty?: Maybe<Scalars['String']>;
  publicVacations: PublicVacation;
};

export enum TableType {
  Mongo = 'MONGO',
  Mysql = 'MYSQL'
}

export type TemporaryTimeslot = {
  __typename?: 'TemporaryTimeslot';
  from: Scalars['Timestamp'];
  to: Scalars['Timestamp'];
};

export type Timeslot = {
  __typename?: 'Timeslot';
  from: Scalars['Timestamp'];
  id: Scalars['ID'];
  periodEndless?: Maybe<Scalars['Boolean']>;
  periodFrom?: Maybe<Scalars['Timestamp']>;
  periodId?: Maybe<Scalars['ID']>;
  /**
   * Period repeating rule.
   * Frequency[(frequency options)]+Interval[+Days]
   *
   * Frequencies:
   * - D (Daily)
   * - W (Weekly)
   * - M (Monthly)
   * - Y (Yearly)
   * - P (Public Holiday [Works like day])
   * - H (School Holiday [Works like day]) // !!!Currently not supported!!!
   *
   * Interval:
   * Defines the interval for the given frequency.
   *
   * Days:
   * A comma seperated list with all the days which will be used
   * => Mo,Tu,We,Th,Fr,Sa,Su
   *
   *  Examples:
   * - Daily: D+1
   * - Weekly: W+1
   * - Monthly at the same day number (e.g. 7.): M+1
   * - Monthly at the same week day (e.g Monday): M+1+Mo
   * - Monthly at the same week days (e.g Monday and Friday): M+1+Mo+Fr
   * - Yearly at the first of January: Y+1
   * - Working days: W+1+Mo,Tu,We,Th,Fr
   * - Weekend: W+1+Sa,Su
   * - Every two weeks monday and tuesday: W+2+Mo,Tu
   * - Every Public Holiday (Germany as country and Rheinland-Pfalz as district): P(DE,RP)+1
   * - At School Holidays every monday and wednesday: H(DE,RP)+1+Mo,We
   */
  periodRule?: Maybe<Scalars['String']>;
  periodTo?: Maybe<Scalars['Timestamp']>;
  to: Scalars['Timestamp'];
};


export type UpdateAbsence = {
  description?: Maybe<Scalars['String']>;
  from: Scalars['Timestamp'];
  name: Scalars['String'];
  to: Scalars['Timestamp'];
};

export type UpdateDepartment = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export enum UpdateDepartmentAdminTypes {
  Accept = 'ACCEPT',
  Decline = 'DECLINE'
}

export type UpdateDepartmentBooking = {
  customer: UpdatePersonData;
  departmentServiceID: Scalars['ID'];
  timeslot: UpdateTemporaryTimeslot;
};

export type UpdateDepartmentService = {
  additional?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  name: Scalars['String'];
};

export type UpdatePersonData = {
  city: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  houseNumber: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  plz: Scalars['String'];
  street: Scalars['String'];
};

export type UpdatePublicVation = {
  from: Scalars['Timestamp'];
  name: Scalars['String'];
  to: Scalars['Timestamp'];
};

export type UpdateTemporaryTimeslot = {
  from: Scalars['Timestamp'];
  to: Scalars['Timestamp'];
};

export type UpdateWorkingHour = {
  day: WorkingHourDay;
  from: UpdateWorkingHourRange;
  to: UpdateWorkingHourRange;
};

export type UpdateWorkingHourRange = {
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  imageURL?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type WorkingHour = {
  __typename?: 'WorkingHour';
  day: WorkingHourDay;
  from: WorkingHourRange;
  id: Scalars['ID'];
  to: WorkingHourRange;
  userID: Scalars['String'];
};

export enum WorkingHourDay {
  Fr = 'FR',
  Mo = 'MO',
  Sa = 'SA',
  Su = 'SU',
  Th = 'TH',
  Tu = 'TU',
  We = 'WE'
}

export type WorkingHourRange = {
  __typename?: 'WorkingHourRange';
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export const PublicVacationFragmentDoc = gql`
    fragment PublicVacation on PublicVacation {
  id
  name
  timeslot {
    id
    from
    to
  }
}
    `;
export const DepartmentServiceFragmentDoc = gql`
    fragment DepartmentService on DepartmentService {
  id
  name
  duration
  additional
  departmentID
  users {
    id
    email
    firstName
    lastName
    fullName
  }
}
    `;
export const DepartmentFragmentDoc = gql`
    fragment Department on Department {
  id
  name
  description
  services {
    ...DepartmentService
  }
  users {
    id
    email
    firstName
    lastName
    fullName
  }
}
    ${DepartmentServiceFragmentDoc}`;
export const ExtendedDepartmentBookingFragmentDoc = gql`
    fragment ExtendedDepartmentBooking on DepartmentBooking {
  id
  timeslot {
    id
    from
    to
    periodFrom
    periodTo
    periodId
    periodRule
    periodEndless
  }
  departmentService {
    id
    name
    duration
    additional
    department {
      id
      name
      description
    }
  }
  customer {
    id
    firstName
    lastName
    email
    phone
  }
  state
  identifier
  clerk {
    id
  }
}
    `;
export const FlyFishingDocumentFragmentDoc = gql`
    fragment FlyFishingDocument on FlyFishingForm {
  id
  city
  withGuestCard
  tickets {
    dateFrom
    dateTo
    route
  }
  country
  lodging
  name
  price
  street
  variant
  meta_creation
}
    `;
export const ExtendedAbsenceFragmentDoc = gql`
    fragment ExtendedAbsence on Absence {
  id
  timeslot {
    id
    from
    to
    periodFrom
    periodTo
    periodId
    periodRule
    periodEndless
  }
  name
  description
}
    `;
export const CreatePublicVacationDocument = gql`
    mutation createPublicVacation($vacation: UpdatePublicVation!) {
  createPublicVacation(vacation: $vacation) {
    ...PublicVacation
  }
}
    ${PublicVacationFragmentDoc}`;
export type CreatePublicVacationMutationFn = Apollo.MutationFunction<CreatePublicVacationMutation, CreatePublicVacationMutationVariables>;

/**
 * __useCreatePublicVacationMutation__
 *
 * To run a mutation, you first call `useCreatePublicVacationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicVacationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicVacationMutation, { data, loading, error }] = useCreatePublicVacationMutation({
 *   variables: {
 *      vacation: // value for 'vacation'
 *   },
 * });
 */
export function useCreatePublicVacationMutation(baseOptions?: Apollo.MutationHookOptions<CreatePublicVacationMutation, CreatePublicVacationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePublicVacationMutation, CreatePublicVacationMutationVariables>(CreatePublicVacationDocument, options);
      }
export type CreatePublicVacationMutationHookResult = ReturnType<typeof useCreatePublicVacationMutation>;
export type CreatePublicVacationMutationResult = Apollo.MutationResult<CreatePublicVacationMutation>;
export type CreatePublicVacationMutationOptions = Apollo.BaseMutationOptions<CreatePublicVacationMutation, CreatePublicVacationMutationVariables>;
export const UpdatePublicVacationDocument = gql`
    mutation updatePublicVacation($id: ID!, $vacation: UpdatePublicVation!) {
  updatePublicVacation(id: $id, vacation: $vacation) {
    ...PublicVacation
  }
}
    ${PublicVacationFragmentDoc}`;
export type UpdatePublicVacationMutationFn = Apollo.MutationFunction<UpdatePublicVacationMutation, UpdatePublicVacationMutationVariables>;

/**
 * __useUpdatePublicVacationMutation__
 *
 * To run a mutation, you first call `useUpdatePublicVacationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicVacationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicVacationMutation, { data, loading, error }] = useUpdatePublicVacationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      vacation: // value for 'vacation'
 *   },
 * });
 */
export function useUpdatePublicVacationMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublicVacationMutation, UpdatePublicVacationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePublicVacationMutation, UpdatePublicVacationMutationVariables>(UpdatePublicVacationDocument, options);
      }
export type UpdatePublicVacationMutationHookResult = ReturnType<typeof useUpdatePublicVacationMutation>;
export type UpdatePublicVacationMutationResult = Apollo.MutationResult<UpdatePublicVacationMutation>;
export type UpdatePublicVacationMutationOptions = Apollo.BaseMutationOptions<UpdatePublicVacationMutation, UpdatePublicVacationMutationVariables>;
export const DeletePublicVacationDocument = gql`
    mutation deletePublicVacation($id: ID!) {
  deletePublicVacation(id: $id) {
    affectedRows
  }
}
    `;
export type DeletePublicVacationMutationFn = Apollo.MutationFunction<DeletePublicVacationMutation, DeletePublicVacationMutationVariables>;

/**
 * __useDeletePublicVacationMutation__
 *
 * To run a mutation, you first call `useDeletePublicVacationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublicVacationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublicVacationMutation, { data, loading, error }] = useDeletePublicVacationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePublicVacationMutation(baseOptions?: Apollo.MutationHookOptions<DeletePublicVacationMutation, DeletePublicVacationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePublicVacationMutation, DeletePublicVacationMutationVariables>(DeletePublicVacationDocument, options);
      }
export type DeletePublicVacationMutationHookResult = ReturnType<typeof useDeletePublicVacationMutation>;
export type DeletePublicVacationMutationResult = Apollo.MutationResult<DeletePublicVacationMutation>;
export type DeletePublicVacationMutationOptions = Apollo.BaseMutationOptions<DeletePublicVacationMutation, DeletePublicVacationMutationVariables>;
export const CreateDepartmentServiceDocument = gql`
    mutation createDepartmentService($departmentID: ID!, $service: UpdateDepartmentService!) {
  createDepartmentService(service: $service, departmentID: $departmentID) {
    ...DepartmentService
  }
}
    ${DepartmentServiceFragmentDoc}`;
export type CreateDepartmentServiceMutationFn = Apollo.MutationFunction<CreateDepartmentServiceMutation, CreateDepartmentServiceMutationVariables>;

/**
 * __useCreateDepartmentServiceMutation__
 *
 * To run a mutation, you first call `useCreateDepartmentServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDepartmentServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDepartmentServiceMutation, { data, loading, error }] = useCreateDepartmentServiceMutation({
 *   variables: {
 *      departmentID: // value for 'departmentID'
 *      service: // value for 'service'
 *   },
 * });
 */
export function useCreateDepartmentServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateDepartmentServiceMutation, CreateDepartmentServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDepartmentServiceMutation, CreateDepartmentServiceMutationVariables>(CreateDepartmentServiceDocument, options);
      }
export type CreateDepartmentServiceMutationHookResult = ReturnType<typeof useCreateDepartmentServiceMutation>;
export type CreateDepartmentServiceMutationResult = Apollo.MutationResult<CreateDepartmentServiceMutation>;
export type CreateDepartmentServiceMutationOptions = Apollo.BaseMutationOptions<CreateDepartmentServiceMutation, CreateDepartmentServiceMutationVariables>;
export const UpdateDepartmentServiceDocument = gql`
    mutation updateDepartmentService($id: ID!, $service: UpdateDepartmentService!) {
  updateDepartmentService(id: $id, service: $service) {
    ...DepartmentService
  }
}
    ${DepartmentServiceFragmentDoc}`;
export type UpdateDepartmentServiceMutationFn = Apollo.MutationFunction<UpdateDepartmentServiceMutation, UpdateDepartmentServiceMutationVariables>;

/**
 * __useUpdateDepartmentServiceMutation__
 *
 * To run a mutation, you first call `useUpdateDepartmentServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepartmentServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepartmentServiceMutation, { data, loading, error }] = useUpdateDepartmentServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      service: // value for 'service'
 *   },
 * });
 */
export function useUpdateDepartmentServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDepartmentServiceMutation, UpdateDepartmentServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDepartmentServiceMutation, UpdateDepartmentServiceMutationVariables>(UpdateDepartmentServiceDocument, options);
      }
export type UpdateDepartmentServiceMutationHookResult = ReturnType<typeof useUpdateDepartmentServiceMutation>;
export type UpdateDepartmentServiceMutationResult = Apollo.MutationResult<UpdateDepartmentServiceMutation>;
export type UpdateDepartmentServiceMutationOptions = Apollo.BaseMutationOptions<UpdateDepartmentServiceMutation, UpdateDepartmentServiceMutationVariables>;
export const AssignDepartmentServiceClerkDocument = gql`
    mutation assignDepartmentServiceClerk($serviceId: ID!, $userId: ID!) {
  assignDepartmentServiceClerk(serviceId: $serviceId, userId: $userId) {
    userId
    objectID
  }
}
    `;
export type AssignDepartmentServiceClerkMutationFn = Apollo.MutationFunction<AssignDepartmentServiceClerkMutation, AssignDepartmentServiceClerkMutationVariables>;

/**
 * __useAssignDepartmentServiceClerkMutation__
 *
 * To run a mutation, you first call `useAssignDepartmentServiceClerkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignDepartmentServiceClerkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignDepartmentServiceClerkMutation, { data, loading, error }] = useAssignDepartmentServiceClerkMutation({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAssignDepartmentServiceClerkMutation(baseOptions?: Apollo.MutationHookOptions<AssignDepartmentServiceClerkMutation, AssignDepartmentServiceClerkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignDepartmentServiceClerkMutation, AssignDepartmentServiceClerkMutationVariables>(AssignDepartmentServiceClerkDocument, options);
      }
export type AssignDepartmentServiceClerkMutationHookResult = ReturnType<typeof useAssignDepartmentServiceClerkMutation>;
export type AssignDepartmentServiceClerkMutationResult = Apollo.MutationResult<AssignDepartmentServiceClerkMutation>;
export type AssignDepartmentServiceClerkMutationOptions = Apollo.BaseMutationOptions<AssignDepartmentServiceClerkMutation, AssignDepartmentServiceClerkMutationVariables>;
export const DeassignDepartmentServiceClerkDocument = gql`
    mutation deassignDepartmentServiceClerk($serviceId: ID!, $userId: ID!) {
  deassignDepartmentServiceClerk(serviceId: $serviceId, userId: $userId) {
    affectedRows
  }
}
    `;
export type DeassignDepartmentServiceClerkMutationFn = Apollo.MutationFunction<DeassignDepartmentServiceClerkMutation, DeassignDepartmentServiceClerkMutationVariables>;

/**
 * __useDeassignDepartmentServiceClerkMutation__
 *
 * To run a mutation, you first call `useDeassignDepartmentServiceClerkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeassignDepartmentServiceClerkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deassignDepartmentServiceClerkMutation, { data, loading, error }] = useDeassignDepartmentServiceClerkMutation({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeassignDepartmentServiceClerkMutation(baseOptions?: Apollo.MutationHookOptions<DeassignDepartmentServiceClerkMutation, DeassignDepartmentServiceClerkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeassignDepartmentServiceClerkMutation, DeassignDepartmentServiceClerkMutationVariables>(DeassignDepartmentServiceClerkDocument, options);
      }
export type DeassignDepartmentServiceClerkMutationHookResult = ReturnType<typeof useDeassignDepartmentServiceClerkMutation>;
export type DeassignDepartmentServiceClerkMutationResult = Apollo.MutationResult<DeassignDepartmentServiceClerkMutation>;
export type DeassignDepartmentServiceClerkMutationOptions = Apollo.BaseMutationOptions<DeassignDepartmentServiceClerkMutation, DeassignDepartmentServiceClerkMutationVariables>;
export const DeleteDepartmentServiceDocument = gql`
    mutation deleteDepartmentService($id: ID!) {
  deleteDepartmentService(id: $id) {
    affectedRows
  }
}
    `;
export type DeleteDepartmentServiceMutationFn = Apollo.MutationFunction<DeleteDepartmentServiceMutation, DeleteDepartmentServiceMutationVariables>;

/**
 * __useDeleteDepartmentServiceMutation__
 *
 * To run a mutation, you first call `useDeleteDepartmentServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDepartmentServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDepartmentServiceMutation, { data, loading, error }] = useDeleteDepartmentServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDepartmentServiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDepartmentServiceMutation, DeleteDepartmentServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDepartmentServiceMutation, DeleteDepartmentServiceMutationVariables>(DeleteDepartmentServiceDocument, options);
      }
export type DeleteDepartmentServiceMutationHookResult = ReturnType<typeof useDeleteDepartmentServiceMutation>;
export type DeleteDepartmentServiceMutationResult = Apollo.MutationResult<DeleteDepartmentServiceMutation>;
export type DeleteDepartmentServiceMutationOptions = Apollo.BaseMutationOptions<DeleteDepartmentServiceMutation, DeleteDepartmentServiceMutationVariables>;
export const CreateDepartmentDocument = gql`
    mutation createDepartment($department: UpdateDepartment!) {
  createDepartment(department: $department) {
    ...Department
  }
}
    ${DepartmentFragmentDoc}`;
export type CreateDepartmentMutationFn = Apollo.MutationFunction<CreateDepartmentMutation, CreateDepartmentMutationVariables>;

/**
 * __useCreateDepartmentMutation__
 *
 * To run a mutation, you first call `useCreateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDepartmentMutation, { data, loading, error }] = useCreateDepartmentMutation({
 *   variables: {
 *      department: // value for 'department'
 *   },
 * });
 */
export function useCreateDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDepartmentMutation, CreateDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDepartmentMutation, CreateDepartmentMutationVariables>(CreateDepartmentDocument, options);
      }
export type CreateDepartmentMutationHookResult = ReturnType<typeof useCreateDepartmentMutation>;
export type CreateDepartmentMutationResult = Apollo.MutationResult<CreateDepartmentMutation>;
export type CreateDepartmentMutationOptions = Apollo.BaseMutationOptions<CreateDepartmentMutation, CreateDepartmentMutationVariables>;
export const UpdateDepartmentDocument = gql`
    mutation updateDepartment($id: ID!, $department: UpdateDepartment!) {
  updateDepartment(id: $id, department: $department) {
    ...Department
  }
}
    ${DepartmentFragmentDoc}`;
export type UpdateDepartmentMutationFn = Apollo.MutationFunction<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>;

/**
 * __useUpdateDepartmentMutation__
 *
 * To run a mutation, you first call `useUpdateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepartmentMutation, { data, loading, error }] = useUpdateDepartmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      department: // value for 'department'
 *   },
 * });
 */
export function useUpdateDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>(UpdateDepartmentDocument, options);
      }
export type UpdateDepartmentMutationHookResult = ReturnType<typeof useUpdateDepartmentMutation>;
export type UpdateDepartmentMutationResult = Apollo.MutationResult<UpdateDepartmentMutation>;
export type UpdateDepartmentMutationOptions = Apollo.BaseMutationOptions<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>;
export const AssignDepartmentAdminDocument = gql`
    mutation assignDepartmentAdmin($departmentId: ID!, $userId: ID!) {
  assignDepartmentAdmin(departmentId: $departmentId, userId: $userId) {
    userId
    objectID
  }
}
    `;
export type AssignDepartmentAdminMutationFn = Apollo.MutationFunction<AssignDepartmentAdminMutation, AssignDepartmentAdminMutationVariables>;

/**
 * __useAssignDepartmentAdminMutation__
 *
 * To run a mutation, you first call `useAssignDepartmentAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignDepartmentAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignDepartmentAdminMutation, { data, loading, error }] = useAssignDepartmentAdminMutation({
 *   variables: {
 *      departmentId: // value for 'departmentId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAssignDepartmentAdminMutation(baseOptions?: Apollo.MutationHookOptions<AssignDepartmentAdminMutation, AssignDepartmentAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignDepartmentAdminMutation, AssignDepartmentAdminMutationVariables>(AssignDepartmentAdminDocument, options);
      }
export type AssignDepartmentAdminMutationHookResult = ReturnType<typeof useAssignDepartmentAdminMutation>;
export type AssignDepartmentAdminMutationResult = Apollo.MutationResult<AssignDepartmentAdminMutation>;
export type AssignDepartmentAdminMutationOptions = Apollo.BaseMutationOptions<AssignDepartmentAdminMutation, AssignDepartmentAdminMutationVariables>;
export const DeassignDepartmentAdminDocument = gql`
    mutation deassignDepartmentAdmin($departmentId: ID!, $userId: ID!) {
  deassignDepartmentAdmin(departmentId: $departmentId, userId: $userId) {
    affectedRows
  }
}
    `;
export type DeassignDepartmentAdminMutationFn = Apollo.MutationFunction<DeassignDepartmentAdminMutation, DeassignDepartmentAdminMutationVariables>;

/**
 * __useDeassignDepartmentAdminMutation__
 *
 * To run a mutation, you first call `useDeassignDepartmentAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeassignDepartmentAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deassignDepartmentAdminMutation, { data, loading, error }] = useDeassignDepartmentAdminMutation({
 *   variables: {
 *      departmentId: // value for 'departmentId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeassignDepartmentAdminMutation(baseOptions?: Apollo.MutationHookOptions<DeassignDepartmentAdminMutation, DeassignDepartmentAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeassignDepartmentAdminMutation, DeassignDepartmentAdminMutationVariables>(DeassignDepartmentAdminDocument, options);
      }
export type DeassignDepartmentAdminMutationHookResult = ReturnType<typeof useDeassignDepartmentAdminMutation>;
export type DeassignDepartmentAdminMutationResult = Apollo.MutationResult<DeassignDepartmentAdminMutation>;
export type DeassignDepartmentAdminMutationOptions = Apollo.BaseMutationOptions<DeassignDepartmentAdminMutation, DeassignDepartmentAdminMutationVariables>;
export const DeleteDepartmentDocument = gql`
    mutation deleteDepartment($id: ID!) {
  deleteDepartment(id: $id) {
    affectedRows
  }
}
    `;
export type DeleteDepartmentMutationFn = Apollo.MutationFunction<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>;

/**
 * __useDeleteDepartmentMutation__
 *
 * To run a mutation, you first call `useDeleteDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDepartmentMutation, { data, loading, error }] = useDeleteDepartmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>(DeleteDepartmentDocument, options);
      }
export type DeleteDepartmentMutationHookResult = ReturnType<typeof useDeleteDepartmentMutation>;
export type DeleteDepartmentMutationResult = Apollo.MutationResult<DeleteDepartmentMutation>;
export type DeleteDepartmentMutationOptions = Apollo.BaseMutationOptions<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>;
export const PublicVacationDocument = gql`
    query publicVacation($from: Timestamp!, $to: Timestamp!) {
  publicVacations(from: $from, to: $to) {
    ...PublicVacation
  }
}
    ${PublicVacationFragmentDoc}`;

/**
 * __usePublicVacationQuery__
 *
 * To run a query within a React component, call `usePublicVacationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicVacationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicVacationQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function usePublicVacationQuery(baseOptions: Apollo.QueryHookOptions<PublicVacationQuery, PublicVacationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicVacationQuery, PublicVacationQueryVariables>(PublicVacationDocument, options);
      }
export function usePublicVacationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicVacationQuery, PublicVacationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicVacationQuery, PublicVacationQueryVariables>(PublicVacationDocument, options);
        }
export type PublicVacationQueryHookResult = ReturnType<typeof usePublicVacationQuery>;
export type PublicVacationLazyQueryHookResult = ReturnType<typeof usePublicVacationLazyQuery>;
export type PublicVacationQueryResult = Apollo.QueryResult<PublicVacationQuery, PublicVacationQueryVariables>;
export const DepartmentsDocument = gql`
    query departments {
  departments(notActive: true) {
    ...Department
  }
}
    ${DepartmentFragmentDoc}`;

/**
 * __useDepartmentsQuery__
 *
 * To run a query within a React component, call `useDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDepartmentsQuery(baseOptions?: Apollo.QueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepartmentsQuery, DepartmentsQueryVariables>(DepartmentsDocument, options);
      }
export function useDepartmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepartmentsQuery, DepartmentsQueryVariables>(DepartmentsDocument, options);
        }
export type DepartmentsQueryHookResult = ReturnType<typeof useDepartmentsQuery>;
export type DepartmentsLazyQueryHookResult = ReturnType<typeof useDepartmentsLazyQuery>;
export type DepartmentsQueryResult = Apollo.QueryResult<DepartmentsQuery, DepartmentsQueryVariables>;
export const CheckAccessDocument = gql`
    query checkAccess($mode: AccessModes!, $objectID: ID!, $tableName: ObjectAccessTable!, $tableType: TableType!) {
  checkAccess(
    mode: $mode
    objectID: $objectID
    tableName: $tableName
    tableType: $tableType
  )
}
    `;

/**
 * __useCheckAccessQuery__
 *
 * To run a query within a React component, call `useCheckAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAccessQuery({
 *   variables: {
 *      mode: // value for 'mode'
 *      objectID: // value for 'objectID'
 *      tableName: // value for 'tableName'
 *      tableType: // value for 'tableType'
 *   },
 * });
 */
export function useCheckAccessQuery(baseOptions: Apollo.QueryHookOptions<CheckAccessQuery, CheckAccessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckAccessQuery, CheckAccessQueryVariables>(CheckAccessDocument, options);
      }
export function useCheckAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckAccessQuery, CheckAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckAccessQuery, CheckAccessQueryVariables>(CheckAccessDocument, options);
        }
export type CheckAccessQueryHookResult = ReturnType<typeof useCheckAccessQuery>;
export type CheckAccessLazyQueryHookResult = ReturnType<typeof useCheckAccessLazyQuery>;
export type CheckAccessQueryResult = Apollo.QueryResult<CheckAccessQuery, CheckAccessQueryVariables>;
export const FindUsersDocument = gql`
    query findUsers($identifier: String!) {
  findUser(identifier: $identifier) {
    id
    email
    firstName
    lastName
    fullName
    imageURL
  }
}
    `;

/**
 * __useFindUsersQuery__
 *
 * To run a query within a React component, call `useFindUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUsersQuery({
 *   variables: {
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useFindUsersQuery(baseOptions: Apollo.QueryHookOptions<FindUsersQuery, FindUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUsersQuery, FindUsersQueryVariables>(FindUsersDocument, options);
      }
export function useFindUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUsersQuery, FindUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUsersQuery, FindUsersQueryVariables>(FindUsersDocument, options);
        }
export type FindUsersQueryHookResult = ReturnType<typeof useFindUsersQuery>;
export type FindUsersLazyQueryHookResult = ReturnType<typeof useFindUsersLazyQuery>;
export type FindUsersQueryResult = Apollo.QueryResult<FindUsersQuery, FindUsersQueryVariables>;
export const SendDepartmentBookingResponseDocument = gql`
    mutation sendDepartmentBookingResponse($identifier: String!, $message: String!, $type: UpdateDepartmentAdminTypes!) {
  manageAdminDepartmentBooking(
    identifier: $identifier
    message: $message
    type: $type
  ) {
    ...ExtendedDepartmentBooking
  }
}
    ${ExtendedDepartmentBookingFragmentDoc}`;
export type SendDepartmentBookingResponseMutationFn = Apollo.MutationFunction<SendDepartmentBookingResponseMutation, SendDepartmentBookingResponseMutationVariables>;

/**
 * __useSendDepartmentBookingResponseMutation__
 *
 * To run a mutation, you first call `useSendDepartmentBookingResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDepartmentBookingResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDepartmentBookingResponseMutation, { data, loading, error }] = useSendDepartmentBookingResponseMutation({
 *   variables: {
 *      identifier: // value for 'identifier'
 *      message: // value for 'message'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSendDepartmentBookingResponseMutation(baseOptions?: Apollo.MutationHookOptions<SendDepartmentBookingResponseMutation, SendDepartmentBookingResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendDepartmentBookingResponseMutation, SendDepartmentBookingResponseMutationVariables>(SendDepartmentBookingResponseDocument, options);
      }
export type SendDepartmentBookingResponseMutationHookResult = ReturnType<typeof useSendDepartmentBookingResponseMutation>;
export type SendDepartmentBookingResponseMutationResult = Apollo.MutationResult<SendDepartmentBookingResponseMutation>;
export type SendDepartmentBookingResponseMutationOptions = Apollo.BaseMutationOptions<SendDepartmentBookingResponseMutation, SendDepartmentBookingResponseMutationVariables>;
export const GetUserCalendarDocument = gql`
    query getUserCalendar($from: Timestamp!, $to: Timestamp!) {
  userAbsences(from: $from, to: $to) {
    id
    userID
    timeslot {
      id
      from
      to
    }
    name
    description
  }
  publicVacations(from: $from, to: $to) {
    id
    name
    timeslot {
      id
      from
      to
    }
  }
  departmentBookings(from: $from, to: $to) {
    id
    clerk {
      id
    }
    timeslot {
      id
      from
      to
    }
    departmentService {
      id
      department {
        id
        name
      }
      name
    }
    state
  }
}
    `;

/**
 * __useGetUserCalendarQuery__
 *
 * To run a query within a React component, call `useGetUserCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCalendarQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetUserCalendarQuery(baseOptions: Apollo.QueryHookOptions<GetUserCalendarQuery, GetUserCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserCalendarQuery, GetUserCalendarQueryVariables>(GetUserCalendarDocument, options);
      }
export function useGetUserCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserCalendarQuery, GetUserCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserCalendarQuery, GetUserCalendarQueryVariables>(GetUserCalendarDocument, options);
        }
export type GetUserCalendarQueryHookResult = ReturnType<typeof useGetUserCalendarQuery>;
export type GetUserCalendarLazyQueryHookResult = ReturnType<typeof useGetUserCalendarLazyQuery>;
export type GetUserCalendarQueryResult = Apollo.QueryResult<GetUserCalendarQuery, GetUserCalendarQueryVariables>;
export const WorkingHoursDocument = gql`
    query workingHours {
  workingHours {
    id
    from {
      hour
      minute
    }
    to {
      hour
      minute
    }
    day
  }
}
    `;

/**
 * __useWorkingHoursQuery__
 *
 * To run a query within a React component, call `useWorkingHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingHoursQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkingHoursQuery(baseOptions?: Apollo.QueryHookOptions<WorkingHoursQuery, WorkingHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkingHoursQuery, WorkingHoursQueryVariables>(WorkingHoursDocument, options);
      }
export function useWorkingHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkingHoursQuery, WorkingHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkingHoursQuery, WorkingHoursQueryVariables>(WorkingHoursDocument, options);
        }
export type WorkingHoursQueryHookResult = ReturnType<typeof useWorkingHoursQuery>;
export type WorkingHoursLazyQueryHookResult = ReturnType<typeof useWorkingHoursLazyQuery>;
export type WorkingHoursQueryResult = Apollo.QueryResult<WorkingHoursQuery, WorkingHoursQueryVariables>;
export const LoadDepartmentBookingDocument = gql`
    query loadDepartmentBooking($id: ID!) {
  departmentBooking(id: $id) {
    ...ExtendedDepartmentBooking
  }
}
    ${ExtendedDepartmentBookingFragmentDoc}`;

/**
 * __useLoadDepartmentBookingQuery__
 *
 * To run a query within a React component, call `useLoadDepartmentBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadDepartmentBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadDepartmentBookingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadDepartmentBookingQuery(baseOptions: Apollo.QueryHookOptions<LoadDepartmentBookingQuery, LoadDepartmentBookingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadDepartmentBookingQuery, LoadDepartmentBookingQueryVariables>(LoadDepartmentBookingDocument, options);
      }
export function useLoadDepartmentBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadDepartmentBookingQuery, LoadDepartmentBookingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadDepartmentBookingQuery, LoadDepartmentBookingQueryVariables>(LoadDepartmentBookingDocument, options);
        }
export type LoadDepartmentBookingQueryHookResult = ReturnType<typeof useLoadDepartmentBookingQuery>;
export type LoadDepartmentBookingLazyQueryHookResult = ReturnType<typeof useLoadDepartmentBookingLazyQuery>;
export type LoadDepartmentBookingQueryResult = Apollo.QueryResult<LoadDepartmentBookingQuery, LoadDepartmentBookingQueryVariables>;
export const CreateFlyfishingDocumentDocument = gql`
    mutation createFlyfishingDocument($form: FlyFishingFormInput!) {
  createFlyFishingForm(flyFishingForm: $form) {
    ...FlyFishingDocument
  }
}
    ${FlyFishingDocumentFragmentDoc}`;
export type CreateFlyfishingDocumentMutationFn = Apollo.MutationFunction<CreateFlyfishingDocumentMutation, CreateFlyfishingDocumentMutationVariables>;

/**
 * __useCreateFlyfishingDocumentMutation__
 *
 * To run a mutation, you first call `useCreateFlyfishingDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFlyfishingDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFlyfishingDocumentMutation, { data, loading, error }] = useCreateFlyfishingDocumentMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useCreateFlyfishingDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateFlyfishingDocumentMutation, CreateFlyfishingDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFlyfishingDocumentMutation, CreateFlyfishingDocumentMutationVariables>(CreateFlyfishingDocumentDocument, options);
      }
export type CreateFlyfishingDocumentMutationHookResult = ReturnType<typeof useCreateFlyfishingDocumentMutation>;
export type CreateFlyfishingDocumentMutationResult = Apollo.MutationResult<CreateFlyfishingDocumentMutation>;
export type CreateFlyfishingDocumentMutationOptions = Apollo.BaseMutationOptions<CreateFlyfishingDocumentMutation, CreateFlyfishingDocumentMutationVariables>;
export const FlyFishingDocumentsDocument = gql`
    query FlyFishingDocuments {
  flyFishingDocument {
    ...FlyFishingDocument
  }
}
    ${FlyFishingDocumentFragmentDoc}`;

/**
 * __useFlyFishingDocumentsQuery__
 *
 * To run a query within a React component, call `useFlyFishingDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlyFishingDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlyFishingDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFlyFishingDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<FlyFishingDocumentsQuery, FlyFishingDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FlyFishingDocumentsQuery, FlyFishingDocumentsQueryVariables>(FlyFishingDocumentsDocument, options);
      }
export function useFlyFishingDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FlyFishingDocumentsQuery, FlyFishingDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FlyFishingDocumentsQuery, FlyFishingDocumentsQueryVariables>(FlyFishingDocumentsDocument, options);
        }
export type FlyFishingDocumentsQueryHookResult = ReturnType<typeof useFlyFishingDocumentsQuery>;
export type FlyFishingDocumentsLazyQueryHookResult = ReturnType<typeof useFlyFishingDocumentsLazyQuery>;
export type FlyFishingDocumentsQueryResult = Apollo.QueryResult<FlyFishingDocumentsQuery, FlyFishingDocumentsQueryVariables>;
export const CreateUserAbsenceDocument = gql`
    mutation createUserAbsence($description: String, $from: Timestamp!, $to: Timestamp!, $name: String!, $periodRule: String, $periodTo: Timestamp) {
  createAbsence(
    absence: {description: $description, from: $from, to: $to, name: $name, periodRule: $periodRule, periodTo: $periodTo}
  ) {
    ...ExtendedAbsence
  }
}
    ${ExtendedAbsenceFragmentDoc}`;
export type CreateUserAbsenceMutationFn = Apollo.MutationFunction<CreateUserAbsenceMutation, CreateUserAbsenceMutationVariables>;

/**
 * __useCreateUserAbsenceMutation__
 *
 * To run a mutation, you first call `useCreateUserAbsenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserAbsenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserAbsenceMutation, { data, loading, error }] = useCreateUserAbsenceMutation({
 *   variables: {
 *      description: // value for 'description'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      name: // value for 'name'
 *      periodRule: // value for 'periodRule'
 *      periodTo: // value for 'periodTo'
 *   },
 * });
 */
export function useCreateUserAbsenceMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserAbsenceMutation, CreateUserAbsenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserAbsenceMutation, CreateUserAbsenceMutationVariables>(CreateUserAbsenceDocument, options);
      }
export type CreateUserAbsenceMutationHookResult = ReturnType<typeof useCreateUserAbsenceMutation>;
export type CreateUserAbsenceMutationResult = Apollo.MutationResult<CreateUserAbsenceMutation>;
export type CreateUserAbsenceMutationOptions = Apollo.BaseMutationOptions<CreateUserAbsenceMutation, CreateUserAbsenceMutationVariables>;
export const UpdateAbsenceDocument = gql`
    mutation updateAbsence($id: ID!, $description: String, $from: Timestamp!, $to: Timestamp!, $name: String!) {
  updateAbsence(
    id: $id
    absence: {description: $description, from: $from, to: $to, name: $name}
  ) {
    ...ExtendedAbsence
  }
}
    ${ExtendedAbsenceFragmentDoc}`;
export type UpdateAbsenceMutationFn = Apollo.MutationFunction<UpdateAbsenceMutation, UpdateAbsenceMutationVariables>;

/**
 * __useUpdateAbsenceMutation__
 *
 * To run a mutation, you first call `useUpdateAbsenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAbsenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAbsenceMutation, { data, loading, error }] = useUpdateAbsenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateAbsenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAbsenceMutation, UpdateAbsenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAbsenceMutation, UpdateAbsenceMutationVariables>(UpdateAbsenceDocument, options);
      }
export type UpdateAbsenceMutationHookResult = ReturnType<typeof useUpdateAbsenceMutation>;
export type UpdateAbsenceMutationResult = Apollo.MutationResult<UpdateAbsenceMutation>;
export type UpdateAbsenceMutationOptions = Apollo.BaseMutationOptions<UpdateAbsenceMutation, UpdateAbsenceMutationVariables>;
export const DeleteAbsenceDocument = gql`
    mutation deleteAbsence($id: ID!, $deletePeriod: Boolean) {
  deleteAbsence(id: $id, deletePeriod: $deletePeriod) {
    affectedRows
  }
}
    `;
export type DeleteAbsenceMutationFn = Apollo.MutationFunction<DeleteAbsenceMutation, DeleteAbsenceMutationVariables>;

/**
 * __useDeleteAbsenceMutation__
 *
 * To run a mutation, you first call `useDeleteAbsenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAbsenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAbsenceMutation, { data, loading, error }] = useDeleteAbsenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deletePeriod: // value for 'deletePeriod'
 *   },
 * });
 */
export function useDeleteAbsenceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAbsenceMutation, DeleteAbsenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAbsenceMutation, DeleteAbsenceMutationVariables>(DeleteAbsenceDocument, options);
      }
export type DeleteAbsenceMutationHookResult = ReturnType<typeof useDeleteAbsenceMutation>;
export type DeleteAbsenceMutationResult = Apollo.MutationResult<DeleteAbsenceMutation>;
export type DeleteAbsenceMutationOptions = Apollo.BaseMutationOptions<DeleteAbsenceMutation, DeleteAbsenceMutationVariables>;
export const CreateWorkingHourDocument = gql`
    mutation createWorkingHour($workingHour: UpdateWorkingHour!) {
  createWorkingHour(workingHour: $workingHour) {
    id
    from {
      hour
      minute
    }
    to {
      hour
      minute
    }
    day
  }
}
    `;
export type CreateWorkingHourMutationFn = Apollo.MutationFunction<CreateWorkingHourMutation, CreateWorkingHourMutationVariables>;

/**
 * __useCreateWorkingHourMutation__
 *
 * To run a mutation, you first call `useCreateWorkingHourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkingHourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkingHourMutation, { data, loading, error }] = useCreateWorkingHourMutation({
 *   variables: {
 *      workingHour: // value for 'workingHour'
 *   },
 * });
 */
export function useCreateWorkingHourMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkingHourMutation, CreateWorkingHourMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkingHourMutation, CreateWorkingHourMutationVariables>(CreateWorkingHourDocument, options);
      }
export type CreateWorkingHourMutationHookResult = ReturnType<typeof useCreateWorkingHourMutation>;
export type CreateWorkingHourMutationResult = Apollo.MutationResult<CreateWorkingHourMutation>;
export type CreateWorkingHourMutationOptions = Apollo.BaseMutationOptions<CreateWorkingHourMutation, CreateWorkingHourMutationVariables>;
export const UpdateWorkingHourDocument = gql`
    mutation updateWorkingHour($id: ID!, $workingHour: UpdateWorkingHour!) {
  updateWorkingHour(id: $id, workingHour: $workingHour) {
    id
    from {
      hour
      minute
    }
    to {
      hour
      minute
    }
    day
  }
}
    `;
export type UpdateWorkingHourMutationFn = Apollo.MutationFunction<UpdateWorkingHourMutation, UpdateWorkingHourMutationVariables>;

/**
 * __useUpdateWorkingHourMutation__
 *
 * To run a mutation, you first call `useUpdateWorkingHourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkingHourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkingHourMutation, { data, loading, error }] = useUpdateWorkingHourMutation({
 *   variables: {
 *      id: // value for 'id'
 *      workingHour: // value for 'workingHour'
 *   },
 * });
 */
export function useUpdateWorkingHourMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkingHourMutation, UpdateWorkingHourMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkingHourMutation, UpdateWorkingHourMutationVariables>(UpdateWorkingHourDocument, options);
      }
export type UpdateWorkingHourMutationHookResult = ReturnType<typeof useUpdateWorkingHourMutation>;
export type UpdateWorkingHourMutationResult = Apollo.MutationResult<UpdateWorkingHourMutation>;
export type UpdateWorkingHourMutationOptions = Apollo.BaseMutationOptions<UpdateWorkingHourMutation, UpdateWorkingHourMutationVariables>;
export const DeleteWorkingHourDocument = gql`
    mutation deleteWorkingHour($id: ID!) {
  deleteWorkingHour(id: $id) {
    affectedRows
  }
}
    `;
export type DeleteWorkingHourMutationFn = Apollo.MutationFunction<DeleteWorkingHourMutation, DeleteWorkingHourMutationVariables>;

/**
 * __useDeleteWorkingHourMutation__
 *
 * To run a mutation, you first call `useDeleteWorkingHourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkingHourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkingHourMutation, { data, loading, error }] = useDeleteWorkingHourMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkingHourMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkingHourMutation, DeleteWorkingHourMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkingHourMutation, DeleteWorkingHourMutationVariables>(DeleteWorkingHourDocument, options);
      }
export type DeleteWorkingHourMutationHookResult = ReturnType<typeof useDeleteWorkingHourMutation>;
export type DeleteWorkingHourMutationResult = Apollo.MutationResult<DeleteWorkingHourMutation>;
export type DeleteWorkingHourMutationOptions = Apollo.BaseMutationOptions<DeleteWorkingHourMutation, DeleteWorkingHourMutationVariables>;
export const LoadAbsenceDocument = gql`
    query loadAbsence($id: ID!) {
  userAbsence(id: $id) {
    ...ExtendedAbsence
  }
}
    ${ExtendedAbsenceFragmentDoc}`;

/**
 * __useLoadAbsenceQuery__
 *
 * To run a query within a React component, call `useLoadAbsenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAbsenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAbsenceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadAbsenceQuery(baseOptions: Apollo.QueryHookOptions<LoadAbsenceQuery, LoadAbsenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadAbsenceQuery, LoadAbsenceQueryVariables>(LoadAbsenceDocument, options);
      }
export function useLoadAbsenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadAbsenceQuery, LoadAbsenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadAbsenceQuery, LoadAbsenceQueryVariables>(LoadAbsenceDocument, options);
        }
export type LoadAbsenceQueryHookResult = ReturnType<typeof useLoadAbsenceQuery>;
export type LoadAbsenceLazyQueryHookResult = ReturnType<typeof useLoadAbsenceLazyQuery>;
export type LoadAbsenceQueryResult = Apollo.QueryResult<LoadAbsenceQuery, LoadAbsenceQueryVariables>;
export type CreatePublicVacationMutationVariables = Exact<{
  vacation: UpdatePublicVation;
}>;


export type CreatePublicVacationMutation = { __typename?: 'Mutation', createPublicVacation: { __typename?: 'PublicVacation', id: string, name: string, timeslot: { __typename?: 'Timeslot', id: string, from: any, to: any } } };

export type UpdatePublicVacationMutationVariables = Exact<{
  id: Scalars['ID'];
  vacation: UpdatePublicVation;
}>;


export type UpdatePublicVacationMutation = { __typename?: 'Mutation', updatePublicVacation: { __typename?: 'PublicVacation', id: string, name: string, timeslot: { __typename?: 'Timeslot', id: string, from: any, to: any } } };

export type DeletePublicVacationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePublicVacationMutation = { __typename?: 'Mutation', deletePublicVacation: { __typename?: 'DeletionPayload', affectedRows: number } };

export type CreateDepartmentServiceMutationVariables = Exact<{
  departmentID: Scalars['ID'];
  service: UpdateDepartmentService;
}>;


export type CreateDepartmentServiceMutation = { __typename?: 'Mutation', createDepartmentService: { __typename?: 'DepartmentService', id: string, name: string, duration: number, additional?: Maybe<string>, departmentID: string, users?: Maybe<Array<{ __typename?: 'User', id?: Maybe<string>, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, fullName?: Maybe<string> }>> } };

export type UpdateDepartmentServiceMutationVariables = Exact<{
  id: Scalars['ID'];
  service: UpdateDepartmentService;
}>;


export type UpdateDepartmentServiceMutation = { __typename?: 'Mutation', updateDepartmentService: { __typename?: 'DepartmentService', id: string, name: string, duration: number, additional?: Maybe<string>, departmentID: string, users?: Maybe<Array<{ __typename?: 'User', id?: Maybe<string>, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, fullName?: Maybe<string> }>> } };

export type AssignDepartmentServiceClerkMutationVariables = Exact<{
  serviceId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type AssignDepartmentServiceClerkMutation = { __typename?: 'Mutation', assignDepartmentServiceClerk: { __typename?: 'ObjectAccessAssignment', userId: string, objectID: string } };

export type DeassignDepartmentServiceClerkMutationVariables = Exact<{
  serviceId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type DeassignDepartmentServiceClerkMutation = { __typename?: 'Mutation', deassignDepartmentServiceClerk: { __typename?: 'DeletionPayload', affectedRows: number } };

export type DeleteDepartmentServiceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDepartmentServiceMutation = { __typename?: 'Mutation', deleteDepartmentService: { __typename?: 'DeletionPayload', affectedRows: number } };

export type CreateDepartmentMutationVariables = Exact<{
  department: UpdateDepartment;
}>;


export type CreateDepartmentMutation = { __typename?: 'Mutation', createDepartment: { __typename?: 'Department', id: string, name: string, description?: Maybe<string>, services: Array<{ __typename?: 'DepartmentService', id: string, name: string, duration: number, additional?: Maybe<string>, departmentID: string, users?: Maybe<Array<{ __typename?: 'User', id?: Maybe<string>, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, fullName?: Maybe<string> }>> }>, users: Array<{ __typename?: 'User', id?: Maybe<string>, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, fullName?: Maybe<string> }> } };

export type UpdateDepartmentMutationVariables = Exact<{
  id: Scalars['ID'];
  department: UpdateDepartment;
}>;


export type UpdateDepartmentMutation = { __typename?: 'Mutation', updateDepartment: { __typename?: 'Department', id: string, name: string, description?: Maybe<string>, services: Array<{ __typename?: 'DepartmentService', id: string, name: string, duration: number, additional?: Maybe<string>, departmentID: string, users?: Maybe<Array<{ __typename?: 'User', id?: Maybe<string>, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, fullName?: Maybe<string> }>> }>, users: Array<{ __typename?: 'User', id?: Maybe<string>, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, fullName?: Maybe<string> }> } };

export type AssignDepartmentAdminMutationVariables = Exact<{
  departmentId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type AssignDepartmentAdminMutation = { __typename?: 'Mutation', assignDepartmentAdmin: { __typename?: 'ObjectAccessAssignment', userId: string, objectID: string } };

export type DeassignDepartmentAdminMutationVariables = Exact<{
  departmentId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type DeassignDepartmentAdminMutation = { __typename?: 'Mutation', deassignDepartmentAdmin: { __typename?: 'DeletionPayload', affectedRows: number } };

export type DeleteDepartmentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDepartmentMutation = { __typename?: 'Mutation', deleteDepartment: { __typename?: 'DeletionPayload', affectedRows: number } };

export type PublicVacationFragment = { __typename?: 'PublicVacation', id: string, name: string, timeslot: { __typename?: 'Timeslot', id: string, from: any, to: any } };

export type DepartmentServiceFragment = { __typename?: 'DepartmentService', id: string, name: string, duration: number, additional?: Maybe<string>, departmentID: string, users?: Maybe<Array<{ __typename?: 'User', id?: Maybe<string>, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, fullName?: Maybe<string> }>> };

export type DepartmentFragment = { __typename?: 'Department', id: string, name: string, description?: Maybe<string>, services: Array<{ __typename?: 'DepartmentService', id: string, name: string, duration: number, additional?: Maybe<string>, departmentID: string, users?: Maybe<Array<{ __typename?: 'User', id?: Maybe<string>, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, fullName?: Maybe<string> }>> }>, users: Array<{ __typename?: 'User', id?: Maybe<string>, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, fullName?: Maybe<string> }> };

export type PublicVacationQueryVariables = Exact<{
  from: Scalars['Timestamp'];
  to: Scalars['Timestamp'];
}>;


export type PublicVacationQuery = { __typename?: 'Query', publicVacations: Array<{ __typename?: 'PublicVacation', id: string, name: string, timeslot: { __typename?: 'Timeslot', id: string, from: any, to: any } }> };

export type DepartmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type DepartmentsQuery = { __typename?: 'Query', departments: Array<{ __typename?: 'Department', id: string, name: string, description?: Maybe<string>, services: Array<{ __typename?: 'DepartmentService', id: string, name: string, duration: number, additional?: Maybe<string>, departmentID: string, users?: Maybe<Array<{ __typename?: 'User', id?: Maybe<string>, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, fullName?: Maybe<string> }>> }>, users: Array<{ __typename?: 'User', id?: Maybe<string>, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, fullName?: Maybe<string> }> }> };

export type CheckAccessQueryVariables = Exact<{
  mode: AccessModes;
  objectID: Scalars['ID'];
  tableName: ObjectAccessTable;
  tableType: TableType;
}>;


export type CheckAccessQuery = { __typename?: 'Query', checkAccess: boolean };

export type FindUsersQueryVariables = Exact<{
  identifier: Scalars['String'];
}>;


export type FindUsersQuery = { __typename?: 'Query', findUser: Array<{ __typename?: 'User', id?: Maybe<string>, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, fullName?: Maybe<string>, imageURL?: Maybe<string> }> };

export type SendDepartmentBookingResponseMutationVariables = Exact<{
  identifier: Scalars['String'];
  message: Scalars['String'];
  type: UpdateDepartmentAdminTypes;
}>;


export type SendDepartmentBookingResponseMutation = { __typename?: 'Mutation', manageAdminDepartmentBooking: { __typename?: 'DepartmentBooking', id: string, state: DepartmentBookingState, identifier: string, timeslot: { __typename?: 'Timeslot', id: string, from: any, to: any, periodFrom?: Maybe<any>, periodTo?: Maybe<any>, periodId?: Maybe<string>, periodRule?: Maybe<string>, periodEndless?: Maybe<boolean> }, departmentService: { __typename?: 'DepartmentServiceBookings', id: string, name: string, duration: number, additional?: Maybe<string>, department: { __typename?: 'BookingDepartment', id: string, name: string, description?: Maybe<string> } }, customer: { __typename?: 'PersonData', id: string, firstName: string, lastName: string, email: string, phone?: Maybe<string> }, clerk?: Maybe<{ __typename?: 'User', id?: Maybe<string> }> } };

export type GetUserCalendarQueryVariables = Exact<{
  from: Scalars['Timestamp'];
  to: Scalars['Timestamp'];
}>;


export type GetUserCalendarQuery = { __typename?: 'Query', userAbsences: Array<{ __typename?: 'Absence', id: string, userID: string, name: string, description?: Maybe<string>, timeslot: { __typename?: 'Timeslot', id: string, from: any, to: any } }>, publicVacations: Array<{ __typename?: 'PublicVacation', id: string, name: string, timeslot: { __typename?: 'Timeslot', id: string, from: any, to: any } }>, departmentBookings: Array<{ __typename?: 'DepartmentBooking', id: string, state: DepartmentBookingState, clerk?: Maybe<{ __typename?: 'User', id?: Maybe<string> }>, timeslot: { __typename?: 'Timeslot', id: string, from: any, to: any }, departmentService: { __typename?: 'DepartmentServiceBookings', id: string, name: string, department: { __typename?: 'BookingDepartment', id: string, name: string } } }> };

export type WorkingHoursQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkingHoursQuery = { __typename?: 'Query', workingHours: Array<{ __typename?: 'WorkingHour', id: string, day: WorkingHourDay, from: { __typename?: 'WorkingHourRange', hour: number, minute: number }, to: { __typename?: 'WorkingHourRange', hour: number, minute: number } }> };

export type ExtendedDepartmentBookingFragment = { __typename?: 'DepartmentBooking', id: string, state: DepartmentBookingState, identifier: string, timeslot: { __typename?: 'Timeslot', id: string, from: any, to: any, periodFrom?: Maybe<any>, periodTo?: Maybe<any>, periodId?: Maybe<string>, periodRule?: Maybe<string>, periodEndless?: Maybe<boolean> }, departmentService: { __typename?: 'DepartmentServiceBookings', id: string, name: string, duration: number, additional?: Maybe<string>, department: { __typename?: 'BookingDepartment', id: string, name: string, description?: Maybe<string> } }, customer: { __typename?: 'PersonData', id: string, firstName: string, lastName: string, email: string, phone?: Maybe<string> }, clerk?: Maybe<{ __typename?: 'User', id?: Maybe<string> }> };

export type LoadDepartmentBookingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LoadDepartmentBookingQuery = { __typename?: 'Query', departmentBooking: { __typename?: 'DepartmentBooking', id: string, state: DepartmentBookingState, identifier: string, timeslot: { __typename?: 'Timeslot', id: string, from: any, to: any, periodFrom?: Maybe<any>, periodTo?: Maybe<any>, periodId?: Maybe<string>, periodRule?: Maybe<string>, periodEndless?: Maybe<boolean> }, departmentService: { __typename?: 'DepartmentServiceBookings', id: string, name: string, duration: number, additional?: Maybe<string>, department: { __typename?: 'BookingDepartment', id: string, name: string, description?: Maybe<string> } }, customer: { __typename?: 'PersonData', id: string, firstName: string, lastName: string, email: string, phone?: Maybe<string> }, clerk?: Maybe<{ __typename?: 'User', id?: Maybe<string> }> } };

export type CreateFlyfishingDocumentMutationVariables = Exact<{
  form: FlyFishingFormInput;
}>;


export type CreateFlyfishingDocumentMutation = { __typename?: 'Mutation', createFlyFishingForm: { __typename?: 'FlyFishingForm', id: string, city: string, withGuestCard: boolean, country: string, lodging?: Maybe<string>, name: string, price: number, street: string, variant: FlyFishingTicketVariants, meta_creation: any, tickets: Array<{ __typename?: 'FlyFishingTicket', dateFrom: any, dateTo: any, route: FlyFishingRoutes }> } };

export type FlyFishingDocumentsQueryVariables = Exact<{ [key: string]: never; }>;


export type FlyFishingDocumentsQuery = { __typename?: 'Query', flyFishingDocument?: Maybe<Array<{ __typename?: 'FlyFishingForm', id: string, city: string, withGuestCard: boolean, country: string, lodging?: Maybe<string>, name: string, price: number, street: string, variant: FlyFishingTicketVariants, meta_creation: any, tickets: Array<{ __typename?: 'FlyFishingTicket', dateFrom: any, dateTo: any, route: FlyFishingRoutes }> }>> };

export type FlyFishingDocumentFragment = { __typename?: 'FlyFishingForm', id: string, city: string, withGuestCard: boolean, country: string, lodging?: Maybe<string>, name: string, price: number, street: string, variant: FlyFishingTicketVariants, meta_creation: any, tickets: Array<{ __typename?: 'FlyFishingTicket', dateFrom: any, dateTo: any, route: FlyFishingRoutes }> };

export type CreateUserAbsenceMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
  from: Scalars['Timestamp'];
  to: Scalars['Timestamp'];
  name: Scalars['String'];
  periodRule?: Maybe<Scalars['String']>;
  periodTo?: Maybe<Scalars['Timestamp']>;
}>;


export type CreateUserAbsenceMutation = { __typename?: 'Mutation', createAbsence: Array<Maybe<{ __typename?: 'Absence', id: string, name: string, description?: Maybe<string>, timeslot: { __typename?: 'Timeslot', id: string, from: any, to: any, periodFrom?: Maybe<any>, periodTo?: Maybe<any>, periodId?: Maybe<string>, periodRule?: Maybe<string>, periodEndless?: Maybe<boolean> } }>> };

export type UpdateAbsenceMutationVariables = Exact<{
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  from: Scalars['Timestamp'];
  to: Scalars['Timestamp'];
  name: Scalars['String'];
}>;


export type UpdateAbsenceMutation = { __typename?: 'Mutation', updateAbsence: { __typename?: 'Absence', id: string, name: string, description?: Maybe<string>, timeslot: { __typename?: 'Timeslot', id: string, from: any, to: any, periodFrom?: Maybe<any>, periodTo?: Maybe<any>, periodId?: Maybe<string>, periodRule?: Maybe<string>, periodEndless?: Maybe<boolean> } } };

export type DeleteAbsenceMutationVariables = Exact<{
  id: Scalars['ID'];
  deletePeriod?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteAbsenceMutation = { __typename?: 'Mutation', deleteAbsence: { __typename?: 'DeletionPayload', affectedRows: number } };

export type CreateWorkingHourMutationVariables = Exact<{
  workingHour: UpdateWorkingHour;
}>;


export type CreateWorkingHourMutation = { __typename?: 'Mutation', createWorkingHour: { __typename?: 'WorkingHour', id: string, day: WorkingHourDay, from: { __typename?: 'WorkingHourRange', hour: number, minute: number }, to: { __typename?: 'WorkingHourRange', hour: number, minute: number } } };

export type UpdateWorkingHourMutationVariables = Exact<{
  id: Scalars['ID'];
  workingHour: UpdateWorkingHour;
}>;


export type UpdateWorkingHourMutation = { __typename?: 'Mutation', updateWorkingHour: { __typename?: 'WorkingHour', id: string, day: WorkingHourDay, from: { __typename?: 'WorkingHourRange', hour: number, minute: number }, to: { __typename?: 'WorkingHourRange', hour: number, minute: number } } };

export type DeleteWorkingHourMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteWorkingHourMutation = { __typename?: 'Mutation', deleteWorkingHour: { __typename?: 'DeletionPayload', affectedRows: number } };

export type LoadAbsenceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LoadAbsenceQuery = { __typename?: 'Query', userAbsence?: Maybe<{ __typename?: 'Absence', id: string, name: string, description?: Maybe<string>, timeslot: { __typename?: 'Timeslot', id: string, from: any, to: any, periodFrom?: Maybe<any>, periodTo?: Maybe<any>, periodId?: Maybe<string>, periodRule?: Maybe<string>, periodEndless?: Maybe<boolean> } }> };

export type ExtendedAbsenceFragment = { __typename?: 'Absence', id: string, name: string, description?: Maybe<string>, timeslot: { __typename?: 'Timeslot', id: string, from: any, to: any, periodFrom?: Maybe<any>, periodTo?: Maybe<any>, periodId?: Maybe<string>, periodRule?: Maybe<string>, periodEndless?: Maybe<boolean> } };

export const namedOperations = {
  Query: {
    publicVacation: 'publicVacation',
    departments: 'departments',
    checkAccess: 'checkAccess',
    findUsers: 'findUsers',
    getUserCalendar: 'getUserCalendar',
    workingHours: 'workingHours',
    loadDepartmentBooking: 'loadDepartmentBooking',
    FlyFishingDocuments: 'FlyFishingDocuments',
    loadAbsence: 'loadAbsence'
  },
  Mutation: {
    createPublicVacation: 'createPublicVacation',
    updatePublicVacation: 'updatePublicVacation',
    deletePublicVacation: 'deletePublicVacation',
    createDepartmentService: 'createDepartmentService',
    updateDepartmentService: 'updateDepartmentService',
    assignDepartmentServiceClerk: 'assignDepartmentServiceClerk',
    deassignDepartmentServiceClerk: 'deassignDepartmentServiceClerk',
    deleteDepartmentService: 'deleteDepartmentService',
    createDepartment: 'createDepartment',
    updateDepartment: 'updateDepartment',
    assignDepartmentAdmin: 'assignDepartmentAdmin',
    deassignDepartmentAdmin: 'deassignDepartmentAdmin',
    deleteDepartment: 'deleteDepartment',
    sendDepartmentBookingResponse: 'sendDepartmentBookingResponse',
    createFlyfishingDocument: 'createFlyfishingDocument',
    createUserAbsence: 'createUserAbsence',
    updateAbsence: 'updateAbsence',
    deleteAbsence: 'deleteAbsence',
    createWorkingHour: 'createWorkingHour',
    updateWorkingHour: 'updateWorkingHour',
    deleteWorkingHour: 'deleteWorkingHour'
  },
  Fragment: {
    PublicVacation: 'PublicVacation',
    DepartmentService: 'DepartmentService',
    Department: 'Department',
    ExtendedDepartmentBooking: 'ExtendedDepartmentBooking',
    FlyFishingDocument: 'FlyFishingDocument',
    ExtendedAbsence: 'ExtendedAbsence'
  }
}