import React from "react";
import "./ButtonSpinner.sass";

const ButtonSpinner = () => (
	<div className="button-spinner">
		<div className="bounce1" />
		<div className="bounce2" />
		<div className="bounce3" />
	</div>
);

export default ButtonSpinner;
