import React, { useEffect } from "react";
import "./Login.scss";
import Action from "../../Utils/Redux/Action";
import BrowserHistory from "../../Utils/BrowserHistory";
import Backend from "../../Utils/Backend/Backend";
import Notify from "../../Utils/Notify";
import Customization from "../../Utils/Customization";
import { Session } from "../../Utils/Session";
import MiniPages from "../MiniPages/MiniPages";
import PasswordChange from "../MiniPages/Pages/PasswordChange";
import ThemeChange from "../MiniPages/Pages/ThemeChange";
import { useAuth } from "../../Auth/AuthContext";

const Login = () => {
	const { isAuthenticated, isLoading, login } = useAuth();
	const [currentStep, updateStep] = React.useState(0);
	const [loadingText, updateText] = React.useState("Sie werden eingeloggt ...");
	const [busy, setBusy] = React.useState(true);

	const passwordChangeError = () => {
		setBusy(false);
		updateText("Es ist ein Fehler bei Ihrer Anmeldung aufgetreten ...");
	};
	const changePassword = async (password: string) => {
		setBusy(true);
		updateText("Ihr Passwort wird geändert ...");

		Backend.put<any>("/base/password", { password }, false)
			.then(() => {
				updateText("Ihr Passwort wurde erfolgreich geändert. Bitte melden Sie sich neu an.");
				setTimeout(() => {
					window.location.reload();
				}, 2500);
			})
			.catch((res) => {
				Notify.e(res.data);
				passwordChangeError();
			});
	};

	const redirect = () => {
		setTimeout(() => {
			setBusy(false);
			const loginRedirect = Session.get("login_redirect", false);
			if (loginRedirect && loginRedirect.indexOf("/login") === -1 && loginRedirect !== "/") {
				setTimeout(() => BrowserHistory.push(loginRedirect), 250);
			} else {
				setTimeout(() => BrowserHistory.push("/dashboard"), 250);
			}
		}, 500);
	};

	const changeTheme = () => {
		setBusy(true);
		updateText("Ihr Einstellungen werden gespeichert ...");
		setTimeout(() => {
			setBusy(false);
			setTimeout(() => {
				redirect();
			}, 300);
		}, 500);
	};
	const checkSteps = async () => {
		try {
			const response = await Backend.get<{ forcePasswordChange: boolean; forceThemeChange: boolean }>("/base/password", false);
			Customization.getUserPreference("colorTheme").then((theme) => {
				if (theme) Action.darkLightChange(theme);
			});

			if (response.data.forcePasswordChange === true) {
				setBusy(false);
				updateStep(0);
			} else if (response.data.forceThemeChange === true) {
				setBusy(false);
				updateStep(1);
			} else {
				redirect();
			}
		} catch (ex) {
			updateText("Wir konnten Sie nicht anmelden. Wir probieren es erneut ...");
			setTimeout(() => {
				window.location.reload();
			}, 1500);
		}
	};

	useEffect(() => {
		if (isAuthenticated) checkSteps();
		if (!isLoading && !isAuthenticated) login();
	});

	return (
		<MiniPages currentStep={currentStep} isLoading={busy} loadingText={loadingText} onPageChange={updateStep}>
			<PasswordChange pwChange onPasswordSubmit={changePassword} />
			<ThemeChange onThemeChanged={changeTheme} />
		</MiniPages>
	);
};

export default Login;
