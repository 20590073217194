import React from "react";
import { Card } from "@onedash/tools";
import dayjs from "dayjs";
import { Form, Numeric } from "onedash-react-input-form";
import {
	namedOperations,
	useCreateWorkingHourMutation,
	useDeleteWorkingHourMutation,
	useUpdateWorkingHourMutation,
	useWorkingHoursQuery,
} from "../../Resources/generated/gql-types";
import { GQLAppContext } from "../../Utils/Backend/GQLAppContext";

const WorkingHours = () => {
	const { data: workingHourData } = useWorkingHoursQuery(GQLAppContext());

	const [createWorkingHour, { loading: createLoading }] = useCreateWorkingHourMutation(
		GQLAppContext({
			refetchQueries: [namedOperations.Query.workingHours],
		})
	);
	const [deleteWorkingHour, { loading: deleteLoading }] = useDeleteWorkingHourMutation(
		GQLAppContext({
			refetchQueries: [namedOperations.Query.workingHours],
		})
	);

	const [updateWorkingHour, { loading: updateLoading }] = useUpdateWorkingHourMutation(GQLAppContext());

	const loading = createLoading || updateLoading || deleteLoading;

	const hours = workingHourData?.workingHours;

	const changeWorkingHours = (id: string, values: any, _form: Form, valid: boolean) => {
		if (!valid) return;
		const day = hours?.find((x) => x.id === id)?.day;
		if (!day) return;
		updateWorkingHour({
			variables: {
				id,
				workingHour: {
					day,
					from: values.from,
					to: values.to,
				},
			},
		});
	};

	return (
		<Card title="Arbeitszeiten" className="working-hours">
			{[...Array(5)].map((_, i) => (
				<div key={i as any} className="working-day">
					<h2>
						{dayjs()
							.startOf("day")
							.set("day", i + 1)
							.format("dddd")}
					</h2>
					{hours
						?.filter(
							(x) =>
								x.day ===
								dayjs()
									.startOf("day")
									.set("day", i + 1)
									.locale("en")
									.format("dd")
									.toUpperCase()
						)
						.map((hour) => (
							<Form key={hour.id} onChange={(values, form, valid) => changeWorkingHours(hour.id, values, form, valid)}>
								<div className="grid-split-3-icon">
									<div className="grid-split-label">
										<p className="label">Von</p>
										<Numeric
											positiveOnly
											maxValue={23}
											required
											icon={<>h</>}
											label="Stunde"
											name="from.hour"
											value={hour.from.hour}
											saveDelay={1000}
										/>
										<Numeric
											positiveOnly
											maxValue={59}
											required
											icon={<>m</>}
											label="Minute"
											name="from.minute"
											value={hour.from.minute}
											saveDelay={1000}
										/>
									</div>
									<div className="grid-split-label">
										<p className="label">Bis</p>
										<Numeric
											positiveOnly
											maxValue={23}
											required
											icon={<>h</>}
											label="Stunde"
											name="to.hour"
											value={hour.to.hour}
											saveDelay={1000}
										/>
										<Numeric
											positiveOnly
											maxValue={59}
											required
											icon={<>m</>}
											label="Minute"
											name="to.minute"
											value={hour.to.minute}
											saveDelay={1000}
										/>
									</div>
									<button
										disabled={loading}
										className="btn delete"
										onClick={() => deleteWorkingHour({ variables: { id: hour.id } })}>
										<i className="im im-x-mark" />
									</button>
								</div>
							</Form>
						))}
					<button
						disabled={loading}
						onClick={() =>
							createWorkingHour({
								variables: {
									workingHour: {
										day: dayjs()
											.startOf("day")
											.set("day", i + 1)
											.locale("en")
											.format("dd")
											.toUpperCase() as any,
										from: { hour: 9, minute: 0 },
										to: { hour: 16, minute: 0 },
									},
								},
							})
						}
						className="btn highlight-btn">
						Arbeitszeit hinzufügen
					</button>
				</div>
			))}
		</Card>
	);
};

export default WorkingHours;
