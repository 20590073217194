import React, { Component } from "react";
import "./Toolbar.scss";
import Action from "../../../Utils/Redux/Action";

interface ToolbarProps {
	split?: boolean;
	sticky?: boolean;
}

class Toolbar extends Component<ToolbarProps> {
	componentDidMount() {
		Action.hasToolbar(true);
	}

	calcClassName = () => {
		let className = "head-toolbar";
		if (this.props.split) {
			className += " split";
		}
		if (this.props.sticky) {
			className += " sticky";
		}
		return className;
	};

	render() {
		return <div className={this.calcClassName()}>{this.props.children}</div>;
	}
}

export default Toolbar;
