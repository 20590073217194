import React from "react";
import { Dialog, usePrompt } from "onedash-dialog";
import { Spinner } from "@onedash/tools";
import Collapsible from "react-collapsible";
import dayjs from "dayjs";
import {
	DepartmentBooking,
	DepartmentBookingState,
	UpdateDepartmentAdminTypes,
	useCreateUserAbsenceMutation,
	useSendDepartmentBookingResponseMutation,
} from "../../Resources/generated/gql-types";
import Backend from "../../Utils/Backend/Backend";
import { GQLAppContext } from "../../Utils/Backend/GQLAppContext";
import { useAuth } from "../../Auth/AuthContext";

interface BookingDetailsProps {
	onClose: () => void;
	departmentBooking?: DepartmentBooking;
	loading?: boolean;
	visible?: boolean;
}

const BookingDetails = ({ onClose, departmentBooking, loading, visible: dialogVisible }: BookingDetailsProps) => {
	const target = document.querySelector(".dashboard");
	const [visible, updateVisible] = React.useState(departmentBooking !== undefined);
	const event = departmentBooking;
	const { user } = useAuth();
	const { buttonsPrompt } = usePrompt();

	const [sendDepartmentBookingResponse, { loading: sendDepartmentBookingResponseLoading }] = useSendDepartmentBookingResponseMutation({
		context: {
			headers: {
				"app-id": Backend.getAppID(),
			},
		},
	});
	const [createUserAbsence, { loading: createUserAbsenceLoading }] = useCreateUserAbsenceMutation(GQLAppContext());

	loading = loading || sendDepartmentBookingResponseLoading || createUserAbsenceLoading;

	const showSidebar = React.useCallback(
		(show: boolean) => {
			if (!target) return;
			if (show) {
				target.classList.add("has-sidebar-v2");
			} else {
				target.classList.remove("has-sidebar-v2");
			}
			updateVisible(show);
		},
		[target]
	);

	React.useEffect(() => {
		showSidebar(dialogVisible ?? false);
		return () => {
			showSidebar(false);
		};
	}, [dialogVisible, showSidebar]);

	const getClassName = () => {
		let className = "sidebar";
		if (!loading) className += " loading";

		return className;
	};

	const close = async () => {
		showSidebar(false);
		setTimeout(() => {
			onClose();
		}, 300);
	};
	const getStateBadge = (booking: DepartmentBooking) => {
		let color = "#007aff";
		let name = "Unbekannt";

		switch (booking.state) {
			case DepartmentBookingState.Accepted:
				if (booking?.clerk?.id === user.id) {
					color = "#25cc88";
					name = "Akzeptiert";
				} else {
					color = "#3c67f1";
					name = "Von anderem Mitarbeiter akzeptiert";
				}
				break;
			case DepartmentBookingState.Pending:
				color = "#ccc400";
				name = "Wartend auf Rückmeldung";
				break;
			case DepartmentBookingState.Declined:
				color = "#ec6d66";
				name = "Abgelehnt";
				break;
			default:
				color = "#007aff";
				name = "Unbekannt";
				break;
		}
		const bgColor = `${color}5f`;
		return (
			<span className="badge" style={{ backgroundColor: bgColor, color }}>
				{name}
			</span>
		);
	};

	const sendResponse = async (type: UpdateDepartmentAdminTypes) => {
		if (!event?.identifier) return;
		let createAbsence = false;
		let message = "";
		if (type === UpdateDepartmentAdminTypes.Decline) {
			const resp = await buttonsPrompt("Termin ablehnen?", "Bestätigen Sie bitte dass Sie den Termin ablehnen wollen", [
				{
					text: "Abbrechen",
					focus: true,
					className: "cancel",
					value: "cancel",
				},
				{
					text: "Termin ablehnen",
					focus: true,
					className: "decline",
					value: "decline",
				},
				{
					text: "Termin ablehnen und Abwesenheit anlegen",
					focus: true,
					className: "decline",
					value: "decline-absence",
				},
			]);

			if (!resp || resp === "cancel") return;
			createAbsence = resp === "decline-absence";
			message =
				"Leider kann der von Ihnen angefragte Zeitraum nicht mehr angeboten werden. Bitte wählen Sei einen Alternativtermin aus.";
		}
		await sendDepartmentBookingResponse({
			variables: {
				identifier: event?.identifier,
				message,
				type,
			},
		});

		if (createAbsence) {
			await createUserAbsence({
				variables: {
					from: event.timeslot.from,
					to: event.timeslot.to,
					name: "Abwesenheit",
				},
			});
		}

		if (type === UpdateDepartmentAdminTypes.Decline) {
			onClose();
		}
	};
	const timeslot = event?.timeslot;
	const from = dayjs(timeslot?.from);
	const to = dayjs(timeslot?.to);
	const fullDay = from.get("hour") + from.get("minute") + to.get("hour") + to.get("minute") === 0;
	const diff = to.diff(from, "day");

	return (
		<Dialog
			disableScrollLocking
			closeBtn={<i className="im im-x-mark" />}
			id="details-sidebar"
			isOpen={visible}
			onClose={close}
			className={getClassName()}>
			{loading && <Spinner defaultVisible />}
			{!loading && event && (
				<div className="event-details">
					<h3>
						{diff > 1 && fullDay ? (
							<>
								{from.format("DD.MM.YYYY")} - {to.subtract(1, "millisecond").format("DD.MM.YYYY")}
							</>
						) : (
							from.format("DD.MM.YYYY")
						)}
					</h3>
					<h2>
						{fullDay ? (
							"Ganztägig"
						) : (
							<>
								{from.format("HH:mm")} - {to.format("HH:mm")} Uhr
							</>
						)}
					</h2>
					<h1>{event?.departmentService.name}</h1>

					<div className="detail-content">
						<div className="booking-details">
							{event.state === DepartmentBookingState.Pending && (
								<div className="btn-split">
									<button className="btn accept" onClick={() => sendResponse(UpdateDepartmentAdminTypes.Accept)}>
										Termin annehmen
									</button>
									<button className="btn decline" onClick={() => sendResponse(UpdateDepartmentAdminTypes.Decline)}>
										Termin ablehnen
									</button>
								</div>
							)}
							{event.state === DepartmentBookingState.Accepted && (
								<button className="btn decline" onClick={() => sendResponse(UpdateDepartmentAdminTypes.Decline)}>
									Termin stornieren
								</button>
							)}
							<Collapsible
								transitionTime={150}
								open
								disabled
								trigger={
									<h4>
										<span>Buchung</span>
										<i className="im im-angle-down" />
									</h4>
								}>
								<div className="list">
									<div className="item">
										<p className="label">Status</p>
										<p className="value">{getStateBadge(event)}</p>
									</div>
									<div className="item">
										<p className="label">Abteilung</p>
										<p className="value">{event.departmentService.department.name}</p>
									</div>
									<div className="item">
										<p className="label">Service</p>
										<p className="value">{event.departmentService.name}</p>
									</div>
									<div className="item">
										<p className="label">Dauer</p>
										<p className="value">{event.departmentService.duration} Minuten</p>
									</div>
								</div>
							</Collapsible>

							<Collapsible
								transitionTime={150}
								open
								trigger={
									<h4>
										<span>Datum</span>
										<i className="im im-angle-down" />
									</h4>
								}>
								<div className="list">
									<div className="item">
										<p className="label">Tag</p>
										<p className="value">{from.format("DD.MM.YYYY")}</p>
									</div>
									<div className="item">
										<p className="label">Uhrzeit</p>
										<p className="value">
											{from.format("HH:mm")} - {to.format("HH:mm")}
										</p>
									</div>
								</div>
							</Collapsible>
							<Collapsible
								transitionTime={150}
								open
								trigger={
									<h4>
										<span>Bürger</span>
										<i className="im im-angle-down" />
									</h4>
								}>
								<div className="list">
									<div className="item">
										<p className="label">Voller Name</p>
										<p className="value">
											{event.customer.firstName} {event.customer.lastName}
										</p>
									</div>
									<div className="item">
										<p className="label">E-Mail Adresse</p>
										<p className="value">
											<a href={`mailto:${event.customer.email}`}>{event.customer.email}</a>
										</p>
									</div>
									<div className="item">
										<p className="label">Telefonnummer</p>
										<p className="value">
											<a href={`tel:${event.customer.phone}`}>{event.customer.phone}</a>
										</p>
									</div>
								</div>
							</Collapsible>
						</div>
					</div>
				</div>
			)}
		</Dialog>
	);
};

export default BookingDetails;
