import React from "react";
import "./Navbar.scss";
import { NavLink, Route } from "react-router-dom";
import { MediaRender } from "@onedash/tools";
import { slide as Menu } from "react-burger-menu";
import ProfileDropdown from "../HeadBar/ProfileDropdown";
import { useAuth } from "../../../Auth/AuthContext";

interface NavbarProps {
	navItems: Module[];
	selectedApp: Application | undefined;
}

const Navbar = ({ navItems, selectedApp }: NavbarProps) => {
	const { logout, user } = useAuth();

	return (
		<>
			<MediaRender type="desktop">
				<div className="nav-bar">
					<div className="top">
						{selectedApp &&
							navItems.map((module, index) => {
								const path = `/dashboard/${selectedApp.appId}${module.basePath}/`;
								return (
									<div title={module.name} key={index as any}>
										<NavLink activeClassName="active" to={path} className="module">
											<span />
											<div className="icon">
												<i className={module.icon} />
											</div>
										</NavLink>
									</div>
								);
							})}
					</div>
					<div className="bottom">
						<div className="app">
							<button className="module" onClick={logout}>
								<div className="icon">
									<i className="im im-sign-out" />
								</div>
							</button>
						</div>
					</div>
				</div>
			</MediaRender>

			<MediaRender type="mobile">
				<div className="mobile-nav">
					<Menu>
						<div className="main-nav">
							<div>
								<ProfileDropdown user={user} />
								{selectedApp &&
									navItems.map((module, index) => {
										const path = `/dashboard/${selectedApp.appId}${module.basePath}`;
										return (
											<div key={index as any} className="nav-item">
												<div className="module">
													<NavLink activeClassName="active" to={path} className="module-header">
														<div className="icon">
															<i className={module.icon} />
														</div>
														<div className="name">{module.name}</div>
													</NavLink>
												</div>
												<div className="tabs">
													<Route path={path}>
														<ul>
															{module.tabs.map((tab, i) => {
																const to = path + (tab.linkTo ?? tab.subPath);

																return (
																	<li key={i as any}>
																		<NavLink activeClassName="active" to={to} className="tab">
																			{tab.name}
																		</NavLink>
																	</li>
																);
															})}
														</ul>
													</Route>
												</div>
											</div>
										);
									})}
							</div>
							<div className="bottom">
								<div className="nav-item">
									<button className="module-header" onClick={logout}>
										<div className="module">
											<div className="icon">
												<i className="im im-sign-out" />
											</div>
											<div className="name">Logout</div>
										</div>
									</button>
								</div>
							</div>
						</div>
					</Menu>
				</div>
			</MediaRender>
		</>
	);
};

export default Navbar;
