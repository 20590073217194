import React, { useCallback, useEffect, useReducer } from "react";
import { Auth0Client } from "@auth0/auth0-spa-js";
import AuthContext, { initialAuthState } from "./AuthContext";
import { hasAuthParams, loginError, tokenError } from "./AuthUtils";
import AuthReducer from "./AuthReducer";
import ENV from "../Utils/ENV";

export const authClient = new Auth0Client({
	domain: ENV.auth0.domain,
	client_id: ENV.auth0.client,
	redirect_uri: ENV.auth0.callback,
	audience: ENV.auth0.audience,
	useRefreshTokens: true,
});
export const checkPermission = async (permission: string) => {
	const isAuthenticated = await authClient.isAuthenticated();
	if (isAuthenticated) {
		const idClaims = await authClient.getIdTokenClaims();
		const permissions: string[] = idClaims?.[ENV.auth0.audience]?.permissions ?? [];
		return permissions.includes(permission);
	}
	return false;
};

export const logoutUser = async () => {
	authClient.logout({ client_id: ENV.auth0.client, returnTo: ENV.auth0.callback });
};
const onRedirectCallback = (appState?: any): void => {
	window.history.replaceState({}, document.title, appState?.returnTo || window.location.pathname);
};

const AuthProvider = ({ children }: any): JSX.Element => {
	const [state, dispatch] = useReducer(AuthReducer, initialAuthState);

	useEffect(() => {
		(async (): Promise<void> => {
			try {
				if (hasAuthParams()) {
					const { appState } = await authClient.handleRedirectCallback();
					onRedirectCallback(appState);
				} else {
					await authClient.checkSession();
				}
				const user = await authClient.getUser();
				// Get permissions
				const idClaims = await authClient.getIdTokenClaims();

				const permissions = idClaims?.[ENV.auth0.audience]?.permissions ?? [];

				dispatch({ type: "INITIALISED", user, permissions, id: idClaims.sub });
			} catch (error) {
				dispatch({ type: "ERROR", error: loginError(error) });
			}
		})();
	}, []);

	const login = useCallback((): Promise<void> => authClient.loginWithRedirect(), []);

	const logout = useCallback((): void => {
		logoutUser();
	}, []);

	const getAccessToken = useCallback(async (): Promise<string> => {
		let token;
		try {
			token = await authClient.getTokenSilently();
		} catch (error) {
			throw tokenError(error);
		}
		return token;
	}, []);

	return (
		<AuthContext.Provider
			value={{
				...state,
				login,
				logout,
				getAccessToken,
			}}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
