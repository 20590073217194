/* eslint-disable consistent-return */
/* eslint-disable default-case */
import { AuthState } from "./AuthContext";

type Action =
	| { type: "LOGIN_POPUP_STARTED" }
	| {
			type: "INITIALISED" | "LOGIN_POPUP_COMPLETE" | "USER_UPDATED";
			user?: any;
			permissions: string[];
			id?: string;
	  }
	| { type: "LOGOUT" }
	| { type: "ERROR"; error: Error };

/**
 * Handles how that state changes in the `useAuth0` hook.
 */
const AuthReducer = (state: AuthState, action: Action): AuthState => {
	switch (action.type) {
		case "LOGIN_POPUP_STARTED":
			return {
				...state,
				isLoading: true,
			};
		case "LOGIN_POPUP_COMPLETE":
		case "INITIALISED":
			return {
				...state,
				isAuthenticated: !!action.user,
				user: action.user,
				isLoading: false,
				error: undefined,
				permissions: action.permissions,
				id: action.id,
			};
		case "USER_UPDATED":
			return {
				...state,
				isAuthenticated: !!action.user,
				user: action.user,
			};
		case "LOGOUT":
			return {
				...state,
				isAuthenticated: false,
				user: undefined,
			};
		case "ERROR":
			return {
				...state,
				isLoading: false,
				error: action.error,
			};
	}
};
export default AuthReducer;
