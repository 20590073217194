import { Form, Input } from "onedash-react-input-form";
import React from "react";
import MiniPage, { MiniPageProps } from "../MiniPage";

interface PasswordIndicator {
	smallChar: boolean;
	bigChar: boolean;
	minNum: boolean;
	repeat: boolean;
	valid: boolean;
	number: boolean;
}
interface PWChangeValues {
	oldpassword: string;
	newpassword: string;
	rnewpassword: string;
}

interface PasswordChangeProps extends MiniPageProps {
	pwChange?: boolean;
	onPasswordSubmit: (pw: string) => void;
}

class PasswordChange extends MiniPage<PasswordChangeProps> {
	form = React.createRef<Form>();

	state = {
		pwIndicator: {} as PasswordIndicator,
	};

	private checkPassword = (values: PWChangeValues) => {
		const p: PasswordIndicator = {
			bigChar: false,
			minNum: false,
			repeat: false,
			smallChar: false,
			valid: false,
			number: false,
		};
		if (values.newpassword && values.rnewpassword && values.newpassword === values.rnewpassword) {
			p.repeat = true;
		}
		if (values.newpassword && /[a-z]/.test(values.newpassword)) {
			p.smallChar = true;
		}
		if (/[A-Z]/.test(values.newpassword)) {
			p.bigChar = true;
		}
		if (/[0-9]/.test(values.newpassword)) {
			p.number = true;
		}
		if (values?.newpassword?.length >= 8) {
			p.minNum = true;
		}
		if (p.bigChar && p.minNum && p.number && p.repeat && p.smallChar) p.valid = true;
		this.setState({ pwIndicator: p });
		return p;
	};

	checkFormValidation = (data: any) => this.checkPassword(data).valid;

	submitPassword = () => {
		const data: { newpassword: string; rnewpassword: string } = this.form.current?.getData();
		this.props.onPasswordSubmit(data.newpassword);
	};

	render() {
		const p = this.state.pwIndicator;
		const { pwChange } = this.props;
		return (
			<div className="password-change">
				<div className="left" />
				<div className="right">
					<div className="left">
						<h1 className="onedash-logo">
							<span>One</span>
							<span>Dash</span>
							<span>.</span>
						</h1>
						<h2>{pwChange ? "Passwort ändern" : "Passwort festlegen"}</h2>
						<h3>{pwChange ? "Bitte ändern Sie Ihr Passwort ab" : "Bitte wählen Sie im Folgenden ein Passwort"}</h3>
						<Form ref={this.form} onChange={this.checkPassword} validateOnSubmit onValidate={this.checkFormValidation}>
							<Input type="password" name="newpassword" required label="Ihr neues Passwort" />
							<Input type="password" name="rnewpassword" required label="Neues Passwort wiederholen" />

							<button
								disabled={p.valid !== true}
								style={{ margin: "0px", marginTop: "30px" }}
								className="btn"
								onClick={this.submitPassword}>
								{pwChange ? "Passwort ändern" : "Passwort festlegen"}
							</button>
						</Form>
					</div>
					<div className="right">
						<h2>Passwortrichtlinien</h2>
						<ul>
							<li className={p.number && p.number === true ? "valid" : ""}>Mindestens eine Ziffer (0 - 9)</li>
							<li className={p.bigChar && p.bigChar === true ? "valid" : ""}>Mindestens ein Großbuchstabe</li>
							<li className={p.smallChar && p.smallChar === true ? "valid" : ""}>Mindestens ein Kleinbuchstabe</li>
							<li className={p.minNum && p.minNum === true ? "valid" : ""}>Mindestens 8 Zeichen lang</li>
							<li className={p.repeat && p.repeat === true ? "valid" : ""}>Passwörter stimmen überein</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

export default PasswordChange;
