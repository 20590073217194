/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { Form, Input, Button } from "@onedash/tools";

interface VGCoronaDataEntryProps {
	maxPeople: number;
	onChange: (valid: boolean) => void;
}
interface Household {
	num: number;
	ref: React.RefObject<Form>;
	valid: boolean;
}
const houseHoldText = ["Erster", "Zweiter", "Dritter", "Vierter"];

class VGCoronaDataEntry extends Component<VGCoronaDataEntryProps> {
	state = {
		households: [] as Household[],
	};

	componentDidMount() {
		this.addHousehold();
	}

	public getHouseholds = () => {
		const households = this.state.households
			.map((household) => {
				if (household.ref.current) {
					const data = household.ref.current.getData();
					if (!data.firstName || !data.lastName) return;
					const members = Object.keys(data)
						.filter((x) => x.indexOf("member") !== -1 && data[x] !== undefined)
						.map((x) => data[x]);
					return {
						name: `${data.firstName} ${data.lastName}`,
						email: data.email,
						street: data.address,
						plz: data.plz,
						place: data.city,
						phone: data.phone,
						members,
					};
				}
				return household;
			})
			.filter((x) => x !== undefined);
		return households;
	};

	public clearData = () => {
		this.setState(
			{
				households: [],
			},
			this.addHousehold
		);
	};

	addHousehold = () => {
		const { households } = this.state;
		households.push({
			num: 0,
			ref: React.createRef<Form>(),
			valid: false,
		});
		this.setState({ households }, this.validate);
	};

	deleteHousehold = (index: number) => {
		const { households } = this.state;
		households.splice(index, 1);
		this.setState({ households }, this.validate);
	};

	addMember = (index: number) => {
		const { households } = this.state;

		households[index].num++;
		this.setState({ households });
	};

	removeMember = (index: number) => {
		const { households } = this.state;

		households[index].num--;
		this.setState({ households });
	};

	validate = () => {
		let globalValid = true;
		this.state.households.forEach((hh) => {
			if (!hh.valid) globalValid = false;
		});
		this.props.onChange(globalValid);
	};

	onChange = (h: Household, valid: boolean) => {
		const { households } = this.state;
		h.valid = valid;
		this.setState({ households }, this.validate);
	};

	render() {
		const { maxPeople } = this.props;
		const { households } = this.state;
		const members = households.reduce((x, y) => x + y.num, 0) + households.length;
		return (
			<div className="data-entry">
				{households.map((x, i) => (
					<Form onChange={(_data, _form, valid) => this.onChange(x, valid)} ref={x.ref} className="household" key={i as any}>
						{i !== 0 && <i className="im im-minus-circle delete-household" onClick={() => this.deleteHousehold(i)} />}
						<h2>{houseHoldText[i]} Haushalt</h2>
						<Input required label="Vorname" autoComplete="given-name" name="firstName" />
						<Input required label="Nachname" autoComplete="family-name" name="lastName" />
						<Input required type="email" label="Email" autoComplete="email" name="email" />
						<Input required type="tel" label="Telefon" autoComplete="tel" name="phone" />
						<Input required label="Adresse" autoComplete="street-address" name="address" />
						<Input required label="Postleitzahl" autoComplete="postal-code" name="plz" />
						<Input required label="Ort" autoComplete="locality address-level2" name="city" />

						{[...Array(x.num)].map((_x, ii) => (
							<div key={ii as any} className={ii === x.num - 1 ? "member-input last-item" : "member-input"}>
								<Input label={`${ii + 1}. Anwohner`} autoComplete="off" name={`member-${ii}`} />
								{ii === x.num - 1 && <i className="im im-minus-circle" onClick={() => this.removeMember(i)} />}
							</div>
						))}

						<div onClick={() => this.addMember(i)} className={members >= maxPeople ? "disabled new-member" : "new-member"}>
							<i className="im im-plus-circle" />
							<p>Weiterer Anwohner</p>
						</div>
					</Form>
				))}

				<Button className="add-household" disabled={members >= maxPeople} onClick={this.addHousehold}>
					Weiteren Haushalt hinzufügen
				</Button>
			</div>
		);
	}
}

export default VGCoronaDataEntry;
