import { authClient } from "../../Auth/AuthProvider";

type FileTypes = "pdf" | "other";

export default class FileDownloader {
	public static openPDF = async (pathToFile: string) => {
		const blobURL = await FileDownloader.createDownloadLink(pathToFile, "pdf");
		window.open(blobURL);
	};

	public static downloadFile = async (pathToFile: string, fileName: string, type?: FileTypes) => {
		const anchor = document.createElement("a");
		document.body.appendChild(anchor);

		const objectUrl = await FileDownloader.createDownloadLink(pathToFile, type);

		anchor.href = objectUrl;
		anchor.download = fileName;
		anchor.click();

		setTimeout(() => {
			window.URL.revokeObjectURL(objectUrl);
		}, 0);
	};

	public static createDownloadLink = async (pathToFile: string, type?: FileTypes) => {
		const headers = new Headers();
		headers.append("Authorization", `Bearer ${await authClient.getTokenSilently()}`);

		return new Promise<string>((resolve, reject) => {
			fetch(pathToFile, { headers })
				.then((response) => response.blob())
				.then((blobby) => {
					let blob = blobby;
					if (type === "pdf") {
						blob = new Blob([blob], { type: "application/pdf" });
					}
					const objectUrl = window.URL.createObjectURL(blob);

					resolve(objectUrl);
				})
				.catch(reject);
		});
	};
}
