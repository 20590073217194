/* eslint-disable default-case */
import Action from "./Action";

export default function reducer(state = {} as any, action: any) {
	switch (action.type) {
		case "TAB_LOADED":
			state.tabLoaded = true;
			break;

		case "TAB_SWITCHED":
			state.tabLoaded = false;
			break;

		case "SIDEBAR":
			state.sidebar = action.hasSidebar;
			break;

		case "ACCESS_TOKEN_LOADED":
			state.accessTokenLoaded = true;
			break;

		case "APPLICATION_INSTALLABLE":
			state.appInstallable = true;
			break;

		case "UPDATE_AVAILABLE":
			state.updateAvailable = true;
			break;

		case "APPLICATION_OFFLINE":
			state.online = false;
			break;

		case "APPLICATION_ONLINE":
			state.online = true;
			break;

		case "DARK_LIGHT_CHANGE":
			state.themeMode = action.mode;
			break;

		case "SET_CURRENT_PAGE":
			state.currentPageName = action.pageName;
			break;

		case "SET_USER_APPLICATION":
			state.userApplications = action.userApplications;
			break;

		case "APPLICATION_ERROR":
			state.applicationErrorMessage = action.message;
			break;

		case "RESET_APPLICATION_ERROR":
			state.applicationErrorMessage = undefined;
			break;
		case "HAS_TOOLBAR":
			state.hasToolbar = action.hasToolbar;
			break;
		case "SELECT_CURRENT_MENU_ITEM":
			state.currentMenuItem = action.currentMenuItem;
			break;
		case "TRIGGER_EVENT":
			if (!state.events) state.events = {};
			state.events[action.eventName] = action.eventValue;
			if (action.eventValue !== null) {
				// Reset trigger
				setTimeout(() => {
					Action.triggerEvent(action.eventName, null);
				}, 300);
			}
			break;
	}
	return state;
}
