import React from "react";
import "./OneDashLogo.sass";

const OneDashLogo = () => (
	<h1 className="onedash-logo">
		<span>One</span>
		<span>Dash</span>
		<span>.</span>
	</h1>
);

export default OneDashLogo;
