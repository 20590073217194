const TABLE_HEADERS_LIST: TableHeader[] = [
	{
		columnName: "columnName",
		title: "Name der DB - Spalte",
		type: "text",
		visible: true,
		maxLength: 200,
		required: true,
	},
	{
		columnName: "title",
		title: "Titel im UI",
		type: "text",
		visible: true,
		maxLength: 200,
		required: true,
	},
	{
		columnName: "type",
		title: "Typ im UI",
		type: "select",
		inputData: [
			{
				value: "text",
				label: "Text",
			},
			{
				value: "number",
				label: "Nummer",
			},
			{
				value: "boolean",
				label: "Boolean",
			},

			{
				value: "password",
				label: "Passwort",
			},
			{
				value: "select",
				label: "Select",
			},
			{
				value: "tag-input",
				label: "Tag Input",
			},
			{
				value: "email",
				label: "Email",
			},
		],
		visible: true,
		maxLength: 200,
		required: true,
	},
	{
		columnName: "maxLength",
		title: "Maximale Länge",
		type: "number",
		visible: false,
	},
	{
		columnName: "inputData",
		title: "Input Data",
		type: "text",
		visible: false,
	},
	{
		columnName: "visible",
		title: "In der Tabelle sichtbar",
		type: "boolean",
		visible: false,
	},
	{
		columnName: "required",
		title: "Required",
		type: "boolean",
		visible: true,
	},
	{
		columnName: "readonly",
		title: "Nur Lesen",
		type: "boolean",
		visible: false,
	},
	{
		columnName: "isId",
		title: "ID?",
		type: "boolean",
		visible: false,
	},
];

export default TABLE_HEADERS_LIST;
