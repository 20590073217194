/* eslint-disable no-underscore-dangle */
import React from "react";
import { Button, Input } from "onedash-react-input-form";
import { Cell, Column, Row, Table } from "onedash-react-table";
import moment from "moment";
import { usePrompt } from "onedash-dialog";
import Backend from "../../Utils/Backend/Backend";
import "./FlyFishing.sass";
import FileDownloader from "../../Utils/Backend/FileDownloader";
import { useFlyFishingDocumentsQuery } from "../../Resources/generated/gql-types";

const variantTypes = {
	tagesschein: "Tagesschein",
	wochenschein: "Wochenschein",
	jahresschein: "Jahresschein",
};
const initialState = {
	searchString: undefined as undefined | string,
	downloadLinks: [] as string[],
};

const FlyFishingStats = () => {
	const { buttonsPrompt } = usePrompt();
	const [state, update] = React.useState(initialState);

	const { data: formData } = useFlyFishingDocumentsQuery({
		context: {
			headers: {
				"app-id": Backend.getAppID(),
			},
		},
	});

	const downloadPDF = (row?: any) => {
		if (!row?.id) return;
		buttonsPrompt("Wählen Sie die Sprache", "In welcher Sprache soll das Formular geöffnet werden?", [
			{
				text: "Deutsch",
				value: "german",
				focus: true,
				className: "yes",
			},
			{
				text: "Niederländisch",
				value: "netherlands",
			},
		]).then((lang) => {
			if (!lang) return;
			FileDownloader.openPDF(Backend.buildPath(`/vg/flyfishing/pdfExport/${row.id}/${lang}`));
		});
	};
	const exportExcel = () => {
		FileDownloader.downloadFile(Backend.buildPath(`/vg/flyfishing/exportExcel`), "fliegenfischen.xlsx");
	};
	const setSearchString = (searchString: string | null | undefined) => {
		update((s) => ({ ...s, searchString: searchString ?? undefined }));
	};

	const forms = [...(formData?.flyFishingDocument ?? [])].sort((x, y) => moment(y.meta_creation).diff(x.meta_creation));
	const { searchString } = state;
	return (
		<>
			<div className="fly-fishing-stats">
				<div className="toolbar">
					<Input placeholder="Suchen Sie einen Eintrag ..." name="search" type="text" onChange={(str) => setSearchString(str)} />
					<Button onClick={exportExcel}>Excel Export</Button>
				</div>

				<Table
					textNoItemFound={(s) => `Es konnte kein Eintrag zu der Eingabe "${s}" gefunden werden.`}
					searchString={searchString}
					minWidth={900}
					textNoRows="Aktuell wurde noch keine Bescheinigung ausgestellt">
					<Column label="Name" name="name" />
					<Column label="Erstellungsdatum" name="meta_creation" />
					<Column label="Variante" name="variant" />
					<Column label="Mit Gästekarte" name="withGuestCard" />
					<Column label="Anzahl: Obere Route" name="upperRouteAmount" />
					<Column label="Anzahl: Untere Route" name="lowerRouteAmount" />
					<Column name="downloadBtn" width="50px" />

					{forms.map((row, i) => (
						<Row key={i as any} row={row}>
							<Cell name="name" />
							<Cell name="meta_creation">
								{(r) => {
									return moment(r).format("L");
								}}
							</Cell>
							<Cell name="variant">{(v) => (variantTypes as any)[v]}</Cell>

							<Cell name="withGuestCard">{(b) => (b === true ? <span className="true" /> : <span className="false" />)}</Cell>
							<Cell name="upperRouteAmount" />
							<Cell name="lowerRouteAmount" />

							<Cell name="downloadBtn">
								<Button className="icon" onClick={() => downloadPDF(row)}>
									<i className="im im-download" />
								</Button>
							</Cell>
						</Row>
					))}
				</Table>
			</div>
		</>
	);
};

export default FlyFishingStats;
