import React, { Component } from "react";
import { Select, Table, Card } from "@onedash/tools";
import { TableHeader } from "@onedash/tools/dist/Table/TableTypes";
import Backend from "../../Utils/Backend/Backend";
import { User } from "./Users";
import Toolbar from "../../Pages/Dashboard/Toolbar/Toolbar";
import Notify from "../../Utils/Notify";

export interface ObjectAccessEntry {
	id: number;
	appID: string;
	objectType: string;
	objectID: string;
	userID: string;
	mode: "read" | "write";
}
interface ObjectAccessProps {}

class ObjectAccess extends Component<ObjectAccessProps> {
	state = {
		users: [] as User[],
		objectAccessEntries: [] as ObjectAccessEntry[],
		applications: [] as Application[],
		selectedUser: undefined as undefined | User,
	};

	componentDidMount() {
		this.loadApplications();
		this.loadUsers();
	}

	loadUsers = async () => {
		const users = await Backend.get<User[]>("/admin/users", false);
		this.setState({
			users: users.data,
		});
	};

	loadObjectAccess = async (userID: string) => {
		const objectAccessEntries = await Backend.get<ObjectAccessEntry[]>(`/admin/access/${userID}`);
		this.setState({
			objectAccessEntries: objectAccessEntries.data,
		});
	};

	onUserChange = async (obj: { value: string; name: string }) => {
		const userID = obj.value;
		// Load access data
		this.loadObjectAccess(userID);

		// Load additional user information (especcially assigned applications)
		const userData = await Backend.get<User>(`/admin/users/${userID}`);

		this.setState({ selectedUser: userData.data });
	};

	loadApplications = async () => {
		const applications = await Backend.get<Application[]>(`/admin/applications`);
		this.setState({ applications: applications.data });
	};

	saveEntry = async (entry: any, isNew: boolean) => {
		const userID = this.state.selectedUser?.id;
		let { objectAccessEntries } = this.state;
		if (isNew) {
			const response = await Backend.post(`/admin/access/${userID}`, entry);
			entry.id = response.properties?.insertedId;
			objectAccessEntries.push(entry);
		} else {
			// Update existing entry
			objectAccessEntries = objectAccessEntries.filter((x) => x.id !== entry.id);
			Backend.put(`/admin/access/${userID}`, entry);
			objectAccessEntries.push(entry);
		}
		this.setState({
			objectAccessEntries,
		});
	};

	deleteEntry = async (rowID: number) => {
		const userID = this.state.selectedUser?.id;
		await Backend.delete(`/admin/access/${userID}`, { id: rowID })
			.then(() => {
				let { objectAccessEntries } = this.state;
				objectAccessEntries = objectAccessEntries.filter((x) => x.id !== rowID);
				this.setState({ objectAccessEntries });
			})
			.catch((ex) => {
				Notify.e(ex.data);
			});
	};

	render() {
		const { users, objectAccessEntries, applications, selectedUser } = this.state;
		const selectUsers = users.map((user) => {
			return { label: user.name, value: user.id };
		});
		const tableHeaders: TableHeader[] = [
			{
				columnName: "appID",
				type: "select",
				title: "Application",
				required: true,
				inputData: applications
					.filter((x) => selectedUser?.applications.includes(x.appId))
					.map((app) => {
						return {
							value: app.appId,
							label: app.appName,
						};
					}),
			},
			{
				columnName: "objectType",
				type: "select",
				title: "Objekt-Typ",
				required: true,
				inputData: [
					{ label: "Bürgerhaus", value: "buergerhaus" },
					{ label: "Schutzhütte", value: "schutzhuette" },
					{ label: "Herberge", value: "Lodgings" },
					{ label: "Abteilung", value: "Departments" },
					{ label: "Abteilungsservice", value: "DepartmentServices" },
				],
			},
			{
				columnName: "objectID",
				type: "text",
				title: "Objekt-ID",
				required: true,
			},
			{
				columnName: "mode",
				type: "select",
				title: "Modus",
				required: true,
				inputData: [
					{ label: "Lesen", value: "read" },
					{ label: "Schreiben", value: "write" },
				],
			},
		];

		return (
			<>
				<Toolbar>
					<Select onChange={this.onUserChange} name="users" placeholder="Wählen Sie einen Benutzer" options={selectUsers} />
				</Toolbar>

				{selectedUser && (
					<Card className="full-width">
						<Table
							editable
							searchable
							className="full-height-table"
							tableValues={objectAccessEntries}
							tableHeaders={tableHeaders}
							onSave={this.saveEntry}
							onDelete={this.deleteEntry}
						/>
					</Card>
				)}
			</>
		);
	}
}

export default ObjectAccess;
