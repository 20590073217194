import React from "react";
import { Dialog, usePrompt } from "onedash-dialog";
import { Spinner } from "@onedash/tools";
import ViewDetails from "./ViewDetails";
import EditDetails from "./EditDetails";
import { CalendarEvent, CreationEvent } from "./TimeCalendarTypes";
import ButtonSpinner from "../../Pages/MiniPages/Components/ButtonSpinner";

interface DetailsSidebarProps<T extends {}> {
	detailedEvent?: CalendarEvent<T>;
	currentEvent?: CreationEvent<T>;
	eventId?: string;
	onClose: () => void;
	onSave: () => void;
	updateEventData: (values: any, valid?: boolean) => void;
	isValid: boolean;
	edit: boolean;
	changed: boolean;
	onDelete: () => void;
	onEdit: () => void;
	eventName: string;
	allowReccurentTimeslots?: boolean;
	allowMultiDay?: boolean;
	allowTimerange?: boolean;
	allowPast?: boolean;
	editEventComponent?: React.ReactChild;
	isLoading?: boolean;
}

const DetailsSidebar = <T extends {}>({
	isLoading,
	eventId,
	detailedEvent,
	onClose,
	updateEventData,
	onSave,
	isValid,
	changed,
	onDelete,
	onEdit,
	allowReccurentTimeslots,
	eventName,
	allowMultiDay,
	allowTimerange,
	allowPast,
	editEventComponent,
	edit,
	currentEvent,
}: DetailsSidebarProps<T>) => {
	const target = document.querySelector(".dashboard");
	const { yesNoPrompt } = usePrompt();
	const [visible, updateVisible] = React.useState(eventId !== undefined);

	const showSidebar = React.useCallback(
		(show: boolean) => {
			if (!target) return;
			if (show) {
				target.classList.add("has-sidebar-v2");
			} else {
				target.classList.remove("has-sidebar-v2");
			}
			updateVisible(show);
		},
		[target]
	);

	React.useEffect(() => {
		const v = eventId !== undefined;
		showSidebar(v);
		return () => {
			showSidebar(false);
		};
	}, [eventId, showSidebar]);

	const getClassName = () => {
		let className = "sidebar";
		if (!detailedEvent) className += " loading";

		return className;
	};

	const close = async () => {
		let yes = true;
		if (changed) {
			yes = await yesNoPrompt("Änderungen verwerfen?", "Sie haben Änderungen durchgeführt. Wollen Sie diese verwerfen?");
		}
		if (!yes) return;
		showSidebar(false);
		setTimeout(() => {
			onClose();
		}, 300);
	};

	return (
		<Dialog
			disableScrollLocking
			closeBtn={<i className="im im-x-mark" />}
			id="details-sidebar"
			isOpen={visible}
			onClose={close}
			className={getClassName()}>
			{!detailedEvent && eventId !== "new" && <Spinner defaultVisible />}
			{!edit && detailedEvent && eventId !== "new" && (
				<>
					<ViewDetails
						onDelete={onDelete}
						onEdit={onEdit}
						event={detailedEvent}
						allowReccurentTimeslots={allowReccurentTimeslots}
						allowTimerange={allowTimerange}
					/>
				</>
			)}
			{edit && (
				<>
					<EditDetails
						eventId={eventId}
						event={currentEvent}
						updateEventData={updateEventData}
						isNew={eventId === "new"}
						eventName={eventName}
						allowReccurentTimeslots={allowReccurentTimeslots}
						allowMultiDay={allowMultiDay}
						allowTimerange={allowTimerange}
						editEventComponent={editEventComponent}
						allowPast={allowPast}
					/>
					<button onClick={onSave} disabled={!isValid || isLoading} className="btn highlight-btn book-btn">
						{!isLoading ? <>{eventId === "new" ? `${eventName} erstellen` : "Änderungen speichern"}</> : <ButtonSpinner />}
					</button>
				</>
			)}
		</Dialog>
	);
};

export default DetailsSidebar;
