/* eslint-disable no-underscore-dangle */
import React from "react";
import { Dialog, usePrompt } from "onedash-dialog";
import { Spinner } from "@onedash/tools";
import Collapsible from "react-collapsible";
import dayjs from "dayjs";
import { DatePicker, Form, Input, NativeSelect, Numeric, Textarea, Toggle } from "onedash-react-input-form";
import { CalendarUtils } from "onedash-react-calendar";
import { Absence, namedOperations, useDeleteAbsenceMutation } from "../../Resources/generated/gql-types";
import { GQLAppContext } from "../../Utils/Backend/GQLAppContext";
import { CreationEvent } from "../../Components/TimeCalendar/TimeCalendarTypes";
import AppUtils from "../../Utils/AppUtils";
import { dayTexts, frequencySelectOptions } from "../../Utils/CalendarUtils";
import ButtonSpinner from "../../Pages/MiniPages/Components/ButtonSpinner";
import InfoBox from "../../Components/Misc/InfoBox";

interface AbsenceProps {
	onClose: () => void;
	absence?: Absence;
	creationAbsence?: CreationEvent<Absence>;
	loading?: boolean;
	visible?: boolean;
	onEdit: () => void;
	edit: boolean;
	isNew: boolean;
	updateAbsenceData: (values?: any, valid?: boolean) => void;
	isValid: boolean;
	hasChanged: boolean;
	absenceId?: string;
	onSave: () => void;
}
const initialState = {
	fromDate: undefined,
	toDate: undefined,
	repeat: false,
	repeatFrequency: "D",
	repeatInterval: 1,
	days: [],
	monthRepeat: "same-date",
} as {
	repeat?: boolean;
	repeatFrequency?: string;
	repeatInterval?: number;
	days: string[];
	fromDate: undefined | number;
	toDate: undefined | number;
	monthRepeat: "same-date" | "same-day";
};

const AbsenceDetails = ({
	onClose,
	absence,
	creationAbsence,
	loading,
	visible: dialogVisible,
	onEdit,
	edit,
	isNew,
	updateAbsenceData,
	isValid,
	hasChanged,
	absenceId,
	onSave,
}: AbsenceProps) => {
	const formRef = React.useRef<Form>();
	const { yesNoPrompt, buttonsPrompt } = usePrompt();
	const [state, update] = React.useState(initialState);

	const target = document.querySelector(".dashboard");
	const [visible, updateVisible] = React.useState(dialogVisible ?? false);

	const [deleteAbsence, { loading: deleteAbsenceLoading }] = useDeleteAbsenceMutation({
		...GQLAppContext(),
		refetchQueries: [namedOperations.Query.getUserCalendar],
	});

	const showSidebar = React.useCallback(
		(show: boolean) => {
			if (!target) return;
			if (show) {
				target.classList.add("has-sidebar-v2");
			} else {
				target.classList.remove("has-sidebar-v2");
			}
			updateVisible(show);
		},
		[target]
	);

	React.useEffect(() => {
		if (absenceId === "new") {
			formRef.current?.resetForm();
			update({ ...initialState });
		}
	}, [absenceId]);

	React.useEffect(() => {
		showSidebar(dialogVisible ?? false);
		return () => {
			showSidebar(false);
		};
	}, [dialogVisible, showSidebar]);

	const getClassName = () => {
		let className = "sidebar";
		if (!loading) className += " loading";

		return className;
	};

	const close = async () => {
		let yes = true;
		if (hasChanged) {
			yes = await yesNoPrompt("Änderungen verwerfen?", "Sie haben Änderungen durchgeführt. Wollen Sie diese verwerfen?");
		}
		if (!yes) return;
		showSidebar(false);
		setTimeout(() => {
			onClose();
		}, 300);
	};

	const onDelete = async (id: string) => {
		if (absence?.timeslot.periodRule) {
			const resp = await buttonsPrompt("Abwesenheit löschen?", "Wollen Sie die sich wiederholenden Abwesenheiten löschen", [
				{
					text: "Abbrechen",
					focus: true,
					className: "cancel",
					value: false,
				},
				{
					text: "Sequenz löschen",
					focus: true,
					className: "decline",
					value: "delete-all",
				},
				{
					text: "Einzeltermin löschen",
					focus: true,
					className: "decline",
					value: "delete-one",
				},
			]);
			if (!resp) return;
			deleteAbsence({ variables: { id, deletePeriod: resp === "delete-all" } }).then(close);
		} else {
			const yes = await yesNoPrompt("Abwesenheit löschen?", "Wollen Sie die Abwesenheit wirklich löschen?");
			if (!yes) return;
			deleteAbsence({ variables: { id } }).then(close);
		}
	};

	const onFormChange = (values: any, _?: Form, valid?: boolean) => {
		const { repeatFrequency, repeatInterval, days, repeat } = state;
		values._creation.repeat = repeat;

		if (repeatFrequency && repeatInterval && repeat) {
			let rule = `${repeatFrequency}+${repeatInterval}`;
			if (days && days.length > 0) {
				rule += `+${days.join(",")}`;
			}
			AppUtils.setObjectValue("timeslot.periodRule", values, rule);
		} else if (values?.timeslot?.periodRule) values.timeslot.periodRule = undefined;

		updateAbsenceData(values, valid);
	};

	const toggleDay = (day: string) => {
		const { days } = state;
		const index = days.indexOf(day);
		if (index === -1) {
			days.push(day);
		} else {
			days.splice(index, 1);
		}
		update((s) => ({ ...s, days }));
		onFormChange(formRef.current?.getData(), formRef.current, formRef.current?.validateInputs(false));
	};

	loading = loading || deleteAbsenceLoading;
	const timeslot = absence?.timeslot;
	const from = dayjs(timeslot?.from);
	const to = dayjs(timeslot?.to);
	const diff = to.diff(from, "day");
	const d = dayjs(state.fromDate);
	const fullDay = from.get("hour") + from.get("minute") + to.get("hour") + to.get("minute") === 0;

	return (
		<Dialog
			disableScrollLocking
			closeBtn={<i className="im im-x-mark" />}
			id="details-sidebar"
			isOpen={visible}
			onClose={close}
			className={getClassName()}>
			{loading && <Spinner defaultVisible />}
			{!loading && absence && !edit && (
				<div className="event-details">
					<div className="btns">
						<button onClick={() => onDelete(absence.id)} className="trash-event">
							<i className="im im-trash-can" />
						</button>
						<button onClick={onEdit} className="toggle-edit-mode">
							<i className="im im-pencil" />
						</button>
					</div>

					<h3>
						{diff > 1 && fullDay ? (
							<>
								{from.format("DD.MM.YYYY")} - {to.subtract(1, "millisecond").format("DD.MM.YYYY")}
							</>
						) : (
							from.format("DD.MM.YYYY")
						)}
					</h3>
					<h2>
						{fullDay ? (
							"Ganztägig"
						) : (
							<>
								{from.format("HH:mm")} - {to.format("HH:mm")} Uhr
							</>
						)}
					</h2>
					<h1>{absence?.name}</h1>

					<div className="detail-content">
						<div className="booking-details">
							<Collapsible
								transitionTime={150}
								open
								disabled
								trigger={
									<h4>
										<span>Abwesenheit</span>
										<i className="im im-angle-down" />
									</h4>
								}>
								<div className="list">
									<div className="item">
										<p className="label">Name</p>
										<p className="value">{absence.name}</p>
									</div>
									<div className="item">
										<p className="label">Beschreibung</p>
										<p className="value">{absence.description}</p>
									</div>
								</div>
							</Collapsible>

							<Collapsible
								transitionTime={150}
								open
								trigger={
									<h4>
										<span>Datum</span>
										<i className="im im-angle-down" />
									</h4>
								}>
								<div className="list">
									<div className="item">
										{diff === 1 && (
											<>
												<p className="label">Tag</p>
												<p className="value">{from.format("DD.MM.YYYY")}</p>
											</>
										)}
										{diff > 1 && (
											<>
												<p className="label">Zeitraum</p>
												<p className="value">
													{from.format("DD.MM.YYYY")} - {to.subtract(1, "millisecond").format("DD.MM.YYYY")}
												</p>
											</>
										)}
									</div>
									{(from.format("HH:mm") !== "00:00" || to.format("HH:mm") !== "00:00") && (
										<div className="item">
											<p className="label">Uhrzeit</p>
											<p className="value">
												{from.format("HH:mm")} - {to.format("HH:mm")}
											</p>
										</div>
									)}

									<div className="item">
										<p className="label">Wiederholend</p>
										<p className="value">{absence.timeslot.periodId ? "✅ Ja" : "❌ Nein"}</p>
									</div>

									{absence.timeslot.periodId && (
										<>
											<div className="item">
												<p className="label">Regel</p>
												<p className="value">{CalendarUtils.periodRuleToText(absence.timeslot.periodRule)}</p>
											</div>
										</>
									)}
								</div>
							</Collapsible>
						</div>
					</div>
				</div>
			)}

			{!loading && (creationAbsence || isNew) && edit && (
				<>
					<div className="edit-details">
						<h1>{isNew ? `Abwesenheit anlegen` : `Bearbeiten`}</h1>
						{!isNew && absence?.timeslot.periodId && (
							<InfoBox type="info">
								Sie können nur die ausgewählte Abwesenheit bearbeiten. Wenn Sie eine wiederholende Abwesenheit anpassen
								möchten, löschen Sie diese und legen Sie diese erneut an.
							</InfoBox>
						)}
						<Form ref={formRef as any} onChange={onFormChange} className="edit-content">
							<div className="section">
								<h2>Zeitraum</h2>
								<Input required name="name" label="Name der Abwesenheit" value={creationAbsence?.name} />
								<Textarea name="description" label="Beschreibung" value={creationAbsence?.description} />
							</div>
							<div className="section">
								<h2>Zeitraum</h2>
								<div className="split">
									<DatePicker
										value={creationAbsence?._creation.dateFrom}
										langKey="de"
										placeholder="Wählen Sie das Startdatum"
										numberOfMonths={1}
										name="_creation.dateFrom"
										label="Startdatum"
										required
										onChange={(val) => {
											update((s) => ({ ...s, date: val ?? undefined }));
										}}
										minDate={dayjs()}
										icon={<i className="im im-calendar" />}
									/>

									<DatePicker
										value={creationAbsence?._creation.dateTo}
										langKey="de"
										placeholder="Wählen Sie ein Enddatum"
										numberOfMonths={1}
										name="_creation.dateTo"
										label="Enddatum"
										minDate={
											creationAbsence?._creation?.dateFrom ? dayjs(creationAbsence?._creation?.dateFrom) : undefined
										}
										onChange={(val) => {
											update((s) => ({ ...s, date: val ?? undefined }));
										}}
										icon={<i className="im im-calendar" />}
									/>
								</div>

								<div className="split">
									<div>
										<Toggle label="Ganztägig" name="_creation.fullDay" value={creationAbsence?._creation.fullDay} />
									</div>
									{isNew && (
										<div>
											<Toggle
												label="Wiederholung"
												name="_creation.repeat"
												value={state.repeat}
												onChange={(r) =>
													update((s) => ({ ...s, repeat: r === undefined ? false : r ?? undefined }))
												}
												icon={<i className="im im-history" />}
											/>
										</div>
									)}
								</div>
								{!creationAbsence?._creation.fullDay && (
									<>
										<div className="item">
											<p className="label">Uhrzeit von:</p>
											<div className="split">
												<Numeric
													value={creationAbsence?._creation.fromHour ?? 0}
													required
													placeholder="Stunde"
													name="_creation.fromHour"
													numeralIntegerScale={2}
													onValidate={(val) => (val ? val >= 0 && val < 24 : false)}
													icon={<i className="im im-clock-o" />}
												/>
												<Numeric
													value={creationAbsence?._creation.fromMinute ?? 0}
													required
													numeralIntegerScale={2}
													placeholder="Minute"
													name="_creation.fromMinute"
													onValidate={(val) => (val ? val >= 0 && val < 60 : false)}
													icon={<i className="im im-timer" />}
												/>
											</div>
										</div>
										<div className="item">
											<p className="label">Uhrzeit bis:</p>
											<div className="split">
												<Numeric
													value={creationAbsence?._creation.toHour ?? 0}
													required
													placeholder="Stunde"
													name="_creation.toHour"
													numeralIntegerScale={2}
													onValidate={(val) => (val ? val >= 0 && val < 24 : false)}
													icon={<i className="im im-clock-o" />}
												/>
												<Numeric
													value={creationAbsence?._creation.toMinute ?? 0}
													required
													type="tel"
													placeholder="Minute"
													name="_creation.toMinute"
													numeralIntegerScale={2}
													onValidate={(val) => (val ? Number(val) >= 0 && Number(val) < 60 : false)}
													icon={<i className="im im-timer" />}
												/>
											</div>
										</div>
									</>
								)}

								{isNew && (
									<>
										{state.repeat && (
											<>
												<div className="item">
													<p className="label">Wiederholen alle:</p>
													<div className="split">
														<Input
															type="tel"
															settings={{
																allowNumberNull: false,
																allowNumberNegative: false,
																validateTel: false,
															}}
															required
															pattern="^$|^[0-9]+$"
															name="_creation.interval"
															maxLength={2}
															onValidate={(val) => (val ? Number(val) >= 0 && Number(val) < 60 : false)}
															onChange={(v) =>
																update((s) => ({ ...s, repeatInterval: v ? Number(v) : undefined }))
															}
															value={String(state.repeatInterval)}
														/>
														<NativeSelect
															value={state.repeatFrequency}
															onChange={(v) => {
																const days: string[] = [];
																if (v === "W" && state.fromDate) {
																	days.push(dayjs(state.fromDate).locale("en").format("dd"));
																}
																update((s) => ({ ...s, days, repeatFrequency: v }));
															}}
															required
															placeholder="Frequenz"
															options={frequencySelectOptions(
																state.repeatInterval ? state.repeatInterval > 1 : false
															)}
															name="_creation.frequency"
														/>
													</div>
												</div>
												{state.repeatFrequency === "W" && (
													<div className="day-selection">
														<p className="label">Wählen Sie die Tage</p>
														<div className="btns">
															<button
																onClick={() => toggleDay("Mo")}
																className={state.days.includes("Mo") ? "active" : ""}>
																Mo
															</button>
															<button
																onClick={() => toggleDay("Tu")}
																className={state.days.includes("Tu") ? "active" : ""}>
																Di
															</button>
															<button
																onClick={() => toggleDay("We")}
																className={state.days.includes("We") ? "active" : ""}>
																Mi
															</button>
															<button
																onClick={() => toggleDay("Th")}
																className={state.days.includes("Th") ? "active" : ""}>
																Do
															</button>
															<button
																onClick={() => toggleDay("Fr")}
																className={state.days.includes("Fr") ? "active" : ""}>
																Fr
															</button>
															<button
																onClick={() => toggleDay("Sa")}
																className={state.days.includes("Sa") ? "active" : ""}>
																Sa
															</button>
															<button
																onClick={() => toggleDay("Su")}
																className={state.days.includes("Su") ? "active" : ""}>
																So
															</button>
														</div>
													</div>
												)}
												{state.repeatFrequency === "M" && state.fromDate && (
													<div className="month-selection">
														<NativeSelect
															name="_"
															required
															value={state.monthRepeat}
															options={[
																{ label: `Monatlich am ${d.date()}.`, value: "same-date" },
																{
																	label: `Monatlich am ersten ${dayTexts[d.locale("en").format("dd")]}.`,
																	value: "same-day",
																},
															]}
															onChange={(v) => {
																const days: string[] = [];
																if (v === "same-day") {
																	days.push(d.locale("en").format("dd"));
																}
																update((s) => ({ ...s, days }));
															}}
														/>
													</div>
												)}
												<DatePicker
													langKey="de"
													placeholder="Enddatum"
													numberOfMonths={1}
													value={
														creationAbsence?.timeslot?.periodTo
															? dayjs(creationAbsence?.timeslot?.periodTo).timestamp()
															: undefined
													}
													name="timeslot.periodTo"
													icon={<i className="im im-calendar" />}
												/>
												<InfoBox type="info">
													Die wiederholenden Ereignisse werden in der Vorschau nicht angezeigt. Erst beim
													Speichern erscheinen alle Abwensenheiten.
												</InfoBox>
											</>
										)}
									</>
								)}
							</div>
						</Form>
					</div>
					<button onClick={onSave} disabled={!isValid || loading} className="btn highlight-btn book-btn">
						{!loading ? <>{isNew ? `Abwesenheit erstellen` : "Änderungen speichern"}</> : <ButtonSpinner />}
					</button>
				</>
			)}
		</Dialog>
	);
};

export default AbsenceDetails;
