import React, { Component } from "react";
import { Table, Card } from "@onedash/tools";
import Backend from "../../Utils/Backend/Backend";
import Action from "../../Utils/Redux/Action";
import { APPLICATION_HEADERS } from "./ApplicationHeaders";

interface ApplicationsProps {}

class Applications extends Component<ApplicationsProps> {
	state = {
		tableValues: [] as any[],
	};

	componentDidMount() {
		this.loadApplications();
	}

	deleteApplication = (appId: string) => {
		Backend.delete("/admin/applications", { appId }).then(this.loadApplications);
	};

	saveApplication = (app: Application, isNew: boolean) => {
		const { length } = app.appId;
		if (length < 10) {
			for (let i = 0; i < 10 - length; i++) {
				app.appId = `0${app.appId}`;
			}
		}
		if (isNew) {
			Backend.post("/admin/applications", app).then(this.loadApplications);
		} else {
			Backend.put("/admin/applications", app).then(this.loadApplications);
		}
	};

	loadApplications = async () => {
		const applications = await Backend.get<Application[]>("/admin/applications");
		applications.data.forEach((app) => {
			(app as any).id = app.appId;
		});
		this.setState({
			tableValues: applications.data,
		});
		Action.tabLoaded();
	};

	render() {
		return (
			<Card>
				<Table
					editable
					searchable
					className="full-width-table full-height-table"
					tableHeaders={APPLICATION_HEADERS}
					tableValues={this.state.tableValues}
					onSave={this.saveApplication}
					onDelete={this.deleteApplication}
				/>
			</Card>
		);
	}
}

export default Applications;
