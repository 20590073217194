/* eslint-disable no-case-declarations */
/* eslint-disable no-async-promise-executor */
import AXIOS from "axios";
import Notify from "../Notify";
import ENV from "../ENV";
import { authClient } from "../../Auth/AuthProvider";

export default class Backend {
	private static app: Application | undefined;

	private static backendDataServer = ENV.default.apiURL;

	private static authEnabled = true;

	public static get = async <T extends any>(path: string, app = true) => {
		return Backend.sendRequest(path, app, undefined, "get") as Promise<GetResponse<T>>;
	};

	public static post = async <T extends any>(path: string, data?: any, app = true) => {
		return Backend.sendRequest(path, app, data, "post") as Promise<GetResponse<T>>;
	};

	public static put = async <T extends undefined>(path: string, data: any, app = true) => {
		return Backend.sendRequest(path, app, data, "put") as Promise<GetResponse<T>>;
	};

	public static patch = async <T extends undefined>(path: string, data: any, app = true) => {
		return Backend.sendRequest(path, app, data, "patch") as Promise<UdpateResponse<T>>;
	};

	public static delete = async <T extends undefined>(path: string, data?: any, app = true) => {
		return Backend.sendRequest(path, app, data, "delete") as Promise<UdpateResponse<T>>;
	};

	public static setApp = (application: Application | undefined) => {
		Backend.app = application;

		if (application?.appId === "base") {
			Backend.app = undefined;
		}
	};

	public static getApp = () => {
		return Backend.app;
	};

	public static getAppID = () => {
		return Backend.app?.appId;
	};

	public static toggleAuth = (authEnabled: boolean) => {
		Backend.authEnabled = authEnabled;
	};

	public static buildPath = (path: string, app = true) => {
		return Backend.backendDataServer + Backend.checkPath(path, app);
	};

	private static sendRequest = async <T extends any>(
		path: string,
		app: boolean,
		data: any,
		method: "get" | "post" | "put" | "patch" | "delete"
	): Promise<T> => {
		const url = Backend.buildPath(path, app);
		let response;
		return new Promise<T>(async (resolve, reject) => {
			// eslint-disable-next-line default-case
			switch (method) {
				case "get":
					response = await AXIOS.get(url, await Backend.getConfig()).catch((ex) => Backend.handleException(ex, reject));
					break;
				case "post":
					response = await AXIOS.post(url, data, await Backend.getConfig()).catch((ex) => Backend.handleException(ex, reject));
					break;
				case "put":
					response = await AXIOS.put(url, data, await Backend.getConfig()).catch((ex) => Backend.handleException(ex, reject));
					break;
				case "patch":
					response = await AXIOS.patch(url, data, await Backend.getConfig()).catch((ex) => Backend.handleException(ex, reject));
					break;
				case "delete":
					const config: any = await Backend.getConfig();
					config.data = data;
					response = await AXIOS.delete(url, config).catch((ex) => Backend.handleException(ex, reject));
					break;
			}
			if (response) {
				const body = response.data;
				resolve(body);
			}
		});
	};

	private static handleException = (ex: any, reject: any) => {
		if (ex.message === "Network Error") {
			Notify.e("Aktuell ist eine Verbindung mit dem Server nicht möglich. Bitte versuchen Sie es in Kürze erneut");
		} else if (ex.response?.data) {
			reject(ex.response.data);
		}
	};

	private static getConfig = async () => {
		if (Backend.authEnabled) {
			return { headers: { Authorization: `Bearer ${await authClient.getTokenSilently()}` } };
		}
		return {};
	};

	private static checkPath = (path: string, appIdNececssary: boolean) => {
		if (path.charAt(0) !== "/") {
			path = `/${path}`;
		}
		// Get AppId
		if (appIdNececssary && Backend.app !== undefined) {
			path = `/${Backend.app.appId}${path}`;
		}

		return path;
	};
}
