/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import Backend from "../../Utils/Backend/Backend";

import "./ImageCollection.sass";

interface ImageCollectionProps {
	editMode?: boolean;
	images: string[];
	onImageDelete: (key: string) => void;
}

class ImageCollection extends Component<ImageCollectionProps> {
	state = {
		droppedFiles: [] as FileState[],
	};

	buildClassName = () => {
		let className = "image-collection";
		if (this.props.editMode) {
			className += " edit-mode";
		}
		return className;
	};

	reset = () => {
		this.setState({ droppedFiles: [] });
	};

	getFiles = () => {
		return this.state.droppedFiles;
	};

	onDrop = async (files: File[]) => {
		let { droppedFiles } = this.state;
		const newFiles: FileState[] = [];
		files.forEach((f) => {
			if (!droppedFiles.find((x) => x.file.name === f.name)) newFiles.unshift({ file: f, isUploading: false, progress: 0 });
		});

		// Get signed response
		const postBody = newFiles.map((droppedFile) => {
			return { extension: droppedFile.file.name.split(".").pop(), public: true };
		});
		const uploadResponse = await Backend.post<UploadResponse[]>("/image/upload/Fundbuero", { files: postBody });

		uploadResponse.data.forEach((r, i) => {
			newFiles[i].signedRequest = r.signedRequest;
			newFiles[i].url = r.url;
			newFiles[i].key = r.key;
			newFiles[i].isUploading = true;

			const f = newFiles[i];

			const xhr = new XMLHttpRequest();
			xhr.open("PUT", r.signedRequest);
			xhr.upload.addEventListener("progress", (d) => {
				const filesArr = this.state.droppedFiles;
				const file = filesArr.find((x) => x === f);
				if (!file) return;
				file.progress = (d.loaded / d.total) * 100;

				this.setState({ droppedFiles });
			});
			xhr.onloadend = () => {
				const filesArr = this.state.droppedFiles;
				const file = filesArr.find((x) => x === f);
				if (!file) return;
				droppedFiles[i].progress = 100;
				droppedFiles[i].isUploading = false;
				this.setState({ droppedFiles });
			};
			xhr.send(f.file);
		});

		droppedFiles = [...newFiles, ...droppedFiles];
		this.setState({ droppedFiles });
	};

	deleteFile = async (key: string | undefined) => {
		if (!key) return;
		this.props.onImageDelete(key);

		let { droppedFiles } = this.state;
		droppedFiles = droppedFiles.filter((x) => x.key !== key);
		this.setState({ droppedFiles });
		await Backend.delete(`/image/delete/${key}`);
	};

	dropzoneClassName = (accept: boolean, active: boolean, reject: boolean) => {
		let className = "dropzone";
		if (accept) className += " accept";
		if (reject) className += " reject";
		return className;
	};

	render() {
		const { images, editMode } = this.props;
		const { droppedFiles } = this.state;
		return (
			<div className={this.buildClassName()}>
				{editMode && (
					<Dropzone onDrop={this.onDrop}>
						{({ getRootProps, getInputProps, isDragAccept, isDragActive, isDragReject }) => (
							<div className="image-upload-area container">
								<div {...getRootProps({ className: this.dropzoneClassName(isDragAccept, isDragActive, isDragReject) })}>
									<input {...getInputProps()} />
									<i className="im im-cloud-upload" />
									<span>Upload</span>
								</div>
							</div>
						)}
					</Dropzone>
				)}

				{droppedFiles.map((file) => (
					<div key={file.key} className="image">
						<i onClick={() => this.deleteFile(file.key)} className="im im-x-mark-circle" />
						<img src={URL.createObjectURL(file.file)} alt="Löschicon" />

						<div className="image-new-indicator">
							<i className="im im-plus" />
						</div>
						<div className="progress-bar">
							<span style={{ width: `${file.progress}%` }} />
						</div>
					</div>
				))}

				{images.map((image, key) => (
					<div key={key as any} className="image">
						<i onClick={() => this.deleteFile(image.match(/([^/]+)(?=\.\w+$)/)?.[0])} className="im im-x-mark-circle" />
						<a href={image} target="_blank" rel="noopener noreferrer">
							<img src={image} alt="Bild" />
						</a>
					</div>
				))}
			</div>
		);
	}
}

export default ImageCollection;
