/* eslint-disable react/no-danger */
import React, { Component } from "react";
import hljs from "highlight.js/lib/core";
import json from "highlight.js/lib/languages/json";
import "highlight.js/styles/dracula.css";

hljs.registerLanguage("json", json);
interface CodeHighlightProps {
	children: string;
}

class CodeHighlight extends Component<CodeHighlightProps> {
	nodeRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		this.highlight();
	}

	componentDidUpdate() {
		this.highlight();
	}

	highlight = () => {
		if (this.nodeRef) {
			const nodes = this.nodeRef.current?.querySelectorAll("pre");
			nodes?.forEach((node) => {
				hljs.highlightBlock(node);
			});
		}
	};

	render() {
		const { children } = this.props;
		return (
			<div ref={this.nodeRef}>
				<pre>
					<code className="json" dangerouslySetInnerHTML={{ __html: children }} />
				</pre>
			</div>
		);
	}
}

export default CodeHighlight;
