import { Button, Input, Spinner } from "@onedash/tools";
import React, { Component } from "react";
import "./List.sass";
import ListItem from "./ListItem";

interface ListProps {
	label: string;
	showCount?: boolean;
	onAddClick?: () => void;
	onSearch?: (searchString: string) => void;
	isLoading?: boolean;
}

export default class List extends Component<ListProps> {
	render() {
		const { label, showCount, onSearch, onAddClick, isLoading } = this.props;

		let count = 0;
		const items: any[] = [];
		React.Children.forEach(this.props.children, (child: any) => {
			if (!child || typeof child !== "object") return;
			if (child?.type === ListItem) {
				count++;
				items.push(child);
			}
		});

		return (
			<div className="list">
				<div className="header">
					<h2>
						{label} <span>({showCount && count})</span>
					</h2>
					<div>
						{onSearch && (
							<Input onChange={(obj) => onSearch(obj.value)} placeholder="Suchen Sie ..." type="search" name="list-search" />
						)}
						{onAddClick && (
							<Button mode="link" className="add-icon">
								<i className="im im-plus-circle" />
							</Button>
						)}
					</div>
				</div>
				<div className="items">{items}</div>
				{isLoading && <Spinner className="list-spinner" defaultVisible />}
			</div>
		);
	}
}
