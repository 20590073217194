import React from "react";
import "./Misc.sass";

interface InfoBoxProps {
	type?: "info" | "success" | "error" | "warning";
	children: any;
	style?: React.CSSProperties;
	className?: string;
}

const InfoBox = (props: InfoBoxProps) => {
	let iconClassName;
	switch (props.type) {
		case "error": {
			iconClassName = "im im-warning-circle";
			break;
		}
		case "warning": {
			iconClassName = "im im-warning-circle";
			break;
		}
		default:
			iconClassName = "im im-info";
	}
	return (
		<div style={props.style} className={`info-box info-box__${props.type ?? "info"} ${props.className ?? ""}`}>
			<i className={iconClassName} />
			<div className="content">{props.children} </div>
		</div>
	);
};

export default InfoBox;
