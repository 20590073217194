import { Card, Dialog, MediaRender, Spinner } from "@onedash/tools";
import { Button, Form, Input, NativeSelect, Toggle } from "onedash-react-input-form";
import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Toolbar from "../../Pages/Dashboard/Toolbar/Toolbar";
import Backend from "../../Utils/Backend/Backend";
import "./Gaestebeitrag.sass";
import { GuestCategory, Lodging, LodgingStat } from "./GaestebeitragTypes";
import Convert from "../../Utils/Convert";
import ButtonSpinner from "../../Pages/MiniPages/Components/ButtonSpinner";
import Privacy from "../../Pages/Dashboard/HeadBar/Privacy";
import SuccessAnimation from "../../Components/Animations/SuccessAnimation";
import ErrorAnimation from "../../Components/Animations/ErrorAnimation";

interface GaestebeitragerfassungProps {}
interface FormValues {
	categories: { persons: string | number; nights: string | number; name: string }[];
	trulyToggle: boolean;
	privacyToggle: boolean;
	isVacation: boolean;
}
class Gaestebeitragerfassung extends Component<GaestebeitragerfassungProps> {
	privacyDialog = React.createRef<Dialog>();

	responseDialog = React.createRef<Dialog>();

	form = React.createRef<Form>();

	state = {
		lodgings: [] as Lodging[],
		selectedLodging: undefined as undefined | Lodging,
		guestCategories: [] as GuestCategory[],
		lodgingStats: undefined as LodgingStat[] | undefined,
		selectedMonthYear: undefined as string | undefined,
		sums: [] as number[],
		total: 0,
		valid: false,
		submittedDate: "",
		submitBusy: false,
		isLoading: true,
		errorMessage: undefined as undefined | string,
		loadingError: undefined,
		isVacationMonth: false,
	};

	componentDidMount() {
		this.loadLodges();
	}

	loadLodges = async () => {
		const lodgingsRes = await Backend.get<Lodging[]>("/vg/lodgings");
		// Remove duplicates
		const lodgings = lodgingsRes.data.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
		const selectedLodging = lodgings[0];
		if (selectedLodging === undefined) {
			this.setState({
				isLoading: false,
				loadingError: "Sie haben aktuell keinen Betrieb zugeordnet. Bitte wenden Sie sich an den Systemandministrator.",
			});
			return;
		}
		this.saveLodgings(selectedLodging, lodgings);
	};

	saveLodgings = (selectedLodging?: Lodging, lodgings?: Lodging[]) => {
		this.setState((s: any) => ({ lodgings: lodgings ?? s.lodgings, selectedLodging, isVacationMonth: false }), this.loadStats);
	};

	loadCategories = async () => {
		const monthYear = this.state.selectedMonthYear;
		if (!monthYear) return;
		const [year, month] = monthYear.split("-");
		const date = moment().clone().set("year", Number(year)).set("month", Number(month)).set("day", 1).timestamp();
		const res = await Backend.get<GuestCategory[]>(`/appSettings/guestCategories?dateTime=${date}`);

		this.setState({ guestCategories: res.data });
	};

	getMonthYear = () => {
		const monthYear = this.state.selectedMonthYear;
		if (!monthYear) return [0, 0];
		const [year, month] = monthYear.split("-");
		return [Number(year), Number(month)];
	};

	getMonthYearFormatted = () => {
		const [year, month] = this.getMonthYear();
		return moment().clone().set("month", month).set("year", year).format("MMMM YYYY");
	};

	loadStats = async () => {
		// Load last stats in order to show all possible months
		const res = await Backend.get<LodgingStat[]>(`/vg/lodgings/${this.state.selectedLodging?.id}/stats`);
		const lodgingStats = res.data.filter((x) => x.notCreated === true);
		this.selectMonth(`${lodgingStats[0]?.year}-${lodgingStats[0]?.month}`);
		this.setState({ lodgingStats });
		setTimeout(() => {
			this.setState({ isLoading: false });
		}, 1000);
	};

	selectMonth = (selectedMonthYear: string) => {
		this.setState({ isVacationMonth: false, selectedMonthYear }, this.loadCategories);
	};

	calculateSum = (values: FormValues) => {
		const { guestCategories } = this.state;
		const sums: number[] = [];
		let total = 0;
		values.categories?.forEach((category, i) => {
			sums[i] = Number(category.nights ?? 0) * guestCategories[i].price;
			total += sums[i];
		});
		this.setState({ sums, total });
	};

	submitForm = () => {
		this.setState({ submitBusy: true, submittedDate: this.getMonthYearFormatted() });
		const [year, month] = this.getMonthYear();
		const values: FormValues = this.form.current?.getData();
		const { guestCategories } = this.state;
		values.categories.forEach((category, i) => {
			category.persons = Number(category.persons ?? 0);
			category.nights = Number(category.nights ?? 0);
			category.name = guestCategories[i].name;
		});

		const body = {
			stat: { lodgingId: this.state.selectedLodging?.id, month, year, categories: values.categories, isVacation: values.isVacation },
		};

		Backend.post(`/vg/lodgings/stats`, body)
			.then(async () => {
				this.setState({ errorMessage: undefined });
			})
			.catch((ex) => {
				this.setState({ errorMessage: ex.data });
			})
			.finally(() => {
				this.setState({ submitBusy: false });
				this.responseDialog.current?.show();
			});
	};

	render() {
		const {
			lodgings,
			selectedLodging,
			lodgingStats,
			selectedMonthYear,
			guestCategories,
			sums,
			total,
			valid,
			submitBusy,
			errorMessage,
			isLoading,
			loadingError,
			isVacationMonth,
		} = this.state;

		const lodgingOptions = lodgings.map((l) => {
			return { label: l.companyName, value: l.id };
		});
		const monthOptions = lodgingStats?.map((l) => {
			return {
				label: moment().set("month", l.month).set("year", l.year).format("MMMM YYYY"),
				value: `${l.year}-${l.month}`,
			};
		});

		return (
			<>
				{lodgings.length > 1 && (
					<Toolbar>
						<NativeSelect
							value={selectedLodging?.id}
							required
							name="lodging"
							placeholder="Wählen Sie einen Betrieb"
							options={lodgingOptions}
							disabled={submitBusy}
							onChange={(id) => this.saveLodgings(lodgings.find((x) => x.id === id))}
						/>
					</Toolbar>
				)}
				<div className="gaestebeitrag-erfassung">
					<Card maxWidth={750}>
						{!isLoading && !loadingError && monthOptions && monthOptions?.length > 0 && (
							<>
								<h1>Gästebeitrag erfassen </h1>
								<p>
									Erfassen Sie im folgenden Formular Ihren Gästebeitrag. Wählen Sie dafür einen Monat und tragen Sie die
									Werte in der Tabelle unten ein. Bitte beachten Sie, dass Sie die Gästebeitragserklärung nicht mehr
									nachträglich ändern können!
								</p>
								<Form
									onValidationUpdate={(v) => this.setState({ valid: v })}
									className="gaestebeitrag-form"
									ref={this.form}
									onChange={this.calculateSum}>
									<NativeSelect
										required
										name="month"
										value={selectedMonthYear}
										placeholder="Wählen Sie einen Monat"
										options={monthOptions}
										onChange={(val) => {
											if (val) this.selectMonth(val);
										}}
										label="Monat und Jahr"
										disabled={submitBusy}
									/>

									<Toggle
										onChange={(isVMonth) => {
											if (isVMonth !== undefined) {
												this.form.current?.resetForm().then(() => {
													const monthSelect: any = this.form.current?.getRef("month")?.ref;
													if (monthSelect) monthSelect.setState({ value: this.state.selectedMonthYear });
													this.setState({ isVacationMonth: isVMonth });
													this.calculateSum(this.form.current?.getData());
												});
											}
										}}
										value={isVacationMonth}
										name="isVacation"
										className="vacation-toggle"
										disabled={submitBusy}>
										<span className="bold">Fehlanzeige</span>
										<br />
										Wählen Sie diese Einstellung, wenn Ihr Betrieb in diesem Monat keine Gäste beherbergt hat.
									</Toggle>

									<MediaRender type="desktop">
										<div className="table-head">
											<p>Kategorie</p>
											<p>Preis</p>
											<p>Personenanzahl gesamt</p>
											<p>Übernachtungen gesamt</p>
											<p style={{ textAlign: "right" }}>Summe</p>
										</div>
									</MediaRender>
									<div className="table-body">
										{guestCategories.map((category, i) => (
											<React.Fragment key={i as any}>
												<p className="label">{category.label}</p>
												<p className="price">
													<span>(</span>
													{Convert.numberToPrice(category.price)}
													<span>)</span>
												</p>

												<Input
													maxLength={5}
													name={`categories[${i}].persons`}
													type="tel"
													settings={{
														allowNumberNull: false,
														allowNumberNegative: false,
														validateTel: false,
													}}
													pattern="^$|^[0-9]+$"
													placeholder="Anzahl Personen "
													disabled={submitBusy || isVacationMonth}
												/>
												<Input
													maxLength={5}
													name={`categories[${i}].nights`}
													type="tel"
													settings={{
														allowNumberNull: false,
														allowNumberNegative: false,
														validateTel: false,
													}}
													pattern="^$|^[0-9]+$"
													placeholder="Anzahl Übernachtungen"
													disabled={submitBusy || isVacationMonth}
												/>

												<div className="sum">
													<span>Summe: </span>
													{Convert.numberToPrice(sums?.[i] ?? 0)}
												</div>
											</React.Fragment>
										))}
									</div>

									<div className="total">
										<div className="label">Gesamtbetrag</div>
										<div className="price">{Convert.numberToPrice(total)}</div>
									</div>

									<div className="toggles">
										<Toggle name="trulyToggle" required disabled={submitBusy}>
											<>
												Hiermit bestätigen Sie, dass die Angaben wahrheitsgemäß und nach bestem Wissen und Gewissen
												gemacht wurden. Ihnen ist bekannt, dass nicht bzw. vorsätzlich falsch gemachte Angaben ein
												Ordnungswidrigkeitsverfahren nach sich ziehen können.
											</>
										</Toggle>
										<Toggle name="privacyToggle" required disabled={submitBusy}>
											<>
												Hiermit bestätigen Sie, dass Sie unsere{" "}
												<Button className="link" onClick={() => this.privacyDialog.current?.show()}>
													Datenschutzerklärung
												</Button>{" "}
												gelesen,verstanden und akzeptiert haben.
											</>
										</Toggle>
									</div>

									<Button onClick={this.submitForm} className="submit-btn" disabled={!valid || submitBusy}>
										{!submitBusy && "Monat übermitteln"}
										{submitBusy && <ButtonSpinner />}
									</Button>
								</Form>
							</>
						)}
						{isLoading && <Spinner defaultVisible />}

						{!isLoading && loadingError && (
							<ErrorAnimation visible title="Es ist ein Fehler aufgetreten">
								{loadingError}
							</ErrorAnimation>
						)}

						{!isLoading &&
							(!monthOptions ||
								(monthOptions.length === 0 && (
									<SuccessAnimation visible title="Alle Beitragserklärungen abgegeben!">
										Aktuell müssen Sie keine weitere Gästebeitragserklärung abgeben. Sie können alle bereits abgegebenen
										Erklärungen unter der <Link to="stats">Statistik</Link> anschauen.
										<br />
										Jeweils zum Ersten des Folgemonats können Sie eine Erklärung abgeben.
									</SuccessAnimation>
								)))}
					</Card>
				</div>

				<Dialog buttons={[]} ref={this.privacyDialog} title="Datenschutzerklärung">
					<Privacy />
				</Dialog>

				<Dialog
					onClose={() => {
						if (!errorMessage) {
							this.setState({ isVacationMonth: false });
							this.loadLodges();
							this.form.current?.resetForm();
						}
					}}
					className="hide-print"
					ref={this.responseDialog}
					buttons={[]}>
					{!errorMessage && (
						<SuccessAnimation visible title="Erklärung erfolgreich übermittelt!">
							Ihre Gästebeitragserklärung für den Zeitraum{" "}
							<span className="italic highlight">{this.state.submittedDate}</span> wurde erfolgreich am{" "}
							<span className="italic highlight">{moment().format("LLL")} Uhr</span> an die Verbandsgemeindeverwaltung
							übermittelt. Im Dashboard finden Sie unter Statistik eine Übersicht Ihrer bereits eingereichten Formulare. Diese
							werden ein Jahr im Dashboard gespeichert. Sollten Sie noch Fragen haben oder Meldescheine bzw. Couponhefte
							benötigen, stehen wir Ihnen gerne zur Verfügung.
							<Button className="print-btn" onClick={() => window.print()}>
								Bestätigung drucken
							</Button>
						</SuccessAnimation>
					)}

					{errorMessage && (
						<ErrorAnimation visible title="Es ist ein Fehler aufgetreten!">
							Bei der Übermittlung Ihrer Gästebeitragserklärung für den Zeitraum{" "}
							<span className="italic highlight">{this.state.submittedDate}</span> ist der folgende Fehler aufgetreten. Bitte
							versuchen Sie es noch einmal erneut. Wenn der Fehler weiterhin auftritt, wenden Sie sich bitte an den{" "}
							<a href="https://o-d.link/support" className="highlight" target="_blank" rel="noopener noreferrer">
								Support
							</a>
							.<p className="italic">{errorMessage}</p>
						</ErrorAnimation>
					)}
				</Dialog>
			</>
		);
	}
}

export default Gaestebeitragerfassung;
