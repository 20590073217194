import * as React from "react";

export interface PrivacyProps {}

const Privacy: React.SFC<PrivacyProps> = () => {
	return (
		<div className="about-text">
			<h2>Allgemein</h2>
			<p>
				Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen Daten
				(nachfolgend auch kurz als “Daten“ bezeichnet) wir zu welchen Zwecken und in welchem Umfang im Rahmen der Bereitstellung
				unserer Applikation verarbeiten.
			</p>
			<p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch. </p>
			<p>
				Die Betreiber dieser Seite nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
				vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
			</p>
			<p>
				Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken
				aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
			</p>
			<h3>Arten der verarbeiteten Daten</h3>
			<ul>
				<li>Bestandsdaten (z.B. Personen-Stammdaten, Namen oder Adressen)</li>
				<li>Unternehmensdaten (z.B. Unternehemensinformationen oder Statistiken)</li>
				<li>Kontaktdaten (z.B. E-Mail, Telefonnummern) </li>
				<li>Inhaltsdaten (z.B. Texteingaben, Fotografien) </li>
				<li>Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen) </li>
			</ul>
			<h2>Geltungsbereich</h2>
			<p>
				Diese Datenschutzerklärung gilt für das Internet- und App - Angebot{" "}
				<a href="https://dashboard.onedash.de">https://dashboard.onedash.de</a> und aller Subdomains des Unternehmens OneDash. und
				die die dort angebotenen eigenen Inhalte. Für Inhalte anderer Anbieter, auf die z.B. über Links verwiesen wird, gelten die
				dortigen Bestimmungen. Insbesondere sind diese gemäß § 7 Abs. 1 Telemediengesetz (TMG) für eigene Inhalte verantwortlich.
			</p>
			<h2>Verantwortlich im Sinne der Datenschutzgesetzes:</h2>
			<p>
				Christian Bußhoff,
				<br />
				Burgstraße 19
				<br />
				54470 Bernkastel - Kues
				<br />
				Email: <a href="mailto:datenschutz@onedash.de">datenschutz@onedash.de</a>
				<br />
			</p>
			<h2>Kategorien betroffener Personen</h2>
			<ul>
				<li>Kommunikationspartner</li>
				<li>Angemeldete Nutzer (Nutzer der Dashboarddienstleistungen)</li>
			</ul>
			<h2>Erfassung von Daten</h2>
			<p>
				Während Sie auf unsere Webseiten zugreifen, erfassen wir automatisch Daten von allgemeiner Natur. Diese Daten
				(Server-Logfiles) umfassen zum Beispiel die Art ihres Browsers, ihr Betriebssystem, den Domainnamen Ihres Internetanbieters
				sowie weitere ähnliche allgemeine Daten. Diese Daten sind absolut Personen unabhängig und werden genutzt, um Ihnen die
				Webseiten korrekt darzustellen und werden bei jeder Nutzung des Internets abgerufen. Die absolut anonymen Daten werden
				statistisch ausgewertet um unseren Service für Sie zu verbessern.
			</p>
			<h2>Anmeldung auf unserer Webseite</h2>
			<p>
				Bei der Anmeldung für spezifische Angebote erfragen wir einige persönliche Daten wie Name, Anschrift, Kontakt, Telefonnummer
				oder E-Mail-Adresse. Angemeldete Nutzer können auf bestimmte Zusatzleistungen zugreifen. Angemeldete Nutzer haben die
				Möglichkeit, alle angegebenen persönlichen Daten zu jedem Zeitpunkt zu ändern oder löschen zu lassen. Sie können auch
				jederzeit die von ihnen gespeicherten Daten bei uns erfragen. Soweit gesetzlich keine Frist für die Aufbewahrung der Daten
				besteht, können diese geändert oder gelöscht werden. Bitte kontaktieren Sie uns dazu über unsere Kontaktseite.
			</p>
			<h2>Rechte der betroffenen Personen</h2>
			<p>
				Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über
				diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.
			</p>
			<p>
				Sie haben entsprechend. den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie betreffenden Daten oder die
				Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
			</p>
			<p>
				Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht zu verlangen, dass betreffende Daten unverzüglich gelöscht
				werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten zu verlangen.
			</p>
			<p>
				Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach Maßgabe der
				gesetzlichen Vorgaben zu erhalten und deren Übermittlung an andere Verantwortliche zu fordern.
			</p>
			<p>
				Sie haben ferner nach Maßgabe der gesetzlichen Vorgaben das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde
				einzureichen.
			</p>
			<h2>Server Log Files</h2>
			<p>
				Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser
				beim Zugriff auf unsere Seiten automatisch an uns übermittelt. Dies sind:
			</p>
			<ul>
				<li>Dateiname</li>
				<li>Datum und Uhrzeit der Anforderung</li>
				<li>übermittelte Datenmenge</li>
				<li>Browsertyp/ Browserversion</li>
				<li>verwendetes Betriebssystem</li>
				<li>Referrer URL</li>
				<li>Zugriffsstatus (übermittelt, nicht gefunden etc.)</li>
			</ul>
			<p>
				Diese Daten sind nicht bestimmten Personen zuordbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
				vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine
				rechtswidrige Nutzung bekannt werden.
			</p>
			<h2>Cookies </h2>
			<p>
				Die Anwendung verwendet teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten
				keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine
				Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Die von uns verwendeten Cookies sind so
				genannte „Session-Cookies“. In einem Session-Cookie wird eine zufällig erzeugte eindeutige Identifikationsnummer abgelegt,
				eine sogenannte Session-ID. Außerdem enthält ein Cookie die Angabe über seine Herkunft und die Speicherfrist. Diese Cookies
				können keine anderen Daten speichern und werden nach Ende Ihres Besuchs automatisch gelöscht. Sie können Ihren Browser so
				einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von
				Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser
				aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein. Bei der Nutzung
				fremder Inhalte über unsere Webseite (z.B. im Fall einer Verlinkung) können gegebenenfalls Cookies von Drittanbietern zum
				Einsatz kommen, ohne dass wir Sie ausdrücklich darauf hinweisen können.
			</p>
			<h2>Lokaler Speicher (Local Storage)</h2>
			<p>
				Um Ihre persönlichen Einstellungen und Reservierungsdaten (Nicht personenbezogen) speichern zu können, verwenden wir den
				sog. &quot; Lokalen Speicher (Local Storage) &quot;. Dabei werden Einstellungen und Daten im Cache des Browsers gespeichert,
				die so lange der Browser cache nicht gelöscht werden bestehen bleiben. Auf die im Local Storage gespeicherten Daten können
				Dritte nicht zugreifen. Sie werden an Dritte nicht weitergegeben und auch nicht zu Werbezwecken verwendet.
			</p>
			<p>Es werden die folgenden Daten im LocalStorage hinterlegt:</p>
			<ul>
				<li>Zuletzt verwendete Dashboard Anwendung</li>
				<li>Temporäre Formuareingaben</li>
			</ul>
			<h2>Sicherheitsmaßnahmen</h2>
			<p>
				Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der Implementierungskosten
				und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen
				Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte und Freiheiten natürlicher Personen, geeignete technische
				und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten. Zu den Maßnahmen gehören
				insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen
				Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der Verfügbarkeit und
				ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung von
				Daten und Reaktion auf Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten
				bereits bei der Entwicklung, bzw. Auswahl von Hardware, Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes
				durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
			</p>
			<h2>Kontaktaufnahme</h2>
			<p>
				Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail oder Ticketsystem ) werden die Angaben des Nutzers zur
				Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b. (im Rahmen vertraglicher-/vorvertraglicher
				Beziehungen), Art. 6 Abs. 1 lit. f. (andere Anfragen) DSGVO verarbeitet. Die Angaben der Nutzer können in einem
				Customer-Relationship-Management System (CRMS) gespeichert werden. Wir löschen die Anfragen, sofern diese nicht mehr
				erforderlich sind. Wir überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten die gesetzlichen
				Archivierungspflichten.
			</p>
			<p>
				Ihre personenbezogenen Daten werden gemäß Art. 6 Abs. 1 b) DSGVO ausschließlich zur Abwicklung Ihrer Reservierungen sowie
				gemäß Art. 6 Abs. 1 f) DSGVO zur Wahrung unserer berechtigten Interessen genutzt.
			</p>
			<h2>Externe Dienstleister</h2>
			<h3>Emailversand</h3>
			<p>
				Um ausgewählte Dienstleistungen zu erbringen (u.a. die Identitätsverifizierung), versenden wir E-Mails, die von einem
				Dienstleister zugestellt werden:
			</p>
			<h4>MailGun</h4>
			<p>
				Der Versand der E-Mails über das Registrierungsportal erfolgt mittels „Mailgun“, einer E-Mailversandplattform des
				US-Anbieters Mailgun Technologies, Inc., 535 Mission St., San Francisco, CA 94105.
			</p>
			<p>
				Der Anbieter ist als Privacy-Shield-zertifziert ausgewiesen , was die Einhaltung der EU DSGVO sowie aller sonstigen in den
				Mitgliedstaaten der EU geltenden Datenschutzgesetze bzw. Bestimmungen mit datenschutzrechtlichem Charakter garantiert (
				<a href="https://www.privacyshield.gov/participant?id=a2zt0000000PCbmAAG">Zur Zertifizierung</a>). Weitere Informationen zur
				Datenverarbeitung durch den Anbieter finden Nutzer unter{" "}
				<a href="https://www.mailgun.com/privacy-policy">https://www.mailgun.com/privacy-policy</a>.
			</p>
			<h3>Identity Provider (Auth0)</h3>
			<p>
				Wir setzten auf Auth0 zur sicheren Identitätsfeststellung (Login). Zu diesem Zweck werden Ihre E-Mail Adresse und Ihr
				Passwort an Auth0 übermittelt und dort gespeichert. Der Nutzer stimmt der Übermittlung seiner personenbezogenen Daten an den
				externen Identity Provider, dessen Server sich sowohl in den USA, als auch in der EU befinden, ausdrücklich zu. Nähere
				Informationen finden Sie auf https://auth0.com/privacy und https://auth0.com/terms.
			</p>
			<h3>Google Fonts</h3>
			<p>
				Wir binden die Schriftarten (Google Fonts) des Anbieters Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
				Irland, ein. Nach Angaben von Google werden die Daten der Nutzer allein zu Zwecken der Darstellung der Schriftarten im
				Browser der Nutzer verwendet. Die Einbindung erfolgt auf Grundlage unserer berechtigten Interessen an einer technisch
				sicheren, wartungsfreien und effizienten Nutzung von Schriftarten, deren einheitlicher Darstellung sowie Berücksichtigung
				möglicher lizenzrechtlicher Restriktionen für deren Einbindung. Datenschutzerklärung:{" "}
				<a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.
			</p>
			<h2>Änderung und Aktualisierung der Datenschutzerklärung</h2>
			<p>
				Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die
				Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
				informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige
				individuelle Benachrichtigung erforderlich wird.
			</p>
		</div>
	);
};

export default Privacy;
