import React from "react";
import "./ResponseAnimation.sass";

const ErrorAnimation = (props: { visible?: boolean; title?: string; children?: any }) => {
	return (
		<div className="error-animation">
			<svg
				id="animation"
				className={props.visible ? "visible" : ""}
				xmlns="http://www.w3.org/2000/svg"
				width="100"
				height="100"
				fillRule="evenodd"
				strokeLinejoin="round"
				strokeMiterlimit="2"
				clipRule="evenodd"
				viewBox="0 0 70 70">
				<path
					id="animation__result"
					d="M35,60C21.193,60 10,48.807 10,35C10,21.193 21.193,10 35,10C48.807,10 60,21.193 60,35C60,48.807 48.807,60 35,60ZM35,33.541L43.92,24.621L45.379,26.08L36.459,35L45.379,43.92L43.92,45.379L35,36.459L26.08,45.379L24.621,43.92L33.541,35L24.621,26.08L26.08,24.621L35,33.541Z"
					fill="#D8D8D8"
				/>

				<circle
					id="animation__circle"
					cx="35"
					cy="35"
					r="24"
					stroke="#979797"
					strokeWidth="2"
					strokeLinecap="round"
					fill="transparent"
				/>
			</svg>
			<h1>{props.title}</h1>
			<div className="children">{props.children}</div>
		</div>
	);
};

export default ErrorAnimation;
