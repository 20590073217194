import React, { useEffect } from "react";
import { Spinner } from "@onedash/tools";
import Dashboard from "./Dashboard";
import Backend from "../../Utils/Backend/Backend";
import Customization from "../../Utils/Customization";
import AppUtils from "../../Utils/AppUtils";
import { DEFAULT_HIGHLIGHT_COLOR } from "../../Resources/Colors";
import { useAuth } from "../../Auth/AuthContext";

const DashboardLoader = () => {
	const { permissions } = useAuth();
	const [applications, updateApplications] = React.useState(undefined as Application[] | undefined);
	const loadCustomizing = async () => {
		// Highlight color
		const highlightColor = await Customization.getUserPreference("highlightColor");
		AppUtils.setHighlightColor(highlightColor ?? DEFAULT_HIGHLIGHT_COLOR);
	};

	const loadApplications = async () => {
		const apps = await Backend.get<Application[]>("/base/applications", false);
		updateApplications(apps.data);
	};

	useEffect(() => {
		loadApplications();
		loadCustomizing();
	}, []);

	return (
		<div>
			{!applications && <Spinner defaultVisible fullPage />}

			{applications && <Dashboard permissions={permissions} applications={applications} />}
		</div>
	);
};

export default DashboardLoader;
