import React, { Component } from "react";
import "./Dashboard.scss";
import { Route, Switch, Redirect } from "react-router-dom";
import { Card } from "@onedash/tools";
import Headbar from "./HeadBar/Headbar";
import Navbar from "./Navbar/Navbar";
import FILTER_MODULES, { HAS_BASE_PERMISSIONS } from "../../Apps/Modules";
import Customization from "../../Utils/Customization";
import Backend from "../../Utils/Backend/Backend";
import BrowserHistory from "../../Utils/BrowserHistory";
import Action from "../../Utils/Redux/Action";

interface DashboardProps {
	applications: Application[];
	permissions: string[];
}

class Dashboard extends Component<DashboardProps> {
	isResetting = false;

	constructor(props: DashboardProps) {
		super(props);
		BrowserHistory.listen((_: any, action: string) => {
			if (action === "PUSH") {
				this.resetTab();
			}
		});
	}

	state = {
		modules: [] as Module[],
		applications: undefined as Application[] | undefined,
		defaultAppPath: "/dashboard",
		defaultModulePath: undefined as undefined | string,
		selectedApp: undefined as Application | undefined,

		tabLoaded: false,
		sidebar: false,
		hasToolbar: false,
	};

	componentDidMount() {
		const store = window.reduxStore;
		const { applications } = this.props;
		const { permissions } = this.props;

		if (HAS_BASE_PERMISSIONS(permissions)) {
			if (!applications.find((x) => x.appId === "base"))
				applications.push({
					appId: "base",
					appName: "Serverkonfiguration",
					appType: "base",
					order: 1,
				});
		}
		this.setState(
			{
				applications,
			},
			this.loadDefaultAppPath
		);

		store.subscribe(() => {
			const data = window.reduxStore.getState();

			if (data.tabLoaded !== this.state.tabLoaded) {
				this.setState({ tabLoaded: data.tabLoaded });
			}
			if (data.sidebar !== this.state.sidebar) {
				this.setState({ sidebar: data.sidebar });
			}
			if (data.hasToolbar !== this.state.hasToolbar) {
				this.setState({ hasToolbar: data.hasToolbar });
			}
		});
	}

	getApp = (appID: string) => this.state.applications?.find((x) => x.appId === appID);

	loadDefaultAppPath = async () => {
		const { applications } = this.state;
		let defaultAppPath = "/dashboard/missing-app-assignment";

		if (applications && applications.length > 0) {
			const latestAppID = await Customization.getUserPreference("latestApp");
			if (latestAppID && this.getApp(latestAppID)) {
				defaultAppPath = `/dashboard/${latestAppID}`;
			} else {
				defaultAppPath = `/dashboard/${applications[0].appId}`;
			}

			if (window.location.href.indexOf("missing-app-assignment") !== -1) {
				BrowserHistory.push("/dashboard");
			}
		}

		this.setState({ defaultAppPath });
	};

	loadDefaultModulePath = async () => {
		const { selectedApp } = this.state;
		if (!selectedApp) return;

		let defaultModulePath = `/dashboard/${selectedApp.appId}/no-apps`;
		const modules = FILTER_MODULES(this.props.permissions, selectedApp.appType);

		if (modules.length > 0) {
			defaultModulePath = `/dashboard/${selectedApp.appId}${modules[0].basePath}`;
		}

		this.setState({ modules, defaultModulePath });
	};

	resetTab = () => {
		if (!this.isResetting) {
			Action.tabSwitched();
			this.isResetting = true;
			setTimeout(() => {
				this.isResetting = false;
			}, 300);
		}
	};

	selectApp = async (appID: string, alreadyRedirected?: boolean) => {
		const oldAppID = this.state.selectedApp?.appId;
		let oldSubPath: string | undefined;
		if (oldAppID) {
			const { pathname } = window.location;
			const oldPathIndex = pathname.indexOf(oldAppID);
			oldSubPath = pathname.substring(oldPathIndex + oldAppID.length, pathname.length);
		}

		const apps = this.props.applications;
		const app = apps.find((x) => x.appId === appID);
		if (!app) return;

		// Set Backend APP
		Backend.setApp(app);
		// Save Latest App to cloud
		Customization.saveUserPreference("latestApp", app.appId);

		if (!alreadyRedirected) {
			BrowserHistory.push(`/dashboard/${appID}`);

			// Second update to rerun componentDidMount on components
			// Timeout is neccessary to force this behavior
			setTimeout(() => {
				if (oldSubPath) {
					BrowserHistory.push(`/dashboard/${appID}${oldSubPath}`);
				}
			});
		}
		// Select app to state
		this.setState({ selectedApp: app }, () => {
			this.loadDefaultModulePath();
		});
	};

	render() {
		const { modules, defaultAppPath, defaultModulePath, selectedApp, sidebar, applications, hasToolbar } = this.state;
		if (!defaultAppPath || !applications) return <></>;

		return (
			<div className="dashboard">
				<Headbar onAppSelect={this.selectApp} selectedApp={selectedApp} applications={applications} modules={modules} />
				<Navbar selectedApp={selectedApp} navItems={this.state.modules} />
				<div className={`main-content${sidebar ? " has-sidebar" : ""}${hasToolbar ? " has-toolbar" : ""}`}>
					<Switch>
						<Route path="/dashboard/missing-app-assignment">
							<Card className="center-center">
								<h1 className="text-center">
									Ihnen wurde noch keine Application zugewiesen. Wenden Sie sich bitte an den Systemadministrator.
								</h1>
							</Card>
						</Route>

						<Route
							path="/dashboard/:appId"
							render={(props) => {
								const app = this.getApp(props.match.params.appId);
								if (!app) {
									BrowserHistory.push("/dashboard");
									return <></>;
								}
								if (!selectedApp) {
									// Initial set app
									setTimeout(() => {
										this.selectApp(app.appId, app !== undefined);
									});
								}

								return (
									<Switch>
										<Route path="/dashboard/:appId/no-apps">
											<Card className="center-center">
												<h1 className="text-center">
													Ihnen wurden noch keine Anwendungen für dieses Unternehmen zugewiesen.
												</h1>
											</Card>
										</Route>

										{modules.map((module) => (
											<Route key={module.basePath} path={`/dashboard/${selectedApp?.appId}${module.basePath}`}>
												<Switch>
													{module.tabs.map((tab, i) => (
														<Route
															key={tab.subPath}
															path={`/dashboard/${selectedApp?.appId}${module.basePath}${module.tabs[i].subPath}`}
															component={tab.page}
														/>
													))}
													<Redirect
														to={`/dashboard/${selectedApp?.appId}${module.basePath}${module.tabs[0].subPath}`}
													/>
												</Switch>
											</Route>
										))}

										{defaultModulePath && <Redirect to={defaultModulePath} />}
									</Switch>
								);
							}}
						/>
						<Redirect to={defaultAppPath} />
					</Switch>
				</div>
			</div>
		);
	}
}

export default Dashboard;
