import React from "react";
import { useAuth } from "./AuthContext";

interface HasPermissionProps {
	permission?: string;
	children: any;
}

const HasPermission = ({ permission, children }: HasPermissionProps) => {
	const { permissions, isAuthenticated } = useAuth();
	if (!isAuthenticated) return <></>;
	return (permission && permissions.includes(permission)) || (isAuthenticated && !permission) ? children : <></>;
};

export default HasPermission;
