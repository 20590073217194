import { Dialog, Spinner } from "@onedash/tools";
import React, { useEffect, useState } from "react";
import moment from "moment";
import CodeHighlight from "./CodeHighlight";
import "./ApplicationLoggerDialog.sass";
import { GuestCategory } from "../../Apps/Gaestebeitrag/GaestebeitragTypes";
import Backend from "../../Utils/Backend/Backend";

interface AppLoggerDialogProps {
	logEntry?: ApplicatationLogEntry;
	title?: string;
	dialogRef: React.RefObject<Dialog>;
}
const LogEntry = ({ logEntry }: { logEntry: ApplicatationLogEntry }) => {
	const date = moment(logEntry.timestamp);

	return (
		<div className="log-entry">
			<span className="label">Datum</span>
			<span className="value">
				{date.fromNow()} ({date.format("DD.MM.YYYY HH:mm:ss")})
			</span>

			<span className="label">Typ</span>
			<span className="value">{logEntry.type}</span>

			<span className="label">Beschreibung</span>
			<span className="value">{logEntry.description}</span>

			<span className="label">User</span>
			<span className="value">
				{logEntry.userId && (
					<>
						{logEntry.userId} ({logEntry.email})
					</>
				)}
				{!logEntry.userId && "Unbekannt"}
			</span>

			<span className="label">IP - Adresse</span>
			<span className="value">{logEntry.ip}</span>

			<span className="label">Useragent</span>
			<span className="value">{logEntry.useragent}</span>
		</div>
	);
};
const ApplicationLoggerDialog = ({ logEntry, title, dialogRef }: AppLoggerDialogProps) => {
	const [categories, updateCategories] = useState([] as GuestCategory[]);
	const isLodgingStatType = (type?: string) =>
		["lodging-stats-updated", "lodging-stats-created", "lodging-stats-updated-category", "lodging-stats-created-category"].includes(
			type ?? ""
		);

	useEffect(() => {
		if (logEntry && isLodgingStatType(logEntry?.type)) {
			const year = logEntry.details.request?.body?.stat?.year;
			const month = logEntry.details.request?.body?.stat?.month;
			if (year && month !== undefined) {
				const date = moment().clone().set("year", Number(year)).set("month", Number(month)).set("day", 1).timestamp();
				Backend.get<GuestCategory[]>(`/appSettings/guestCategories?dateTime=${date}`).then((res) => {
					updateCategories(res.data);
				});
			}
		}
	}, [logEntry]);

	const replaceId = (text: string, getVal: (id: string) => string) => {
		return text.replace(/##(.*?)##/g, (_, p1) => getVal(p1));
	};
	return (
		<Dialog buttons={[]} className="application-logger-dialog" ref={dialogRef} title={title}>
			{!logEntry && <Spinner defaultVisible />}
			{logEntry && (
				<>
					<h3>Übersicht</h3>
					<LogEntry logEntry={logEntry} />
					{logEntry.subtasks && logEntry.subtasks.length > 0 && (
						<>
							<h3>Subtasks</h3>
							<div className="subtasks">
								{logEntry.subtasks?.map((st) => (
									<div key={st.id} className="subtask">
										{isLodgingStatType(st.type) ? (
											<>
												<span className="description">
													{replaceId(
														st.description,
														(name) => categories.find((x) => x.name === name)?.label ?? ""
													)}
												</span>
												<span className="persons">Personen: {st.data.persons}</span>
												<span className="nights">Übernachtungen: {st.data.nights}</span>
											</>
										) : (
											<>{st.description}</>
										)}
									</div>
								))}
							</div>
						</>
					)}

					<h3>Raw</h3>
					<h4>Details</h4>
					<CodeHighlight>{JSON.stringify(logEntry.details, undefined, 4)}</CodeHighlight>

					{logEntry.data && (
						<>
							<h4>Data</h4>
							<CodeHighlight>{JSON.stringify(logEntry.data, undefined, 4)}</CodeHighlight>
						</>
					)}
				</>
			)}
		</Dialog>
	);
};
export default ApplicationLoggerDialog;
