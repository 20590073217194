import BrowserHistory from "./BrowserHistory";

export default class BrowserUtils {
	static getParam = (parameterName: string): undefined | string => {
		let result: undefined | string;
		let tmp = [];
		window.location.search
			.substr(1)
			.split("&")
			.forEach((item) => {
				tmp = item.split("=");
				if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
			});
		return result;
	};

	static addParam = (key: string, value: string | number) => {
		key = encodeURI(key);
		value = encodeURI(String(value));
		if (BrowserUtils.getParam(key) !== undefined) {
			// No duplicate keys
			BrowserUtils.removeParam(key);
		}

		let searchString = document.location.search;

		let sign = "&";
		if (searchString.indexOf("?") === -1) {
			sign = "?";
		}

		searchString += `${sign}${key}=${value}`;

		const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${searchString}`;
		window.history.pushState({ path: newurl }, "", newurl);
	};

	static removeParam = (key: string) => {
		key = encodeURI(key);
		let searchString = document.location.search;
		const index = searchString.search(new RegExp(`(\\?|&)${key}=`, "i"));

		if (index !== -1) {
			const end = searchString.search(/(&|\/)/);

			if (end === index || end === -1) {
				searchString = searchString.substr(0, index);
			} else {
				searchString = searchString.substr(0, index) + searchString.substr(end);
			}
			if (searchString.indexOf("?") === -1 && searchString.indexOf("&") === 0) {
				searchString = `?${searchString.slice(1)}`;
			}

			const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${searchString}`;

			window.history.pushState({ path: newurl }, "", newurl);
		}
	};

	static push = (to: string) => {
		BrowserHistory.push(to);
	};

	static append = (to: string, preventDuplicates = true) => {
		let path = window.location.pathname;
		let toPath = to;
		if (path.charAt(path.length - 1) !== "/") {
			path += "/";
		}
		if (to.charAt(0) === "/") {
			toPath = toPath.substr(1, toPath.length - 1);
		}
		if (preventDuplicates === true && window.location.pathname.indexOf(toPath) !== -1) {
			return;
		}
		BrowserHistory.push(path + toPath);
	};

	static isOutOfViewport = (elem: HTMLElement) => {
		// Get element's bounding
		const bounding = elem.getBoundingClientRect();

		// Check if it's out of the viewport on each side
		const out: any = {};
		out.top = bounding.top;
		out.left = bounding.left;
		out.bottom = (window.innerHeight || document.documentElement.clientHeight) - bounding.bottom;
		out.right = (window.innerWidth || document.documentElement.clientWidth) - bounding.right;

		return out;
	};
}
