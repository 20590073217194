import React from "react";
import { AccessModes, ObjectAccessTable, TableType, useCheckAccessQuery } from "../Resources/generated/gql-types";
import Backend from "../Utils/Backend/Backend";
import { useAuth } from "./AuthContext";

interface HasObjectAccessProps {
	children: any;
	mode: AccessModes;
	objectID: string;
	tableName: ObjectAccessTable;
	tableType: TableType;
}

const HasObjectAccess = ({ children, mode, objectID, tableName, tableType }: HasObjectAccessProps) => {
	const { isAuthenticated } = useAuth();
	const { data, loading } = useCheckAccessQuery({
		variables: {
			mode,
			objectID,
			tableName,
			tableType,
		},
		context: {
			headers: {
				"app-id": Backend.getAppID(),
			},
		},
	});
	const hasAccess = data?.checkAccess;
	if (!isAuthenticated || !hasAccess || loading) return <></>;
	return children;
};

export default HasObjectAccess;
