/* eslint-disable no-console */
import React, { Component } from "react";
import "./Users.sass";
import { Card, Button, Form, Input, Boolean as OneDashBoolean, Table } from "@onedash/tools";
import Backend from "../../Utils/Backend/Backend";
import List from "../../Components/List/List";
import ListItem from "../../Components/List/ListItem";
import BrowserUtils from "../../Utils/BrowserUtils";
import { USER_PERMISSION_TABLE_HEADER, USER_APPLICATION_TABLE_HEADER } from "./UserTableHeaders";

export interface User {
	id: string;
	applications: string[];
	email: string;
	family_name?: string;
	given_name?: string;
	forcePWChange?: boolean;
	forceThemeChange?: boolean;
	isV2?: boolean;
	logins_count: number;
	name: string;
	picture: string;
	username: string;
	permissions?: Permission[];
}
interface Permission {
	name: string;
	description: string;
	type: "default" | "admin";
}
interface UsersProps {}

class Users extends Component<UsersProps> {
	state = {
		users: [] as User[],
		selectedUser: undefined as undefined | User,
		isEditing: false,

		// permissions: [] as Permission[],
		applications: [] as Application[],
		filterString: "",
	};

	componentDidMount() {
		this.loadUsers(BrowserUtils.getParam("id"), BrowserUtils.getParam("edit"));
		this.loadInformations();
	}

	loadUsers = async (id?: string, editMode?: string) => {
		const users = await Backend.get<User[]>("/admin/users", false);
		this.setState({
			users: users.data,
		});
		if (id) {
			this.selectUser(
				users.data.find((x) => x.id === id),
				Boolean(editMode)
			);
		}
	};

	loadInformations = async () => {
		// const permissions = await Backend.get<Permission[]>("/admin/permissions", false);
		const applications = await Backend.get<Application[]>("/admin/applications", false);

		this.setState({ /* permissions: permissions.data, */ applications: applications.data });
	};

	loadUserPermissons = async (userID: string) => {
		const permissionData = await Backend.get<Permission[]>(`/admin/permissions/${userID}`);
		if (this.state.selectedUser?.id === userID) {
			const { selectedUser } = this.state;
			selectedUser.permissions = permissionData.data;
			this.setState({ selectedUser });
		}
	};

	selectUser = (user?: User, editMode = false) => {
		if (!user) return;
		this.loadUserPermissons(user.id);
		this.setState({ selectedUser: user, isEditing: editMode });
		if (!editMode) BrowserUtils.removeParam("edit");
		BrowserUtils.addParam("id", user.id);
	};

	isEditing = () => {
		BrowserUtils.addParam("edit", "true");
		this.setState({ isEditing: true });
	};

	cancelUser = () => {
		this.setState({ isEditing: false });

		BrowserUtils.removeParam("edit");
	};

	saveUser = () => {
		this.setState({ isEditing: false });
		BrowserUtils.removeParam("edit");
	};

	removeApplication = (appID: any) => {
		console.log(appID);
	};

	removePermission = (permName: any) => {
		console.log(permName);
	};

	render() {
		const { isEditing, users, selectedUser, applications, filterString } = this.state;

		const userApplications = selectedUser?.applications.map((app) => {
			return {
				id: app,
				appId: app,
				appName: applications.find((x) => x.appId === app)?.appName,
			};
		});
		const userPermissions = selectedUser?.permissions?.map((permission) => {
			return {
				id: permission.name,
				name: permission.name,
				description: permission.description,
			};
		});

		const filteredUsers = users.filter((x) => {
			const filter = filterString.toLowerCase();
			return (
				x.name.toLowerCase().indexOf(filter) !== -1 ||
				x.id.toLowerCase().indexOf(filter) !== -1 ||
				(x.given_name && x.given_name.toLowerCase().indexOf(filter) !== -1) ||
				(x.family_name && x.family_name.toLowerCase().indexOf(filter) !== -1)
			);
		});

		if (!this.state.selectedUser && filterString !== "" && filteredUsers.length === 1) {
			// Select the last user found with this name or id
			this.selectUser(filteredUsers[0]);
		}

		return (
			<div className="users">
				<Card className="list-detail" maxWidth={1200}>
					<div className="left-list">
						<List
							onSearch={(fs) => this.setState({ filterString: fs ?? "", selectedUser: undefined })}
							label="Benutzer"
							isLoading={filteredUsers.length === 0}
							showCount>
							{filteredUsers.map((user) => (
								<ListItem
									selected={user === selectedUser}
									onClick={() => this.selectUser(user)}
									key={user.id}
									id={user.id}
									image={<img src={user.picture} alt="Benutzer Icon" />}>
									{user.name}
								</ListItem>
							))}
						</List>
					</div>
					<div className="detail-view">
						{selectedUser && (
							<>
								<div className="toolbar">
									<div className="split">
										<h2>{selectedUser.name}</h2>
										<div>
											{isEditing ? (
												<>
													<Button onClick={this.cancelUser} className="collapsed blue-btn" mode="primary">
														Abbrechen
													</Button>
													<Button onClick={this.saveUser} className="collapsed blue-btn" mode="primary">
														Speichern
													</Button>
												</>
											) : (
												<Button onClick={this.isEditing} className="collapsed blue-btn" mode="primary">
													Bearbeiten
												</Button>
											)}
										</div>
									</div>
								</div>
								<Form className="content">
									<div id="user-account-info" className="pane">
										<div className="content split">
											<div>
												<img className="user-picture" src={selectedUser.picture} alt="Benutzer icon" />
											</div>
											<div>
												{isEditing ? (
													<Input label="Accountname" name="name" required value={selectedUser.name} />
												) : (
													<div className="property">
														<span className="label">Accountname</span>
														<span className="value">{selectedUser.name}</span>
													</div>
												)}

												{isEditing ? (
													<div className="split name-split">
														<Input label="Vorname" name="given_name" value={selectedUser.given_name} />
														<Input label="Nachname" name="family_name" value={selectedUser.family_name} />
													</div>
												) : (
													<div className="property">
														<span className="label">Nutzername</span>
														<span className="value">
															{selectedUser.given_name} {selectedUser.family_name}
														</span>
													</div>
												)}

												{isEditing ? (
													<Input label="E-Mail Adresse" name="email" type="email" value={selectedUser.email} />
												) : (
													<div className="property">
														<span className="label">E-Mail Adresse</span>
														<span className="value">
															<a href={`mailto:${selectedUser.email}`}>{selectedUser.email}</a>
														</span>
													</div>
												)}
											</div>
										</div>
									</div>

									<div className="pane">
										<h3>
											<i className="im im-user-circle" /> Allgemein
										</h3>
										<div className="content">
											{!isEditing && (
												<div className="property">
													<div className="label">ID</div>
													<div className="value">{selectedUser.id}</div>
												</div>
											)}
											{!isEditing && (
												<div className="property">
													<div className="label">Anzahl der Logins</div>
													<div className="value">{selectedUser.logins_count}</div>
												</div>
											)}
											{!isEditing && (
												<div className="property">
													<div className="label">Ist V2</div>
													<div className="value">{selectedUser.isV2 ? "✅" : "❌"}</div>
												</div>
											)}

											<div className="property">
												<div className="label">Erstanmeldung</div>
												<div className="value">
													{isEditing ? (
														<OneDashBoolean name="forceThemeChange" value={selectedUser.forceThemeChange} />
													) : (
														<>{selectedUser.forceThemeChange ? "✅" : "❌"}</>
													)}
												</div>
											</div>
											<div className="property">
												<div className="label">Passwort ändern</div>
												<div className="value">
													{isEditing ? (
														<OneDashBoolean name="forcePWChange" value={selectedUser.forcePWChange} />
													) : (
														<>{selectedUser.forcePWChange ? "✅" : "❌"}</>
													)}
												</div>
											</div>
										</div>
									</div>

									<div className="pane">
										<h3>
											<i className="im im-windows-o" /> Applications
										</h3>
										<div className="content">
											{userApplications && (
												<Table
													editable={isEditing}
													onDelete={this.removeApplication}
													tableValues={userApplications}
													tableHeaders={USER_APPLICATION_TABLE_HEADER}
												/>
											)}
										</div>
									</div>

									<div className="pane">
										<h3>
											<i className="im im-key" /> Berechtigungen
										</h3>
										<div className="content">
											{userPermissions && (
												<Table
													editable={isEditing}
													onDelete={this.removePermission}
													tableValues={userPermissions}
													tableHeaders={USER_PERMISSION_TABLE_HEADER}
												/>
											)}
										</div>
									</div>
								</Form>
							</>
						)}
					</div>
				</Card>
			</div>
		);
	}
}

export default Users;
