/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import moment from "moment";
import { DialogButton } from "@onedash/tools/dist/Dialog/DialogTypes";
import { Cell, Column, Row, Table } from "onedash-react-table";
import { Button, Input, NativeSelect, Toggle, ValueLabelPair } from "onedash-react-input-form";
import { Card, Dialog } from "@onedash/tools";
import Backend from "../../Utils/Backend/Backend";
import "./Gaestebeitrag.sass";
import Toolbar from "../../Pages/Dashboard/Toolbar/Toolbar";
import { GuestCategory, Lodging, LodgingStat } from "./GaestebeitragTypes";
import ErrorAnimation from "../../Components/Animations/ErrorAnimation";
import FileDownloader from "../../Utils/Backend/FileDownloader";
import ButtonSpinner from "../../Pages/MiniPages/Components/ButtonSpinner";
import Notify from "../../Utils/Notify";

interface MonatsansichtProps {}

class Monatsansicht extends Component<MonatsansichtProps> {
	detailDialog = React.createRef<Dialog>();

	state = {
		months: [] as ValueLabelPair[],
		selectedMonthYear: undefined as string | undefined,
		loadingError: undefined as undefined | string,
		busy: true,
		lodgingStats: [] as LodgingStat[],
		lodgings: [] as Lodging[],
		downloadStarted: false,
		selectedStat: undefined as LodgingStat | undefined,
		guestCategories: [] as GuestCategory[],
		changed: false,
		searchString: "",
	};

	componentDidMount() {
		this.loadStartMonth();
		this.loadLodges();
	}

	loadLodges = async () => {
		const lodgingsRes = await Backend.get<Lodging[]>("/vg/lodgings");
		// Remove duplicates
		const lodgings = lodgingsRes.data.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
		const selectedLodging = lodgings[0];
		if (selectedLodging === undefined) {
			this.setState({
				busy: false,
				loadingError: "Sie haben aktuell keinen Betrieb zugeordnet. Bitte wenden Sie sich an den Systemandministrator.",
			});
			return;
		}
		this.setState({ lodgings });
	};

	getMonthYearFormatted = () => {
		const [month, year] = this.getMonthYear();
		return moment().clone().set("month", month).set("year", year).format("MMMM YYYY");
	};

	getLastYearFormatted = () => {
		const [endMonth, endYear] = this.getMonthYear();
		let startYear = Number(endYear);
		let startMonth = Number(endMonth);
		for (let i = 0; i < 11; i++) {
			startMonth--;
			if (startMonth < 0) {
				startMonth = 11;
				startYear--;
			}
		}
		const end = moment().clone().set("month", endMonth).set("year", endYear).format("MMMM YYYY");
		const start = moment().clone().set("month", startMonth).set("year", startYear).format("MMMM YYYY");
		return `${start} - ${end}`;
	};

	loadStartMonth = async () => {
		const minStartingDate = await Backend.get<number>(`/appSettings/gaestebeitragStartingDate`);
		this.calcMonths(minStartingDate.data);
	};

	loadMonthStats = async () => {
		this.setState({ busy: true });
		const [month, year] = this.getMonthYear();
		const stats = await Backend.get<LodgingStat[]>(`/vg/lodgings/stats/${month}/${year}`);
		this.setState({ busy: false, lodgingStats: stats.data });
	};

	calcMonths = (minStartingDate: number) => {
		const months: ValueLabelPair[] = [];
		const date = moment().startOf("month").subtract(1, "month").add(8, "hour");

		while (date.isAfter(moment(minStartingDate)) && date.isAfter(moment().subtract(2, "year"))) {
			months.push({ label: date.format("MMMM YYYY"), value: `${date.get("month")}-${date.get("year")}` });
			date.subtract(1, "month");
		}
		if (months.length === 0) {
			this.setState({
				busy: false,
				loadingError: "Aktuell ist noch keine Datenabfrage möglich, da das Startdatum in der Zukunft liegt",
			});
			return;
		}
		this.setState({ months, selectedMonthYear: months[0].value }, this.loadMonthStats);
	};

	getMonthYear = (monthYear = this.state.selectedMonthYear) => {
		if (!monthYear) return [0, 0];
		const [month, year] = monthYear.split("-");
		return [Number(month), Number(year)];
	};

	selectMonth = (selectedMonthYear: string) => {
		this.setState({ selectedMonthYear }, this.loadMonthStats);
	};

	exportExcel = (fullYear = false) => {
		const [month, year] = this.getMonthYear();
		const downloadPath = Backend.buildPath(`/vg/lodgings/stats/export/${month}/${year}/${fullYear}`);
		this.setState({ downloadStarted: true });
		FileDownloader.downloadFile(downloadPath, `${fullYear ? this.getLastYearFormatted() : this.getMonthYearFormatted()}.xlsx`).then(
			() => {
				this.setState({ downloadStarted: false });
			}
		);
	};

	loadCategories = async () => {
		const { selectedStat } = this.state;
		if (!selectedStat) return;
		const date = moment().clone().set("year", selectedStat.year).set("month", selectedStat.month).set("day", 1).timestamp();
		const res = await Backend.get<GuestCategory[]>(`/appSettings/guestCategories?dateTime=${date}`);
		this.setState({ guestCategories: res.data, dialogIsLoading: false });
	};

	showDetailedInformation = (index?: number) => {
		if (!this.state.lodgingStats || index === undefined) return;
		// eslint-disable-next-line react/no-access-state-in-setstate
		const selectedStat = JSON.parse(JSON.stringify(this.state.lodgingStats[index]));
		this.setState({ selectedStat, dialogIsLoading: true, changed: false }, this.loadCategories);
		this.detailDialog.current?.show();
	};

	changeCurrentStat = (value: any, category: number, name: "persons" | "nights") => {
		const { selectedStat } = this.state;
		if (!selectedStat) return;
		selectedStat.categories[category][name] = value;

		this.setState({ selectedStat, changed: true });
	};

	saveChanges = () => {
		const { selectedStat } = this.state;
		if (!selectedStat) return;

		const { guestCategories } = this.state;
		selectedStat.categories.forEach((category, i) => {
			category.persons = Number(category.persons ?? 0);
			category.nights = Number(category.nights ?? 0);
			category.name = guestCategories[i].name;
		});

		const body = { stat: selectedStat };

		if (selectedStat.notCreated) {
			Backend.post(`/vg/lodgings/stats`, body)
				.then(this.loadMonthStats)
				.catch((ex) => {
					Notify.e(ex.data);
				});
		} else {
			Backend.put(`/vg/lodgings/stats`, body)
				.then(this.loadMonthStats)
				.catch((ex) => {
					Notify.e(ex.data);
				});
		}
	};

	render() {
		const {
			months,
			selectedMonthYear,
			busy,
			loadingError,
			lodgingStats,
			lodgings,
			downloadStarted,
			selectedStat,
			guestCategories,
			changed,
			searchString,
		} = this.state;
		const selectedTableStat: any[] = [];
		guestCategories.forEach((cat) => {
			const c = selectedStat?.categories.find((x) => x.name === cat.name);
			selectedTableStat.push({
				category: cat.label,
				price: cat.price,
				persons: c?.persons,
				nights: c?.nights,
				sum: cat.price * (c?.nights ?? 0),
			});
		});
		const calculatedTotalPrice = selectedTableStat?.reduce((x, y) => x + y.sum, 0);

		const buttons: DialogButton[] = [{ type: "close", mode: "secondary", text: "Abbrechen", side: "right" }];
		if (changed) {
			buttons.push({
				type: "save",
				mode: "primary",
				text: selectedStat?.notCreated ? "Gästeerklärung einreichen" : "Änderungen speichern",
				side: "right",
				onClick: this.saveChanges,
			});
		} else {
			buttons.push({
				type: "save",
				mode: "light",
				text: selectedStat?.notCreated ? "Gästeerklärung einreichen" : "Änderungen speichern",
				side: "right",
			});
		}

		lodgingStats.forEach((stat: any) => {
			stat.lodgingName = lodgings.find((x) => x.id === stat.lodgingId)?.companyName ?? "Unternehmen wurde nicht gefunden";
		});

		return (
			<>
				<Toolbar split>
					<div>
						<NativeSelect
							value={selectedMonthYear}
							required
							name="month-year"
							placeholder="Wählen Sie einen Monat"
							options={months}
							disabled={busy}
							onChange={this.selectMonth}
						/>
					</div>
					<div className="monatsansicht-export">
						<Button disabled={downloadStarted || busy} className="export-btn" onClick={() => this.exportExcel(true)}>
							{downloadStarted && <ButtonSpinner />}
							{!downloadStarted && <i className="im im-export" />}
							{this.getLastYearFormatted()} herunterladen
						</Button>
						<Button
							style={{ marginLeft: "1rem" }}
							disabled={downloadStarted || busy}
							className="export-btn"
							onClick={() => this.exportExcel(false)}>
							{downloadStarted && <ButtonSpinner />}
							{!downloadStarted && <i className="im im-export" />}
							{this.getMonthYearFormatted()} herunterladen
						</Button>
					</div>
				</Toolbar>
				<div className="gaestebeitrag-monat">
					{!busy && loadingError && (
						<Card>
							<ErrorAnimation title="Es ist ein Fehler aufgetreten" visible>
								{loadingError}
							</ErrorAnimation>
						</Card>
					)}
					{!busy && !loadingError && (
						<>
							<div className="toolbar">
								<Input
									placeholder="Suchen Sie einen Eintrag ..."
									name="search"
									type="text"
									onChange={(str) => this.setState({ searchString: str })}
								/>
							</div>
							<Table
								onRowClick={this.showDetailedInformation}
								select="click"
								minWidth={700}
								searchString={searchString}
								rightIcon={<i className="im im-angle-right" />}>
								<Column width="300px" label="Unternehmen" name="lodgingName" />
								<Column name="numPersons" label="Anzahl der Personen" />
								<Column name="numNights" label="Anzahl der Übernachtungen" />
								<Column name="totalPrice" label="Gesamtpreis" />
								{lodgingStats.map((stat, i) => (
									<Row key={i as any} row={stat}>
										<Cell name="lodgingName" />
										<Cell name="numPersons" />
										<Cell name="numNights">
											{(numNights) => {
												if (stat.isVacation) {
													return <span className="is-vacation">Fehlanzeige</span>;
												}
												if (numNights !== undefined && numNights !== null) {
													return numNights;
												}
												return <span className="not-entered">Unvollständig</span>;
											}}
										</Cell>
										<Cell name="totalPrice">{(val) => Number(val).toPrice()}</Cell>
									</Row>
								))}
							</Table>
						</>
					)}
				</div>

				<Dialog
					buttons={buttons}
					settings={{ maxWidth: 800 }}
					ref={this.detailDialog}
					className="gaestebeitrag-stat-dialog new-design"
					title={`Details: ${lodgings.find((x) => x.id === selectedStat?.lodgingId)?.companyName}`}>
					<Toggle
						onChange={(isVacation) =>
							this.setState((s: any) => ({ selectedStat: { ...s.selectedStat, isVacation }, changed: true }))
						}
						value={selectedStat?.isVacation}
						name="isVacation"
						className="vacation-toggle">
						<span className="bold">Fehlanzeige</span>
						<br />
						Wählen Sie diese Einstellung, wenn der Betrieb in diesem Monat keine Gäste beherbergt hat.
					</Toggle>
					{!selectedStat?.isVacation && (
						<>
							<Table onRowClick={this.showDetailedInformation} minWidth={600}>
								<Column name="category" label="Kategorie" />
								<Column name="price" width="70px" label="Preis" />
								<Column name="persons" width="70px" label="Personen" />
								<Column name="nights" width="130px" label="Übernacht&shy;ungen" />
								<Column className="sum" name="sum" width="70px" label="Summe" />

								{selectedTableStat?.map((row, i) => (
									<Row key={i as any} row={row}>
										<Cell name="category" />
										<Cell name="price">{(val) => Number(val).toPrice()}</Cell>
										<Cell name="persons">
											{(v) => (
												<Input
													name="persons"
													maxLength={5}
													type="tel"
													settings={{ allowNumberNull: false, allowNumberNegative: false, validateTel: false }}
													pattern="^$|^[0-9]+$"
													value={v}
													onChange={(val) => this.changeCurrentStat(val, i, "persons")}
												/>
											)}
										</Cell>
										<Cell name="nights">
											{(v) => (
												<Input
													name="nights"
													maxLength={5}
													type="tel"
													settings={{ allowNumberNull: false, allowNumberNegative: false, validateTel: false }}
													pattern="^$|^[0-9]+$"
													value={v}
													onChange={(val) => this.changeCurrentStat(val, i, "nights")}
												/>
											)}
										</Cell>
										<Cell name="sum">{(val) => Number(val).toPrice()}</Cell>
									</Row>
								))}
							</Table>

							{calculatedTotalPrice !== selectedStat?.totalPrice && selectedStat?.totalPrice && (
								<div className="old-total">
									<div className="label">Alter Gesamtbetrag</div>
									<div className="price">{selectedStat?.totalPrice?.toPrice()}</div>
								</div>
							)}

							<div className="total">
								<div className="label">Gesamtbetrag</div>
								<div className="price">{calculatedTotalPrice?.toPrice()}</div>
							</div>
						</>
					)}
				</Dialog>
			</>
		);
	}
}

export default Monatsansicht;
