import { Dialog } from "@onedash/tools";
import React, { Component } from "react";
import Impress from "../Dashboard/HeadBar/Impress";
import Privacy from "../Dashboard/HeadBar/Privacy";
import "./MiniPages.sass";

interface MiniPagesProps {
	currentStep: number;
	onPageChange: (newIndex: number, oldIndex: number) => void;
	showHeader?: boolean;
	isLoading?: boolean;
	loadingText?: string;
	className?: string;
}
interface MiniPageItem {
	element: any;
	label: string;
	hideHeader: boolean;
}

class MiniPages extends Component<MiniPagesProps> {
	privacyDialog = React.createRef<Dialog>();

	impressDialog = React.createRef<Dialog>();

	interval: NodeJS.Timeout | undefined;

	loadingX = 1;

	loadingStart = 0;

	state = {
		loadingPosition: 0,
		isLoading: false,
	};

	componentDidMount() {
		if (this.props.isLoading) {
			this.startLoading();
		}
	}

	componentDidUpdate(lastProps: MiniPagesProps) {
		if (lastProps.isLoading !== this.props.isLoading) {
			if (this.props.isLoading) {
				this.startLoading();
			} else {
				this.finishLoading();
			}
		}
	}

	loadingAnimation = () => {
		const mult = Math.log(this.loadingX++); // 0 - 4
		if (mult < 4) {
			this.setState({ loadingPosition: mult * 25 });
		} else {
			this.setState({ loadingPosition: 100 });
		}
	};

	clonePages = () => {
		const minipages: MiniPageItem[] = [];
		React.Children.forEach(this.props.children, (child: any, i) => {
			const element = React.cloneElement(child, {
				_active: this.props.currentStep === i,
				_onNextPage: (stepSize: number) => this.props.onPageChange(i + stepSize, i),
			});

			minipages.push({ element, label: child.props.label, hideHeader: child.props.hideHeader });
		});

		return minipages;
	};

	startLoading = () => {
		this.loadingStart = new Date().getTime();
		this.setState({ loadingPosition: 0, isLoading: true });
		this.interval = setInterval(this.loadingAnimation, 400);
	};

	finishLoading = () => {
		const diff = new Date().getTime() - this.loadingStart;
		setTimeout(
			() => {
				this.loadingX = 0;
				if (this.interval) clearInterval(this.interval);
				this.setState({ loadingPosition: 100 });
				setTimeout(() => {
					this.setState({ isLoading: false });
				}, 300);
			},
			diff < 1000 ? 1000 - diff : 0
		);
	};

	render() {
		const { showHeader, currentStep, loadingText, className } = this.props;
		const { loadingPosition, isLoading } = this.state;
		const minipages = this.clonePages();

		const currentPage = minipages[currentStep];

		const miniPageStyle: React.CSSProperties = {};
		if (!isLoading && showHeader && !currentPage.hideHeader === true) {
			miniPageStyle.gridTemplateRows = "40px 1fr";
		}
		let diff = 0;
		return (
			<>
				<div className={`mini-page-wrapper ${className ?? ""}`}>
					<div className="mini-page" style={miniPageStyle}>
						{isLoading && (
							<div className="content">
								<div className="loading-page">
									<div className="img" />
									<p>{loadingText ?? "Bitte warten ..."}</p>
									<div className="loader">
										<span style={{ width: `${loadingPosition}%` }} />
									</div>
								</div>
							</div>
						)}
						{!isLoading && (
							<>
								{showHeader && !currentPage.hideHeader === true && (
									<div
										className="header"
										style={{
											gridTemplateColumns: "1fr ".repeat(minipages.filter((x) => x.hideHeader !== true).length),
										}}>
										{minipages.map((page, i) => {
											if (page.hideHeader) {
												diff++;
												return <React.Fragment key={i as any} />;
											}

											return (
												<div key={page.label} className={i === currentStep ? "page active" : "page"}>
													<span className="num">{i - diff + 1}.</span>
													<span className="label">{page.label}</span>
												</div>
											);
										})}
									</div>
								)}

								<div className="content">{currentPage.element}</div>
							</>
						)}
					</div>

					<div className="footer">
						<button onClick={() => this.impressDialog.current?.show()}>Impressum</button>
						<button onClick={() => this.privacyDialog.current?.show()}>Datenschutzerklärung</button>
						<a href="https://o-d.link/support" target="_blank" rel="noopener noreferrer">
							Hilfe
						</a>
					</div>
				</div>

				<Dialog className="minipage-dialog" buttons={[]} ref={this.privacyDialog}>
					<Privacy />
				</Dialog>
				<Dialog className="minipage-dialog" buttons={[]} ref={this.impressDialog}>
					<Impress />
				</Dialog>
			</>
		);
	}
}

export default MiniPages;
