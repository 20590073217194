export const USER_APPLICATION_TABLE_HEADER: TableHeader[] = [
	{
		columnName: "appId",
		title: "Application ID",
		type: "text",
		readonly: true,
		visible: 0,
	},
	{
		columnName: "appName",
		title: "App Name",
		type: "text",
		readonly: true,
	},
];

export const USER_PERMISSION_TABLE_HEADER: TableHeader[] = [
	{
		columnName: "name",
		title: "Berechtigung",
		type: "text",
		readonly: true,
	},
	{
		columnName: "description",
		title: "Beschreibung",
		type: "text",
		readonly: true,
		visible: 0,
	},
];
