import AppUtils from "./AppUtils";
import Backend from "./Backend/Backend";

export class Session {
	public static set = (entryName: string, value: any, appSpecific = true) => {
		sessionStorage.setItem(Session.getSelector(entryName, appSpecific), JSON.stringify(value));
	};

	public static setObject = (entryName: string, path: string, value: any, appSpecific = true) => {
		const obj = Session.get(entryName, appSpecific) ?? {};
		AppUtils.setObjectValue(path, obj, value);
		Session.set(entryName, obj, appSpecific);
	};

	public static getObject = (entryName: string, path: string, appSpecific = true) => {
		const obj = Session.get(entryName, appSpecific);
		if (typeof obj === "object") {
			return AppUtils.getObjectValue(path, obj);
		}
		return undefined;
	};

	public static mergeObject = (entryName: string, value: any, appSpecific = true) => {
		const obj = Session.get(entryName, appSpecific) ?? {};
		const merge = AppUtils.mergeDeep(obj, value);
		Session.set(entryName, merge, appSpecific);
	};

	public static get = (entryName: string, appSpecific = true) => {
		const content = sessionStorage.getItem(Session.getSelector(entryName, appSpecific));
		if (!content) return;
		return JSON.parse(content);
	};

	private static getSelector = (entryName: string, isAppSpecific: boolean) => {
		if (isAppSpecific) {
			return `${entryName}-${Backend.getAppID()}`;
		}
		return entryName;
	};
}
