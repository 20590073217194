import { Form, Input, VerificationCode } from "onedash-react-input-form";
import React from "react";
import InfoBox from "../../../Components/Misc/InfoBox";
import Backend from "../../../Utils/Backend/Backend";
import BrowserHistory from "../../../Utils/BrowserHistory";
import ButtonSpinner from "../Components/ButtonSpinner";
import MiniPage, { MiniPageProps } from "../MiniPage";

interface EMailValidationProps extends MiniPageProps {
	email?: string;
	onMailChange: (mail: string) => void;
}

class EMailValidation extends MiniPage<EMailValidationProps> {
	verificationInput = React.createRef<VerificationCode>();

	state = {
		valid: false,
		busy: false,
		mailSend: false,
		email: undefined as undefined | string,
		errorText: undefined as undefined | string,
		showMailInfo: false,
	};

	componentDidMount() {
		if (this.props.email) this.setState({ mailSend: true, email: this.props.email });
	}

	componentDidUpdate(lProps: any) {
		// eslint-disable-next-line react/no-did-update-set-state
		if (this.props.email !== lProps.email) this.setState({ mailSend: !!this.props.email, email: this.props.email });
	}

	mailValidationFinished = () => {
		this.nextStep();
	};

	sendValidationMail = () => {
		this.setState({ busy: true, errorText: undefined });
		const { email } = this.state;
		if (!email) return;
		Backend.post("/email/validate", { email })
			.then((x) => {
				this.props.onMailChange(email);

				if (x.data === "Diese E-Mail wurde bereits bestätigt.") {
					this.mailValidationFinished();
				} else {
					this.setState({ mailSend: true, busy: false });
				}
			})
			.catch((x) => {
				this.setState({ email: "", busy: false, errorText: x.data });
			});
	};

	setMail = (email?: string | null) => {
		this.setState({
			email,
		});

		const regex = new RegExp(/(.)*@gmx\.(.)*|(.)*@web\.(.)*/);
		let showMailInfo = false;
		if (regex.test(email ?? "") === true) {
			showMailInfo = true;
		}
		this.setState({ showMailInfo });
	};

	onCodeChange = (code?: string[] | null) => {
		if (!code || code?.length < 4) return;
		let valid = true;
		code.forEach((c) => {
			if (!c || String(c).length === 0) valid = false;
		});
		if (!valid) return;

		this.setState({ busy: true, errorText: undefined });
		const startDate = new Date().getTime();
		Backend.post("/email/validate", { email: this.state.email, code: code.join("") })
			.then(() => {
				const diff = new Date().getTime() - startDate;
				setTimeout(
					() => {
						this.setState({ busy: false });
						this.mailValidationFinished();
					},
					diff < 1500 ? 1500 - diff : 0
				);
			})
			.catch((x) => {
				const diff = new Date().getTime() - startDate;
				setTimeout(
					() => {
						this.verificationInput.current?.reset();
						this.verificationInput.current?.setInvalid();
						this.setState({ busy: false, errorText: x.data });
					},
					diff < 1500 ? 1500 - diff : 0
				);
			});
	};

	render() {
		const { valid, busy, mailSend, errorText } = this.state;
		return (
			<div className="email-validation split-v-2-1">
				<div className="left" />
				<div className="right">
					<h2>E-Mail verifizieren</h2>
					<h3>Bitte verifizieren Sie in diesem Schritt Ihre E-Mail Adresse</h3>

					{!mailSend && (
						<>
							<p style={{ marginTop: "40px" }}>
								Um sicherzustellen, dass wir Sie erreichen können, müssen wir Ihre E-Mail Adresse verifizieren. Geben Sie
								dafür im Folgenden Ihre E-Mail Adresse an.
							</p>

							<Form onValidationUpdate={(v) => this.setState({ valid: v })}>
								<Input
									value={this.state.email}
									autoComplete="email"
									placeholder="Geben Sie Ihre E-Mail Adresse ein"
									required
									name="email-address"
									type="email"
									onFocus={() => this.setState({ errorText: undefined })}
									onChange={this.setMail}
								/>
								<InfoBox type="warning" className={this.state.showMailInfo && !errorText ? "small" : "small hidden"}>
									<p>
										Aufgrund von technischen Besonderheiten dauert der E-Mailversand zu GMX und Web länger als
										gewöhnlich.
									</p>
								</InfoBox>
								<button
									disabled={!valid || busy}
									style={{ marginTop: "0px" }}
									className="btn"
									onClick={this.sendValidationMail}>
									{busy ? <ButtonSpinner /> : "E-Mail zur Überprüfung senden"}
								</button>
							</Form>
							<p className="warning" style={{ marginTop: "20px" }}>
								{errorText}
								{errorText === "Zu der angegebenen E-Mail existiert bereits ein User. Bitte loggen Sie sich ein." && (
									<button style={{ display: "block" }} onClick={() => BrowserHistory.push("/login")}>
										Zum Login
									</button>
								)}
							</p>
						</>
					)}

					{mailSend && (
						<>
							<p style={{ marginTop: "40px" }}>
								Um sicherzustellen, dass wir Sie erreichen können, haben wir Ihnen eine E-Mail ({this.state.email})
								zugesendet. Bitte geben Sie den mitgeschickten Code hier ein:
							</p>
							<p>
								Sie haben keinen Code erhalten?
								<button onClick={() => this.setState({ mailSend: false })}>Neu versuchen</button>
							</p>
							<VerificationCode
								ref={this.verificationInput}
								onChange={this.onCodeChange}
								loadingSpinner={<ButtonSpinner />}
								autoFocus
								name="verification-code"
								codeLength={4}
								disabled={busy}
								autoComplete="one-time-code"
								onFocus={() => this.setState({ errorText: undefined })}
							/>

							<p className="warning">{errorText}</p>
						</>
					)}
				</div>
			</div>
		);
	}
}

export default EMailValidation;
