/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-types */
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { usePrompt } from "onedash-dialog";
import { useHistory, useParams } from "react-router-dom";
import "./TimeCalendar.sass";
import { Calendar, CalendarItem, CalendarToolbarItem, DateUtils } from "onedash-react-calendar";
import DetailsSidebar from "./DetailsSidebar";
import { TimeCalendarProps, TimeCalendarState, CreationEvent, CalendarEvent } from "./TimeCalendarTypes";

const initialState = {
	valid: false,
	changed: false,
	edit: false,
	event: undefined,
	updatedEvent: undefined,
	isLoading: false,
};

const TimeCalendar = <T extends {}>(props: TimeCalendarProps<T>) => {
	const { eventId } = useParams<{ eventId?: string }>();
	const { push } = useHistory();
	const [events, updateEvents] = React.useState([] as CalendarEvent<T>[]);
	const { yesNoPrompt } = usePrompt();
	const [dayNum, updateDaynum] = React.useState(window.innerWidth < 720 ? 3 : 7);
	const [state, update] = React.useState<TimeCalendarState<T>>(initialState);

	useEffect(() => {
		const checkWindowWidth = () => {
			const width = window.innerWidth;
			if (width < 720 && dayNum !== 3) {
				updateDaynum(3);
			}
			if (width >= 720 && dayNum !== 7) {
				updateDaynum(7);
			}
		};
		window.addEventListener("resize", checkWindowWidth);
		checkWindowWidth();
		return () => {
			window.removeEventListener("resize", checkWindowWidth);
		};
	}, [dayNum]);

	const onClose = () => {
		update(initialState);
		const url = `${window.location.pathname.split(props.subRoute)[0]}${props.subRoute}`;
		push(url);
	};

	const onDelete = async () => {
		const r = await yesNoPrompt("Event löschen?", "Soll das Event wirklich gelöscht werden?");
		if (!r) return;

		const { currentEvent } = props;
		if (!currentEvent) return;
		let deleteSequence = false;
		if (currentEvent.timeslot.periodId) {
			deleteSequence = await yesNoPrompt("Sequenz löschen?", "Sollen alle Events dieser Sequenz gelöscht werden?");
		}
		update((s) => ({ ...s, isLoading: true }));
		props.onDeleteEvent(currentEvent.id, deleteSequence).then(() => {
			onClose();
		});
	};

	React.useEffect(() => {
		if (props.events) {
			updateEvents(props.events);
		}
	}, [props.events]);

	React.useEffect(() => {
		if (eventId === "new") {
			update(() => ({ ...initialState, edit: true }));
		} else if (eventId !== undefined) {
			props.onLoadCurrentEvent(eventId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventId]);

	const updateEventData = (formData: any, valid?: boolean) => {
		update((s) => {
			let e: CreationEvent<T> = s.updatedEvent ?? props.currentEvent ?? ({ timeslot: {} } as any);
			e = { ...e, ...formData };
			if (!e._creation) {
				e._creation = {};
			}

			if (e._creation.dateFrom && !dayjs(e._creation.dateFrom).startOf("week").isSame(dayjs(props.calendarDate, "day"))) {
				// Update week to this
				props.onCalendarDateChange(dayjs(e._creation.dateFrom).startOf("week").timestamp());
			}

			if (e._creation.dateFrom && e._creation.fromHour !== undefined && e._creation.fromMinute !== undefined) {
				e.timeslot.from = dayjs(e._creation.dateFrom)
					.set("hour", e._creation.fromHour)
					.set("minute", e._creation.fromMinute)
					.set("second", 0)
					.set("millisecond", 0)
					.toDate()
					.getTime();
			} else if (!props.allowTimerange && e._creation.dateFrom) {
				e.timeslot.from = dayjs(e._creation.dateFrom)
					.set("hour", 0)
					.set("minute", 0)
					.set("second", 0)
					.set("millisecond", 0)
					.toDate()
					.getTime();
			} else {
				e.timeslot.from = undefined;
			}

			if ((e._creation.dateTo || e._creation.dateFrom) && e._creation.toHour !== undefined && e._creation.toMinute !== undefined) {
				e.timeslot.to = dayjs(e._creation.dateTo ?? e._creation.dateFrom)
					.set("hour", e._creation.toHour)
					.set("minute", e._creation.toMinute)
					.set("second", 0)
					.set("millisecond", 0)
					.toDate()
					.getTime();
			} else if (!props.allowTimerange && (e._creation.dateTo || e._creation.dateFrom)) {
				e.timeslot.to = dayjs(e._creation.dateTo ?? e._creation.dateFrom)
					.add(1, "day")
					.set("hour", 0)
					.set("minute", 0)
					.set("second", 0)
					.set("millisecond", 0)
					.toDate()
					.getTime();
			} else {
				e.timeslot.to = undefined;
			}
			return { ...s, updatedEvent: e, changed: true, valid: valid === undefined ? false : valid };
		});
	};

	const switchEvent = async (id: string, edit: boolean, ask = true) => {
		let yes = true;
		if (state.changed && ask) {
			yes = await yesNoPrompt("Änderungen verwerfen?", "Sie haben Änderungen durchgeführt. Wollen Sie diese verwerfen?");
		}
		if (!yes) return;
		update({ ...initialState, edit, isLoading: true });
		push(`${window.location.pathname.split(props.subRoute)[0]}${props.subRoute}/${id}`);
	};

	const onEdit = () => {
		if (!props.currentEvent) return;
		const e = props.currentEvent;
		const from = dayjs(e.timeslot.from);
		const to = dayjs(e.timeslot.to);
		let dateTo: number | undefined = DateUtils.setTime(dayjs(e.timeslot.to)).subtract(1, "millisecond").timestamp();
		if (from.get("minute") + from.get("second") + to.get("minute") + from.get("minute") === 0 && to.diff(from, "day") === 1) {
			dateTo = undefined;
		}

		const creationEvent: CreationEvent<T> = {
			...e,
			_creation: {
				dateFrom: DateUtils.setTime(from).timestamp(),
				dateTo,
				fromHour: from.get("hour"),
				fromMinute: from.get("minute"),
				toHour: to.get("hour"),
				toMinute: to.get("minute"),
				repeat: e.timeslot.periodId !== undefined,
			},
		};

		update((s) => ({ ...s, edit: true, updatedEvent: creationEvent }));
	};

	const onSave = () => {
		if (!state.updatedEvent) return;
		// Is creation
		const { timeslot, _creation } = state.updatedEvent;
		if (!_creation.repeat) {
			timeslot.periodRule = undefined;
		}

		update((s) => ({ ...s, isLoading: true }));
		if (eventId === "new") {
			// NEW
			props.onCreateEvent(state.updatedEvent).then((id) => {
				if (id !== undefined) switchEvent(id, false, false);
			});
		} else {
			if (!eventId) return;
			props.onUpdateEvent(eventId, state.updatedEvent).then(() => {
				switchEvent(eventId, false, false);
			});
		}
	};

	const { updatedEvent } = state;

	return (
		<div className="time-calendar">
			<Calendar
				dayNum={dayNum}
				onStartDateChange={props.onCalendarDateChange}
				startDate={props.calendarDate}
				hourFrom={props.hourFrom}
				hourHeight="74px"
				dateChangeDisabled={eventId !== undefined}
				hourTo={props.hourTo}>
				<CalendarToolbarItem>
					<button disabled={state.edit === true} onClick={() => switchEvent("new", true)} className="toolbar-btn highlight-btn">
						{props.newEventText}
					</button>
				</CalendarToolbarItem>

				{events
					?.filter((x) => !state.edit || x.id !== eventId)
					.map((e) => (
						<CalendarItem
							key={e.id}
							title={e.title}
							description={e.description}
							from={e.timeslot.from}
							to={e.timeslot.to}
							className="event"
							active={eventId === e.id}
							onClick={() => switchEvent(e.id, false)}
						/>
					))}
				{updatedEvent && state.edit === true && (updatedEvent.timeslot.from || updatedEvent.timeslot.to) && (
					<CalendarItem
						key="new"
						title={updatedEvent.title ?? "Neuer Eintrag"}
						description={updatedEvent.description ?? undefined}
						from={updatedEvent.timeslot.from ?? dayjs(updatedEvent.timeslot.to).subtract(1, "hour").toDate().getTime()}
						to={updatedEvent.timeslot.to ?? dayjs(updatedEvent.timeslot.from).add(1, "hour").toDate().getTime()}
						className="event"
						color="#33d353"
						active={eventId === "new"}
					/>
				)}
			</Calendar>

			<DetailsSidebar<T>
				onDelete={onDelete}
				onEdit={onEdit}
				edit={state.edit}
				onSave={onSave}
				changed={state.changed}
				isValid={state.valid}
				updateEventData={updateEventData}
				eventId={eventId}
				onClose={onClose}
				eventName={props.eventName}
				detailedEvent={props.currentEvent}
				editEventComponent={props.editEventComponent}
				allowReccurentTimeslots={props.allowReccurentTimeslots}
				allowMultiDay={props.allowMultiDay}
				allowTimerange={props.allowTimerange}
				currentEvent={state.updatedEvent}
			/>
		</div>
	);
};
export default TimeCalendar;
