/* eslint-disable no-underscore-dangle */
import dayjs from "dayjs";
import { usePrompt } from "onedash-dialog";
import { Calendar, CalendarItem, CalendarToolbarItem, DateUtils } from "onedash-react-calendar";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../Auth/AuthContext";
import InfoBox from "../../Components/Misc/InfoBox";
import { CreationEvent } from "../../Components/TimeCalendar/TimeCalendarTypes";
import {
	DepartmentBookingState,
	useGetUserCalendarQuery,
	useWorkingHoursQuery,
	useLoadDepartmentBookingQuery,
	useSendDepartmentBookingResponseMutation,
	UpdateDepartmentAdminTypes,
	Absence,
	useLoadAbsenceQuery,
	useCreateUserAbsenceMutation,
	useUpdateAbsenceMutation,
	GetUserCalendarDocument,
} from "../../Resources/generated/gql-types";
import Backend from "../../Utils/Backend/Backend";
import { GQLAppContext } from "../../Utils/Backend/GQLAppContext";
import AbsenceDetails from "./AbsenceDetails";
import BookingDetails from "./BookingDetails";

const initialState = {
	valid: false,
	changed: false,
	edit: false,
	updateAbsence: undefined,
	isLoading: false,
} as {
	valid: boolean;
	changed: boolean;
	edit: boolean;
	updateAbsence?: CreationEvent<Absence>;
	isLoading: boolean;
};

const DepartmentBookings = () => {
	const { yesNoPrompt } = usePrompt();
	const { push } = useHistory();
	const [calendarDate, updateCalendarDate] = React.useState(dayjs().startOf("week").timestamp());
	const [dayNum, updateDaynum] = React.useState(window.innerWidth < 720 ? 3 : 7);
	const queryVars = { from: dayjs(calendarDate).format(), to: dayjs(calendarDate).add(2, "week").format() };
	const [state, update] = React.useState(initialState);
	const { eventId, responseType } = useParams<{ eventId?: string; responseType?: string }>();
	const { id: userID } = useAuth();

	const eventType = eventId?.split("-")[0] as undefined | "booking" | "absence";

	const [sendDepartmentBookingResponse, { loading: sendDepartmentBookingResponseLoading }] = useSendDepartmentBookingResponseMutation(
		GQLAppContext()
	);
	const { loading: departmentBookingLoading, data: deparmentBookingData } = useLoadDepartmentBookingQuery({
		...GQLAppContext(),
		variables: {
			id: eventId?.split("-")[1] as any,
		},
		skip: !eventId || !eventType || eventType === "absence",
	});

	const { loading: absenceLoading, data: absenceData } = useLoadAbsenceQuery({
		...GQLAppContext(),
		variables: {
			id: eventId?.split("-")[1] as any,
		},
		skip: !eventId || !eventType || eventType === "booking",
	});

	const { data: workingHourData } = useWorkingHoursQuery(GQLAppContext());
	const [createUserAbsence, { loading: loadingCreateUserAbsence }] = useCreateUserAbsenceMutation({
		...GQLAppContext(),
		refetchQueries: [{ query: GetUserCalendarDocument, variables: queryVars, ...GQLAppContext() }],
	});
	const [updateUserAbsence, { loading: loadingUpdateUserAbsence }] = useUpdateAbsenceMutation({
		...GQLAppContext(),
		refetchQueries: [{ query: GetUserCalendarDocument, variables: queryVars, ...GQLAppContext() }],
	});
	const { data: userCalendarData } = useGetUserCalendarQuery({
		context: {
			headers: {
				"app-id": Backend.getAppID(),
			},
		},
		fetchPolicy: "cache-and-network",
		variables: queryVars,
		pollInterval: 15000,
	});

	useEffect(() => {
		const checkWindowWidth = () => {
			const width = window.innerWidth;
			if (width < 720 && dayNum !== 3) {
				updateDaynum(3);
			}
			if (width >= 720 && dayNum !== 7) {
				updateDaynum(7);
			}
		};
		window.addEventListener("resize", checkWindowWidth);
		checkWindowWidth();
		return () => {
			window.removeEventListener("resize", checkWindowWidth);
		};
	}, [dayNum]);

	React.useEffect(() => {
		const d = deparmentBookingData?.departmentBooking;
		if (!d) return;
		if (d && !dayjs(d.timeslot.from).startOf("week").isSame(dayjs(calendarDate))) {
			updateCalendarDate(dayjs(d.timeslot.from).startOf("week").timestamp());
		}
		update((s) => ({ ...s, departmentBooking: d as any }));

		if (d && responseType) {
			if (responseType !== "accept" && responseType !== "decline") {
				push(`${window.location.pathname.split("bookings")[0]}bookings/${eventType}-${eventId?.split("-")[1]}`);
				return;
			}
			sendDepartmentBookingResponse({
				variables: {
					identifier: d.identifier,
					type: responseType === "accept" ? UpdateDepartmentAdminTypes.Accept : UpdateDepartmentAdminTypes.Decline,
					message: "",
				},
			}).then(() => {
				push(`${window.location.pathname.split("bookings")[0]}bookings/${eventType}-${eventId?.split("-")[1]}`);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deparmentBookingData, responseType]);

	React.useEffect(() => {
		const d = absenceData?.userAbsence;
		if (!d) return;
		if (d && !dayjs(d.timeslot.from).startOf("week").isSame(dayjs(calendarDate))) {
			updateCalendarDate(dayjs(d.timeslot.from).startOf("week").timestamp());
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [absenceData?.userAbsence, responseType]);

	const switchEvent = async (id: string, type: "absence" | "booking", edit: boolean, ask = true) => {
		let yes = true;
		if (state.changed && ask) {
			yes = await yesNoPrompt("Änderungen verwerfen?", "Sie haben Änderungen durchgeführt. Wollen Sie diese verwerfen?");
		}
		if (!yes) return;
		update({ ...initialState, edit, isLoading: true });
		push(`${window.location.pathname.split("bookings")[0]}bookings/${type}-${id}`);
	};

	const onCloseSidebar = () => {
		update(initialState);
		const url = `${window.location.pathname.split("bookings")[0]}bookings`;
		push(url);
	};

	const getColor = (s: DepartmentBookingState) => {
		switch (s) {
			case DepartmentBookingState.Accepted:
				return "#25cc88";
			case DepartmentBookingState.Pending:
				return "#ccc400";
			case DepartmentBookingState.Declined:
				return "#ec6d66";
			default:
				return "#007aff";
		}
	};

	const updateAbsence = (formData: any, valid?: boolean) => {
		update((s) => {
			let e: CreationEvent<Absence> = s.updateAbsence ?? absenceData?.userAbsence ?? ({ timeslot: {} } as any);
			e = { ...e, ...formData };
			if (!e._creation) {
				e._creation = {};
			}

			if (e._creation.dateFrom && !dayjs(e._creation.dateFrom).startOf("week").isSame(dayjs(calendarDate, "day"))) {
				// Update week to this
				updateCalendarDate(dayjs(e._creation.dateFrom).startOf("week").timestamp());
			}

			if (e._creation.fullDay) {
				e.timeslot.from = dayjs(e._creation.dateFrom)
					.set("hour", 0)
					.set("minute", 0)
					.set("second", 0)
					.set("millisecond", 0)
					.toDate()
					.getTime();
				e.timeslot.to = dayjs(e._creation.dateTo ?? e._creation.dateFrom)
					.add(1, "day")
					.set("hour", 0)
					.set("minute", 0)
					.set("second", 0)
					.set("millisecond", 0)
					.toDate()
					.getTime();
			} else {
				if (e._creation.dateFrom && e._creation.fromHour !== undefined && e._creation.fromMinute !== undefined) {
					e.timeslot.from = dayjs(e._creation.dateFrom)
						.set("hour", e._creation.fromHour)
						.set("minute", e._creation.fromMinute)
						.set("second", 0)
						.set("millisecond", 0)
						.toDate()
						.getTime();
				} else {
					e.timeslot.from = undefined;
				}

				if (
					(e._creation.dateTo || e._creation.dateFrom) &&
					e._creation.toHour !== undefined &&
					e._creation.toMinute !== undefined
				) {
					e.timeslot.to = dayjs(e._creation.dateTo ?? e._creation.dateFrom)
						.set("hour", e._creation.toHour)
						.set("minute", e._creation.toMinute)
						.set("second", 0)
						.set("millisecond", 0)
						.toDate()
						.getTime();
				} else {
					e.timeslot.to = undefined;
				}
			}

			if (e.timeslot.to < e.timeslot.from) {
				return { ...s, valid: false };
			}

			return { ...s, updateAbsence: e, changed: true, valid: valid === undefined ? false : valid };
		});
	};

	const onEdit = () => {
		if (!absenceData?.userAbsence) return;
		const e = absenceData?.userAbsence;
		const from = dayjs(e.timeslot.from);
		const to = e.timeslot.to ? dayjs(e.timeslot.to) : undefined;
		let fullDay = from.get("hour") + from.get("minute") === 0;
		if (to) {
			fullDay = fullDay && to.get("hour") + to.get("minute") === 0;
		}

		const creationAbsence: CreationEvent<Absence> = {
			...e,
			timeslot: { ...e.timeslot },
			_creation: {
				dateFrom: DateUtils.setTime(from).timestamp(),
				dateTo: to ? DateUtils.setTime(to).timestamp() : undefined,
				fromHour: from.get("hour"),
				fromMinute: from.get("minute"),
				toHour: to ? to.get("hour") : 0,
				toMinute: to ? to.get("minute") : 0,
				repeat: e.timeslot.periodId !== undefined,
				fullDay,
			},
		} as any;

		update((s) => ({ ...s, edit: true, updateAbsence: creationAbsence }));
	};

	const onSave = () => {
		if (!state.updateAbsence) return;
		// Is creation
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const { timeslot, name, description, _creation } = state.updateAbsence;
		if (!_creation.repeat) {
			timeslot.periodRule = undefined;
		}

		update((s) => ({ ...s, isLoading: true }));
		if (eventId?.split("-")[1] === "new") {
			// NEW
			createUserAbsence({
				variables: {
					from: timeslot.from,
					to: timeslot.to,
					name,
					description,
					periodRule: timeslot.periodRule,
					periodTo: timeslot.periodTo,
				},
			}).then((x) => {
				const id = x.data?.createAbsence[0]?.id;
				if (id !== undefined) switchEvent(id, "absence", false, false);
			});
		} else {
			const id = eventId?.split("-")[1];
			if (id === undefined) return;
			updateUserAbsence({
				variables: {
					id,
					from: timeslot.from,
					to: timeslot.to,
					name,
					description,
				},
			}).then(() => {
				if (id !== undefined) switchEvent(id, "absence", false, false);
			});
		}
	};

	const updatedAbsence = state.updateAbsence;

	return (
		<div className="time-calendar">
			{workingHourData?.workingHours.length === 0 && (
				<InfoBox type="info">
					Damit Sie Reservierungen erhalten können, müssen Sie Ihre Arbeitszeiten im Tab Arbeitszeiten angeben. Danach können
					Bürger Services bei Ihnen buchen
				</InfoBox>
			)}
			<Calendar
				dayNum={dayNum}
				onStartDateChange={updateCalendarDate}
				startDate={calendarDate}
				hourFrom={8}
				hourHeight="150px"
				hourTo={17}>
				<CalendarToolbarItem>
					<button className="toolbar-btn highlight-btn" onClick={() => switchEvent("new", "absence", true)}>
						Neue Abwesenheit
					</button>
				</CalendarToolbarItem>

				{userCalendarData?.userAbsences
					?.filter((x) => !state.edit || x.id !== eventId?.split("-")[1])
					.map((absence) => (
						<CalendarItem
							key={`absence-${absence.id}`}
							title={absence.name}
							description={absence.description ?? undefined}
							from={absence.timeslot.from}
							to={absence.timeslot.to}
							className="event"
							color="#e66f59"
							onClick={() => switchEvent(absence.id, "absence", false)}
							active={eventId === `absence-${absence.id}`}
						/>
					))}

				{updatedAbsence && state.edit === true && (updatedAbsence.timeslot.from || updatedAbsence.timeslot.to) && (
					<CalendarItem
						key="new"
						title={updatedAbsence.name ?? "Neuer Eintrag"}
						description={updatedAbsence.description ?? undefined}
						from={updatedAbsence.timeslot.from ?? dayjs(updatedAbsence.timeslot.to).subtract(1, "hour").toDate().getTime()}
						to={updatedAbsence.timeslot.to ?? dayjs(updatedAbsence.timeslot.from).add(1, "hour").toDate().getTime()}
						className="event"
						color="#e66f59"
						active={eventId === "new"}
					/>
				)}

				{userCalendarData?.publicVacations.map((vacation) => (
					<CalendarItem
						key={`vacation-${vacation.id}`}
						title={vacation.name}
						from={vacation.timeslot.from}
						to={vacation.timeslot.to}
						className="event"
						color="#a2a2a2"
					/>
				))}

				{userCalendarData?.departmentBookings
					.filter((x) => x.state !== DepartmentBookingState.Storno)
					.filter((x) => x.state !== DepartmentBookingState.Declined)
					.filter((x) => !(x.state === DepartmentBookingState.Accepted && x.clerk?.id !== userID))
					.map((booking) => (
						<CalendarItem
							key={`bookings-${booking.id}`}
							title={booking.departmentService.name}
							description={booking.departmentService.department.name}
							from={booking.timeslot.from}
							to={booking.timeslot.to}
							className="event"
							color={getColor(booking.state)}
							onClick={() => switchEvent(booking.id, "booking", false)}
							active={eventId === `booking-${booking.id}`}
						/>
					))}
			</Calendar>
			{eventType === "booking" && (
				<BookingDetails
					visible={eventType === "booking"}
					onClose={onCloseSidebar}
					departmentBooking={deparmentBookingData?.departmentBooking as any}
					loading={departmentBookingLoading || sendDepartmentBookingResponseLoading}
				/>
			)}
			{eventType === "absence" && (
				<AbsenceDetails
					visible={eventType === "absence"}
					onClose={onCloseSidebar}
					absence={absenceData?.userAbsence as any}
					loading={absenceLoading || loadingCreateUserAbsence || loadingUpdateUserAbsence}
					onEdit={onEdit}
					edit={state.edit}
					isNew={eventId?.split("-")[1] === "new"}
					updateAbsenceData={updateAbsence}
					creationAbsence={state.updateAbsence}
					isValid={state.valid}
					hasChanged={state.changed}
					absenceId={eventId?.split("-")[1]}
					onSave={onSave}
				/>
			)}
		</div>
	);
};

export default DepartmentBookings;
