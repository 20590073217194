/* eslint-disable react/no-unused-state */
import { Card, Dialog, Spinner } from "@onedash/tools";
import { NativeSelect } from "onedash-react-input-form";
import React, { Component } from "react";
import { Cell, Column, Row, Table } from "onedash-react-table";
import moment from "moment";
import Toolbar from "../../Pages/Dashboard/Toolbar/Toolbar";
import Backend from "../../Utils/Backend/Backend";
import "./Gaestebeitrag.sass";
import { GuestCategory, Lodging, LodgingStat } from "./GaestebeitragTypes";
import ErrorAnimation from "../../Components/Animations/ErrorAnimation";
import BrowserHistory from "../../Utils/BrowserHistory";
import Notify from "../../Utils/Notify";

interface GaestebeitragstatistikProps {}

export const MONTHS = [
	"Januar",
	"Februar",
	"März",
	"April",
	"Mai",
	"Juni",
	"Juli",
	"August",
	"September",
	"Oktober",
	"November",
	"Dezember",
];

class Gaestebeitragstatistik extends Component<GaestebeitragstatistikProps> {
	detailDialog = React.createRef<Dialog>();

	state = {
		lodgings: [] as Lodging[],
		selectedLodging: undefined as undefined | Lodging,
		lodgingStats: undefined as LodgingStat[] | undefined,
		isLoading: true,
		selectedStat: undefined as LodgingStat | undefined,
		guestCategories: [] as GuestCategory[],
		dialogIsLoading: true,
		loadingError: undefined as undefined | string,
	};

	componentDidMount() {
		this.loadLodges();
	}

	getMonthYearFormatted = () => {
		const { selectedStat } = this.state;
		if (!selectedStat) return;
		const date = moment().clone().set("year", selectedStat.year).set("month", selectedStat.month).set("day", 1);
		return date.format("MMMM YYYY");
	};

	loadStats = async () => {
		// eslint-disable-next-line react/no-access-state-in-setstate
		const res = await Backend.get<LodgingStat[]>(`/vg/lodgings/${this.state.selectedLodging?.id}/stats`);
		// Load last stats in order to show all possible months
		this.setState({ lodgingStats: res.data });
		setTimeout(() => {
			this.setState({ isLoading: false });
		}, 1000);
	};

	loadLodges = async () => {
		const lodgingsRes = await Backend.get<Lodging[]>("/vg/lodgings");
		// Remove duplicates
		const lodgings = lodgingsRes.data.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
		const selectedLodging = lodgings[0];
		if (selectedLodging === undefined) {
			this.setState({
				isLoading: false,
				loadingError: "Sie haben aktuell keinen Betrieb zugeordnet. Bitte wenden Sie sich an den Systemandministrator.",
			});
			return;
		}

		this.saveLodgings(selectedLodging, lodgings);
	};

	saveLodgings = (selectedLodging?: Lodging, lodgings?: Lodging[]) => {
		this.setState((s: any) => ({ lodgings: lodgings ?? s.lodgings, selectedLodging }), this.loadStats);
	};

	loadCategories = async () => {
		const { selectedStat } = this.state;
		if (!selectedStat) return;
		const date = moment().clone().set("year", selectedStat.year).set("month", selectedStat.month).set("day", 1).timestamp();
		const res = await Backend.get<GuestCategory[]>(`/appSettings/guestCategories?dateTime=${date}`);
		this.setState({ guestCategories: res.data, dialogIsLoading: false });
	};

	showDetailedInformation = (index?: number) => {
		if (!this.state.lodgingStats || index === undefined) return;
		// eslint-disable-next-line react/no-access-state-in-setstate
		const selectedStat = this.state.lodgingStats[index];
		if (selectedStat.isVacation) {
			Notify.w("An diesem Monat war das Unternehmen geschlossen, weswegen keine weiteren Informationen angezeigt werden können.");
			return;
		}
		if (selectedStat.notCreated === true) {
			BrowserHistory.push("erfassung");
			return;
		}

		this.setState({ selectedStat, dialogIsLoading: true }, this.loadCategories);
		this.detailDialog.current?.show();
	};

	render() {
		const { lodgings, selectedLodging, isLoading, lodgingStats, selectedStat, guestCategories, loadingError } = this.state;
		const lodgingOptions = lodgings.map((l) => {
			return { label: l.companyName, value: l.id };
		});

		const selectedTableStat: any[] = [];
		guestCategories.forEach((cat) => {
			const c = selectedStat?.categories.find((x) => x.name === cat.name);
			selectedTableStat.push({
				category: cat.label,
				price: cat.price,
				persons: c?.persons,
				nights: c?.nights,
				sum: cat.price * (c?.nights ?? 0),
			});
		});

		return (
			<>
				{lodgings.length > 1 && (
					<Toolbar>
						<NativeSelect
							value={selectedLodging?.id}
							required
							name="lodging"
							placeholder="Wählen Sie einen Betrieb"
							options={lodgingOptions}
							onChange={(id) => this.saveLodgings(lodgings.find((x) => x.id === id))}
						/>
					</Toolbar>
				)}
				<div className="gaestebeitrag-statistik">
					{isLoading && <Spinner defaultVisible />}
					{!isLoading && !loadingError && (
						<Table
							onRowClick={this.showDetailedInformation}
							select="click"
							minWidth={700}
							textNoRows="Es wurden noch keine Daten übermittelt"
							rightIcon={<i className="im im-angle-right" />}>
							<Column width="100px" name="month" label="Monat" />
							<Column width="100px" name="year" label="Jahr" />
							<Column name="numPersons" label="Anzahl der Personen" />
							<Column name="numNights" label="Anzahl der Übernachtungen" />
							<Column name="totalPrice" label="Gesamtpreis" />

							{lodgingStats?.map((row, i) => (
								<Row key={i as any} row={row}>
									<Cell name="month">{(month) => MONTHS[month]}</Cell>
									<Cell name="year" />
									<Cell name="numPersons" />
									<Cell name="numNights" />
									<Cell name="totalPrice">
										{(val) => {
											if (row.isVacation) {
												return <span className="is-vacation">Betrieb geschlosssen</span>;
											}
											if (!row.notCreated) {
												return Number(val).toPrice();
											}
											return <span className="link-erfassung">Monat erfassen</span>;
										}}
									</Cell>
								</Row>
							))}
						</Table>
					)}

					{!isLoading && loadingError && (
						<Card>
							<ErrorAnimation visible title="Es ist ein Fehler aufgetreten">
								{loadingError}
							</ErrorAnimation>
						</Card>
					)}
				</div>

				<Dialog
					buttons={[]}
					settings={{ maxWidth: 800 }}
					ref={this.detailDialog}
					className="gaestebeitrag-stat-dialog"
					title={`Details: ${this.getMonthYearFormatted()}`}>
					<p>
						In der folgenden Tabelle können Sie die aufgeschlüsselten Zahlen für den Zeitraum{" "}
						<span className="highlight">{this.getMonthYearFormatted()}</span> einsehen:
					</p>

					{selectedTableStat && selectedTableStat.length > 0 && (
						<Table style={{ marginTop: "40px" }} minWidth={600}>
							<Column name="category" label="Kategorie" />
							<Column name="price" label="Preis" />
							<Column name="persons" label="Personen" />
							<Column name="nights" label="Übernachtungen" />
							<Column name="sum" label="Gesamt" />

							{selectedTableStat?.map((row, i) => (
								<Row key={i as any} row={row}>
									<Cell name="category" />
									<Cell name="price">{(val) => Number(val).toPrice()}</Cell>
									<Cell name="persons" />
									<Cell name="nights" />
									<Cell name="sum">{(val) => Number(val).toPrice()}</Cell>
								</Row>
							))}
						</Table>
					)}

					<div className="total">
						<div className="label">Gesamtbetrag</div>
						<div className="price">{(selectedStat?.totalPrice ?? 0).toPrice()}</div>
					</div>
				</Dialog>
			</>
		);
	}
}

export default Gaestebeitragstatistik;
