import React from "react";
/* eslint-disable no-underscore-dangle */
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client";
/* eslint-disable import/no-extraneous-dependencies */
import { setContext } from "@apollo/client/link/context";
import { authClient } from "../../Auth/AuthProvider";
import ENV from "../ENV";
import ApolloError from "./ApolloError";

const AuthorizedApolloProvider = ({ children }: { children: any }) => {
	const httpLink = createHttpLink({
		uri: ENV.default.gqlURL,
	});

	const authLink = setContext(async (res, ctx) => {
		const isAuthenticated = await authClient.isAuthenticated();
		if (!isAuthenticated) return {};

		const token = await authClient.getTokenSilently().catch(() => {
			//
		});

		if (!token) return {};
		return {
			headers: {
				Authorization: `Bearer ${token}`,
				...ctx.headers,
			},
		};
	});

	const apolloClient = new ApolloClient({
		link: authLink.concat(ApolloError).concat(httpLink),
		cache: new InMemoryCache({}),
		connectToDevTools: true,
		defaultOptions: {
			mutate: { errorPolicy: "ignore" },
			query: { errorPolicy: "ignore" },
		},
	});

	return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
