import React from "react";
import { Spinner, StyleLoader } from "@onedash/tools";
import { createStore } from "redux";
import "./App.scss";
import { Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import STYLE_TWENTY from "../../Resources/Styles/StyleTwenty";
import reducer from "../../Utils/Redux/Reducer";
import Login from "../Login/Login";
import BrowserHistory from "../../Utils/BrowserHistory";
import DashboardLoader from "../Dashboard/DashboardLoader";
import "react-toastify/dist/ReactToastify.css";
import ENV from "../../Utils/ENV";
import "moment/locale/de";
import Action from "../../Utils/Redux/Action";
import ServerStatus from "../../Utils/ServerStatus";
import { Session } from "../../Utils/Session";
import GaestebeitragRegister from "../GaestebeitragRegister/GaestebeitragRegister";
import { useAuth } from "../../Auth/AuthContext";

const AUTH_FREE_PAGES = ENV.default.authFree.split(",");
const SUB_PAGES = ENV.default.subPages.split(",");

moment.locale("de");

declare global {
	interface Window {
		// auth: Auth;
		reduxStore: any;
		_env: any;
	}
}

window.reduxStore = createStore(reducer);

const App = () => {
	const { isAuthenticated, isLoading } = useAuth();
	const [theme, changeTheme] = React.useState("light" as "light" | "dark");
	React.useEffect(() => {
		window.reduxStore.subscribe(() => {
			const data = window.reduxStore.getState();
			if (data.themeMode) {
				changeTheme(data.themeMode);
			}
		});
		// eslint-disable-next-line no-new
		new ServerStatus(ENV.serverStatus.url, ENV.serverStatus.id);
	}, []);

	React.useEffect(() => {
		if (isLoading) return;
		const currentPage = BrowserHistory.location.pathname;
		if (!AUTH_FREE_PAGES.find((x) => currentPage.indexOf(x) !== -1) && isAuthenticated === false) {
			// Save current State to SessionStore
			if (currentPage.indexOf("/login") === -1) {
				Session.set("login_redirect", currentPage, false);
			}
			BrowserHistory.push("/login");
		} else {
			const t = SUB_PAGES?.find((x) => {
				const r = new RegExp(x);
				return r.test(currentPage);
			});

			if (!t) {
				if (currentPage.indexOf("dashboard") === -1 && AUTH_FREE_PAGES?.indexOf(currentPage) === -1) {
					BrowserHistory.push("/dashboard");
				}
			}
		}
	}, [isAuthenticated, isLoading]);

	return (
		<StyleLoader toolStyle="twenty" theme={theme} additionalStyle={STYLE_TWENTY}>
			{!isLoading ? (
				<Switch>
					<Route path="/dashboard" component={DashboardLoader} />
					<Route path="/login" component={Login} />

					<Route path="/gaestebeitrag-register/:appId" component={GaestebeitragRegister} />
				</Switch>
			) : (
				<Spinner defaultVisible fullPage />
			)}

			<ToastContainer />
		</StyleLoader>
	);
};

export default App;

window.onfocus = () => {
	const r = new XMLHttpRequest();
	r.onload = () => {
		const t = r.responseText;
		const versionStart = t.indexOf('"/precache-manifest.') + 20;
		const versionEnd = t.indexOf('.js"', versionStart);

		if (versionEnd - versionStart === 32) {
			const ls = localStorage;
			const oldPrecacheManifestVersion = ls.getItem("pmv");
			const newPrecacheManifestVersion = t.substring(versionStart, versionEnd);

			if (newPrecacheManifestVersion !== oldPrecacheManifestVersion) {
				ls.setItem("pmv", newPrecacheManifestVersion);
				return Action.updateAvailable();
			}
		}
	};
	r.open("GET", `/service-worker.js?c=${new Date().getTime()}`);
	r.setRequestHeader("Cache-Control", "no-cache, no-store, must-revalidate");
	r.send();
};
