import moment from "moment";

declare module "moment" {
	export interface Moment {
		timestamp(): number;
	}
}

// eslint-disable-next-line func-names
moment.fn.timestamp = function () {
	return this.toDate().getTime();
};

export default class DateUtils {
	public static setTime(date: moment.Moment, hours = 0, minutes = 0, seconds = 0, milliseconds = 0) {
		return date.clone().set("milliseconds", milliseconds).set("seconds", seconds).set("minutes", minutes).set("hours", hours);
	}
}
