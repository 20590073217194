import { Card } from "@onedash/tools";
import React from "react";
import GaestebeitragData from "./GaestebeitragData";

const GaestebeitragKontakt = () => (
	<>
		<Card title="Kontakt">
			<p>Sollten Sie Fragen haben, oder Meldescheine bzw. Couponhefte benötigen, stehen wir Ihnen gerne zur Verfügung:</p>
			<p>
				<span className="bold">Verbandsgemeinde Wittlich-Land</span>
				<br />
				{GaestebeitragData.contact.name} <br />
				{GaestebeitragData.contact.street}
				<br />
				{GaestebeitragData.contact.city}
				<br />
				<br />
				<span className="bold">Telefon:</span>{" "}
				<a className="highlight" href={GaestebeitragData.contact.phone}>
					{GaestebeitragData.contact.phoneFormat}
				</a>
				<br />
				<span className="bold">Email:</span>{" "}
				<a className="highlight" href={`mailto:${GaestebeitragData.contact.mail}`}>
					{GaestebeitragData.contact.mail}
				</a>
			</p>
		</Card>
		<Card title="SEPA Lastschriftmandat">
			<p>
				Unter dem folgenden Link finden Sie das SEPA - Lastschriftmandat zum Download. Bitte füllen Sie dieses aus, wenn Ihre
				Beiträge automatisch durch die Verbandsgemeinde eingezogen werden sollen.
			</p>
			<a
				href="https://static.onedash.de/vg-wittlich-land/sepa-lastschriftmandat.pdf"
				target="_blank"
				rel="noopener noreferrer"
				className="btn">
				PDF Herunterladen
			</a>
		</Card>
	</>
);

export default GaestebeitragKontakt;
