import AppUtils from "./AppUtils";
import Backend from "./Backend/Backend";

export default class Storage {
	public static set = (entryName: string, value: any, appSpecific = true) => {
		localStorage.setItem(Storage.getSelector(entryName, appSpecific), JSON.stringify(value));
	};

	public static setObject = (entryName: string, path: string, value: any, appSpecific = true) => {
		const obj = Storage.get(entryName, appSpecific) ?? {};
		AppUtils.setObjectValue(path, obj, value);
		Storage.set(entryName, obj, appSpecific);
	};

	public static get = (entryName: string, appSpecific = true) => {
		const content = localStorage.getItem(Storage.getSelector(entryName, appSpecific));
		if (!content) return;
		return JSON.parse(content);
	};

	private static getSelector = (entryName: string, isAppSpecific: boolean) => {
		if (isAppSpecific) {
			return `${entryName}-${Backend.getAppID()}`;
		}
		return entryName;
	};
}
