/* eslint-disable react/prop-types */
import React from "react";
import "./Headbar.scss";
import { MediaRender } from "@onedash/tools";
import { Route, NavLink } from "react-router-dom";
import ProfileDropdown from "./ProfileDropdown";
import AppDropdown from "./AppDropdown";
import { useAuth } from "../../../Auth/AuthContext";

interface HeadbarProps {
	modules: Module[];
	applications: Application[];
	onAppSelect: (appID: string) => void;
	selectedApp: Application | undefined;
}

const Headbar = ({ modules, applications, onAppSelect, selectedApp }: HeadbarProps) => {
	const { user } = useAuth();
	return (
		<>
			<MediaRender type="desktop">
				<div className="head-bar">
					<AppDropdown onAppSelect={onAppSelect} selectedApp={selectedApp} applications={applications} />
					<div className="tabs">
						<ul>
							{modules.map((module) => {
								let path = `/dashboard/:appId${module.basePath}`;
								if (module.isBaseModule === true) {
									path = `/dashboard${module.basePath}`;
								}
								return (
									<Route
										key={path}
										path={path}
										render={(props) => (
											<>
												{module.tabs.map((tab) => {
													let to = `/dashboard/${
														props.match.params.appId + module.basePath + (tab.linkTo ?? tab.subPath)
													}`;
													if (module.isBaseModule === true) {
														to = `/dashboard${module.basePath + (tab.linkTo ?? tab.subPath)}`;
													}
													return (
														<li key={tab.subPath}>
															<NavLink activeClassName="active" to={to}>
																<span />
																<p>{tab.name}</p>
															</NavLink>
														</li>
													);
												})}
											</>
										)}
									/>
								);
							})}
						</ul>
					</div>
					<div className="side">
						<span />
						<button className="notifications">
							<i className="im im-bell" />
						</button>

						<span />
						<ProfileDropdown user={user} />
					</div>
				</div>
			</MediaRender>
			<MediaRender type="mobile">
				<div className="mobile-head-bar">
					<AppDropdown onAppSelect={onAppSelect} selectedApp={selectedApp} applications={applications} />
				</div>
			</MediaRender>
		</>
	);
};

export default Headbar;
