/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/style-prop-object */
import React, { Component } from "react";
import QrReader from "react-qr-reader";
import moment from "moment";
import { Spinner, Card } from "@onedash/tools";
import Action from "../../Utils/Redux/Action";
import "./TicketScanner.scss";
import Backend from "../../Utils/Backend/Backend";
import AppUtils from "../../Utils/AppUtils";
import Notify from "../../Utils/Notify";

interface TicketScannerProps {}
interface ScanResponse {
	valid: boolean;
	errorMessage?: string;
	bookingData?: CoronaTicket;
}
interface CoronaTicket {
	freeTimeslots?: number;
	buy: number;
	people: number;
	poolID: string;
	state: "open" | "storno" | "inside" | "redeemed";
	arrival?: number;
	timeSlot: number;
	households: {
		name: string;
		email: string;
		street: string;
		plz: string;
		address: string;
		phone: string;
		members?: string[];
	}[];
}

class TicketScanner extends Component<TicketScannerProps> {
	intervall: NodeJS.Timeout | undefined;

	ticketID: string | undefined;

	state = {
		response: undefined as ScanResponse | undefined,
		fadeOut: false,
		scannerBusy: false,
		closingPercentage: 0,
		poolData: undefined as undefined | PoolData,
		permissionDenied: false,
	};

	componentDidMount() {
		Action.tabLoaded();
		this.loadPools();
	}

	handleScan = (ticketID: string | null) => {
		if (!ticketID || this.state.scannerBusy === true) return;
		this.ticketID = ticketID;
		this.setState({ scannerBusy: true });
		Backend.get(`/vg/corona/ticket/${ticketID}`).catch(this.redeemFailure).then(this.redeemSuccess);
	};

	redeemFailure = (data: any) => {
		const response: ScanResponse = {
			valid: false,
			errorMessage: "Es ist ein Fehler beim Scannen aufgetreten. Bitte versuchen Sie es erneut.",
		};
		this.setState({ response });
		// eslint-disable-next-line no-console
		console.error(data);
	};

	redeemSuccess = (data: any) => {
		let response: ScanResponse;
		if (data.data.length === 0) {
			response = {
				valid: false,
				errorMessage: "Das gescannte Ticket konnte nicht gefunden werden.",
			};
			this.setState({ response });
			return;
		}

		const ticket: CoronaTicket = data.data[0];

		// Check date
		if (!moment().isSame(moment(ticket.timeSlot), "day")) {
			response = {
				valid: false,
				errorMessage: `Das Datum des Tickets unterscheidet sich vom heutigen Datum. (${moment(ticket.timeSlot).format("LLLL")})`,
			};
			this.setState({ response });
			return;
		}

		// Check state
		if (ticket.state === "redeemed") {
			response = {
				valid: false,
				errorMessage: `Das Ticket wurde bereits eingelöst.`,
			};
			this.setState({ response });
			return;
		}
		// Check state
		if (ticket.state === "storno") {
			response = {
				valid: false,
				errorMessage: `Das Ticket wurde storniert und ist deswegen nicht gültig.`,
			};
			this.setState({ response });
			return;
		}

		response = {
			valid: true,
			bookingData: ticket,
		};
		this.setState({ response });
	};

	handleError = async (err: any) => {
		// eslint-disable-next-line no-console
		console.error(err.message);
		await AppUtils.timeout(300);
		this.setState({ permissionDenied: true });
	};

	touchStart = () => {
		this.intervall = setInterval(() => {
			if (this.state.closingPercentage > 130) {
				if (this.intervall) clearInterval(this.intervall);
				this.setState({ fadeOut: true });
				if (this.state.response?.valid === true) this.redeemTicket();
				this.closeTicket();
			} else {
				this.setState((s: any) => ({ closingPercentage: s.closingPercentage + 3 }));
			}
		}, 10);
	};

	touchEnd = () => {
		if (this.intervall) clearInterval(this.intervall);
		this.setState({ closingPercentage: 0 });
	};

	closeTicket = async () => {
		await AppUtils.timeout(300);
		this.setState({
			fadeOut: false,
			response: undefined,
			closingPercentage: 0,
			scannerBusy: false,
		});
	};

	redeemTicket = async () => {
		const response = await Backend.get(`/tickets/redeem/${this.ticketID}`);
		if (response.type === "E") {
			Notify.e("Das Ticket konnte nicht bestätigt werden. Bitte versuchen Sie es erneut");
		}
	};

	loadPools = async () => {
		const res = await Backend.get<PoolData>("/appSettings/swimmingPools");
		this.setState({ poolData: res.data });
	};

	removeMember = (memberName: string) => {
		const { response } = this.state;
		const bookingData = response?.bookingData;
		if (!bookingData) return;
		bookingData.households.forEach((household) => {
			const i = household.members?.findIndex((x) => x === memberName);

			if (i !== undefined && i !== -1) {
				household.members?.splice(i, 1);
				bookingData.people--;
			}
		});
		this.setState({
			response,
		});
		Backend.delete(`/vg/corona/ticket/${this.ticketID}`, { memberName }).catch(() => {
			Notify.e("Das Löschen des Familienmitglieds ist fehlgeschlagen. Bitte versuchen Sie es erneut.");
		});
	};

	render() {
		const { response, permissionDenied } = this.state;
		let poolName = "";
		const pool = this.state.poolData?.pools.find((x) => x.id === response?.bookingData?.poolID);
		if (pool) poolName = pool.name;
		let ticketWidth = window.innerHeight < window.innerWidth ? window.innerHeight - 200 : window.innerWidth - 200;
		if (window.innerWidth < 720) {
			ticketWidth = window.innerHeight < window.innerWidth ? window.innerHeight - 50 : window.innerWidth - 50;
		}

		return (
			<div className="ticket-scanner">
				{!this.state.scannerBusy && !permissionDenied && (
					<QrReader
						className="scanner"
						delay={300}
						onError={this.handleError}
						onScan={this.handleScan}
						style={{ width: ticketWidth }}
					/>
				)}
				{permissionDenied === true && (
					<Card>
						<div className="error-info-box">
							<h1>Auf die Kamera kann nicht zugegriffen werden</h1>
							<p>
								Das Modul besitzt keine Berechtigung, um auf die Kamera zugreifen zu können. Bitte erlauben Sie der Webseite
								die Kamera benutzen zu dürfen.
							</p>
							<h2>Apple (IOS)</h2>
							<p>
								Um bei IOS auf die Kamera zugreifen zu können, stellen Sie sicher, dass sie diese Webseite mit dem
								&quot;Safari&quot; Browser öffnen. Zusätzlich sollten Sie die Folgende Einstellung aktivieren:
							</p>
							<p>Einstellungen &gt; Safari &gt; Kamera &gt; Erlauben</p>
						</div>
					</Card>
				)}

				{this.state.scannerBusy && (
					<div className="spinner-wrapper">
						<Spinner defaultVisible style="twenty" />
					</div>
				)}

				{response && (
					<div className={this.state.fadeOut ? "fadeout scan-response" : "scan-response"}>
						<div className={response.valid === false ? "response-background invalid" : "response-background"} />

						{!response.valid && <h1>{response.errorMessage}</h1>}
						{response.valid && (
							<div>
								<div className="close-btn" onClick={this.closeTicket}>
									x
								</div>

								<div className="booking-data">
									<div>
										<div className="base">
											<h2>Allgemein</h2>

											<div>
												<p className="label">Einrichtung</p>
												<p>{poolName}</p>
											</div>
											<div>
												<p className="label">Uhrzeit</p>
												<p>{moment(response?.bookingData?.timeSlot).format("HH:mm")} Uhr</p>
											</div>
											<div>
												<p className="label">Anzahl der Personen</p>
												<p>{response?.bookingData?.people}</p>
											</div>
											{response.bookingData?.arrival && (
												<div>
													<p className="label">Einlass</p>
													<p>{moment(response?.bookingData?.arrival).format("HH:mm")}</p>
												</div>
											)}
											{response.bookingData?.freeTimeslots && (
												<div style={{ marginTop: 50 }}>
													<p className="label">Aktuell freie Tickets</p>
													<p>{response?.bookingData?.freeTimeslots}</p>
												</div>
											)}
										</div>
									</div>
									<div className="households">
										{response.bookingData?.state === "open" &&
											response.bookingData?.households.map((h, i) => (
												<div key={i as any} className="household">
													<h2>{i + 1}. Haushalt </h2>
													<div>
														<p className="label">Name</p>
														<p>{h.name}</p>
													</div>
													<div>
														<p className="label">Adresse</p>
														<p>{h.street}</p>
														<p>
															{h.plz} {h.address}
														</p>
													</div>
													{h.members && (
														<div>
															<p className="label">Haushaltsmitglieder</p>
															<ul>
																{h.members?.map((member, ii) => (
																	<li key={ii as any}>
																		<button onClick={() => this.removeMember(member)}>
																			<i className="im im-minus-circle" />
																		</button>
																		<div className="name">{member}</div>
																	</li>
																))}
															</ul>
														</div>
													)}
												</div>
											))}
									</div>
								</div>
							</div>
						)}
						<div
							className="closing-circle"
							onMouseUp={this.touchEnd}
							onMouseDown={this.touchStart}
							onTouchStart={this.touchStart}
							onTouchEnd={this.touchEnd}>
							<div className="state-icon">
								{response.bookingData?.state === "inside" && <i className="im im-export" />}
								{response.bookingData?.state === "open" && <i className="im im-save" />}
							</div>
							<svg viewBox="0 0 36 36" className="circular-close">
								<path
									className="circle-bg"
									d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
								/>
								{this.state.closingPercentage > 0 && (
									<path
										className="circle"
										strokeDasharray={`${this.state.closingPercentage}, 100`}
										d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
									/>
								)}
								<text x="18" y="19.5" className="text">
									{response.valid
										? response.bookingData?.arrival !== undefined
											? "Entwerten"
											: "Einlösen"
										: "Schließen"}
								</text>
							</svg>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default TicketScanner;
