/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import "./Sidebar.scss";
import { MediaRender } from "@onedash/tools";
import Action from "../../../Utils/Redux/Action";

interface SidebarProps {
	collapseable?: boolean;
	className?: string;
}

class Sidebar extends Component<SidebarProps> {
	state = {
		collapsed: false,
	};

	componentDidMount() {
		Action.showSidebar();
	}

	public toggleCollapsed = () => {
		if (this.state.collapsed === true) {
			Action.showSidebar();
		} else {
			Action.hideSidebar();
		}
		this.setState((s: any) => ({
			collapsed: !s.collapsed,
		}));
	};

	public close = () => {
		Action.hideSidebar();
		this.setState({
			collapsed: true,
		});
	};

	public show = () => {
		Action.showSidebar();
		this.setState({
			collapsed: true,
		});
	};

	private getClassName = () => {
		let className = "sidebar-wrapper";
		if (this.state.collapsed) {
			className += " collapsed";
		}
		if (this.props.className) {
			className += ` ${this.props.className}`;
		}
		return className;
	};

	render() {
		return (
			<div className={this.getClassName()}>
				<div className="sidebar-toggle-btn">
					<button onClick={this.toggleCollapsed}>
						<i className="im im-data" />
					</button>
				</div>
				<MediaRender type="mobile">
					<div className={this.state.collapsed ? "mobile-toggle visible" : "mobile-toggle"} onClick={this.toggleCollapsed}>
						<p>Einstellungen</p>
						<i className="im im-angle-down" />
					</div>
				</MediaRender>
				<div className="sidebar">
					<div className="side-bar-content">{this.props.children}</div>
				</div>
			</div>
		);
	}
}

export default Sidebar;
