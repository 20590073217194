import Backend from "./Backend/Backend";

interface Preferences {
	defaultNavApp: string;
	colorTheme: "light" | "dark";
	latestApp: string;
	highlightColor: string;
}
export default class Customization {
	private static settingsCache: any = {};

	public static saveUserPreference = async (settingName: keyof Preferences, data: any) => {
		Customization.settingsCache[settingName] = data;
		await Backend.post(`/base/userSetting/${settingName}`, { updatedSetting: data }, false);
	};

	public static getUserPreference = async <T extends keyof Preferences, U = Preferences[T] | undefined>(settingName: T): Promise<U> => {
		if (Customization.settingsCache[settingName]) return Customization.settingsCache[settingName];

		const response = await Backend.get(`/base/userSetting/${settingName}`, false);
		Customization.settingsCache[settingName] = response.data;
		return response.data as U;
	};
}
