import React from "react";
import MiniPage, { MiniPageProps } from "../MiniPage";
import darkMode from "../Images/dark-mode.png";
import lightMode from "../Images/light-mode.png";
import Action from "../../../Utils/Redux/Action";
import Customization from "../../../Utils/Customization";
import AppUtils from "../../../Utils/AppUtils";
import { COLORS, DEFAULT_HIGHLIGHT_COLOR } from "../../../Resources/Colors";

interface ThemeChangeProps extends MiniPageProps {
	onThemeChanged: () => void;
}
type LightModes = undefined | "dark" | "light";

class ThemeChange extends MiniPage<ThemeChangeProps> {
	themeChanged = false;

	colorChanged = false;

	state = {
		colorMode: "light" as LightModes,
		highlightColor: DEFAULT_HIGHLIGHT_COLOR as undefined | string,
	};

	componentDidMount() {
		this.loadColor();
	}

	selectTheme = (theme: LightModes) => {
		if (!theme) return;
		this.themeChanged = true;
		this.setState({
			colorMode: theme,
		});
		Action.darkLightChange(theme);
		Customization.saveUserPreference("colorTheme", theme);
	};

	loadColor = async () => {
		const highlightColor = await Customization.getUserPreference("highlightColor");
		AppUtils.setHighlightColor(highlightColor ?? DEFAULT_HIGHLIGHT_COLOR);
		this.setState({
			highlightColor,
		});
	};

	selectHighlightColor = (highlightColor: string) => {
		this.colorChanged = true;
		Customization.saveUserPreference("highlightColor", highlightColor);
		AppUtils.setHighlightColor(highlightColor);
		this.setState({
			highlightColor,
		});
	};

	saveChangedSettings = () => {
		if (!this.themeChanged) this.selectTheme("light");
		if (!this.colorChanged) this.selectHighlightColor(DEFAULT_HIGHLIGHT_COLOR);
		this.props.onThemeChanged();
	};

	render() {
		return (
			<div className="theme-selection">
				<div className="split">
					<div className="left">
						<div>
							<h2>Wählen Sie ein Farbschema</h2>
							<h3>Personalisieren Sie das Farbschema und die Akzentfarbe</h3>
							<p>Sowohl das Farbschema, als auch die Akzentfarbe kann noch nachträglich im Dashboard geändert werden</p>
						</div>
						<button style={{ margin: "0px", marginTop: "30px" }} className="btn" onClick={this.saveChangedSettings}>
							Weiter
						</button>
					</div>
					<div className="right">
						<h2>Farbschema</h2>
						<div className="schemas">
							<button
								onClick={() => this.selectTheme("dark")}
								className={this.state.colorMode === "dark" ? "schema selected" : "schema"}>
								<div>
									<img src={darkMode} alt="Dunkles Farbschema" />
								</div>
							</button>
							<button
								onClick={() => this.selectTheme("light")}
								className={this.state.colorMode === "light" ? "schema selected" : "schema"}>
								<div>
									<img src={lightMode} alt="Helles Farbschema" />
								</div>
							</button>
						</div>

						<div className="highlight-colors">
							<h2>Akzentfarbe</h2>
							<div className="colors">
								{COLORS.map((color) => (
									<button key={color} onClick={() => this.selectHighlightColor(color)}>
										<span
											className={this.state.highlightColor === color ? "selected" : ""}
											style={{ backgroundColor: color }}
										/>
									</button>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ThemeChange;
