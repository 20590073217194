/* eslint-disable no-restricted-syntax */
import { Boolean, Button, Form, Card, Input, Select } from "@onedash/tools";
import React, { Component } from "react";
import { ValueLabelPair } from "onedash-react-input-form";
import moment from "moment";
import GroupedList, { Group } from "../../Components/List/GroupedList";
import Backend from "../../Utils/Backend/Backend";
import ReactUtils from "../../Utils/ReactUtils";
import Action from "../../Utils/Redux/Action";
import "./Fundsachen.sass";
import BrowserUtils from "../../Utils/BrowserUtils";

import AppUtils from "../../Utils/AppUtils";
import ImageCollection from "./ImageCollection";
import Notify from "../../Utils/Notify";
import ListItem from "../../Components/List/ListItem";

interface FundsachenProps {}

const categoryMap = new Map([
	["phone", "Telefon"],
	["moneyBag", "Portemonnaie"],
	["key", "Schlüssel"],
	["electric", "Elektrisches Gerät"],
	["misc", "Sonstiges"],
]);

const statusMap = new Map([
	["pending", "🟡 Wartend"],
	["active", "🟢 Aktiv"],
	["inactive", "🔴 Inaktiv"],
]);

class Fundsachen extends Component<FundsachenProps> {
	fundsacheForm = React.createRef<Form>();

	imageCollection = React.createRef<ImageCollection>();

	state = {
		fundsachen: [] as Fundsache<any>[],
		groupedFundsachen: { active: [], inactive: [], pending: [] } as GroupedFundsachen,
		selectedFundsache: undefined as undefined | Fundsache<any>,
		locations: [] as Location[],

		viewMode: true,
		externalUser: false,
	};

	componentDidMount() {
		this.loadFundsachen(BrowserUtils.getParam("id"));
		this.loadLocations();
	}

	loadFundsachen = async (id?: number | string) => {
		const fundsachen = await Backend.get<Fundsache<any>[]>("/vg/fundsache");

		// Group them by their state
		const groupedFundsachen = this.groupFundsachen(fundsachen.data);
		this.setState({ groupedFundsachen, fundsachen: fundsachen.data });

		if (id) {
			this.selectFundsache(id);
		}

		Action.tabLoaded();
	};

	groupFundsachen = (fundsachen: Fundsache<any>[]) => {
		const groupedFundsachen: GroupedFundsachen = { active: [], inactive: [], pending: [] };

		fundsachen.forEach((f) => {
			// Set personalData "" to undefined
			for (const prop in f.personalData) {
				if ((f.personalData as any)[prop] === "") {
					(f.personalData as any)[prop] = undefined;
				}
			}
			(groupedFundsachen as any)[f.fundsache.status].push(f);
		});
		return groupedFundsachen;
	};

	loadLocations = async () => {
		const locationResponse = await Backend.get<Location[]>("/masterData/location");
		this.setState({ locations: locationResponse.data });
	};

	selectFundsache = (id: string | number) => {
		// eslint-disable-next-line react/no-access-state-in-setstate
		const selectedFundsache = this.state.fundsachen.find((x) => x.id === Number(id));
		if (!selectedFundsache) return;

		this.setState({
			selectedFundsache,
			externalUser:
				selectedFundsache.personalData.userID === undefined ||
				selectedFundsache.personalData.userID === null ||
				selectedFundsache.personalData.userID === "",
			viewMode: true,
		});
		BrowserUtils.addParam("id", id);
	};

	resetFundsache = () => {
		// eslint-disable-next-line react/no-access-state-in-setstate
		const selectedFundsache = this.state.fundsachen.find((x) => x.id === this.state.selectedFundsache?.id);
		this.setState({ selectedFundsache, viewMode: true });
	};

	saveFundsache = async () => {
		if (!this.fundsacheForm.current?.validateInputs()) {
			Notify.e("Füllen Sie mindestens Fundort, Kategorie und Fundumstände aus");
			return;
		}

		const fundsache: any = this.getSelectedFundsacheValues();
		fundsache.id = this.state.selectedFundsache?.id;
		fundsache.useCurrentUser = !fundsache.externalUser;

		const pictureKeys = this.imageCollection?.current?.getFiles().map((x) => x.key);
		(fundsache as any).pictureKeys = pictureKeys;
		if (!fundsache.personalData) {
			fundsache.personalData = {};
		}
		if (fundsache.id) {
			await Backend.put("/vg/fundsache", fundsache);
			this.loadFundsachen(fundsache.id);
		} else {
			const response = await Backend.post("/vg/fundsache", fundsache);
			this.loadFundsachen(response.properties?.insertedId);
		}
		this.imageCollection?.current?.reset();
		this.setState({ viewMode: true });
	};

	getSelectedFundsacheValues = () => {
		const values = this.fundsacheForm.current?.getData();
		const selectedFundsache = {};
		// eslint-disable-next-line guard-for-in
		for (const path in values) {
			AppUtils.setObjectValue(path, selectedFundsache, values[path]);
		}
		return selectedFundsache;
	};

	addNewEntry = () => {
		const selectedFundsache: NewFundsache = {
			fundsache: {
				status: "active",
				admin_created: new Date().getTime(),
			},
			personalData: {},
			pictures: [],
		};
		this.setState({ selectedFundsache, viewMode: false, externalUser: false });
	};

	imageDelete = (key: string) => {
		const { selectedFundsache } = this.state;
		if (!selectedFundsache) return;
		selectedFundsache.pictures = selectedFundsache.pictures.filter((x) => x.indexOf(key) === -1);

		this.setState({ selectedFundsache });
	};

	render() {
		const { groupedFundsachen, selectedFundsache: sf, locations, viewMode, externalUser } = this.state;

		let name = "Anonym";
		if (sf?.personalData.firstName && sf?.personalData.lastName) {
			name = `${sf.personalData.firstName} ${sf.personalData.lastName}`;
		}
		const locationOptions = locations.map((l) => {
			return { label: l.name, value: l.id };
		});
		const categoryOptions: ValueLabelPair[] = [];
		categoryMap.forEach((label, value) => categoryOptions.push({ value, label }));

		const statusOptions: ValueLabelPair[] = [];
		statusMap.forEach((label, value) => statusOptions.push({ value, label }));

		return (
			<>
				<div className="fundsachen">
					<Card className="list-detail" maxWidth={1200}>
						<div className="left-list">
							<Button mode="link" className="add-icon" onClick={this.addNewEntry}>
								<i className="im im-plus-circle" />
							</Button>
							<GroupedList>
								<Group label="Auf Entscheidung wartend" showCount>
									{groupedFundsachen.pending.map((f, i) => (
										<ListItem
											selected={sf === f}
											key={i as any}
											onClick={this.selectFundsache}
											date={f.fundsache.admin_created}
											id={f.id}
											image={ReactUtils.getUserIcon(
												f.personalData.imageURL,
												f.personalData.firstName,
												f.personalData.lastName
											)}>
											{f.fundsache.location}: {categoryMap.get(f.fundsache.category)}
										</ListItem>
									))}
								</Group>
								<Group label="Aktiv" showCount>
									{groupedFundsachen.active.map((f, i) => (
										<ListItem
											selected={sf === f}
											key={i as any}
											onClick={this.selectFundsache}
											date={f.fundsache.admin_created}
											id={f.id}
											image={ReactUtils.getUserIcon(
												f.personalData.imageURL,
												f.personalData.firstName,
												f.personalData.lastName
											)}>
											{f.fundsache.location}: {categoryMap.get(f.fundsache.category)}
										</ListItem>
									))}
								</Group>
								<Group defaultCollapsed label="Inaktiv" showCount>
									{groupedFundsachen.inactive.map((f, i) => (
										<ListItem
											selected={sf === f}
											key={i as any}
											onClick={this.selectFundsache}
											date={f.fundsache.admin_created}
											id={f.id}
											image={ReactUtils.getUserIcon(
												f.personalData.imageURL,
												f.personalData.firstName,
												f.personalData.lastName
											)}>
											{f.fundsache.location}: {categoryMap.get(f.fundsache.category)}
										</ListItem>
									))}
								</Group>
							</GroupedList>
						</div>
						<div className="detail-view">
							{!sf && (
								<div className="center-center">
									<h1 className="info-text">
										Wählen Sie links eine Fundsache um sich weitere Informationen anzeigen zu lassen
									</h1>
								</div>
							)}
							{sf && (
								<>
									<div className="toolbar">
										<div className="split">
											<h2>
												{sf.fundsache.location}: {categoryMap.get(sf.fundsache.category)}
											</h2>
											<div>
												{viewMode ? (
													<Button
														onClick={() => this.setState({ viewMode: false })}
														className="collapsed blue-btn"
														mode="primary">
														Bearbeiten
													</Button>
												) : (
													<>
														<Button onClick={this.resetFundsache} className="collapsed blue-btn" mode="primary">
															Abbrechen
														</Button>
														<Button onClick={this.saveFundsache} className="collapsed blue-btn" mode="primary">
															Speichern
														</Button>
													</>
												)}
											</div>
										</div>
									</div>
									<Form ref={this.fundsacheForm} className="content">
										<div className="pane summary">
											<h3>
												<i className="im im-info" /> Zusammenfassung
											</h3>
											<div>
												{viewMode && (
													<div className="property">
														<div className="label">id</div>
														<div className="value">{sf.id}</div>
													</div>
												)}
												<div className="property">
													<div className="label">Status</div>
													<div className="value">
														{viewMode ? (
															<>{statusMap.get(sf.fundsache.status)}</>
														) : (
															<Select
																required
																name="fundsache.status"
																value={sf.fundsache.status}
																options={statusOptions}
															/>
														)}
													</div>
												</div>
												<div className="property">
													<div className="label">Fundort</div>
													<div className="value">
														{viewMode ? (
															<>{sf.fundsache.location}</>
														) : (
															<Select
																required
																name="fundsache.locationID"
																value={sf.fundsache.locationID}
																options={locationOptions}
															/>
														)}
													</div>
												</div>
												<div className="property">
													<div className="label">Kategorie der Fundsache</div>
													<div className="value">
														{viewMode ? (
															<>{categoryMap.get(sf.fundsache.category)}</>
														) : (
															<Select
																required
																name="fundsache.category"
																value={sf.fundsache.category}
																options={categoryOptions}
															/>
														)}
													</div>
												</div>
												<div className="property">
													<div className="label">Fundumstände</div>
													<div className="value">
														{viewMode ? (
															<>{sf.fundsache.circumstances}</>
														) : (
															<Input
																required
																type="textarea"
																name="fundsache.circumstances"
																value={sf.fundsache.circumstances}
															/>
														)}
													</div>
												</div>
											</div>
										</div>
										<div className="pane person">
											<h3>
												<i className="im im-user-circle" /> Informationen zum Finder
											</h3>

											<div className="content">
												{!viewMode && (
													<div className="property">
														<div className="label">Finder bekannt</div>
														<div className="value">
															<Boolean
																name="externalUser"
																onChange={(external) => this.setState({ externalUser: external.value })}
																value={externalUser}
															/>
														</div>
													</div>
												)}
												{(viewMode || externalUser) && (
													<div className="split">
														<div>
															{!externalUser || viewMode ? (
																<>
																	{sf.personalData.firstName && (
																		<div className="property">
																			<span className="label">Vorname</span>
																			<span className="value">{sf.personalData.firstName}</span>
																		</div>
																	)}
																</>
															) : (
																<Input
																	name="personalData.firstName"
																	label="Vorname"
																	value={sf.personalData.firstName}
																/>
															)}
															{!externalUser || viewMode ? (
																<>
																	{sf.personalData.lastName && (
																		<div className="property">
																			<span className="label">Nachname</span>
																			<span className="value">{sf.personalData.lastName}</span>
																		</div>
																	)}
																</>
															) : (
																<Input
																	name="personalData.lastName"
																	label="Nachname"
																	value={sf.personalData.lastName}
																/>
															)}
															{!externalUser || viewMode ? (
																<>
																	{sf.personalData.email && (
																		<div className="property">
																			<span className="label">E-Mail</span>
																			<span className="value">
																				<a href={`mailto:${sf.personalData.email}`}>
																					{sf.personalData.email}
																				</a>
																			</span>
																		</div>
																	)}
																</>
															) : (
																<Input
																	name="personalData.email"
																	type="email"
																	label="E-Mail Adresse"
																	value={sf.personalData.email}
																/>
															)}
															{!externalUser || viewMode ? (
																<>
																	{sf.personalData.phone && (
																		<div className="property">
																			<span className="label">Telefonnummer</span>
																			<span className="value">
																				<a href={`tel:${sf.personalData.phone}`}>
																					{sf.personalData.phone}
																				</a>
																			</span>
																		</div>
																	)}
																</>
															) : (
																<Input
																	name="personalData.phone"
																	type="tel"
																	label="Telefonnummer"
																	value={sf.personalData.phone}
																/>
															)}
														</div>

														<div>
															{!externalUser || viewMode ? (
																<>
																	{sf.personalData.street && (
																		<div className="property">
																			<span className="label">Straße</span>
																			<span className="value">{sf.personalData.street}</span>
																		</div>
																	)}
																</>
															) : (
																<Input
																	name="personalData.street"
																	label="Straße"
																	value={sf.personalData.street}
																/>
															)}
															{!externalUser || viewMode ? (
																<>
																	{sf.personalData.houseNumber && (
																		<div className="property">
																			<span className="label">Hausnummer</span>
																			<span className="value">{sf.personalData.houseNumber}</span>
																		</div>
																	)}
																</>
															) : (
																<Input
																	name="personalData.houseNumber"
																	label="Hausnummer"
																	value={sf.personalData.houseNumber}
																/>
															)}
															{!externalUser || viewMode ? (
																<>
																	{sf.personalData.plz && (
																		<div className="property">
																			<span className="label">Postleitzahl</span>
																			<span className="value">{sf.personalData.plz}</span>
																		</div>
																	)}
																</>
															) : (
																<Input
																	name="personalData.plz"
																	label="Postleitzahl"
																	value={sf.personalData.plz}
																/>
															)}

															{!externalUser || viewMode ? (
																<>
																	{sf.personalData.city && (
																		<div className="property">
																			<span className="label">Ort</span>
																			<span className="value">{sf.personalData.city}</span>
																		</div>
																	)}
																</>
															) : (
																<Input name="personalData.city" label="Ort" value={sf.personalData.city} />
															)}
														</div>
													</div>
												)}
											</div>
										</div>

										<div className="pane images">
											<h3>
												<i className="im im-photo-camera" /> Bilder
											</h3>
											<ImageCollection
												ref={this.imageCollection}
												onImageDelete={this.imageDelete}
												editMode={!viewMode}
												images={sf.pictures}
											/>
										</div>

										<div className="pane header">
											<div className="header-childs">
												<div className="split">
													<div className="person">
														{ReactUtils.getUserIcon(
															sf.personalData.imageURL,
															sf.personalData.firstName,
															sf.personalData.lastName
														)}
													</div>
													<div>
														<div className="label">Eingetragen von</div>
														<div className="value">{name}</div>
													</div>
												</div>
												<div className="split">
													<div className="icon-wrapper">
														<i className="im im-date-o" />
													</div>
													<div>
														<div className="label">Erstellt am</div>
														<div className="value">
															{AppUtils.firstToUpper(moment(sf.fundsache.admin_created).calendar())}
														</div>
													</div>
												</div>
											</div>
										</div>
									</Form>
								</>
							)}
						</div>
					</Card>
				</div>
			</>
		);
	}
}

export default Fundsachen;
