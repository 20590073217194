/* eslint-disable no-extend-native */
import Convert from "./Convert";

declare global {
	export interface Number {
		toPrice(): string;
	}
}
export const initPrototype = () => {
	// eslint-disable-next-line func-names
	Number.prototype.toPrice = function () {
		return Convert.numberToPrice(this as any);
	};
};
