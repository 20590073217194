import React, { Component } from "react";
import "./PoolAbsences.sass";
import { TimeCalendar, Card, Select, MediaRender } from "@onedash/tools";
import { ValueLabelPair } from "@onedash/tools/dist/ToolTypes";
import Action from "../../Utils/Redux/Action";
import Backend from "../../Utils/Backend/Backend";
import Notify from "../../Utils/Notify";
import Toolbar from "../../Pages/Dashboard/Toolbar/Toolbar";

interface PoolAbsencesProps {}

const baseWorkingSchema = {
	numberOfDays: 7,
	workingDays: [
		{
			days: [0, 1, 2, 3, 4, 5, 6],
			startingHour: 6,
			startingMinute: 0,
			endHour: 22,
			endMinute: 0,
		},
	],
};

class PoolAbsences extends Component<PoolAbsencesProps> {
	state = {
		poolData: undefined as PoolData | undefined,
		selectedPool: undefined as SwimmingPool | undefined,
		appointments: [] as any[],
	};

	componentDidMount() {
		this.loadPoolData();
	}

	loadPoolData = async () => {
		const poolData = await Backend.get<PoolData>("/vg/corona");
		this.setState(
			{
				poolData: poolData.data,
			},
			() => {
				if (poolData.data.pools.length === 1) {
					this.loadSlots({ value: poolData.data.pools[0].id, name: poolData.data.pools[0].name });
				}
			}
		);

		Action.tabLoaded();
	};

	loadSlots = async (obj: { value: string; name: string }) => {
		const poolID = obj.value;
		const timeslots = this.state.poolData?.pools.find((x) => x.id === obj.value);
		if (!timeslots) return;
		const appointments: {
			timestamp_from: number;
			timestamp_to: number;
			type: string;
		}[] = [];
		const absences = await Backend.get<Absence[]>(`/base/absences/pool?objectID=${poolID}`);
		absences.data.forEach((ab) => {
			appointments.push({
				timestamp_from: ab.from,
				timestamp_to: ab.to,
				type: "out-of-office",
			});
		});
		Object.keys(timeslots.slots).forEach((dayName) => {
			const slots = timeslots.slots[dayName];
			slots.forEach((s) => {
				if (appointments.find((x) => x.timestamp_from === s.from && x.timestamp_to === s.to)) return;
				appointments.push({
					timestamp_from: s.from,
					timestamp_to: s.to,
					type: "appointment",
				});
			});
		});

		this.setState((s: any) => ({
			appointments,
			selectedPool: s.poolData?.pools.find((x: any) => x.id === poolID),
		}));
	};

	addAbsence = (appointment: any) => {
		const data = {
			absenceType: "pool",
			objectID: this.state.selectedPool?.id,
			from: appointment.timestamp_from,
			to: appointment.timestamp_to,
		};

		Backend.post(`/base/absences/pool`, data).catch((ex) => {
			Notify.e(ex.data);
		});
	};

	removeAbsence = (appointment: any) => {
		if (appointment.type === "appointment") {
			const { appointments } = this.state;
			appointments.push({
				timestamp_from: appointment.timestamp_from,
				timestamp_to: appointment.timestamp_to,
				type: "out-of-office",
			});
			this.setState({ appointments });
			this.addAbsence(appointment);

			return;
		}

		const data = {
			absenceType: "pool",
			objectID: this.state.selectedPool?.id,
			from: appointment.timestamp_from,
			to: appointment.timestamp_to,
		};
		Backend.delete(`/base/absences/pool`, data).catch((ex) => {
			Notify.e(ex.data);
		});
	};

	render() {
		const { selectedPool, poolData, appointments } = this.state;
		const pools: ValueLabelPair[] = [];
		poolData?.pools?.forEach((pool) => {
			pools.push({
				label: pool.name,
				value: pool.id,
			});
		});

		const promptText = (
			<>
				<p>Wollen Sie den Termin wirklich löschen?</p>
				<p>Wenn Sie einen &quot;grünen&quot; Eintrag löschen, dann erhalten alle Besucher automatisch eine Stornierungsemail. </p>
			</>
		);

		return (
			<>
				<MediaRender type="desktop" size={1000}>
					<Toolbar>
						<Select
							onChange={this.loadSlots}
							options={pools}
							value={selectedPool?.id}
							name="poolID"
							placeholder="Wählen Sie eine Einrichtung"
							required
						/>
					</Toolbar>
					<Card maxWidth={"100%" as any} className="pool-absences">
						{!selectedPool && (
							<div className="center-center">
								<h1 className="info-text">Wählen Sie eine Einrichtung in der Toolbar</h1>
							</div>
						)}
						{selectedPool && (
							<>
								<div className="error-info-box">
									<h1>Warnung: Ändern der Öffnungszeiten</h1>
									<p>
										Wenn Sie die Einrichtung für einen Termin schließen, an dem bereits Buchungen vorliegen, werden alle
										Buchungen für diesen Termin storniert und alle Besucher erhalten eine Stornierungsmail. Bitte
										verwenden Sie dieses Feature nur, wenn die Einrichtung geschlossen werden soll.
									</p>
								</div>
								<TimeCalendar
									startDate={new Date().getTime()}
									appointments={appointments}
									showWeekend
									addAppointment={this.addAbsence}
									removeAppointment={this.removeAbsence}
									workingSchema={baseWorkingSchema}
									slotMinutes={30}
									removeAppointmentPromptText={promptText as any}
									removeAppointmentTitle="Einrichtung schließen / öffnen"
								/>
							</>
						)}
					</Card>
				</MediaRender>
				<MediaRender type="mobile" size={1000}>
					<div className="center-center">
						<Card>
							<div className="error-info-box">
								<p>Bitte verwenden Sie ein Endgerät mit großem Bildschirm, um die Öffnungszeiten anzupassen.</p>
							</div>
						</Card>
					</div>
				</MediaRender>
			</>
		);
	}
}

export default PoolAbsences;
