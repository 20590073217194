import React from "react";
import moment from "moment";
import { Card, Spinner } from "@onedash/tools";
import { Form, Input, Toggle, NativeSelect, DatePicker } from "onedash-react-input-form";
import DayPicker, { DateUtils } from "react-day-picker";
import InfoBox from "../../Components/Misc/InfoBox";
import Backend from "../../Utils/Backend/Backend";
import Notify from "../../Utils/Notify";
import FileDownloader from "../../Utils/Backend/FileDownloader";
import { FlyFishingPrices } from "./FlyFishingTypes";
import "react-day-picker/lib/style.css";
import "./FlyFishing.sass";
import {
	FlyFishingDocumentFragment,
	FlyFishingDocumentsDocument,
	FlyFishingRoutes,
	FlyFishingTicketInput,
	useCreateFlyfishingDocumentMutation,
} from "../../Resources/generated/gql-types";

const initialState = {
	withGuestCard: false,
	prices: undefined as undefined | FlyFishingPrices,
	totalPrice: 0,
	values: undefined as undefined | any,
	variant: undefined as undefined | "tagesschein" | "wochenschein" | "jahresschein",
	lowerRouteDates: [] as Date[],
	upperRouteDates: [] as Date[],
	price: 0 as number,
};
const FlyFishingForm = () => {
	const [state, update] = React.useState(initialState);
	const [createFlyfishingDocument] = useCreateFlyfishingDocumentMutation({
		onError: (err) => Notify.e(err.message),
		context: {
			headers: {
				"app-id": Backend.getAppID(),
			},
		},
		update: (c, r) => {
			const created = r.data?.createFlyFishingForm;
			const data = c.readQuery<{ flyFishingDocument: FlyFishingDocumentFragment[] }>({
				query: FlyFishingDocumentsDocument,
			});

			c.writeQuery({
				query: FlyFishingDocumentsDocument,
				data: { flyFishingDocument: [...(data?.flyFishingDocument ?? []), created] },
			});
		},
	});

	const loadPrices = async () => {
		const res = await Backend.get<FlyFishingPrices>("appSettings/flyFishingPrices");
		update((s) => ({ ...s, prices: res.data }));
	};

	React.useEffect(() => {
		loadPrices();
	}, []);

	const checkValidation = () => {
		return true;
	};

	const submitForm = (values: any, form: Form) => {
		const { language, lowerRouteAmount, upperRouteAmount, dateFrom } = values;
		delete values.language;
		delete values.lowerRouteAmount;
		delete values.upperRouteAmount;
		delete values.dateFrom;

		if (!values.country) {
			values.country = "Deutschland";
		}
		let tickets: FlyFishingTicketInput[] = [];
		if (state.variant === "tagesschein") {
			tickets = [
				...state.lowerRouteDates.map((date) => ({ route: FlyFishingRoutes.LowerRoute, dateFrom: date })),
				...state.upperRouteDates.map((date) => ({ route: FlyFishingRoutes.UpperRoute, dateFrom: date })),
			];
			tickets.sort((x, y) => moment(x.dateFrom).diff(y.dateFrom));
		} else {
			tickets = [];
			if (lowerRouteAmount) tickets.push({ dateFrom, route: FlyFishingRoutes.LowerRoute });
			if (upperRouteAmount) tickets.push({ dateFrom, route: FlyFishingRoutes.UpperRoute });
		}

		createFlyfishingDocument({
			variables: {
				form: {
					...values,
					withGuestCard: state.withGuestCard,
					tickets,
				},
			},
		}).then((res) => {
			form.resetForm();
			update((s) => ({ ...initialState, prices: s.prices }));
			const createdID = res.data?.createFlyFishingForm.id;
			if (createdID) FileDownloader.openPDF(Backend.buildPath(`/vg/flyfishing/pdfExport/${createdID}/${language}`));
		});
	};

	const onFormChange = (values: {
		withGuestCard: boolean;
		variant: "tagesschein" | "wochenschein" | "jahresschein";
		lowerRouteAmount: number;
		upperRouteAmount: number;
	}) => {
		if (typeof values.lowerRouteAmount === "boolean") {
			values.lowerRouteAmount = values.lowerRouteAmount ? 1 : 0;
			values.upperRouteAmount = values.upperRouteAmount ? 1 : 0;
		}

		const { prices } = state;
		const price: number = prices?.[values.withGuestCard ? "mit" : "ohne"]?.[values.variant] ?? 0;
		let totalPrice = 0;
		if (values.variant === "jahresschein" && !values.withGuestCard && prices?.ohne.jahresschein_onePrice) {
			totalPrice = price;
		} else if (values.variant === "tagesschein") {
			totalPrice = price * state.lowerRouteDates.length + price * state.upperRouteDates.length;
		} else {
			totalPrice = price * ((values.lowerRouteAmount ? 1 : 0) + (values.upperRouteAmount ? 1 : 0));
		}
		update((s) => ({ ...s, values, totalPrice, variant: values.variant, price }));
	};

	const onWithGuestCardChange = (withGuestCard: any) => {
		update((s) => ({ ...s, withGuestCard }));
	};

	const handleDayClick = (d: "lowerRouteDates" | "upperRouteDates", day: Date, { selected }: any) => {
		const selectedDays = state[d].concat();
		if (selected) {
			const selectedIndex = selectedDays.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay, day));
			selectedDays.splice(selectedIndex, 1);
		} else {
			selectedDays.push(day);
		}
		const price: number = state.prices?.[state.withGuestCard ? "mit" : "ohne"]?.tagesschein ?? 0;
		let totalPrice = 0;
		if (d === "lowerRouteDates") {
			totalPrice = price * selectedDays.length + price * state.upperRouteDates.length;
		} else {
			totalPrice = price * selectedDays.length + price * state.lowerRouteDates.length;
		}

		update((s) => ({ ...s, [d]: selectedDays, totalPrice }));
	};

	const { withGuestCard, prices, totalPrice } = state;

	if (!prices) return <Spinner defaultVisible />;
	const p = prices[withGuestCard ? "mit" : "ohne"];

	const variants = [
		{ label: `Tagesschein (${p.tagesschein.toPrice()})`, value: "tagesschein" },
		{ label: `Wochenschein  (${p.wochenschein.toPrice()})`, value: "wochenschein" },
	];
	if (!withGuestCard) variants.push({ label: `Jahresschein (${prices.ohne.jahresschein.toPrice()})`, value: "jahresschein" });

	return (
		<Card className="fly-fishing-form" title="Formular zum Fliegenfischen">
			<InfoBox>Ein Erlaubnisschein ist nur mit einem gültigen Fischereischein gültig.</InfoBox>
			<Form
				validateOnSubmit
				onChange={onFormChange}
				onValidate={checkValidation}
				onSubmit={submitForm}
				submitText="Erlaubnisschein ausstellen">
				<h3>Angaben zur Person</h3>
				<div className="grid">
					<Input icon={<i className="im im-users" />} name="name" required label="Name der Antragsperson" />
					<Input icon={<i className="im im-direction" />} name="street" required label="Straße" />
					<Input icon={<i className="im im-apartment" />} name="city" required label="Wohnort" />
					<Input icon={<i className="im im-map-o" />} name="country" label="Land" />
					<Input icon={<i className="im im-home" />} name="lodging" label="Unterkunft" />
				</div>

				<div style={{ marginTop: "20px" }}>
					<Toggle
						icon={<i className="im im-gift-card" />}
						className="full-width"
						onChange={onWithGuestCardChange}
						name="withGuestCard">
						{withGuestCard && (
							<>
								<span className="highlight bold">Mit Gästekarte:</span> Nur in Kombination mit Couponheft des GLV und der
								MoselEifel
							</>
						)}
						{!withGuestCard && (
							<>
								<span className="highlight bold">Ohne Gästekarte:</span> Ausschließlich für Manderscheider Bürger und
								Zweitwohnungsinhaber
							</>
						)}
					</Toggle>
				</div>

				<h3>Variantenauswahl</h3>

				<div className="grid">
					<NativeSelect
						icon={<i className="im im-reset" />}
						required
						placeholder="Wählen Sie die Art der Erlaubnis"
						name="variant"
						label="Art"
						options={variants}
					/>
					<NativeSelect
						icon={<i className="im im-language" />}
						label="Sprache"
						name="language"
						required
						placeholder="Wählen Sie eine Sprache"
						options={[
							{ value: "german", label: "Deutsch" },
							{ value: "netherlands", label: "Niederländisch" },
						]}
					/>
					{(state.variant === "jahresschein" || state.variant === "wochenschein") && (
						<DatePicker
							icon={<i className="im im-calendar" />}
							placeholder=""
							langKey="de"
							required
							name="dateFrom"
							label="Startdatum"
						/>
					)}
					{(state.variant === "jahresschein" || state.variant === "wochenschein") && (
						<div style={{ gridColumn: "1/3" }}>
							<Toggle
								label="Untere Strecke: 'Burgenstrecke'"
								name="lowerRouteAmount"
								icon={<i className="im im-arrow-down" />}
							/>

							<Toggle
								label="Obere Strecke: 'Herrenaustrecke'"
								name="upperRouteAmount"
								icon={<i className="im im-arrow-up" />}
							/>
						</div>
					)}
				</div>
				<div className="date-selection">
					{state.variant === "tagesschein" && (
						<div className="grid">
							<div>
								<h3>Obere Strecke: Herrenaustrecke</h3>
								<DayPicker
									selectedDays={state.upperRouteDates.map((s) => new Date(s))}
									onDayClick={(d, e) => handleDayClick("upperRouteDates", d, e)}
								/>
							</div>
							<div>
								<h3>Untere Strecke: Burgenstrecke</h3>
								<DayPicker
									selectedDays={state.lowerRouteDates.map((s) => new Date(s))}
									onDayClick={(d, e) => handleDayClick("lowerRouteDates", d, e)}
								/>
							</div>
						</div>
					)}
				</div>

				<p className="bold">
					Abrechnungsbetrag: <span className="italic">{totalPrice.toPrice()}</span>
				</p>
			</Form>
		</Card>
	);
};

export default FlyFishingForm;
