import { DatePicker, Form, Input, NativeSelect, Toggle, VerificationCode } from "onedash-react-input-form";
import React from "react";
import Backend from "../../../Utils/Backend/Backend";
import ButtonSpinner from "../Components/ButtonSpinner";
import MiniPage, { MiniPageProps } from "../MiniPage";
import ContactUsImage from "../Images/contact-us.svg";
import GaestebeitragData from "../../../Apps/Gaestebeitrag/GaestebeitragData";

interface CompanyInformationType {
	accessCode?: string;
	user: {
		firstName: string;
		lastName: string;
	};
	company: {
		companyName: string;
		phone: string;
		street: string;
		houseNumber: string;
		mailing_name: string;
		mailing_street: string;
		mailing_houseNumber: string;
		mailing_location: string;
		estDate?: number;
	};
}

interface CompanyInformationProps extends MiniPageProps {
	locations: Location[];
	onDataChange: (information: CompanyInformationType) => void;
}

class CompanyInformation extends MiniPage<CompanyInformationProps> {
	form = React.createRef<Form>();

	state = {
		otherLocation: false,
		valid: false,
		accessCode: undefined as undefined | string,
		isCodeChecking: false,
		codeIsValid: false,
		alreadyExistsStep: 0,
		alreadyExisted: true,
	};

	updateAddress = (otherLocation?: boolean | null) => {
		this.setState({ otherLocation }, () => {
			this.form.current?.validateSubmitBtn();
		});
	};

	saveData = () => {
		const data: CompanyInformationType = this.form.current?.getData();
		data.accessCode = (data.accessCode as any)?.join("");

		if (!data.company.estDate) {
			data.company.estDate = undefined;
		}

		this.props.onDataChange(data);
		this.nextStep(data.accessCode ? 2 : 1);
	};

	onCodeChange = (code?: string[] | null) => {
		const verificationInput: any = this.form.current?.getRef("accessCode")?.ref;

		const accessCode = !code ? undefined : code.join("");
		this.setState({ accessCode }, () => {
			if (accessCode && accessCode.length === 6) {
				this.setState({ isCodeChecking: true });
				Backend.get(`/vg/lodgings/validateCode/${accessCode}`)
					.then(() => {
						this.setState({ isCodeChecking: false, codeIsValid: true }, () => this.form.current?.validateSubmitBtn());
					})
					.catch(() => {
						verificationInput?.reset();
						verificationInput?.setInvalid();
						this.setState({ isCodeChecking: false, codeIsValid: false }, () => this.form.current?.validateSubmitBtn());
					});
			} else {
				this.setState({ codeIsValid: false }, () => this.form.current?.validateSubmitBtn());
			}
			this.form.current?.validateSubmitBtn();
		});
	};

	postValidate = () => {
		if (!this.state.accessCode || this.state.accessCode.length === 0) return true;
		return this.state.codeIsValid;
	};

	render() {
		const { otherLocation, valid, accessCode, isCodeChecking, alreadyExistsStep } = this.state;
		const locations = this.props.locations.map((l) => {
			return {
				label: l.name,
				value: l.id,
			};
		});
		const isRequired = !(accessCode && accessCode?.length > 0) && !this.state.alreadyExisted;
		return (
			<>
				{alreadyExistsStep === 1 && (
					<div className="company-information">
						<Form
							validateOnSubmit
							onValidate={this.postValidate}
							onValidationUpdate={(v) => this.setState({ valid: v })}
							ref={this.form}>
							<div className="entry-form">
								<div>
									<h2>Allgemeine Angaben</h2>
									<div className="split-input-50">
										<Input
											label="Vorname"
											placeholder="Geben Sie hier Ihren Namen ein"
											name="user.firstName"
											required
											autoComplete="given-name"
										/>
										<Input
											label="Nachname"
											placeholder="Geben Sie hier Ihren Namen ein"
											name="user.lastName"
											required
											autoComplete="family-name"
										/>
									</div>
									<Input
										label="Name des Unternehmens"
										placeholder="Geben Sie den Namen des Unternehmens ein"
										name="company.companyName"
										required={isRequired}
										disabled={!isRequired}
										minLength={2}
										autoComplete="organization"
									/>
									<Input
										placeholder="Telefonnummer des Unternehmens"
										label="Telefonnummer"
										name="company.phone"
										required={isRequired}
										disabled={!isRequired}
										type="tel"
										autoComplete="tel"
									/>
									<DatePicker
										langKey="de"
										name="company.estDate"
										placeholder="Geben Sie das Gründungsdatum des Unternehmens an"
										label="Gründungsdatum (Wenn nach 1.1.2021)"
									/>

									<Toggle
										disabled={!isRequired}
										onChange={this.updateAddress}
										name="_"
										label="Abweichende Postanschrift"
									/>
								</div>
								<div>
									<h2>Anschrift</h2>
									<div className="street-house-number">
										<Input
											label="Straße"
											placeholder="Geben Sie hier den Straßennamen ein"
											name="company.street"
											required={isRequired}
											disabled={!isRequired}
											minLength={2}
											autoComplete="off"
										/>
										<Input
											label="Hausnummer"
											autoComplete="off"
											name="company.houseNumber"
											required={isRequired}
											disabled={!isRequired}
											maxLength={4}
										/>
									</div>

									<NativeSelect
										name="company.locationID"
										options={locations}
										required={isRequired}
										disabled={!isRequired}
										placeholder="Wählen Sie den Ort"
									/>

									{otherLocation && (
										<div className="mailing-address">
											<h2>Postanschrift</h2>
											<Input
												label="Name des Empfängers"
												placeholder="Geben Sie hier den Namen ein"
												name="company.mailing_name"
												required={isRequired}
												disabled={!isRequired}
												minLength={2}
												autoComplete="name"
											/>
											<div className="street-house-number">
												<Input
													label="Straße"
													placeholder="Geben Sie hier den Straßennamen ein"
													name="company.mailing_street"
													required={isRequired}
													disabled={!isRequired}
													minLength={2}
													autoComplete="address-line1"
												/>
												<Input
													label="Hausnummer"
													autoComplete="address-line2"
													name="company.mailing_houseNumber"
													required={isRequired}
													disabled={!isRequired}
													maxLength={4}
												/>
											</div>

											<Input
												label="Postleitzahl und Ort"
												placeholder="Geben Sie PLZ und Ort an"
												name="company.mailing_location"
												required={isRequired}
												disabled={!isRequired}
											/>
										</div>
									)}
								</div>

								<div>
									<div className="img" />
									<div className="transfer-code">
										{this.state.alreadyExisted && (
											<>
												<p style={{ marginBottom: "0px" }}>
													Bitte geben Sie hier Ihren <span className="orange bold">Transfer - Code</span> ein :
												</p>
												<VerificationCode
													onChange={this.onCodeChange}
													loadingSpinner={<ButtonSpinner />}
													name="accessCode"
													codeLength={6}
													disabled={isCodeChecking}
													onFocus={() => this.setState({ errorText: undefined })}
												/>
											</>
										)}
									</div>
									<div>
										<button
											disabled={!valid || (!this.state.accessCode && this.state.alreadyExisted)}
											style={{ margin: "0px" }}
											className="btn"
											onClick={this.saveData}>
											Beriebsdaten übermitteln
										</button>
									</div>
								</div>
							</div>
						</Form>
					</div>
				)}
				{alreadyExistsStep === 0 && (
					<div className="company-exists-contact">
						<div>
							<h2>Fordern Sie Ihren Transfercode an</h2>
							<h3>
								Damit Sie Ihr Unternehmen verwalten können, benötigen Sie einen Transfercode. Bitte kontaktieren Sie dafür
								den folgenden Sachbearbeiter:
							</h3>
							<p className="contact-data">
								{GaestebeitragData.contact.name} <br />
								{GaestebeitragData.contact.street}
								<br />
								{GaestebeitragData.contact.city}
								<br />
								<br />
								<span className="bold">Telefon:</span>{" "}
								<a className="highlight" href={GaestebeitragData.contact.phone}>
									{GaestebeitragData.contact.phoneFormat}
								</a>
								<br />
								<span className="bold">Email:</span>{" "}
								<a className="highlight" href={`mailto:${GaestebeitragData.contact.mail}`}>
									{GaestebeitragData.contact.mail}
								</a>
							</p>

							<button onClick={() => this.setState({ alreadyExistsStep: 1 })} className="btn">
								Der Access-Code liegt vor
							</button>
						</div>
						<div>
							<img alt="Kontakieren Sie uns" src={ContactUsImage} />
						</div>
					</div>
				)}
			</>
		);
	}
}

export default CompanyInformation;
