/* eslint-disable no-underscore-dangle */
import dayjs from "dayjs";
import { DatePicker, Form, Input, NativeSelect, Toggle } from "onedash-react-input-form";
import React from "react";
import AppUtils from "../../Utils/AppUtils";
import { dayTexts, frequencySelectOptions } from "../../Utils/CalendarUtils";
import { CreationEvent } from "./TimeCalendarTypes";

interface EditDetailsProps<T extends {}> {
	eventId?: string;
	event?: CreationEvent<T>;
	isNew: boolean;
	updateEventData: (values?: any, valid?: boolean) => void;
	eventName: string;
	allowReccurentTimeslots?: boolean;
	allowMultiDay?: boolean;
	allowTimerange?: boolean;
	allowPast?: boolean;
	editEventComponent?: React.ReactChild;
}
const EditDetails = <T extends {}>({
	eventId,
	event,
	isNew,
	updateEventData,
	allowReccurentTimeslots,
	allowMultiDay,
	allowTimerange,
	eventName,
	editEventComponent,
	allowPast,
}: EditDetailsProps<T>) => {
	const formRef = React.useRef<Form>();
	const [state, update] = React.useState({
		fromDate: undefined,
		toDate: undefined,
		repeat: false,
		repeatFrequency: "D",
		repeatInterval: 1,
		days: [],
		monthRepeat: "same-date",
		location: undefined,
		capacity: undefined,
	} as {
		repeat?: boolean;
		repeatFrequency?: string;
		repeatInterval?: number;
		days: string[];
		fromDate: undefined | number;
		toDate: undefined | number;
		monthRepeat: "same-date" | "same-day";
		location?: Location;
		capacity?: number;
	});

	React.useEffect(() => {
		if (eventId === "new") {
			formRef.current?.resetForm();
		}
	}, [eventId]);

	const d = dayjs(state.fromDate);

	const onFormChange = (values: any, _?: Form, valid?: boolean) => {
		const { repeatFrequency, repeatInterval, days } = state;
		if (repeatFrequency && repeatInterval) {
			let rule = `${repeatFrequency}+${repeatInterval}`;
			if (days) {
				rule += `+${days.join(",")}`;
			}

			AppUtils.setObjectValue("timeslot.periodRule", values, rule);
		}
		updateEventData(values, valid);
	};
	const toggleDay = (day: string) => {
		const { days } = state;
		const index = days.indexOf(day);
		if (index === -1) {
			days.push(day);
		} else {
			days.splice(index, 1);
		}
		update((s) => ({ ...s, days }));
		onFormChange(formRef.current?.getData(), formRef.current, formRef.current?.validateInputs(false));
	};

	return (
		<div className="edit-details">
			<h1>{isNew ? `${eventName} anlegen` : `${eventName} bearbeiten`}</h1>
			<Form ref={formRef as any} onChange={onFormChange} className="edit-content">
				<div className="section">
					<h2>Zeitraum</h2>
					<div className={allowMultiDay ? "split" : ""}>
						<DatePicker
							value={event?._creation.dateFrom}
							langKey="de"
							placeholder={allowMultiDay ? "Wählen Sie das Startdatum" : "Wählen Sie ein Datum"}
							numberOfMonths={1}
							name="_creation.dateFrom"
							label={allowMultiDay ? "Startdatum" : "Datum"}
							required
							onChange={(val) => {
								update((s) => ({ ...s, date: val ?? undefined }));
							}}
							minDate={allowPast ? undefined : dayjs()}
							icon={<i className="im im-calendar" />}
						/>
						{allowMultiDay && (
							<DatePicker
								value={event?._creation.dateTo}
								langKey="de"
								placeholder="Wählen Sie ein Enddatum"
								numberOfMonths={1}
								name="_creation.dateTo"
								label="Enddatum"
								minDate={(event as any)?._creation?.dateFrom ? dayjs((event as any)?._creation?.dateFrom) : undefined}
								onChange={(val) => {
									update((s) => ({ ...s, date: val ?? undefined }));
								}}
								icon={<i className="im im-calendar" />}
							/>
						)}
					</div>
					{allowTimerange && (
						<>
							<div className="item">
								<p className="label">Uhrzeit von:</p>
								<div className="split">
									<Input
										required
										type="tel"
										settings={{
											allowNumberNull: false,
											allowNumberNegative: false,
											validateTel: false,
										}}
										pattern="^$|^[0-9]+$"
										placeholder="Stunde"
										name="_creation.fromHour"
										maxLength={2}
										onValidate={(val) => (val ? Number(val) >= 0 && Number(val) < 24 : false)}
										icon={<i className="im im-clock-o" />}
									/>
									<Input
										required
										type="tel"
										settings={{
											allowNumberNull: false,
											allowNumberNegative: false,
											validateTel: false,
										}}
										pattern="^$|^[0-9]+$"
										placeholder="Minute"
										name="_creation.fromMinute"
										maxLength={2}
										onValidate={(val) => (val ? Number(val) >= 0 && Number(val) < 60 : false)}
										icon={<i className="im im-timer" />}
									/>
								</div>
							</div>
							<div className="item">
								<p className="label">Uhrzeit bis:</p>
								<div className="split">
									<Input
										required
										type="tel"
										settings={{
											allowNumberNull: false,
											allowNumberNegative: false,
											validateTel: false,
										}}
										pattern="^$|^[0-9]+$"
										placeholder="Stunde"
										name="_creation.toHour"
										maxLength={2}
										onValidate={(val) => (val ? Number(val) >= 0 && Number(val) < 24 : false)}
										icon={<i className="im im-clock-o" />}
									/>
									<Input
										required
										type="tel"
										settings={{
											allowNumberNull: false,
											allowNumberNegative: false,
											validateTel: false,
										}}
										pattern="^$|^[0-9]+$"
										placeholder="Minute"
										name="_creation.toMinute"
										maxLength={2}
										onValidate={(val) => (val ? Number(val) >= 0 && Number(val) < 60 : false)}
										icon={<i className="im im-timer" />}
									/>
								</div>
							</div>
						</>
					)}

					{allowReccurentTimeslots && (
						<>
							<h3>Wiederholung</h3>
							<Toggle
								name="repeat"
								value={state.repeat}
								onChange={(r) => update((s) => ({ ...s, repeat: r === undefined ? false : r ?? undefined }))}
								icon={<i className="im im-history" />}
							/>
						</>
					)}

					{state.repeat && (
						<>
							<div className="item">
								<p className="label">Wiederholen alle:</p>
								<div className="split">
									<Input
										type="tel"
										settings={{
											allowNumberNull: false,
											allowNumberNegative: false,
											validateTel: false,
										}}
										required
										pattern="^$|^[0-9]+$"
										name="_creation.interval"
										maxLength={2}
										onValidate={(val) => (val ? Number(val) >= 0 && Number(val) < 60 : false)}
										onChange={(v) => update((s) => ({ ...s, repeatInterval: v ? Number(v) : undefined }))}
										value={String(state.repeatInterval)}
									/>
									<NativeSelect
										value={state.repeatFrequency}
										onChange={(v) => {
											const days: string[] = [];
											if (v === "W" && state.fromDate) {
												days.push(dayjs(state.fromDate).locale("en").format("dd"));
											}
											update((s) => ({ ...s, days, repeatFrequency: v }));
										}}
										required
										placeholder="Frequenz"
										options={frequencySelectOptions(state.repeatInterval ? state.repeatInterval > 1 : false)}
										name="_creation.frequency"
									/>
								</div>
							</div>
							{state.repeatFrequency === "W" && (
								<div className="day-selection">
									<p className="label">Wählen Sie die Tage</p>
									<div className="btns">
										<button onClick={() => toggleDay("Mo")} className={state.days.includes("Mo") ? "active" : ""}>
											Mo
										</button>
										<button onClick={() => toggleDay("Tu")} className={state.days.includes("Tu") ? "active" : ""}>
											Di
										</button>
										<button onClick={() => toggleDay("We")} className={state.days.includes("We") ? "active" : ""}>
											Mi
										</button>
										<button onClick={() => toggleDay("Th")} className={state.days.includes("Th") ? "active" : ""}>
											Do
										</button>
										<button onClick={() => toggleDay("Fr")} className={state.days.includes("Fr") ? "active" : ""}>
											Fr
										</button>
										<button onClick={() => toggleDay("Sa")} className={state.days.includes("Sa") ? "active" : ""}>
											Sa
										</button>
										<button onClick={() => toggleDay("Su")} className={state.days.includes("Su") ? "active" : ""}>
											So
										</button>
									</div>
								</div>
							)}
							{state.repeatFrequency === "M" && state.fromDate && (
								<div className="month-selection">
									<NativeSelect
										name="_"
										required
										value={state.monthRepeat}
										options={[
											{ label: `Monatlich am ${d.date()}.`, value: "same-date" },
											{
												label: `Monatlich am ersten ${dayTexts[d.locale("en").format("dd")]}.`,
												value: "same-day",
											},
										]}
										onChange={(v) => {
											const days: string[] = [];
											if (v === "same-day") {
												days.push(d.locale("en").format("dd"));
											}
											update((s) => ({ ...s, days }));
										}}
									/>
								</div>
							)}
							<DatePicker
								langKey="de"
								placeholder="Enddatum"
								numberOfMonths={1}
								value={event?.timeslot?.periodTo ? dayjs(event?.timeslot?.periodTo).timestamp() : undefined}
								name="timeslot.periodTo"
								icon={<i className="im im-calendar" />}
							/>
						</>
					)}
				</div>
				{editEventComponent}
			</Form>
		</div>
	);
};

export default EditDetails;
