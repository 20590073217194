export const APPLICATION_HEADERS: TableHeader[] = [
	{
		columnName: "appId",
		required: true,
		title: "ID",
		type: "text",
	},
	{
		columnName: "appName",
		required: true,
		title: "Name",
		type: "text",
	},
	{
		columnName: "appType",
		required: true,
		title: "Type",
		type: "select",
		inputData: [
			{
				label: "Gastronomie",
				value: "gastro",
			},
			{
				label: "Verbandsgemeinde",
				value: "vg",
			},
			{
				label: "APP",
				value: "app",
			},
			{
				label: "Webseite",
				value: "website",
			},
		],
	},
	{
		columnName: "databaseName",
		required: true,
		title: "Datenbank",
		visible: false,
		type: "text",
	},
];
