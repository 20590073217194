export default class Action {
	/**
	 * Access token has been loaded
	 */
	public static accessTokenLoaded() {
		window.reduxStore.dispatch({ type: "ACCESS_TOKEN_LOADED" });
	}

	/**
	 * Page or component loads currently data.
	 */
	public static contentIsLoading = () => {
		window.reduxStore.dispatch({ type: "NAVIGATION_LOADING_ON" });
	};

	/**
	 * Update is available for application
	 */
	public static updateAvailable = () => {
		window.reduxStore.dispatch({ type: "UPDATE_AVAILABLE" });
	};

	/**
	 * Application Offline
	 */
	public static appIsOffline = () => {
		window.reduxStore.dispatch({ type: "APPLICATION_OFFLINE" });
	};

	/**
	 * Application installable
	 */
	public static isInstallable = () => {
		window.reduxStore.dispatch({ type: "APPLICATION_INSTALLABLE" });
	};

	/**
	 * Application Online
	 */
	public static appIsOnline = () => {
		window.reduxStore.dispatch({ type: "APPLICATION_ONLINE" });
	};

	/**
	 * Loading process of Page or component finished.
	 */
	public static tabLoaded = (timeout = 500) => {
		setTimeout(() => {
			window.reduxStore.dispatch({ type: "TAB_LOADED" });
		}, timeout);
	};

	/**
	 * Loading process of Page or component finished.
	 */
	public static tabSwitched = () => {
		window.reduxStore.dispatch({ type: "TAB_SWITCHED" });
		Action.hideSidebar();
		Action.hasToolbar(false);
	};

	/**
	 * Shows the sidebar
	 */
	public static showSidebar = () => {
		window.reduxStore.dispatch({ type: "SIDEBAR", hasSidebar: true });
	};

	/**
	 * Hides the sidebar
	 */
	public static hideSidebar = () => {
		window.reduxStore.dispatch({ type: "SIDEBAR", hasSidebar: false });
	};

	/**
	 * Loading process of Page or component finished.
	 */
	public static applicationError = (errorMessage: string) => {
		window.reduxStore.dispatch({ type: "APPLICATION_ERROR", message: errorMessage });
	};

	/**
	 * Theme Mode change
	 */
	public static darkLightChange = (mode: "light" | "dark") => {
		window.reduxStore.dispatch({ type: "DARK_LIGHT_CHANGE", mode });
	};

	/**
	 * Has Toolbar
	 */
	public static hasToolbar = (hasToolbar: boolean) => {
		window.reduxStore.dispatch({ type: "HAS_TOOLBAR", hasToolbar });
	};

	public static triggerEvent = (eventName: string, eventValue?: any) => {
		window.reduxStore.dispatch({ type: "TRIGGER_EVENT", eventName, eventValue });
	};
}
