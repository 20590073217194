import * as React from "react";
import { Spinner } from "@onedash/tools";
import Backend from "../../../Utils/Backend/Backend";

export interface ImpressProps {}

class About extends React.Component {
	state = {
		version: undefined as undefined | string,
	};

	componentDidMount() {
		// Load API Version
		Backend.get<{ version: string }>("/version", false).then((x) => {
			this.setState({
				version: x.data.version,
			});
		});
	}

	render() {
		return (
			<div className="about-text">
				<table>
					<thead>
						<tr>
							<th>Komponente</th>
							<th>Version</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Main API</td>
							<td>{this.state.version ?? <Spinner defaultVisible />}</td>
						</tr>
					</tbody>
				</table>

				<div className="footer">
					<p>
						Copyright ©{new Date().getFullYear()} by <a href="https://onedash.de">OneDash.</a>
					</p>
					<p>All Rights Reserved</p>
				</div>
			</div>
		);
	}
}

export default About;
