/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
import { onError } from "@apollo/client/link/error";

const ApolloError = onError(({ response, graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.map(({ message, locations, path }) =>
			console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
		);

	if (networkError) console.log(`[Network error]: ${networkError}`);

	if (response) response.errors = undefined;
});
export default ApolloError;
