/* eslint-disable no-underscore-dangle */
const ENV = {
	auth0: {
		domain: String(window._env.REACT_APP_AUTH0_DOMAIN),
		client: String(window._env.REACT_APP_AUTH0_CLIENT_ID),
		audience: String(window._env.REACT_APP_AUTH0_AUDIENCE),
		callback: String(window._env.REACT_APP_AUTH0_CALLBACK_URL),
	},
	default: {
		apiURL: String(window._env.REACT_APP_API_URL),
		gqlURL: String(window._env.REACT_APP_GQL_URL),
		authFree: String(window._env.REACT_APP_AUTH_FREE),
		subPages: String(window._env.REACT_APP_SUB_PAGES),
	},
	serverStatus: {
		url: String(window._env.REACT_APP_STATUS_URL),
		id: String(window._env.REACT_APP_STATUS_ID),
	},

	gastroAPP: {
		url: String(window._env.REACT_APP_GASTRO_APP_URL),
	},
};

export default ENV;
