import React from "react";

export default class ReactUtils {
	public static getUserIcon = (imageURL?: string | null, firstName?: string | null, lastName?: string | null) => (
		<div className={!imageURL ? "user-icon initials" : "user-icon"}>
			{imageURL && <img src={imageURL} alt="User Icon" />}
			{!imageURL && (
				<>
					{firstName && lastName ? (
						<span>
							{firstName[0].toUpperCase()}
							{lastName[0].toUpperCase()}
						</span>
					) : (
						<span>XX</span>
					)}
				</>
			)}
		</div>
	);
}
