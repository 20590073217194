import * as React from "react";

export interface ImpressProps {}

const Impress: React.SFC<ImpressProps> = () => {
	return (
		<div className="about-text">
			<h2>OneDash.</h2>
			<h3>Verantwortliche Person</h3>
			<p>
				Christian Bußhoff <br />
				Burgstraße 19 <br />
				54470 <br />
				Bernkastel - Kues
			</p>
			<h3>Kontakt</h3>
			<p>
				Telefon: +49 160 98739688 <br />
				Telefax: 06531 971129 <br />
				E-Mail: <a href="mailto:info@onedash.de">info@onedash.de</a>
			</p>

			<h2>Angaben zur Berufs&shy;haft&shy;pflicht&shy;versicherung</h2>
			<h3>Name und Sitz des Versicherers:</h3>
			<p>
				Markel Insurance SE
				<br />
				Sophienstr. 26
				<br />
				80333 München
				<br />
				Registergericht: Amtsgericht München Handelsregisternummer: HRB 233618
			</p>
			<h3>Geltungsraum der Versicherung:</h3>
			<p>Deutschland</p>

			<h2>Haftungsausschluss</h2>
			<h3>Haftung für Inhalte</h3>
			<p>
				Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
				verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte
				fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
				Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon
				unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
				möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
			</p>
			<h3>Haftung für Links</h3>
			<p>
				Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir
				für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige
				Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
				Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
				inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
				Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
			</p>
			<h3>Urheberrecht</h3>
			<p>
				Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die
				Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
				schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten,
				nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
				Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
				Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
				Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
			</p>
		</div>
	);
};

export default Impress;
