/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import "./List.sass";
import ListItem from "./ListItem";

interface GroupedListProps {
	children: JSX.Element | JSX.Element[];
}
interface GroupProps {
	label: string;
	showCount?: boolean;
	defaultCollapsed?: boolean;
}

const GroupedList = ({ children }: GroupedListProps) => <div className="grouped-list">{children}</div>;
export default GroupedList;

export class Group extends Component<GroupProps> {
	state = {
		collapsed: this.props.defaultCollapsed ?? false,
	};

	toggleCollapsed = () => {
		this.setState((s: any) => ({ collapsed: !s.collapsed }));
	};

	render() {
		const { label, showCount } = this.props;

		let count = 0;
		const items: any[] = [];
		React.Children.forEach(this.props.children, (child: any) => {
			if (!child || typeof child !== "object") return;
			if (child?.type === ListItem) {
				count++;
				items.push(child);
			}
		});

		return (
			<div className={this.state.collapsed ? "group collapsed" : "group"}>
				<div className="header" onClick={this.toggleCollapsed}>
					<i className="im im-angle-down" />
					<h2>
						{label} <span>({showCount && count})</span>
					</h2>
				</div>
				<div className="items">{items}</div>
			</div>
		);
	}
}
