import React, { Component } from "react";
import { Dialog, DarkLightModeSwitch } from "@onedash/tools";
import Impress from "./Impress";
import Privacy from "./Privacy";
import About from "./About";
import ProfilePage from "./ProfilePage";

import Action from "../../../Utils/Redux/Action";
import Customization from "../../../Utils/Customization";
import "./ProfileDropdown.scss";

interface ProfileDropdownProps {
	user: Profile;
}

class ProfileDropdown extends Component<ProfileDropdownProps> {
	switch = React.createRef<DarkLightModeSwitch>();

	impressDialog = React.createRef<Dialog>();

	privacyDialog = React.createRef<Dialog>();

	aboutDialog = React.createRef<Dialog>();

	profileDialog = React.createRef<Dialog>();

	state = {
		dropDown: false,
		colorTheme: "light" as "light" | "dark",
		updateAvailable: false,
	};

	componentDidMount() {
		this.loadColorTheme();

		window.reduxStore.subscribe(() => {
			const data = window.reduxStore.getState();
			if (data.updateAvailable) {
				this.setState({ updateAvailable: true });
			}
		});
	}

	loadColorTheme = async () => {
		const colorTheme = await Customization.getUserPreference("colorTheme");
		Action.darkLightChange(colorTheme ?? "light");
		this.setState({
			colorTheme,
		});
	};

	openDialog = (ref: React.RefObject<Dialog>) => {
		if (ref.current) {
			ref.current.show();
		}
	};

	colorThemeChange = (mode: "light" | "dark") => {
		Customization.saveUserPreference("colorTheme", mode);
		Action.darkLightChange(mode);
	};

	render() {
		const profile = this.props.user;
		return (
			<>
				<div className="dropdown profile-dropdown">
					<button onClick={() => this.setState((s: any) => ({ dropDown: !s.dropDown }))} className="user-profile">
						{profile && (
							<>
								<div className="left">
									<img src={profile.picture} alt={profile.name} />
								</div>
								<div className="right">
									<p className="name">{profile.name}</p>
									<p className="email">{profile.email}</p>
									{this.state.updateAvailable && <p className="update-available">Update verfügbar</p>}
								</div>
								<div className={this.state.dropDown === true ? "icon shown" : "icon"}>
									<i className="im im-care-down" />
								</div>
							</>
						)}
					</button>
					<div className={this.state.dropDown === true ? "dropdown-content show" : "dropdown-content"}>
						<ul>
							<li>
								<p>Farbtheme</p>
								<DarkLightModeSwitch mode={this.state.colorTheme} onModeChange={this.colorThemeChange} cookie />
							</li>
							<li>
								<button onClick={() => this.openDialog(this.profileDialog)}>Ihr Profil</button>
							</li>
							<li>
								<button onClick={() => this.openDialog(this.impressDialog)}>Impressum</button>
							</li>
							<li>
								<button onClick={() => this.openDialog(this.privacyDialog)}>Datenschutzerklärung</button>
							</li>
							<li>
								<a
									style={{ color: "var(--dark)" }}
									href="https://o-d.link/support"
									target="_blank"
									rel="noopener noreferrer">
									Hilfe & Support
								</a>
							</li>
							<li>
								<button onClick={() => this.openDialog(this.aboutDialog)}>About</button>
							</li>
							{this.state.updateAvailable && (
								<li>
									<button onClick={() => window.location.reload(true)}>App Updaten</button>
								</li>
							)}
						</ul>
					</div>
				</div>
				<Dialog buttons={[]} ref={this.impressDialog} title="Impressum">
					<Impress />
				</Dialog>
				<Dialog buttons={[]} ref={this.privacyDialog} title="Datenschutzerklärung">
					<Privacy />
				</Dialog>
				<Dialog buttons={[]} settings={{ maxWidth: 400, showX: false }} ref={this.aboutDialog} title="OneDash. Dashboard">
					<About />
				</Dialog>

				<Dialog buttons={[]} ref={this.profileDialog} settings={{ showX: false, maxWidth: 450 }}>
					<ProfilePage />
				</Dialog>
			</>
		);
	}
}

export default ProfileDropdown;
