import React from "react";
import { authClient, logoutUser } from "../../../Auth/AuthProvider";
import OneDashLogo from "../../../Components/Logo/OneDashLogo";
import BrowserHistory from "../../../Utils/BrowserHistory";
import MiniPage, { MiniPageProps } from "../MiniPage";

interface GaestebeitragRegisterResponseProps extends MiniPageProps {
	succeeded: boolean;
}

class GaestebeitragRegisterResponse extends MiniPage<GaestebeitragRegisterResponseProps> {
	redirectToLogin = async () => {
		if (await authClient.isAuthenticated()) {
			logoutUser();
		} else {
			BrowserHistory.push("/login");
		}
	};

	render() {
		const { succeeded } = this.props;
		return (
			<div className="gaestebeitrag-response">
				<div className="left" />
				<div className="right">
					<OneDashLogo />
					<div style={{ display: "flex", alignItems: "center" }}>
						{succeeded && (
							<div>
								<h2>Account erfolgreich erstellt</h2>
								<h3>Ihr Account wurde erfolgreich erstellt. Bitte melden Sie sich nun mit ihren Daten an um loszulegen.</h3>

								<button className="btn" onClick={this.redirectToLogin}>
									Zum Login
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default GaestebeitragRegisterResponse;
