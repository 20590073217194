import React, { Component } from "react";
import "./Tischuebersicht.scss";
import { Popover, Select, Form, Card } from "@onedash/tools";
import Backend from "../../Utils/Backend/Backend";
import Action from "../../Utils/Redux/Action";
import ENV from "../../Utils/ENV";

interface TischuebersichtProps {}
const INTERVAL = 10000;
class Tischuebersicht extends Component<TischuebersichtProps> {
	interval: number | undefined;

	timeout: number | undefined;

	popover = React.createRef<Popover>();

	formRef = React.createRef<Form>();

	selectedRoomIndex: number | undefined;

	state = {
		rooms: [] as GastroRoom[],
		selectedRoom: undefined as undefined | GastroRoom,
		toTable: undefined as undefined | GastroTable,
		fromTable: undefined as undefined | GastroTable,
		closeable: false,
	};

	componentDidMount() {
		this.loadTables();
		this.interval = setInterval(this.loadTables, INTERVAL) as any;
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	loadTables = async () => {
		// Get all possible rooms
		const rooms = (await Backend.get<GastroRoom[]>("/appSettings/gastroRooms")).data;
		await Promise.all(
			rooms.map(async (room) => {
				const tables = (await Backend.get<GastroTable[]>(`/gastro/corona/${room.id}`)).data;
				room.tables = tables;
			})
		);
		this.setState({ rooms });
		Action.tabLoaded();
	};

	onTableClick = (selectedTable: GastroTable, room: GastroRoom) => {
		if (selectedTable.status === "free") {
			window.open(`${ENV.gastroAPP.url}?appID=${Backend.getAppID()}&roomID=${room?.id}&table=${selectedTable?.table}&external=true`);
		} else {
			Backend.put(`/gastro/corona/${room.id}`, { table: selectedTable.table }).then(this.loadTables);
		}
	};

	showPopover = () => {
		this.setState((s: any) => ({ closeable: false, selectedRoom: s.rooms[this.selectedRoomIndex ?? 0] }));

		setTimeout(() => {
			this.setState({ closeable: true });
		}, 500);
		this.popover.current?.show();
	};

	mouseDown = (table: GastroTable, roomIndex: number) => {
		if (table.status === "free") return;
		this.selectedRoomIndex = roomIndex;
		this.setState({ fromTable: table });
		this.timeout = setTimeout(this.showPopover, 300) as any;
	};

	mouseUp = () => {
		clearTimeout(this.timeout);
	};

	updateTable = (data: any) => {
		Backend.patch(`/gastro/corona/${this.state.fromTable?.room?.id}`, {
			fromTable: this.state.fromTable?.table,
			toTable: data.table,
			toRoom: this.state.selectedRoom?.id,
		}).then(() => {
			this.loadTables();
			this.popover.current?.close();
		});
	};

	render() {
		const { rooms, selectedRoom, closeable } = this.state;

		return (
			<>
				<div className="table-overview">
					{rooms.map((room, i) => (
						<Card maxWidth={900} className="room" key={room.id}>
							{rooms.length > 1 && (
								<h1>
									{Backend.getApp()?.appName} - {room.name}
								</h1>
							)}
							<div className="tables">
								{room.tables.map((table) => (
									<button
										key={table.name}
										onClick={() => this.onTableClick(table, room)}
										onMouseDown={() => this.mouseDown(table, i)}
										onMouseUp={this.mouseUp}
										onTouchStart={() => this.mouseDown(table, i)}
										onTouchEnd={this.mouseUp}
										className={table.status === "blocked" ? "blocked table" : "table"}>
										<h2>
											{table.name} {table.people > 0 && <>({table.people})</>}
										</h2>

										{table.locations &&
											table.locations.length > 0 &&
											[...new Set(table.locations)].map((location) => (
												<p className="people-location" key={location}>
													{location}
												</p>
											))}
									</button>
								))}
							</div>
						</Card>
					))}
				</div>

				<Popover className="change-places" closeable={closeable} ref={this.popover}>
					<Form ref={this.formRef} validateOnChange onSubmit={this.updateTable} submitText="Tisch umsetzen">
						{rooms.length > 1 && (
							<Select
								name="room"
								options={rooms.map((x) => {
									return { label: x.name, value: x.id };
								})}
								value={selectedRoom?.id}
								onChange={(obj) => {
									this.setState({ selectedRoom: rooms.find((x) => x.id === obj.value), toTable: undefined });
									setTimeout(() => this.formRef.current?.validateSubmitBtn(), 100);
								}}
								native
								required
								label="Wählen Sie den neuen Raum"
							/>
						)}
						{selectedRoom && (
							<Select
								name="table"
								native
								required
								label="Wählen Sie den neuen Tisch"
								options={selectedRoom.tables
									.filter((x) => x.status === "free")
									.map((x) => {
										return {
											label: x.name,
											value: x.table,
										};
									})}
								value={this.state.toTable?.table}
								onChange={(obj) => this.setState({ toTable: selectedRoom.tables.find((x) => x.table === obj.value) })}
							/>
						)}
					</Form>
				</Popover>
			</>
		);
	}
}

export default Tischuebersicht;
