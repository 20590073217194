import { Card, Spinner } from "@onedash/tools";
import { usePrompt } from "onedash-dialog";
import { Form, Input, Numeric, Textarea } from "onedash-react-input-form";
import { Cell, Column, Row, Table } from "onedash-react-table";
import React from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import HasObjectAccess from "../../Auth/HasObjectAccess";
import UserSelectionDialog from "../../Components/Misc/UserSelectionDialog";
import ButtonSpinner from "../../Pages/MiniPages/Components/ButtonSpinner";
import {
	AccessModes,
	Department,
	DepartmentsDocument,
	DepartmentService,
	namedOperations,
	ObjectAccessTable,
	TableType,
	useAssignDepartmentServiceClerkMutation,
	useCreateDepartmentMutation,
	useCreateDepartmentServiceMutation,
	useDeassignDepartmentServiceClerkMutation,
	useDeleteDepartmentMutation,
	useDeleteDepartmentServiceMutation,
	useDepartmentsQuery,
	User,
	useUpdateDepartmentMutation,
	useUpdateDepartmentServiceMutation,
} from "../../Resources/generated/gql-types";
import Backend from "../../Utils/Backend/Backend";
import "./BaseData.scss";

const initialState = {
	valid: false,
	changed: false,
	form: undefined as undefined | Form,
	service: undefined as undefined | DepartmentService,
	department: undefined as undefined | Department,
	userSelectionOpen: false as boolean,
	currentSelectedUsers: [] as User[],
};

const DepartmentsPage = () => {
	const { push } = useHistory();
	const { departmentId } = useParams<{ departmentId?: string }>();
	const [state, update] = React.useState(initialState);
	const { yesNoPrompt } = usePrompt();
	const basePath = `${window.location.pathname.split("departments")[0]}departments`;

	const { data: departmentsData, loading } = useDepartmentsQuery({
		context: {
			headers: {
				"app-id": Backend.getAppID(),
			},
		},
	});

	const [createDepartmentMutation, { loading: createDepartmentLoading }] = useCreateDepartmentMutation({
		context: {
			headers: {
				"app-id": Backend.getAppID(),
			},
		},
		update: (c, r) => {
			const created = r.data?.createDepartment;
			const data = c.readQuery<{ departments: Department[] }>({
				query: DepartmentsDocument,
			});

			c.writeQuery({
				query: DepartmentsDocument,
				data: { departments: [...(data?.departments ?? []), created] },
			});
			push(`${basePath}/${r.data?.createDepartment.id}`);
		},
	});

	const [updateDepartmentMutation, { loading: updateDepartmentLoading }] = useUpdateDepartmentMutation({
		context: {
			headers: {
				"app-id": Backend.getAppID(),
			},
		},
	});
	const [deleteDepartmentMutation, { loading: deleteDepartmentLoading }] = useDeleteDepartmentMutation({
		context: {
			headers: {
				"app-id": Backend.getAppID(),
			},
		},
	});

	const [createDepartmentServiceMutation, { loading: createServiceLoading }] = useCreateDepartmentServiceMutation({
		context: {
			headers: {
				"app-id": Backend.getAppID(),
			},
		},
		update: (c, r) => {
			const created = r.data?.createDepartmentService;
			const data = c.readQuery<{ departments: Department[] }>({
				query: DepartmentsDocument,
			});
			if (!data) return;
			let dep: any = data.departments.find((x) => x.id === created?.departmentID);
			if (dep) {
				dep = JSON.parse(JSON.stringify(dep));
				dep.services.unshift(created as any);
			}
			c.writeQuery({
				query: DepartmentsDocument,
				data: { departments: [...data.departments.filter((x) => x.id !== created?.departmentID), dep] },
			});
		},
	});
	const [updateDepartmentServiceMutation, { loading: updateServiceLoading }] = useUpdateDepartmentServiceMutation({
		context: {
			headers: {
				"app-id": Backend.getAppID(),
			},
		},
	});
	const [assignDepartmentServiceClerkMutation] = useAssignDepartmentServiceClerkMutation({
		context: {
			headers: {
				"app-id": Backend.getAppID(),
			},
		},
		refetchQueries: [namedOperations.Query.departments],
	});
	const [deassignDepartmentServiceClerkMutation] = useDeassignDepartmentServiceClerkMutation({
		context: {
			headers: {
				"app-id": Backend.getAppID(),
			},
		},
		refetchQueries: [namedOperations.Query.departments],
	});

	const [deleteDepartmentServiceMutation, { loading: deleteServiceLoading }] = useDeleteDepartmentServiceMutation({
		context: {
			headers: {
				"app-id": Backend.getAppID(),
			},
		},
	});

	const updateLoading =
		updateServiceLoading ||
		createServiceLoading ||
		deleteServiceLoading ||
		createDepartmentLoading ||
		updateDepartmentLoading ||
		deleteDepartmentLoading;

	const onSelectDepartment = (_index: number | undefined, row: Department) => {
		push(`${basePath}/${row.id}`);
	};

	const departments = departmentsData?.departments;
	const department = departments?.find((x) => x.id === departmentId);

	const selectService = async (service: any) => {
		if (state.changed) {
			const yes = await yesNoPrompt("Änderungen verwerfen?", "Wollen Sie die Änderungen verwerfen?");
			if (!yes) return;
			state.form?.loadDefaultValues();
		}

		update({ ...initialState, service });
	};

	const onServiceChange = (values: any, form: Form, valid: boolean) => {
		update((s) => ({
			...s,
			userSelectionOpen: false,
			valid,
			changed: true,
			department: undefined,
			form,
			service: { ...s.service, ...values },
		}));
	};
	const onDepartmentChange = (values: any, form: Form, valid: boolean) => {
		update((s) => ({
			...s,
			userSelectionOpen: false,
			valid,
			changed: true,
			department: { ...s.department, ...values },
			form,
			service: undefined,
		}));
	};

	const onDepartmentServiceSave = async () => {
		if (!state.valid || !state.service || !departmentId) return;
		const { service } = state;

		if (state.service.id === "new") {
			await createDepartmentServiceMutation({
				variables: {
					departmentID: departmentId,
					service: {
						duration: service.duration,
						name: service.name,
						additional: service.additional,
					},
				},
			});
		} else {
			await updateDepartmentServiceMutation({
				variables: {
					id: service.id,
					service: {
						duration: service.duration,
						name: service.name,
						additional: service.additional,
					},
				},
			});
		}
		update(initialState);
	};

	const onDepartmentSave = async () => {
		if (!state.valid || !state.department || !departmentId) return;
		const { department: dep } = state;

		if (departmentId === "new") {
			await createDepartmentMutation({
				variables: {
					department: {
						name: dep.name,
						description: dep.description,
					},
				},
			});
		} else {
			await updateDepartmentMutation({
				variables: {
					id: departmentId,
					department: {
						name: dep.name,
						description: dep.description,
					},
				},
			});
		}
		update(initialState);
	};

	const onNewDepartmentService = () => {
		if (!departmentId) return;
		const service: DepartmentService = {
			duration: 0,
			name: "Neuer Service",
			id: "new",
			departmentID: departmentId,
		};
		update((s) => ({ ...s, service }));
	};

	const onDeleteDepartment = () => {
		if (!departmentId) return;
		deleteDepartmentMutation({
			variables: {
				id: departmentId,
			},
			update: (c) => {
				const d = c.readQuery<{ departments: Department[] }>({
					query: DepartmentsDocument,
				});
				if (!d) return;
				c.writeQuery({
					query: DepartmentsDocument,
					data: { departments: [...d.departments.filter((x) => x.id !== departmentId)] },
				});
				push(basePath);
			},
		});
	};

	const onDeleteDepartmentService = (serviceID: string) => {
		deleteDepartmentServiceMutation({
			variables: {
				id: serviceID,
			},
			update: (c) => {
				const d = c.readQuery<{ departments: Department[] }>({
					query: DepartmentsDocument,
				});
				if (!d) return;
				let dep: any = d.departments.find((x) => x.services.find((y) => y.id === serviceID));
				if (dep) {
					dep = JSON.parse(JSON.stringify(dep));
					dep.services = dep.services.filter((x: any) => x.id !== serviceID);
				}
				c.writeQuery({
					query: DepartmentsDocument,
					data: { departments: [...d.departments.filter((x) => !x.services.find((y) => y.id === serviceID)), dep] },
				});
			},
		});
	};

	const selectDepartment = async (dep: any) => {
		if (state.changed) {
			const yes = await yesNoPrompt("Änderungen verwerfen?", "Wollen Sie die Änderungen verwerfen?");
			if (!yes) return;
			state.form?.loadDefaultValues();
		}

		update({ ...initialState, department: dep });
	};

	const onSaveNewUsers = () => {
		const { currentSelectedUsers, service } = state;
		if (!service?.id) return;
		currentSelectedUsers.forEach((user) => {
			if (!user.id) {
				return;
			}
			assignDepartmentServiceClerkMutation({
				variables: {
					serviceId: service?.id,
					userId: user.id,
				},
			});
		});

		update((s) => ({ ...s, currentSelectedUsers: [], userSelectionOpen: false, service: undefined }));
	};

	const services = department?.services ? [...department.services] : [];
	if (services && state.service?.id === "new") {
		services?.unshift(state.service as any);
	}

	return (
		<div className="departments-page">
			{(loading || !departments) && <Spinner defaultVisible />}
			{!loading && departments && (
				<Switch>
					<Route path={`${basePath}/:departmentId`}>
						<button onClick={() => push(basePath)} className="small-back-btn">
							<i className="im im-arrow-left" /> <span>Zurück zur Übersicht</span>
						</button>
						<div className="department-overview">
							<Card title="Abteilungsübersicht">
								<div className="split">
									<h3>{department?.name}</h3>
									{departmentId === state.department?.id || departmentId === "new" ? (
										<button disabled={!state.valid} onClick={onDepartmentSave} className="save-btn">
											<i className="im im-floppy-disk" />
										</button>
									) : (
										<div className="grid-split">
											{departmentId !== "new" ? (
												<button onClick={onDeleteDepartment}>
													<i className="im im-trash-can" />
												</button>
											) : (
												<span />
											)}
											<button onClick={() => selectDepartment(department)}>
												<i className="im im-pencil" />
											</button>
										</div>
									)}
								</div>
								<Form onChange={onDepartmentChange}>
									<div className="grid-split">
										<Input
											maxLength={50}
											minLength={5}
											required
											name="name"
											label="Name der Abteilung"
											value={department?.name}
											placeholder="Geben Sie hier den Namen der Abteilung an"
											disabled={state.department === undefined && departmentId !== "new"}
										/>
										<Input
											required
											minLength={5}
											maxLength={120}
											name="description"
											label="Abteilungsbeschreibung"
											value={department?.description}
											placeholder="Geben Sie hier eine kurze Beschreibung zur Abteilung an."
											disabled={state.department === undefined && departmentId !== "new"}
										/>
									</div>
								</Form>
							</Card>
							<Card title="Services">
								{departmentId !== "new" && (
									<button
										disabled={state.service?.id === "new"}
										onClick={onNewDepartmentService}
										className="btn highlight-btn">
										Service hinzufügen
									</button>
								)}
								{departmentId &&
									departmentId !== "new" &&
									services?.map((service) => (
										<Form onChange={onServiceChange} key={service.id} className="service">
											<div className="split">
												<h3>{service.name}</h3>
												{updateLoading ? (
													<ButtonSpinner />
												) : (
													<>
														{service.id === state.service?.id ? (
															<button
																disabled={!state.valid}
																onClick={onDepartmentServiceSave}
																className="save-btn">
																<i className="im im-floppy-disk" />
															</button>
														) : (
															<div className="grid-split">
																<button onClick={() => onDeleteDepartmentService(service.id)}>
																	<i className="im im-trash-can" />
																</button>
																<button onClick={() => selectService(service)}>
																	<i className="im im-pencil" />
																</button>
															</div>
														)}
													</>
												)}
											</div>
											<div className="grid-split">
												<Input
													maxLength={50}
													minLength={5}
													required
													name="name"
													label="Name des Services"
													defaultValue={service.name}
													disabled={service.id !== state.service?.id}
												/>
												<Numeric
													maxValue={1000}
													required
													name="duration"
													label="Dauer des Services"
													defaultValue={service.duration}
													icon={<>min</>}
													disabled={service.id !== state.service?.id}
												/>
											</div>

											<Textarea
												defaultValue={service.additional}
												name="additional"
												label="Zusätzliche informationen"
												placeholder={`Geben Sie hier weitere Informationen zu dem Service an.
Welche Unterlagen oder Formulare muss der Bürger mitbringen?`}
												className="full-width"
												rows={6}
												disabled={service.id !== state.service?.id}
											/>

											<HasObjectAccess
												mode={AccessModes.Rw}
												objectID={departmentId}
												tableName={ObjectAccessTable.Departments}
												tableType={TableType.Mysql}>
												<div className="split">
													<h3>Zugewiesene Benutzer</h3>
													<button
														className="btn highlight-btn"
														onClick={() =>
															update((s) => ({
																...s,
																service,
																userSelectionOpen: true,
																currentSelectedUsers: [],
															}))
														}>
														Sachbearbeiter hinzufügen
													</button>
												</div>
												<Table textNoRows="Keine Benutzer zugewiesen">
													<Column name="email" label="E-Mail" />
													<Column name="fullName" label="Accountname" />
													<Column name="_" label="" />
													{service.users?.map((user) => (
														<Row key={user.id} row={user}>
															<Cell name="email" />
															<Cell name="fullName" />
															<Cell name="_">
																<button
																	onClick={() =>
																		user.id
																			? deassignDepartmentServiceClerkMutation({
																					variables: { serviceId: service.id, userId: user.id },
																			  })
																			: () => {}
																	}
																	className="btn delete">
																	<i className="im im-x-mark" />
																</button>
															</Cell>
														</Row>
													))}
												</Table>
											</HasObjectAccess>
										</Form>
									))}
								{departmentId === "new" && (
									<p>Sie müssen die Abteilung erst erstellen, bevor Sie Services hinzufügen können.</p>
								)}
							</Card>
						</div>
					</Route>
					<Route path="/">
						<Card className="full-width">
							<button onClick={() => push(`${basePath}/new`)} className="btn highlight-btn">
								Abteilung anlegen
							</button>
							<Table select="click" rightIcon={<i className="im im-angle-right" />} onRowClick={onSelectDepartment}>
								<Column name="name" label="Name" />
								<Column name="description" label="Beschreibung" />
								<Column name="_serviceNum" label="Anzahl der Services" />

								{departments.map((dep) => (
									<Row key={dep.id} row={dep}>
										<Cell name="name" />
										<Cell name="description" />
										<Cell name="_serviceNum">{dep.services.length || "Kein Service angelegt"}</Cell>
									</Row>
								))}
							</Table>
						</Card>
					</Route>
				</Switch>
			)}
			<UserSelectionDialog
				onSave={onSaveNewUsers}
				isOpen={state.userSelectionOpen}
				onClose={() => update((s) => ({ ...s, service: undefined, userSelectionOpen: false }))}
				selectedUsers={state.currentSelectedUsers}
				addUser={(user) => update((s) => ({ ...s, currentSelectedUsers: [...s.currentSelectedUsers, user] }))}
				removeUser={(user) =>
					update((s) => ({ ...s, currentSelectedUsers: s.currentSelectedUsers.filter((x) => x.id !== user.id) }))
				}
				excluded={state.service?.users as any}
			/>
		</div>
	);
};

export default DepartmentsPage;
