import { Dialog } from "onedash-dialog";
import { Input } from "onedash-react-input-form";
import React from "react";
import { useFindUsersQuery, User } from "../../Resources/generated/gql-types";
import "./Misc.sass";

interface UserSelectionDialogProps {
	isOpen: boolean;
	onClose: () => void;
	selectedUsers: User[];
	addUser: (user: User) => void;
	removeUser: (user: User) => void;
	excluded?: User[];
	onSave: () => void;
}

const UserSelectionDialog = ({ isOpen, onClose, removeUser, addUser, onSave, selectedUsers, excluded }: UserSelectionDialogProps) => {
	const [usersState, updateData] = React.useState([] as User[]);
	const [identifier, update] = React.useState(undefined as undefined | null | string);
	const skip = !identifier || identifier.length < 3;
	const { data } = useFindUsersQuery({
		variables: {
			identifier: identifier as any,
		},
		skip,
	});

	React.useEffect(() => {
		if (data?.findUser || skip) {
			updateData(data?.findUser ?? []);
		}
	}, [data?.findUser, skip]);

	React.useEffect(() => {
		updateData([]);
		update("");
	}, [isOpen]);

	let users = usersState.filter((x) => !selectedUsers.find((y) => y.id === x.id));
	if (excluded) {
		users = users.filter((x) => !excluded.find((y) => y.id === x.id));
	}
	return (
		<Dialog className="user-selection-dialog" isOpen={isOpen} onClose={onClose}>
			<div className="split">
				<h1>Benutzer hinzufügen</h1>
				<button onClick={onSave} disabled={selectedUsers.length === 0} className="btn highlight-btn">
					Speichern
				</button>
			</div>
			<div className="selected-users-list">
				{selectedUsers.map((user) => (
					<div key={user.id} className="user-profile full-width">
						<div className="left">{user.imageURL && <img src={user.imageURL} alt={user.fullName ?? ""} />}</div>
						<div className="right">
							<p className="name">{user.fullName}</p>
							<p className="email">{user.email}</p>
						</div>
						<button className="remove-user-btn" onClick={() => removeUser(user)}>
							<i className="im im-x-mark" />
						</button>
					</div>
				))}
			</div>

			<Input
				icon={<i className="im im-magnifier" />}
				placeholder="Geben Sie den Namen, E-Mail Adresse oder die ID eines Benutzers ein"
				name="identifier"
				onChange={update}
				value={identifier}
				autoFocus
			/>
			{users.length > 0 && (
				<div className="user-recommendation">
					{users.map((user) => (
						<button
							onClick={() => {
								addUser(user);
								update("");
							}}
							key={user.id}
							className="user-profile without-icon">
							<div className="left">{user.imageURL && <img src={user.imageURL} alt={user.fullName ?? ""} />}</div>
							<div className="right">
								<p className="name">{user.fullName}</p>
								<p className="email">{user.email}</p>
							</div>
						</button>
					))}
				</div>
			)}
		</Dialog>
	);
};

export default UserSelectionDialog;
