/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
import React, { Component } from "react";
import "./Gaestebeitrag.sass";
import { Button, Card, Dialog, Spinner } from "@onedash/tools";
import { CustomSelect, DatePicker, Form, Input, NativeSelect, Toggle } from "onedash-react-input-form";
import { Cell, Column, Row, Table } from "onedash-react-table";
import moment from "moment";
import Toolbar from "../../Pages/Dashboard/Toolbar/Toolbar";
import Backend from "../../Utils/Backend/Backend";
import { Lodging, LodgingAccessItem } from "./GaestebeitragTypes";
import Notify from "../../Utils/Notify";
import HasPermission from "../../Auth/HasPermission";
import ApplicationLoggerDialog from "../../Components/Log/ApplicationLoggerDialog";
import { checkPermission } from "../../Auth/AuthProvider";

interface BetriebsinformationenProps {}

class Betriebsinformationen extends Component<BetriebsinformationenProps> {
	form = React.createRef<Form>();

	loggerDialog = React.createRef<Dialog>();

	state = {
		lodgings: [] as Lodging[],
		selectedLodging: undefined as undefined | Lodging,
		locations: [] as Location[],
		otherLocation: false,
		busy: false,
		logOffset: 0,
		logEntry: undefined as undefined | ApplicatationLogEntry,
		disable: true,
		// eslint-disable-next-line react/no-unused-state
		loadingError: undefined as undefined | string,
	};

	componentDidMount() {
		checkPermission("edit:AllGuestStats").then((x) => {
			this.setState({ disable: !x });
		});
		this.loadLodgings();
		this.loadLocations();
	}

	loadLocations = async () => {
		const locations = await Backend.get<Location[]>(`/masterData/location`);
		if (locations) this.setState({ locations: locations.data });
	};

	loadLodgings = async (id?: number) => {
		const lodgingsRes = await Backend.get<Lodging[]>("/vg/lodgings");
		// Remove duplicates
		const lodgings = lodgingsRes.data.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
		const selectedLodging = !id ? lodgings[0] : lodgings.find((x) => x.id === id);
		if (selectedLodging === undefined) {
			this.setState({
				// eslint-disable-next-line react/no-unused-state
				loadingError: "Sie haben aktuell keinen Betrieb zugeordnet. Bitte wenden Sie sich an den Systemandministrator.",
			});
			return;
		}

		this.selectLodging(selectedLodging, lodgings);
	};

	updateLodging = (values: Lodging) => {
		this.setState({ busy: true });
		const company: any = values;
		company.sepa = company.sepa ? 1 : 0;
		company.id = this.state.selectedLodging?.id as any;
		if (!values.closingDate) {
			company.closingDate = undefined;
		}
		if (!values.estDate) {
			company.estDate = undefined;
		}

		if (!this.state.otherLocation) {
			company.mailing_houseNumber = null;
			company.mailing_location = null;
			company.mailing_name = null;
			company.mailing_street = null;
		}
		if (!company.id) {
			Backend.post<{ id: number }>("/vg/lodgings", { company })
				.then((d) => {
					this.loadLodgings(d.data.id);
					Notify.s("Betrieb wurde erfolgreich erstellt");
				})
				.catch((ex) => Notify.e(ex.data))
				.finally(() => {
					setTimeout(() => {
						this.setState({ busy: false });
					}, 1500);
				});
		} else {
			Backend.put("/vg/lodgings", { company })
				.then(() => Notify.s("Betrieb wurde aktualisiert"))
				.catch((ex) => Notify.e(ex.data))
				.finally(() => {
					setTimeout(() => {
						this.setState({ busy: false });
					}, 1500);
				});
		}
	};

	deleteConnection = async (id: number) => {
		if (
			// eslint-disable-next-line no-alert
			confirm(
				"Wollen sie wirklich die Verbindung zwischen dem Nutzer und dem Betrieb trennen? \n\nDer Nutzer wird keine Gästeerklärungen mehr einsehen oder anlegen können."
			)
		) {
			await Backend.delete(`/vg/lodgings/access/${id}`);
			const { selectedLodging } = this.state;
			if (!selectedLodging) return;
			// Reload content
			this.changeLodging(selectedLodging.id);
		}
	};

	loadLodgingLog = async (lodgingId: number) => {
		const logEntries = await Backend.get<ApplicatationLogEntry[]>(
			`/vg/lodgings/${lodgingId}/log?limit=50&offset=${this.state.logOffset}`
		);
		return logEntries;
	};

	selectLodging = async (selectedLodging?: Lodging, lodgings?: Lodging[]) => {
		this.setState({ busy: true, logOffset: 0, selectedLodging: undefined });
		if (selectedLodging && selectedLodging.id && (await checkPermission("edit:AllGuestStats"))) {
			const [access, log] = await Promise.all([
				Backend.get<LodgingAccessItem[]>(`/vg/lodgings/access/${selectedLodging.id}`),
				this.loadLodgingLog(selectedLodging.id),
			]);

			selectedLodging.access = access.data;
			if (!selectedLodging.log) selectedLodging.log = [];
			selectedLodging.log.push(...log.data);

			// Assign tasks to subtasks
			selectedLodging.log.forEach((logEntry) => {
				if (!logEntry.parentId) return;
				const parentLog = selectedLodging.log?.find((x) => x.id === logEntry.parentId);
				if (parentLog) {
					if (!parentLog.subtasks) parentLog.subtasks = [];
					if (!parentLog.subtasks.find((x) => x.id === logEntry.id)) parentLog.subtasks.push(logEntry);
				}
			});
		}

		this.setState((s: any) => ({
			busy: false,
			lodgings: lodgings ?? s.lodgings,
			selectedLodging,
			otherLocation: selectedLodging?.mailing_name && selectedLodging.mailing_name.length > 0,
		}));
	};

	updateAddress = (otherLocation?: boolean | null) => {
		this.setState({ otherLocation }, () => {
			this.form.current?.validateSubmitBtn();
		});
	};

	createNewLodging = () => {
		const lodging: any = {
			log: [],
		};
		this.selectLodging(lodging);
	};

	changeLodging = (id: number) => {
		const lodging = this.state.lodgings.find((x) => x.id === id);
		if (lodging) {
			lodging.log = [];
			this.selectLodging(lodging);
		}
	};

	render() {
		const { lodgings, selectedLodging, otherLocation, busy, logEntry, disable } = this.state;
		const lodgingOptions = lodgings.map((l) => {
			return { label: l.companyName, value: l.id };
		});
		const locations = this.state.locations.map((l) => {
			return {
				label: l.name,
				value: l.id,
			};
		});

		return (
			<>
				{lodgings.length > 1 && (
					<Toolbar split>
						<div>
							<CustomSelect
								value={selectedLodging?.id}
								required
								name="lodging"
								placeholder="Wählen Sie einen Betrieb"
								options={lodgingOptions}
								onChange={this.changeLodging}
							/>
						</div>
						<div>
							<HasPermission permission="edit:AllGuestStats">
								<Button mode="light" disabled={busy} className="new-btn" onClick={this.createNewLodging}>
									Neuen Betrieb anlegen
								</Button>
							</HasPermission>
						</div>
					</Toolbar>
				)}
				<div className="betriebsinformationen">
					<Card maxWidth={600}>
						{!selectedLodging && <Spinner defaultVisible />}
						{selectedLodging && (
							<Form
								submitText={busy ? "Wird gespeichert ..." : "Änderungen Speichern"}
								validateOnSubmit
								onSubmit={this.updateLodging}
								ref={this.form}>
								<div className="entry-form">
									<div>
										<h3>Allgemeine Angaben</h3>

										<Input
											label="Name des Unternehmens"
											placeholder="Geben Sie den Namen des Unternehmens ein"
											name="companyName"
											required
											minLength={2}
											autoComplete="organization"
											value={selectedLodging?.companyName}
											disabled={disable}
										/>
										<Input
											placeholder="Telefonnummer des Unternehmens"
											label="Telefonnummer"
											name="phone"
											required
											type="tel"
											autoComplete="tel"
											value={selectedLodging?.phone}
										/>
										<Toggle
											onChange={this.updateAddress}
											value={otherLocation}
											name="_"
											label="Abweichende Postanschrift"
										/>
									</div>
									<div>
										<h3>Anschrift</h3>
										<div className="street-house-number">
											<Input
												label="Straße"
												placeholder="Geben Sie hier den Straßennamen ein"
												name="street"
												required
												minLength={2}
												autoComplete="off"
												value={selectedLodging?.street}
												disabled={disable}
											/>
											<Input
												label="Hausnummer"
												value={selectedLodging?.houseNumber}
												autoComplete="off"
												name="houseNumber"
												required
												maxLength={4}
												disabled={disable}
											/>
										</div>

										<NativeSelect
											label="Ort"
											name="locationID"
											value={selectedLodging?.locationID}
											options={locations}
											required
											placeholder="Wählen Sie den Ort"
											disabled={disable}
										/>
									</div>

									{otherLocation && (
										<div>
											<h3>Postanschrift</h3>
											<Input
												label="Name des Empfängers"
												placeholder="Geben Sie hier den Namen ein"
												name="mailing_name"
												minLength={2}
												autoComplete="name"
												value={selectedLodging?.mailing_name}
											/>
											<div className="street-house-number">
												<Input
													label="Straße"
													placeholder="Geben Sie hier den Straßennamen ein"
													name="mailing_street"
													minLength={2}
													autoComplete="address-line1"
													value={selectedLodging?.mailing_street}
												/>
												<Input
													label="Hausnummer"
													autoComplete="address-line2"
													name="mailing_houseNumber"
													maxLength={4}
													value={selectedLodging?.mailing_houseNumber}
												/>
											</div>

											<Input
												name="mailing_location"
												label="Postleitzahl und Ort"
												placeholder="Geben Sie Postleitzahl und Ort an"
												value={selectedLodging?.mailing_location}
											/>
										</div>
									)}
									<div>
										<h3>Weiteres</h3>
										<Input
											placeholder="Geben Sie die Anzahl der Betten ein"
											name="beds"
											required
											settings={{ allowNumberNull: false, allowNumberNegative: false, validateTel: false }}
											maxLength={4}
											type="tel"
											pattern="^$|^[0-9]+$"
											label="Bettenanzahl"
											value={String(selectedLodging?.beds ?? "")}
										/>
										<Input
											label="Kassenkonto"
											placeholder="Geben Sie das Kassenkonto an"
											name="cashAccount"
											maxLength={6}
											type="number"
											value={
												selectedLodging?.cashAccount && String(selectedLodging?.cashAccount) !== "null"
													? String(selectedLodging?.cashAccount)
													: undefined
											}
											disabled={disable}
										/>

										<HasPermission permission="edit:AllGuestStats">
											<DatePicker
												langKey="de"
												name="estDate"
												placeholder="Geben Sie das Gründungsdatum des Unternehmens an"
												label="Gründungsdatum"
												value={selectedLodging.estDate}
											/>
											<DatePicker
												langKey="de"
												name="closingDate"
												placeholder="Datum wann das Unternehmen endgültig geschlossen wurde"
												label="Schließdatum"
												value={selectedLodging.closingDate}
											/>
										</HasPermission>

										<Toggle
											disabled={disable}
											label="SEPA - Mandat erteilt"
											name="sepa"
											value={selectedLodging?.sepa === 1}
										/>
									</div>
								</div>
							</Form>
						)}
					</Card>
					<div>
						<HasPermission permission="edit:AllGuestStats">
							{selectedLodging?.accessCode ? (
								<Card title="Transfer Code">
									<p>
										Um einem Bürger die Möglichkeit zu bieten das Unternehmen nachträglich selbst zu verwalten, können
										Sie ihm den folgenden <span className="italic">Transfercode</span> mitteilen. Diesen kann er dann
										bei der Registrierung angeben, um Zugriff auf sein Unternehmen zu erhalten.
									</p>
									<p className="highlight bold">{selectedLodging.accessCode}</p>
								</Card>
							) : selectedLodging?.access && selectedLodging?.access.length > 0 ? (
								<Card title="Verbundene Accounts">
									{!selectedLodging && <Spinner defaultVisible />}
									{selectedLodging && (
										<>
											<p>
												Die folgenden Accounts besitzen Zugriff auf die Unternehmensdaten. Verwenden Sie den{" "}
												<span className="italic">Verbindung löschen</span> Button, wenn Sie einem Account den
												Zugriff enziehen möchten.
											</p>
											{selectedLodging?.access?.map((account, i) => (
												<li key={i as any} className="account">
													<span className="highlight bold">{account.name}</span> [{account.email}] (
													<button onClick={() => this.deleteConnection(account.id)} className="delete-btn">
														Verbindung löschen
													</button>
													)
												</li>
											))}
										</>
									)}
								</Card>
							) : (
								<></>
							)}
							<Card title="Änderungslog">
								{!selectedLodging && <Spinner defaultVisible />}
								{selectedLodging && (
									<Table
										onRowClick={(_, lEntry) => {
											this.setState({ logEntry: lEntry }, () => {
												this.loggerDialog.current?.show();
											});
										}}
										textNoRows="Aktuell noch keine Daten verfügbar"
										minWidth={550}
										select="click">
										<Column name="timestamp" width="100px" label="Datum" />
										<Column className="break-content" name="email" width="150px" label="E-Mail" />
										<Column name="description" label="Beschreibung" />
										{selectedLodging?.log
											?.filter((x) => x.parentId === null)
											.map((lEntry) => (
												<Row row={lEntry} key={lEntry.id}>
													<Cell name="timestamp" noValue="Unbekannt">
														{(timestamp) => moment(timestamp).fromNow()}
													</Cell>
													<Cell name="email" noValue="Unbekannt" />
													<Cell name="description" />
												</Row>
											))}
									</Table>
								)}
							</Card>
						</HasPermission>
					</div>
				</div>
				<ApplicationLoggerDialog dialogRef={this.loggerDialog} title="Details: Änderungslog" logEntry={logEntry} />
			</>
		);
	}
}

export default Betriebsinformationen;
