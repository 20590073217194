import React, { Component } from "react";
import "./Permissions.scss";
import { Table, Card } from "@onedash/tools";
import { TableHeader } from "@onedash/tools/dist/Table/TableTypes";
import Action from "../../Utils/Redux/Action";
import Backend from "../../Utils/Backend/Backend";

interface PermissionsProps {}
interface Permission {
	id: string;
	name: string;
	description: string;
	type: "default" | "admin";
}
class Permissions extends Component<PermissionsProps> {
	state = {
		permissions: [] as Permission[],
	};

	componentDidMount() {
		this.loadPermissions();
	}

	loadPermissions = async () => {
		const permissions = await Backend.get<Permission>("/admin/permissions", false);
		this.setState({ permissions: permissions.data });
		Action.tabLoaded();
	};

	onDeleteEntry = (item: any) => {
		this.deleteEntry(item);
	};

	deleteEntry = async (name: string) => {
		await Backend.delete(
			"/admin/permissions",
			{
				name,
			},
			false
		).then(() => {
			let { permissions } = this.state;
			permissions = permissions.filter((x) => x.name !== name);
			this.setState({ permissions });
		});
	};

	saveEntry = async (entry: any, isNewEntry: boolean) => {
		if (!isNewEntry) {
			await this.deleteEntry(entry.name);
		}

		Backend.post("/admin/permissions", entry, false).then(() => {
			const { permissions } = this.state;
			permissions.push(entry);
			this.setState({ permissions });
		});
	};

	render() {
		const { permissions } = this.state;
		const tableHeaders: TableHeader[] = [
			{
				columnName: "name",
				title: "Name",
				type: "text",
				required: true,
			},
			{
				columnName: "description",
				title: "Beschreibung",
				type: "text",
				required: true,
			},
			{
				columnName: "type",
				title: "Typ",
				type: "select",
				required: true,
				inputData: [
					{ value: "default", label: "Default" },
					{ value: "admin", label: "Admin" },
				],
			},
		];
		permissions.forEach((perm) => {
			perm.id = perm.name;
		});
		return (
			<Card className="full-width">
				<Table
					editable
					searchable
					className="full-height-table"
					tableHeaders={tableHeaders}
					tableValues={permissions}
					onSave={this.saveEntry}
					onDelete={this.onDeleteEntry}
				/>
			</Card>
		);
	}
}

export default Permissions;
