import TableHeaders from "./Configuration/TableHeaders";
import TicketScanner from "./VGCorona/TicketScanner";
import TicketTable from "./VGCorona/TicketTable";
import PoolAbsences from "./VGCorona/PoolAbsences";
import Tischuebersicht from "./CoronaGastro/Tischuebersicht";
import Applications from "./Configuration/Applications";
import Permissions from "./UserManagement/Permissions";
import Users from "./UserManagement/Users";
import ObjectAccess from "./UserManagement/ObjectAccess";
import Fundsachen from "./Fundsachen/Fundsachen";
import Gaestebeitragerfassung from "./Gaestebeitrag/Gaestebeitragerfassung";
import Gaestebeitragstatistik from "./Gaestebeitrag/Gaestebeitragstatistik";
import Monatsansicht from "./Gaestebeitrag/Monatsansicht";
import Betriebsinformationen from "./Gaestebeitrag/Betriebsinformationen";
import FlyFishingForm from "./FlyFishing/FlyFishingForm";
import FlyFishingStats from "./FlyFishing/FlyFishingStats";
import GaestebeitragKontakt from "./Gaestebeitrag/GaestebeitragKontakt";
import PublicVacationPage from "./BaseData/PublicVacation";
import DepartmentsPage from "./BaseData/Departments";
import DepartmentBookings from "./DepartmentBookings/DepartmentBookings";
import WorkingHours from "./DepartmentBookings/Workinghours";

export const MODULES: Module[] = [
	{
		name: "Gästebeitrag",
		icon: "im im-line-chart-up",
		basePath: "/gaestebeitrag",
		appTypes: ["vg"],
		tabs: [
			{
				name: "Monatsansicht",
				subPath: "/monat-gaestebeitrag",
				permissions: ["edit:AllGuestStats"],
				notPermissions: [],
				page: Monatsansicht,
			},
			{
				name: "Gästebeitrag erfassen",
				subPath: "/erfassung",
				permissions: ["edit:OwnGuestStats"],
				notPermissions: ["edit:AllGuestStats"],
				page: Gaestebeitragerfassung,
			},
			{
				name: "Statistik",
				subPath: "/stats",
				permissions: ["read:OwnGuestStats"],
				notPermissions: ["edit:AllGuestStats"],
				page: Gaestebeitragstatistik,
			},
			{
				name: "Betriebsinformationen",
				subPath: "/betrieb",
				permissions: ["edit:OwnGuestStats"],
				notPermissions: ["edit:AllGuestStats"],
				page: Betriebsinformationen,
			},
			{
				name: "Betriebsübersicht",
				subPath: "/betriebe",
				permissions: ["edit:AllGuestStats"],
				notPermissions: [],
				page: Betriebsinformationen,
			},
			{
				name: "Sonstiges",
				subPath: "/sonstiges",
				permissions: ["edit:OwnGuestStats"],
				notPermissions: [],
				page: GaestebeitragKontakt,
			},
		],
	},

	{
		name: "Fliegenfischen",
		icon: "im im-anchor",
		basePath: "/fly-fishing",
		appTypes: ["vg"],
		tabs: [
			{
				name: "Übersicht Fliegenfischen",
				subPath: "/stats",
				permissions: ["edit:FliegenfischenAdmin"],
				notPermissions: [],
				page: FlyFishingStats,
			},
			{
				name: "Formular: Fliegenfischen",
				subPath: "/form",
				permissions: ["edit:FliegenfischenUser"],
				notPermissions: [],
				page: FlyFishingForm,
			},
		],
	},

	{
		name: "Serverkonfiguration",
		icon: "im im-control-panel",
		basePath: "/server-config",
		appTypes: ["base"],
		tabs: [
			{
				name: "Applications",
				subPath: "/server-applications",
				permissions: ["superadmin"],
				notPermissions: [],
				page: Applications,
			},
			{
				name: "Table Headers",
				subPath: "/table-headers",
				permissions: ["edit:GeneralTableHeaders"],
				notPermissions: [],
				page: TableHeaders,
			},
		],
	},
	{
		name: "Usermangement",
		icon: "im im-users",
		basePath: "/user-management",
		appTypes: ["base"],
		tabs: [
			{
				name: "Benutzer",
				subPath: "/users",
				permissions: ["superadmin"],
				notPermissions: [],
				page: Users,
			},
			{
				name: "Berechtigungen",
				subPath: "/permissions",
				permissions: ["superadmin"],
				notPermissions: [],
				page: Permissions,
			},
			{ name: "Object Access", subPath: "/object-access", permissions: ["superadmin"], notPermissions: [], page: ObjectAccess },
		],
	},

	{
		name: "Corona - Datenerfassung",
		icon: "im im-users",
		basePath: "/corona-gastro",
		appTypes: ["gastro"],
		tabs: [
			{
				name: "Tischübersicht",
				subPath: "/corona-tables",
				permissions: ["edit:GastroCoronaTables"],
				notPermissions: [],
				page: Tischuebersicht,
			},
		],
	},
	{
		name: "Corona - Reservierung",
		icon: "im im-barcode",
		basePath: "/ticket-server",
		appTypes: ["vg"],
		tabs: [
			{
				name: "Scanner",
				subPath: "/scanner",
				permissions: ["edit:Ticket"],
				notPermissions: [],
				page: TicketScanner,
			},
			{
				name: "Besucherübersicht",
				subPath: "/data",
				permissions: ["read:Ticket"],
				notPermissions: [],
				page: TicketTable,
			},
			{
				name: "Öffnungszeiten",
				subPath: "/opening-times",
				permissions: ["edit:PoolAbsences"],
				notPermissions: [],
				page: PoolAbsences,
			},
		],
	},
	{
		name: "Fundbüro",
		icon: "im im-banknote",
		basePath: "/fundbuero",
		appTypes: ["vg"],
		tabs: [
			{
				name: "Fundsachen",
				subPath: "/fundsachen",
				permissions: ["edit:Fundsache"],
				notPermissions: [],
				page: Fundsachen,
			},
		],
	},
	{
		name: "Stammdaten",
		icon: "im im-database",
		basePath: "/base-data",
		appTypes: ["vg", "gastro"],
		tabs: [
			{
				name: "Gesetzliche Feiertage",
				subPath: "/public-vacations/:eventId?",
				linkTo: "/public-vacations",
				permissions: ["edit:BaseData"],
				notPermissions: [],
				page: PublicVacationPage,
			},
			{
				name: "Abteilungen und Services",
				subPath: "/departments/:departmentId?",
				linkTo: "/departments",
				permissions: ["edit:BaseData"],
				notPermissions: [],
				page: DepartmentsPage,
			},
		],
	},
	{
		name: "Abteilungsbuchungen",
		icon: "im im-date-o",
		basePath: "/vg-departments",
		appTypes: ["vg"],
		tabs: [
			{
				name: "Buchungen und Abwesenheiten",
				subPath: "/bookings/:eventId?/:responseType?",
				linkTo: "/bookings",
				permissions: ["edit:DepartmentBooking"],
				notPermissions: [],
				page: DepartmentBookings,
			},
			{
				name: "Arbeitszeiten",
				subPath: "/working-hours",
				permissions: ["edit:DepartmentBooking"],
				notPermissions: [],
				page: WorkingHours,
			},
		],
	},
];

const FILTER_MODULES = (permissions: string[], appType: string): Module[] => {
	const modules: Module[] = [];
	MODULES.filter((x) => x.appTypes?.includes(appType)).forEach((module) => {
		const tabs: Tab[] = [];
		module.tabs.forEach((tab) => {
			if (tab.permissions.every((x) => permissions.includes(x)) && !tab.notPermissions.find((x) => permissions.includes(x))) {
				tabs.push(tab);
			}
		});
		const m = { ...module };
		m.tabs = tabs;
		if (m.tabs.length > 0) modules.push(m);
	});
	return modules;
};

export const HAS_BASE_PERMISSIONS = (userPermissions: string[]) => {
	let baseAllowed = false;
	MODULES.filter((x) => x.appTypes?.includes("base")).forEach((module) => {
		module.tabs.forEach((tab) => {
			if (tab.permissions.every((x) => userPermissions.includes(x))) {
				baseAllowed = true;
			}
		});
	});
	return baseAllowed;
};
export default FILTER_MODULES;
