import React, { Component } from "react";
import { MediaRender } from "@onedash/tools";
import { GET_COLOR } from "../../../Resources/Colors";

interface ProfileDropdownProps {
	applications: Application[];
	onAppSelect: (appID: string) => void;
	selectedApp: Application | undefined;
}

class AppDropdown extends Component<ProfileDropdownProps> {
	state = {
		dropDown: false,
	};

	getShortName = (app?: Application) => {
		let appName = "XX";
		if (app) {
			const splitted = app.appName.split(" ");
			if (splitted.length > 1) {
				appName = splitted[0].charAt(0) + splitted[1].charAt(0);
			} else {
				appName = app.appName.charAt(0);
			}
		}
		return appName;
	};

	render() {
		const { applications, onAppSelect, selectedApp: app } = this.props;
		const shortAppName = this.getShortName(app);

		const appColor = GET_COLOR(applications.findIndex((x) => x.appId === app?.appId));
		return (
			<>
				<div className="dropdown app-dropdown">
					<button
						className={applications.length <= 1 ? "disabled" : ""}
						onClick={() => this.setState((s: any) => ({ dropDown: !s.dropDown }))}>
						<div className="app-switch">
							<MediaRender type="desktop">
								<div style={{ background: appColor }} className="icon">
									<span>{shortAppName}</span>
								</div>
							</MediaRender>
							<MediaRender type="mobile">
								<h2>{app?.appName}</h2>
							</MediaRender>
							{applications.length > 1 && (
								<div className={this.state.dropDown === true ? "app-switch-button show" : "app-switch-button"}>
									<i style={{ color: appColor }} className="im im-angle-down" />
								</div>
							)}
						</div>
					</button>
					<div className={this.state.dropDown === true ? "dropdown-content show" : "dropdown-content"}>
						<ul>
							{applications.map((a, i) => {
								const shortName = this.getShortName(a);
								const color = GET_COLOR(i);
								if (a === app) return;
								return (
									<li key={a.appId}>
										<button
											onClick={() => {
												onAppSelect(a.appId);
												this.setState({ dropDown: false });
											}}>
											<div style={{ background: color }} className="icon">
												<span>{shortName}</span>
											</div>
											<p>{a.appName}</p>
										</button>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</>
		);
	}
}

export default AppDropdown;
