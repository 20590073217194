import { Form, Input, Toggle } from "onedash-react-input-form";
import React from "react";
import MiniPage, { MiniPageProps } from "../MiniPage";

interface CompanyStats {
	company: {
		beds: number;
		cashAccount: number;
		sepa: boolean;
	};
}
interface CompanyStatsProps extends MiniPageProps {
	onDataChange: (information: CompanyStats) => void;
}

class CompanyStats extends MiniPage<CompanyStatsProps> {
	form = React.createRef<Form>();

	state = { valid: false };

	saveData = () => {
		const data: CompanyStats = this.form.current?.getData();
		this.props.onDataChange(data);
		this.nextStep();
	};

	render() {
		const { valid } = this.state;
		return (
			<div className="company-stats">
				<Form ref={this.form} onValidationUpdate={(v: boolean) => this.setState({ valid: v })}>
					<div className="inputs">
						<div>
							<h2>Bettenanzahl</h2>
							<p>
								Bitte geben Sie hier die Gesamtzahl der Betten (Schlafgelegenheiten) an, die zur Beherbergung von Gästen zur
								Verfügung stehen. Die Anzahl der Betten entspricht der Anzahl der Personen, die bei Normalbelegung
								gleichzeitig übernachten können. Betten ab 1,40 m Breite zählen als zwei Schlafgelegenheiten. Klappbetten
								und Schlafsofas, die regulär als Schlafgelegenheit angeboten werden, sind ebenfalls mitzuzählen. Nicht zu
								berücksichtigen sind behelfsmäßige Schlafgelegenheiten (z. B. Zustellbetten, Kinderbetten), bei deren
								Benutzung lediglich ein Aufschlag zum Übernachtungspreis berechnet wird.
							</p>

							<Input
								placeholder="Bettenanzahl"
								name="company.beds"
								required
								settings={{ allowNumberNull: false, allowNumberNegative: false, validateTel: false }}
								maxLength={4}
								type="tel"
								pattern="^$|^[0-9]+$"
							/>
						</div>
						<div className="split-h-50">
							<div>
								<h2>Kassenkonto</h2>
								<p>Wenn Sie das zuständige Kassenkonto kennen, dann geben Sie dieses bitte im Folgenden an.</p>
								<Input
									placeholder="Kassenkonto"
									name="company.cashAccount"
									settings={{ allowNumberNull: false, allowNumberNegative: false, validateTel: false }}
									maxLength={6}
									type="tel"
									pattern="^$|^[0-9]+$"
								/>
							</div>

							<div>
								<h2>SEPA - Lastschriftmandat</h2>
								<p>Wenn Sie bereits ein SEPA - Lastschriftmandat erteilt haben, aktivieren Sie bitte diese Einstellung</p>
								<Toggle name="company.sepa" />
							</div>
						</div>
					</div>
				</Form>
				<div>
					<div className="img" />
					<div>
						<button disabled={!valid} style={{ margin: "0px" }} className="btn" onClick={this.saveData}>
							Informationen speichern
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default CompanyStats;
