import dayjs from "dayjs";
import React from "react";
import Collapsible from "react-collapsible";
import CalendarUtils from "../../Utils/CalendarUtils";
import { CalendarEvent } from "./TimeCalendarTypes";

export const initialBookingState = {
	bookingStep: 0,
	horseId: undefined as undefined | number,
};
interface ViewDetailsProps<T extends {}> {
	event: CalendarEvent<T>;
	onDelete: () => void;
	onEdit: () => void;
	allowReccurentTimeslots?: boolean;
	allowTimerange?: boolean;
}

const ViewDetails = <T extends {}>({ event, onDelete, allowReccurentTimeslots, allowTimerange, onEdit }: ViewDetailsProps<T>) => {
	const timeslot = event?.timeslot;
	const from = dayjs(timeslot?.from);
	const to = dayjs(timeslot?.to);
	const fullDay = from.get("hour") + from.get("minute") + to.get("hour") + to.get("minute") === 0;
	const diff = to.diff(from, "day");

	return (
		<>
			<div className="event-details">
				<div className="btns">
					<button onClick={onDelete} className="trash-event">
						<i className="im im-trash-can" />
					</button>
					<button onClick={onEdit} className="toggle-edit-mode">
						<i className="im im-pencil" />
					</button>
				</div>

				<h3>
					{diff > 1 && fullDay ? (
						<>
							{from.format("DD.MM.YYYY")} - {to.subtract(1, "millisecond").format("DD.MM.YYYY")}
						</>
					) : (
						from.format("DD.MM.YYYY")
					)}
				</h3>
				<h2>
					{fullDay ? (
						"Ganztägig"
					) : (
						<>
							{from.format("HH:mm")} - {to.format("HH:mm")} Uhr
						</>
					)}
				</h2>
				<h1>{event.title}</h1>

				<div className="detail-content">
					<div className="timeslot-details">
						<Collapsible
							transitionTime={150}
							open
							trigger={
								<h4>
									<span>Datum</span>
									<i className="im im-angle-down" />
								</h4>
							}>
							<div className="list">
								<div className="item">
									{diff === 1 && (
										<>
											<p className="label">Tag</p>
											<p className="value">{from.format("DD.MM.YYYY")}</p>
										</>
									)}
									{diff > 1 && (
										<>
											<p className="label">Zeitraum</p>
											<p className="value">
												{from.format("DD.MM.YYYY")} - {to.subtract(1, "millisecond").format("DD.MM.YYYY")}
											</p>
										</>
									)}
								</div>
								{allowTimerange && (
									<div className="item">
										<p className="label">Uhrzeit</p>
										<p className="value">
											{from.format("HH:mm")} - {to.format("HH:mm")} Uhr
										</p>
									</div>
								)}

								{allowReccurentTimeslots && (
									<div className="item">
										<p className="label">Wiederholend</p>
										<p className="value">{event.timeslot.periodId ? "✅ Ja" : "❌ Nein"}</p>
									</div>
								)}

								{event.timeslot.periodId && (
									<>
										<div className="item">
											<p className="label">Regel</p>
											<p className="value">{CalendarUtils.periodRuleToText(event.timeslot.periodRule)}</p>
										</div>
									</>
								)}
							</div>
						</Collapsible>
					</div>
				</div>
			</div>
		</>
	);
};

export default ViewDetails;
