import React from "react";
import OneDashLogo from "../../../Components/Logo/OneDashLogo";
import BrowserHistory from "../../../Utils/BrowserHistory";
import MiniPage, { MiniPageProps } from "../MiniPage";

interface GaestebeitragLandingPageProps extends MiniPageProps {
	appData?: ApplicationData;
}

class GaestebeitragLandingPage extends MiniPage<GaestebeitragLandingPageProps> {
	render() {
		const { appData } = this.props;
		return (
			<div className="gaestebeitrag-landing-page ">
				<div className="left" />
				<div className="right">
					<OneDashLogo />
					<div className="split-h-50" style={{ marginTop: "20px" }}>
						<div className="login">
							<h2>Login</h2>
							<h3>Sie haben bereits ein Account? Dann melden Sie sich hier an</h3>

							<button className="btn" onClick={() => BrowserHistory.push("/login")}>
								Zum Login
							</button>
						</div>
						<div className="register">
							<h2>Registrieren</h2>
							<h3>Registieren Sie Ihren Betrieb noch heute, um Ihre Gästebeitragserklärungen zu erfassen</h3>

							<button className="btn" onClick={() => this.nextStep()}>
								Betrieb registrieren
							</button>
						</div>
					</div>
				</div>

				<img src={appData?.logo.url} className="vg-logo" alt="Logo der Verbandsgemeinde" />
			</div>
		);
	}
}

export default GaestebeitragLandingPage;
