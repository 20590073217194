import Backend from "./Backend";

export const GQLAppContext = (additional?: any) => ({
	context: {
		headers: {
			"app-id": Backend.getAppID(),
		},
	},
	...additional,
});
