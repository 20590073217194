import * as React from "react";
import { Spinner } from "@onedash/tools";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button } from "onedash-react-input-form";
import Backend from "../../../Utils/Backend/Backend";
import BrowserHistory from "../../../Utils/BrowserHistory";
import Notify from "../../../Utils/Notify";
import { COLORS } from "../../../Resources/Colors";
import Customization from "../../../Utils/Customization";
import AppUtils from "../../../Utils/AppUtils";
import { useAuth } from "../../../Auth/AuthContext";
import HasPermission from "../../../Auth/HasPermission";

export interface ImpressProps {}

const ProfilePage = () => {
	const { user, getAccessToken } = useAuth();
	const [highlightColor, updateHighlightColor] = React.useState(undefined as undefined | string);
	const [accessToken, updateAccessToken] = React.useState("");

	const loadColor = async () => {
		const hc = await Customization.getUserPreference("highlightColor");
		updateHighlightColor(hc);
	};
	const selectHighlightColor = (hc: string) => {
		Customization.saveUserPreference("highlightColor", hc);
		AppUtils.setHighlightColor(hc);
		updateHighlightColor(hc);
	};

	const setPWReset = () => {
		Backend.post("/base/password", undefined, false)
			.then(() => {
				BrowserHistory.push("/login");
			})
			.catch((ex) => {
				// eslint-disable-next-line no-console
				console.error(ex);
				Notify.e("Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut");
			});
	};

	React.useEffect(() => {
		loadColor();
		getAccessToken().then(updateAccessToken);
	}, [getAccessToken]);

	const p = user;
	return (
		<div className="profile">
			{!p && <Spinner defaultVisible />}
			{p && (
				<>
					<h1>Hallo, {p.given_name ?? p.name}!</h1>
					<div>
						<div className="split">
							<div>
								<div>
									<p className="title">Accountname</p>
									<p className="value">{p.name}</p>
								</div>
								{(p.given_name || p.family_name) && (
									<div>
										<p className="title">Name</p>
										<p className="value">
											{p.given_name} {p.family_name}
										</p>
									</div>
								)}
								<div>
									<p className="title">Email - Adresse</p>
									<p className="value">
										<a href={`mailto:${p.email}`}>{p.email}</a> (<Button className="link">Ändern</Button>)
									</p>
								</div>
							</div>
							<div className="profile-image">
								<img src={p.picture} alt="Ihr Profilbild" />
							</div>
						</div>
					</div>
					<div>
						<div>
							<p className="title">Akzentfarbe</p>
							<div className="colors value">
								{COLORS.map((color) => (
									<button key={color} onClick={() => selectHighlightColor(color)}>
										<span className={highlightColor === color ? "selected" : ""} style={{ backgroundColor: color }} />
									</button>
								))}
							</div>
						</div>
						<div>
							<p className="title">Passwort ändern</p>
							<p className="value">
								<Button className="link" onClick={setPWReset}>
									Klicken Sie hier um Ihr Passwort zu ändern
								</Button>
							</p>
						</div>
						<HasPermission permission="read:AccessToken">
							<div>
								<p className="title">Zugriffstoken</p>
								<p className="value">
									<CopyToClipboard text={accessToken}>
										<Button className="link">Kopieren Sie Ihren Zugriffstoken in die Zwischenablage</Button>
									</CopyToClipboard>
								</p>
							</div>
						</HasPermission>
					</div>
				</>
			)}
		</div>
	);
};

export default ProfilePage;
