import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { setLocaleMessages as setCalendarLocaleMessages, CalendarLocales } from "onedash-react-calendar";
import { setLocaleMessages as setFormLocaleMessages, FormLocales } from "onedash-react-input-form";
import { PromptProvider, setLocaleMessages as setDialogLocaleMessages, DialogLocales, Dialog } from "onedash-dialog";
import dayjs from "dayjs";
import dayjsRelativeTime from "dayjs/plugin/relativeTime";
import GermanDate from "dayjs/locale/de";
import BrowserHistory from "./Utils/BrowserHistory";
import App from "./Pages/App/App";
import "./Styles/default.scss";
import "./Styles/input.sass";
import "./Styles/table.sass";
import "./Styles/calendar.sass";
import "./Styles/dialog.sass";
import "./Styles/dropdown.scss";
import "react-tabs/style/react-tabs.css";
import { initPrototype } from "./Utils/Prototype";
import AuthProvider from "./Auth/AuthProvider";
import AuthorizedApolloProvider from "./Utils/Backend/AuthorizedApolloProvider";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const Index = () => {
	const [showReload, setShowReload] = React.useState(false);
	const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

	const reloadPage = () => {
		waitingWorker?.postMessage({ type: "SKIP_WAITING" });
		setShowReload(false);
		window.location.reload(true);
	};

	// eslint-disable-next-line react-hooks/rules-of-hooks
	React.useEffect(() => {
		initPrototype();

		// Init date locale
		dayjs.locale(GermanDate);

		// Allow e.g. 2 days ago ...
		dayjs.extend(dayjsRelativeTime);

		// Set Tool locale to German
		setFormLocaleMessages(FormLocales.DE);

		// Set dialog locale to German
		setDialogLocaleMessages(DialogLocales.DE);

		setCalendarLocaleMessages(CalendarLocales.DE);

		const onSWUpdate = (registration: ServiceWorkerRegistration) => {
			setShowReload(true);
			setWaitingWorker(registration.waiting);
		};
		const applyHeightHelper = () => {
			document.documentElement.style.setProperty("--wh", `${window.innerHeight / 100}px`);
		};
		applyHeightHelper();
		document.addEventListener("resize", applyHeightHelper);
		setInterval(applyHeightHelper, 500);

		window.addEventListener("focus", () => {
			// Check for service worker updates => Show dialog if update exists
			navigator.serviceWorker.ready.then((registration) => registration.update());
		});

		reportWebVitals();
		serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
	}, []);

	return (
		<Router history={BrowserHistory}>
			<AuthProvider>
				<AuthorizedApolloProvider>
					<PromptProvider>
						<App />
						<Dialog isOpen={showReload} disableEsc disableWrapperClick title="Update verfügbar">
							<p>
								Es ist ein Update für die Anwendung verfügbar. Bitte schließen Sie alle Tabs und klicken Sie auf den Button
								&quot;Update Installieren&quot;.
							</p>
							<button className="btn highlight-btn" onClick={reloadPage}>
								Update installieren
							</button>
						</Dialog>
					</PromptProvider>
				</AuthorizedApolloProvider>
			</AuthProvider>
		</Router>
	);
};

ReactDOM.render(<Index />, document.getElementById("root"));
