import React, { Component } from "react";
import { Session } from "../../Utils/Session";

export type MiniPageTypes =
	| "company-personal-entry"
	| "gaestebeitrag-landing-page"
	| "password-change"
	| "theme-change"
	| "email-validation";
export interface MiniPageProps {
	onSubmit?: (data: any) => void;
	label?: string;
	hideHeader?: boolean;
	_active?: boolean;
	_onNextPage?: (stepSize: number) => void;
}

class MiniPage<PageProps extends MiniPageProps> extends Component<PageProps> {
	protected nextStep = (stepSize = 1) => {
		const step = Session.getObject("gaestebeitrag-register", "currentStep") ?? 0;
		Session.setObject("gaestebeitrag-register", "currentStep", step + stepSize);
		// eslint-disable-next-line no-underscore-dangle
		this.props._onNextPage?.(stepSize);
	};

	render() {
		return <></>;
	}
}

export default MiniPage;
