import React from "react";
import dayjs from "dayjs";
import { Input } from "onedash-react-input-form";
import TimeCalendar from "../../Components/TimeCalendar/TimeCalendar";
import {
	PublicVacation,
	usePublicVacationQuery,
	useCreatePublicVacationMutation,
	PublicVacationDocument,
	useDeletePublicVacationMutation,
	useUpdatePublicVacationMutation,
} from "../../Resources/generated/gql-types";
import { CalendarEvent, CreationEvent } from "../../Components/TimeCalendar/TimeCalendarTypes";
import Backend from "../../Utils/Backend/Backend";

const PublicVacationPage = () => {
	const [currentVacationId, setVacationId] = React.useState(undefined as string | undefined);
	const [calendarDate, updateCalendarDate] = React.useState(dayjs().startOf("week").timestamp());
	const queryVars = { from: dayjs(calendarDate).format(), to: dayjs(calendarDate).add(2, "week").format() };

	const [createPublicVacationMutation] = useCreatePublicVacationMutation({
		context: {
			headers: {
				"app-id": Backend.getAppID(),
			},
		},
		update: (c, r) => {
			const created = r.data?.createPublicVacation;
			const d = c.readQuery<{ publicVacations: PublicVacation[] }>({
				query: PublicVacationDocument,
				variables: queryVars,
			});
			c.writeQuery({
				query: PublicVacationDocument,
				variables: queryVars,
				data: { publicVacations: [...(d?.publicVacations ?? []), created] },
			});
		},
	});
	const [updatePublicVacationMutation] = useUpdatePublicVacationMutation({
		context: {
			headers: {
				"app-id": Backend.getAppID(),
			},
		},
	});
	const [deletePublicVacationMutation] = useDeletePublicVacationMutation({
		context: {
			headers: {
				"app-id": Backend.getAppID(),
			},
		},
	});

	const { data } = usePublicVacationQuery({
		variables: queryVars,
		context: {
			headers: {
				"app-id": Backend.getAppID(),
			},
		},
	});

	const onCreate = async (publicVacation: CreationEvent<PublicVacation>) => {
		publicVacation.name = publicVacation.title ?? "";
		const a = await createPublicVacationMutation({
			variables: {
				vacation: {
					from: publicVacation.timeslot.from,
					to: publicVacation.timeslot.to,
					name: publicVacation.name,
				},
			},
		});
		return a.data?.createPublicVacation.id;
	};
	const onUpdate = async (id: string, publicVacation: CreationEvent<PublicVacation>) => {
		publicVacation.name = publicVacation.title ?? "";

		updatePublicVacationMutation({
			variables: {
				id,
				vacation: {
					from: publicVacation.timeslot.from,
					to: publicVacation.timeslot.to,
					name: publicVacation.name,
				},
			},
		});
	};
	const onDelete = async (id: string) => {
		await deletePublicVacationMutation({
			variables: { id },
			update: (c) => {
				const d = c.readQuery<{ publicVacations: PublicVacation[] }>({
					query: PublicVacationDocument,
					variables: queryVars,
				});
				const filtered = (d?.publicVacations ?? []).filter((x) => x.id !== id);

				c.writeQuery({
					query: PublicVacationDocument,
					variables: queryVars,
					data: { publicVacations: [...filtered] },
				});
			},
		});
	};
	const publicVacations: CalendarEvent<PublicVacation>[] = (data?.publicVacations ?? []).map((x) => ({ ...x, title: x.name }));
	const currentEvent = publicVacations.find((x) => x.id === currentVacationId);

	return (
		<div className="public-vacation">
			<TimeCalendar<PublicVacation>
				calendarDate={calendarDate}
				newEventText="Neuer Feiertag"
				onCalendarDateChange={updateCalendarDate}
				events={publicVacations}
				currentEvent={currentEvent}
				onLoadCurrentEvent={setVacationId}
				subRoute="public-vacations"
				onCreateEvent={onCreate}
				onUpdateEvent={onUpdate}
				onDeleteEvent={onDelete}
				eventName="Feiertag"
				allowMultiDay
				editEventComponent={
					<div className="section">
						<Input
							value={currentEvent?.name}
							placeholder="Geben Sie den Namen des Feiertages ein"
							name="title"
							label="Name des Feiertages"
							required
						/>
					</div>
				}
			/>
		</div>
	);
};

export default PublicVacationPage;
