import { createContext, useContext } from "react";

export interface AuthState {
	error?: Error;
	isAuthenticated: boolean;
	isLoading: boolean;
	user?: any;
	permissions: string[];
	id?: string;
}
export const initialAuthState: AuthState = {
	isAuthenticated: false,
	isLoading: true,
	permissions: [],
	id: undefined,
};

export interface AuthContextInterface extends AuthState {
	login: () => Promise<void>;
	logout: () => void;
	getAccessToken: () => Promise<string>;
}

/**
 * @ignore
 */
const stub = (): never => {
	throw new Error("You forgot to wrap your component in <Auth0Provider>.");
};

/**
 * @ignore
 */
const initialContext = {
	...initialAuthState,
	login: stub,
	logout: stub,
	getAccessToken: stub,
};

/**
 * The Auth0 Context
 */
const AuthContext = createContext<AuthContextInterface>(initialContext);

export default AuthContext;

export const useAuth = (): AuthContextInterface => useContext(AuthContext);
